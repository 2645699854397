// v2.0
// src/plugins/vuetify.js

import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import ja from '../i18n/ja';

Vue.use(Vuetify);

const theme = {
  primary: '#1D75BE',
  secondary: '#7EA3C1',
  accent: '#0A375D',
  error: '#FB5721',
  success: '#4CAF50',
  warning: '#FB9824'
};

export default new Vuetify({
  icons: {
    iconfont: 'md'
  },
  lang: {
    locales: { ja },
    current: 'ja'
  },
  theme: {
    themes: {
      dark: theme,
      light: theme
    }
  }
});
