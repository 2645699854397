<template>
  <v-layout wrap>
    <v-flex xs12>
      <v-skeleton-loader
        :loading="!$store.state.manifest.stateText"
        type="table"
      >
        <v-card>
          <v-card-title v-if="isTomUser" class="caption">
            {{ title }}
          </v-card-title>
          <v-card-text class="text-no-wrap">
            <div class="title text--primary font-weight-bold">詳細情報</div>
            <v-simple-table>
              <tbody>
                <tr
                  v-for="field in MANIFEST_FIELDS"
                  :key="'fields-' + field.key"
                >
                  <th>{{ field.name }}</th>
                  <td>
                    {{
                      field.filter
                        ? field.filter($store.state.manifest[field.key])
                        : $store.state.manifest[field.key]
                    }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
          <v-card-actions></v-card-actions>
        </v-card>
      </v-skeleton-loader>
    </v-flex>
    <v-flex xs12>
      <v-card class="mt-4">
        <v-card-title class="title text--primary font-weight-bold">
          内容一覧
        </v-card-title>

        <v-simple-table>
          <tbody>
            <tr>
              <th v-for="header in ITEMS_HEADER" :key="'header-' + header">
                {{ header }}
              </th>
            </tr>
            <tr
              v-for="manifestItem in $store.state.manifest.manifestItems"
              :key="'item-' + manifestItem._id"
            >
              <td>
                <v-btn
                  small
                  text
                  :to="`/items/${manifestItem.item._id}`"
                  :title="manifestItem.item._id"
                >
                  <v-icon small left>open_in_new</v-icon>
                  {{ manifestItem.item.barcode }}
                </v-btn>
              </td>
              <td>{{ manifestItem.item.ownerItemCode }}</td>
              <td>{{ manifestItem.item.names.jaJp }}</td>
              <td>{{ manifestItemStatusText(manifestItem) }}</td>
              <td>{{ manifestItem.qty.waiting }}</td>
              <td>{{ manifestItem.qty.stocked }}</td>
              <td>{{ manifestItem.qty.canceled }}</td>
              <td>{{ manifestItem.qty.damaged }}</td>
              <td>{{ manifestItem.qty.label }}</td>
              <td>{{ formatDate({ date: manifestItem.updatedAt }) }}</td>
              <!--キャンセルが付く予定だが，現時点では未実装のため空-->
              <td></td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>
    </v-flex>
    <v-flex xs12>
      <v-btn class="mt-4" color="primary accent-4" text to="/manifests">
        一覧に戻る
      </v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
import { FETCH_MANIFEST_DETAIL } from '@/store/action-types';
import { formatDate, formatEditor } from '@/util/formatter';

export default {
  data() {
    const manifestId = this.$route.params.manifestId;
    return {
      title: `manifests: ${manifestId}`
    };
  },
  computed: {
    ITEMS_HEADER() {
      return [
        'バーコード',
        '荷主管理コード',
        '商品名',
        'ステータス',
        '未入庫数',
        '入庫数',
        'キャンセル数',
        '不良品数',
        'バーコード貼付数',
        '最終更新日',
        'アクション'
      ];
    },
    MANIFEST_FIELDS() {
      return [
        {
          name: '入庫依頼番号',
          key: 'ownerManifestNo'
        },
        {
          name: 'ステータス',
          key: 'stateText'
        },
        {
          name: '登録日',
          key: 'createdAt',
          filter: (d) => this.formatDate({ date: d, format: 'YYYY/MM/DD' })
        },
        {
          name: '着荷予定日',
          key: 'deliverySchedule',
          filter: (d) => this.formatDate({ date: d, format: 'YYYY/MM/DD' })
        },
        {
          name: '更新者',
          key: 'createdBy',
          filter: (c) => this.formatEditor({ editor: c })
        },
        {
          name: 'トラッキングコード',
          key: 'trackingCode'
        },
        {
          name: '運送会社',
          key: 'carrier'
        }
      ];
    },
    isTomUser: (self) => self.$store.state.user.isTomUser
  },
  async mounted() {
    try {
      await this.$store.dispatch(`manifest/${FETCH_MANIFEST_DETAIL}`, {
        manifestId: this.$route.params.manifestId
      });
    } catch (error) {
      await this.$store.dispatch(
        'notify/showErrorNotify',
        'エラーが発生しました、URLが正しいか確認して再試行してください。'
      );
      throw error;
    }
  },
  methods: {
    manifestItemStatusText(manifestItem) {
      if (manifestItem.qty.total === manifestItem.qty.canceled) {
        return 'キャンセル';
      } else if (manifestItem.qty.waiting === 0) {
        return '入庫完了';
      } else if (manifestItem.qty.waiting > 0) {
        return '入庫待ち';
      } else {
        return '未定義の状態';
      }
    },
    formatDate,
    formatEditor
  }
};
</script>
