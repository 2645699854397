import querystring from 'querystring';
import _ from 'lodash';

import {
  BUNDLES_FETCHED,
  ITEM_BUNDLE_CREATED,
  ITEM_BUNDLE_DELETED,
  ITEM_BUNDLE_DELETE_FAILED
} from '../mutation-types';
import {
  FETCH_BUNDLES,
  CREATE_ITEM_BUNDLE,
  DELETE_ITEM_BUNDLE
} from '../action-types';
import axios from '../../api/common/axios';
import { JsonError } from '@/util/error';

export default {
  namespaced: true,
  state: {
    headers: [
      'バーコード / セット名',
      'セット管理番号',
      'セットに含まれる商品'
    ],
    bundles: [],
    sortLabels: [
      { text: '登録日 (古い順)', value: 'created_at:asc' },
      { text: '登録日 (新しい順)', value: 'created_at:desc' },
      { text: 'セット管理番号 (A-Z)', value: 'owner_item_code:asc' },
      { text: 'セット管理番号 (Z-A)', value: 'owner_item_code:desc' },
      { text: 'セット名 (A-Z)', value: 'name:asc' },
      { text: 'セット名 (Z-A)', value: 'name:desc' }
    ],
    total: 0
  },
  mutations: {
    [BUNDLES_FETCHED](state, { bundles, total }) {
      state.bundles = bundles;
      state.total = total;
    },
    [ITEM_BUNDLE_CREATED]() {},
    [ITEM_BUNDLE_DELETED]() {},
    [ITEM_BUNDLE_DELETE_FAILED]() {}
  },
  actions: {
    async [FETCH_BUNDLES]({ commit }, query) {
      const errorMsg =
        'データ取得時にエラーがありました。セカイロジ担当者までお問い合わせください。';
      const response = await axios
        .get(`/bundles?${querystring.stringify(query)}`)
        .catch((e) => {
          throw new Error(_.get(e, 'response.data.errors.general', errorMsg));
        });

      if (_.get(response, 'data.status') === 'ng') {
        throw new Error(_.get(response, 'data.errors.general', errorMsg));
      }

      const bundles = response.data.bundles || [];

      commit(BUNDLES_FETCHED, {
        bundles: bundles.map((bundle) => {
          return {
            ...bundle,
            // セットが作れる在庫数は，セットに何個の商品が含まれるかで商品の在庫数を割って
            // それの一番少なく作れる数がセットの作れる数になる
            // 同じ商品が含まれるセットを複数選べばその分ズレるが，細かいvalidationはサーバ側にやらせる
            ...{
              stock: _.min(
                bundle.items.map((item) =>
                  Math.floor(item.item.stock / item.qty)
                )
              )
            }
          };
        }),
        total: response.data.total
      });
    },
    async [CREATE_ITEM_BUNDLE]({ commit }, params) {
      await axios.post('/bundles', params).catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          throw new JsonError(error.response.data.errors);
        } else {
          throw new Error(
            _.get(
              error,
              'message',
              'エラーが発生しました、画面を更新してやり直してください'
            )
          );
        }
      });
      commit(ITEM_BUNDLE_CREATED);
    },
    async [DELETE_ITEM_BUNDLE]({ commit }, { _id }) {
      const response = await axios.delete(`/bundles/${_id}`).catch((err) => {
        throw err;
      });
      commit(ITEM_BUNDLE_DELETED);
      return response;
    }
  }
};
