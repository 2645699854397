<template>
  <v-container px-0>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-layout align-center justify-center>
              <v-flex md6 xs12 text-center>
                <v-form @submit.prevent>
                  <v-text-field
                    class="mb-4"
                    v-model="email"
                    name="email"
                    v-validate="'required|email'"
                    data-vv-validate-on="blur"
                    data-vv-as="メールアドレス"
                    :error-messages="errors.collect('email')"
                    label="メールアドレスを入力する"
                    required
                  ></v-text-field>
                  <v-text-field
                    class="mb-4"
                    v-model="creditLimit"
                    name="creditLimit"
                    v-validate="'required|numeric'"
                    data-vv-validate-on="blur"
                    data-vv-as="与信金額"
                    :error-messages="errors.collect('creditLimit')"
                    label="与信金額を入力する"
                    required
                  ></v-text-field>
                  <v-btn text color="primary" @click="onAddUser">
                    荷主を追加する
                  </v-btn>
                </v-form>
              </v-flex></v-layout
            ></v-card-text
          >
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { START_LOADING, FINISH_LOADING } from '@/store/action-types';
import axios from '@/api/common/axios';

export default {
  components: {},
  $_veeValidate: {
    validator: 'new'
  },
  data: function () {
    const initialData = {
      email: '',
      creditLimit: 0
    };

    return initialData;
  },
  methods: {
    async onAddUser() {
      this.$validator.errors.clear();
      await this.$store.dispatch('notify/clearNotify');
      if (await this.$validator.validate()) {
        await this.$store.dispatch(`app/${START_LOADING}`, new Date());

        try {
          await axios.post('/account_registrations/create', {
            email: this.email,
            creditLimit: this.creditLimit
          });
          this.$router.push('/owners/registrations');
        } catch (e) {
          const message =
            (e.response &&
              e.response.data &&
              e.response.data.errors &&
              e.response.data.errors.general) ||
            'エラーが発生しました。';
          await this.$store.dispatch('notify/showErrorNotify', message);
        }

        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
      }
    }
  }
};
</script>
