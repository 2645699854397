<template>
  <div>
    <MainHeader title="商品更新履歴" />

    <v-scroll-x-reverse-transition mode="out-in">
      <div>
        <v-card>
          <v-card-text>
            <v-layout>
              <v-row>
                <v-col v-if="$store.state.user.isTomUser" sm="2">
                  <v-autocomplete
                    v-if="$store.state.user.isTomUser"
                    v-model="owner"
                    :items="owners"
                    item-value="id"
                    item-text="name"
                    label="荷主"
                    class="mr-2 flex-grow-0"
                    outlined
                    hide-details
                  ></v-autocomplete>
                </v-col>
                <v-col :sm="`${$store.state.user.isTomUser ? 2 : 4}`">
                  <v-text-field
                    label="バーコード / 商品管理コード / 商品名で絞り込む"
                    v-model="keywords"
                    prepend-inner-icon="search"
                    outlined
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col sm="2">
                  <v-menu
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="startOfEventOccurredAt"
                        label="から"
                        prepend-icon="event"
                        append-icon="cancel"
                        outlined
                        max-width="290px"
                        hide-details
                        @click:append="deleteDate('startOfEventOccurredAt')"
                        readonly
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="startOfEventOccurredAt"
                      no-title
                      scrollable
                      :day-format="(date) => new Date(date).getDate()"
                    />
                  </v-menu>
                </v-col>
                <v-col sm="2">
                  <v-menu
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="endOfEventOccurredAt"
                        label="まで"
                        prepend-icon="event"
                        append-icon="cancel"
                        outlined
                        hide-details
                        @click:append="deleteDate('endOfEventOccurredAt')"
                        readonly
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="endOfEventOccurredAt"
                      no-title
                      scrollable
                      :day-format="(date) => new Date(date).getDate()"
                    />
                  </v-menu>
                </v-col>
                <v-col sm="2">
                  <template>
                    <v-select
                      v-model="categories"
                      :items="categoryLabel"
                      label="更新内容"
                      multiple
                      class="ml-2 flex-grow-0"
                      style="max-width: 260px"
                      outlined
                      hide-details
                    >
                      <template v-slot:prepend-item>
                        <v-list-item
                          ripple
                          @mousedown.prevent
                          @click="onClickClearAllChecks"
                        >
                          <v-list-item-action> </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title v-if="isSelectedAllCategories"
                              >全選択解除</v-list-item-title
                            >
                            <v-list-item-title v-else>
                              全選択
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                      <template v-slot:selection="{ index }">
                        <v-chip v-if="index === 0">
                          <span>{{ categories.length }}件選択</span>
                        </v-chip>
                      </template>
                    </v-select>
                  </template>
                </v-col>
                <v-col sm="2">
                  <v-btn
                    color="primary"
                    height="100%"
                    outlined
                    tile
                    @click.stop="onClickCsvDownload"
                  >
                    CSVダウンロード
                  </v-btn>
                </v-col>
              </v-row>
            </v-layout>
          </v-card-text>
          <v-card-subtitle class="font-weight-light caption text-right">
            ※ 括弧内の表示は数の増減を示します
          </v-card-subtitle>
          <TableAndPaging
            ref="search"
            :headers="headers"
            :items="$store.state.itemlogs.logs"
            :total="$store.state.itemlogs.total"
            :query-params="queryParams"
            :action="itemLogsAction"
          >
            <template #row="props">
              <td v-if="$store.state.user.isTomUser">
                {{ props.item.ownerNames }}
              </td>
              <td>
                {{
                  formatDate({
                    date: props.item.eventOccurredAt,
                    format: 'YYYY/MM/DD HH:mm'
                  })
                }}
              </td>
              <td v-if="props.item.shipment">
                <v-btn
                  small
                  text
                  :to="`/shipment/${props.item.shipment}`"
                  :title="props.item.shipment"
                >
                  <v-icon small left>open_in_new</v-icon>
                  出荷詳細
                </v-btn>
              </td>
              <td v-else-if="props.item.manifest">
                <v-btn
                  small
                  text
                  :to="`/manifests/${props.item.manifest}`"
                  :title="props.item.manifest"
                >
                  <v-icon small left>open_in_new</v-icon>
                  入庫詳細
                </v-btn>
              </td>
              <td v-else-if="props.item.stocktakeConfirmation">
                <v-btn
                  small
                  text
                  :to="`/stocktake/${props.item.stocktakeConfirmation}`"
                  rel="noopener noreferrer"
                >
                  <v-icon small left>open_in_new</v-icon>
                  在庫差分詳細
                </v-btn>
              </td>
              <td v-else-if="props.item.ownerOrderNo">
                <v-btn
                  small
                  text
                  :to="`/returns/?filter_owner_order_no=${props.item.ownerOrderNo}`"
                  :title="props.item.ownerOrderNo"
                >
                  <v-icon small left>open_in_new</v-icon>
                  返送詳細
                </v-btn>
              </td>
              <td v-else-if="props.item.shipmentRequest">
                <v-btn
                  small
                  text
                  :to="`/shipments/${props.item.shipmentRequest}`"
                  :title="props.item.shipmentRequest"
                >
                  <v-icon small left>open_in_new</v-icon>
                  出荷依頼詳細
                </v-btn>
              </td>
              <td v-else>-</td>
              <td>{{ props.item.item.barcode }}</td>
              <td>
                <router-link
                  :to="`/items/${props.item.item._id}`"
                  class="link-row--main"
                  >{{ getItemTitle(props.item.item) }}</router-link
                >
              </td>
              <td>
                <v-chip label outlined>{{ props.item.category }}</v-chip>
                <div class="mt-2">
                  {{ formatEditor({ editor: props.item.editor }) }}
                </div>
              </td>
              <td>
                {{
                  props.item.afterQty +
                  props.item.afterReservedQty +
                  props.item.afterConfirmedQty +
                  props.item.afterReturnedQty
                }}
                <span
                  :class="
                    selectDiffClass(
                      props.item.diffQty +
                        props.item.diffReservedQty +
                        props.item.diffConfirmedQty +
                        props.item.diffReturnedQty
                    )
                  "
                >
                  ({{
                    props.item.diffQty +
                    props.item.diffReservedQty +
                    props.item.diffConfirmedQty +
                    props.item.diffReturnedQty
                  }})
                </span>
              </td>
              <td>
                {{ props.item.afterQty }}
                <span :class="selectDiffClass(props.item.diffQty)">
                  ({{ props.item.diffQty }})
                </span>
              </td>
              <td>
                {{ props.item.afterReservedQty + props.item.afterConfirmedQty }}
                <span
                  :class="
                    selectDiffClass(
                      props.item.diffReservedQty + props.item.diffConfirmedQty
                    )
                  "
                >
                  ({{
                    props.item.diffReservedQty + props.item.diffConfirmedQty
                  }})
                </span>
              </td>
              <td>
                {{ props.item.afterReturnedQty }}
                <span :class="selectDiffClass(props.item.diffReturnedQty)">
                  ({{ props.item.diffReturnedQty }})
                </span>
              </td>
            </template>
          </TableAndPaging>
        </v-card>
      </div>
    </v-scroll-x-reverse-transition>
  </div>
</template>

<script>
import _ from 'lodash';
import MainHeader from '@/components/layout/MainHeader';
import TableAndPaging from '@/components/TableAndPaging';
import {
  FETCH_ITEM_LOGS,
  FETCH_OWNERS_ALL,
  START_LOADING,
  FINISH_LOADING
} from '@/store/action-types';
import { formatDate, formatEditor } from '@/util/formatter';
import axios from '../api/common/axios';
import {
  CATEGORY_TEXT,
  CATEGORY_PATTERN
} from '../../lib/document/schema/item_event_logs';
import querystring from 'querystring';

export default {
  components: {
    MainHeader,
    TableAndPaging
  },
  data() {
    const query = this.$route.query;
    let categories = CATEGORY_PATTERN;
    if (Object.keys(query).length) {
      categories = Array.isArray(query.filter_categories)
        ? query.filter_categories
        : [query.filter_categories].filter((v) => v); // 全て未選択の時undefined除去
    }
    return {
      owner: this.$store.state.user.isTomUser ? query.filter_owner : '',
      keywords: query.filter_keywords || '',
      startOfEventOccurredAt: query.filter_start_of_event_occurred_at || '',
      endOfEventOccurredAt: query.filter_end_of_event_occurred_at || '',
      categories: categories
    };
  },
  computed: {
    headers: function () {
      return this.$store.state.user.isTomUser
        ? [
            '荷主',
            '更新日時',
            '関連ページ',
            'バーコード',
            '商品名',
            '更新内容',
            '総在庫数',
            '引当可能在庫数',
            '引当済在庫数',
            '返送在庫数'
          ]
        : [
            '更新日時',
            '関連ページ',
            'バーコード',
            '商品名',
            '更新内容',
            '総在庫数',
            '引当可能在庫数',
            '引当済在庫数',
            '返送在庫数'
          ];
    },
    categoryLabel: () => {
      const categoryLabel = [];
      for (const item in CATEGORY_TEXT) {
        categoryLabel.push({ text: CATEGORY_TEXT[item], value: item });
      }
      return categoryLabel;
    },
    owners: function () {
      return [{ id: '', name: '全て' }].concat(
        Object.keys(this.$store.state.owners.owners).map((index) => {
          return {
            id: this.$store.state.owners.owners[index]._id,
            name: this.$store.state.owners.owners[index].names.ja_jp
          };
        })
      );
    },
    getItemTitle() {
      return (item) => {
        if (item.names.ja_jp) {
          return item.names.ja_jp;
        }
        return item.names.en_us;
      };
    },
    queryParams: function () {
      return {
        filter_owner: this.owner,
        filter_keywords: this.keywords,
        filter_start_of_event_occurred_at: this.startOfEventOccurredAt || '',
        filter_end_of_event_occurred_at: this.endOfEventOccurredAt || '',
        filter_categories: this.categories
      };
    },
    itemLogsAction() {
      return `itemlogs/${FETCH_ITEM_LOGS}`;
    },
    isSelectedAllCategories: function () {
      return this.categories.length === this.categoryLabel.length;
    }
  },
  methods: {
    deleteDate: function (target) {
      this[target] = '';
    },
    async onClickCsvDownload() {
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      try {
        const errorMsg =
          'データ取得時にエラーがありました。サポートへご連絡ください。';

        await axios
          .get(
            `/itemlogs/csv?${querystring.stringify({
              filterOwner: this.owner,
              filterKeywords: this.keywords,
              filterStartOfEventOccurredAt: this.startOfEventOccurredAt || '',
              filterEndOfEventOccurredAt: this.endOfEventOccurredAt || '',
              filterCategories: this.categories
            })}`
          )
          .catch((e) => {
            throw new Error(_.get(e, 'response.data.errors.general', errorMsg));
          });
      } catch (error) {
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        await this.$store.dispatch(
          'notify/showErrorNotify',
          _.get(
            error,
            'message',
            'エラーが発生しました、画面を更新してやり直してください'
          )
        );
        throw error;
      }
      await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
      await this.$store.dispatch(
        'notify/showNotify',
        '処理を開始しました。処理完了時にメールで通知します。'
      );
    },
    onClickClearAllChecks: function () {
      if (this.isSelectedAllCategories) {
        this.categories = [];
      } else {
        this.categories = this.categoryLabel;
      }
    },
    selectDiffClass(diffNum) {
      if (parseInt(diffNum) < 0) {
        return 'red--text';
      }

      return 'blue--text';
    },
    formatDate,
    formatEditor
  },
  async beforeMount() {
    await this.$store.dispatch(`owners/${FETCH_OWNERS_ALL}`);
  },
  created() {
    this.lastQuery = this.$route.query;
  }
};
</script>
