import axios from '@/api/common/axios';
import { FETCH_VERSION_SUCCESS } from '../mutation-types';
import {
  FETCH_VERSION,
  NOTIFY_NEW_VERSION_IF_EXISTS,
  SHOW_NOTIFY_WITH_CALLBACK
} from '../action-types';

export default {
  namespaced: true,
  /**
   * @property {string} hash - ハッシュ値の現在のバージョン
   */
  state: {
    hash: ''
  },
  mutations: {
    [FETCH_VERSION_SUCCESS](state, hash) {
      state.hash = hash;
    }
  },
  actions: {
    /**
     * 現在のバージョンを取得する
     * @param {Function} commit
     * @returns {Promise<void>}
     */
    async [FETCH_VERSION]({ commit }) {
      const hash = (await axios.get('/hash.json')).data;
      commit(FETCH_VERSION_SUCCESS, hash);
    },
    /**
     * 新しいバージョンがあったら通知を表示する
     * @param {Object} state
     * @param {Function} dispatch
     * @returns {Promise<void>}
     */
    async [NOTIFY_NEW_VERSION_IF_EXISTS]({ state, dispatch }) {
      const hash = (await axios.get('/hash.json')).data;
      if (state.hash !== hash) {
        dispatch(
          `notify/${SHOW_NOTIFY_WITH_CALLBACK}`,
          {
            message:
              '新しいバージョンがリリースされました。数秒後に画面が更新されます。',
            callback: () => {
              window.location.reload(true);
            },
            callbackText: '画面を更新する'
          },
          { root: true }
        );
        setTimeout(async function () {
          window.location.reload(true);
        }, 3000);
      }
    }
  }
};
