<template>
  <v-container>
    <v-card outlined>
      <v-card-title>
        <v-row>
          <v-col sm="4">
            <v-text-field
              label="バーコードで絞り込む"
              v-model="filterBarcode"
            />
          </v-col>
          <v-col sm="4">
            <v-text-field
              label="荷主管理コードで絞り込む"
              v-model="filterOwnerItemCode"
            />
          </v-col>
          <v-col sm="4">
            <v-text-field label="商品名で絞り込む" v-model="filterName" />
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-subtitle> 検索結果 </v-card-subtitle>

      <TableAndPaging
        ref="search"
        :headers="headers"
        :items="$store.state.items.items"
        :total="$store.state.items.total"
        :params="params"
        :query-params="queryParams"
        :action="`items/${FETCH_ITEMS}`"
      >
        <template #row="props">
          <td>
            <v-btn
              outlined
              small
              class="ma-1 mt-4 mb-2"
              @click="$emit('select-item', { item: props.item })"
            >
              選択する
            </v-btn>
          </td>
          <td>{{ props.item.barcode }}</td>
          <td>{{ props.item.owner_item_code }}</td>
          <td>
            <v-list-item dense>
              <v-list-item-icon>(日)</v-list-item-icon>
              <v-list-item-title>
                {{ props.item.names.ja_jp }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item dense>
              <v-list-item-icon>(英)</v-list-item-icon>
              <v-list-item-title>
                {{ props.item.names.en_us }}
              </v-list-item-title>
            </v-list-item>
          </td>
          <td v-if="props.item.can_show_stock_count">
            {{ props.item.stock }}
          </td>
          <td v-else>
            <v-chip label class="no-hover">入庫待ち</v-chip>
          </td>
        </template>
      </TableAndPaging>
    </v-card>
  </v-container>
</template>

<script>
import TableAndPaging from '@/components/TableAndPaging';
import { FETCH_ITEMS } from '@/store/action-types';

export default {
  name: 'ItemSelector',
  components: {
    TableAndPaging
  },
  props: ['initialParams'],
  data: () => {
    return {
      filterOwnerItemCode: '',
      filterBarcode: '',
      filterName: '',
      FETCH_ITEMS,
      headers: [
        '操作',
        'バーコード',
        '荷主管理コード',
        '商品名',
        '引当可能在庫数'
      ]
    };
  },
  computed: {
    params() {
      return {
        ...this.initialParams,
        ...{
          filter_owner_item_code: this.filterOwnerItemCode,
          filter_barcode: this.filterBarcode,
          filter_name: this.filterName
        }
      };
    },
    queryParams() {
      return {
        limit: 5
      };
    }
  }
};
</script>
