<template>
  <v-app id="app">
    <layout-toolbar></layout-toolbar>
    <layout-drawer></layout-drawer>
    <layout-view></layout-view>
    <AgreementDialog></AgreementDialog>
    <v-overlay :value="isLoading" opacity="0.8" z-index="301">
      <v-progress-circular
        color="primary"
        :size="100"
        :width="10"
        indeterminate
      />
    </v-overlay>
  </v-app>
</template>

<style>
@import './css/styles.css';
</style>

<script>
import LayoutToolbar from '@/components/layout/Toolbar';
import LayoutDrawer from '@/components/layout/Drawer';
import LayoutView from '@/components/layout/View';
import AgreementDialog from '@/components/AgreementDialog';
import {
  FETCH_VERSION,
  NOTIFY_NEW_VERSION_IF_EXISTS,
  NOTIFY_NEW_AGREEMENT_VERSION_IF_EXISTS
} from '@/store/action-types';

export default {
  components: {
    LayoutToolbar,
    LayoutDrawer,
    LayoutView,
    AgreementDialog
  },
  async created() {
    // クライアントの更新がないかをチェックする
    await this.$store.dispatch(`version/${FETCH_VERSION}`);
    setInterval(() => {
      this.$store.dispatch(`version/${NOTIFY_NEW_VERSION_IF_EXISTS}`);
    }, 30000); // 30秒ごと

    // 利用規約の更新がないかをチェックする
    setInterval(() => {
      if (this.$store.state.user.isLoggedIn) {
        this.$store.dispatch(
          `agreement/${NOTIFY_NEW_AGREEMENT_VERSION_IF_EXISTS}`,
          {
            userAgreementVersion: this.$store.state.user.agreementVersion
          }
        );
      }
    }, 30000); // 30秒ごと
  },
  computed: {
    isLoading: (self) => self.$store.state.app.loading
  }
};
</script>
