<template>
  <v-container fluid fill-height>
    <v-layout row wrap align-center justify-center>
      <v-flex md4 xs4 text-center>
        <Logo></Logo>
        <v-card class="mt-10 mx-auto" max-width="480">
          <v-card-text class="pa-6">
            <v-form @submit.prevent>
              <p>パスワードをリセットするメールアドレスを入力してください</p>
              <v-text-field
                outlined
                required="required"
                name="email"
                v-model="email"
                data-vv-as="メールアドレス"
                v-validate="'required|email|max:256'"
                data-vv-validate-on="blur"
                :error-messages="emailErrors"
                prepend-inner-icon="mail"
                label="メールアドレス"
                @keyup.enter="send"
              ></v-text-field>
              <v-flex text-center>
                <v-btn @click="send" color="primary" large depressed
                  >送信</v-btn
                >
              </v-flex>
            </v-form>
          </v-card-text>
        </v-card>

        <p class="mt-8 mb-8">
          <router-link to="/login" class="u-text-decoration-none">
            ログインにもどる
          </router-link>
        </p>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
const _ = require('lodash');

import { mergeErrors } from '@/util/input';
import {
  FINISH_LOADING,
  INITIALIZE_FORGOT_PAGE,
  START_LOADING
} from '@/store/action-types';

import Logo from '../components/Logo.vue';

export default {
  components: {
    Logo
  },
  $_veeValidate: {
    validator: 'new'
  },
  data: () => ({
    email: ''
  }),
  computed: {
    emailErrors: (self) =>
      mergeErrors(
        self.$validator.errors,
        self.$store.state.auth.forgotErrors,
        'email'
      )
  },
  async beforeMount() {
    await this.$store.dispatch(`auth/${INITIALIZE_FORGOT_PAGE}`);
  },
  methods: {
    /**
     * パスワードリセットリクエスト処理を行う
     * @returns {Promise<void>}
     */
    async send() {
      this.$validator.errors.clear();
      await this.$store.dispatch('notify/clearNotify');
      if (await this.$validator.validate()) {
        await this.$store.dispatch(`app/${START_LOADING}`, new Date());
        try {
          await this.$store.dispatch('auth/forgot', this.email);
        } catch (e) {
          await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
          await this.$store.dispatch(
            'notify/showErrorNotify',
            'エラーが発生しました、画面を更新してやり直してください'
          );
          throw e;
        }
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());

        const serverErrors = this.$store.state.auth.forgotErrors;
        if (serverErrors === null) {
          await this.$store.dispatch(
            'notify/showNotify',
            `パスワードリセット用のリンクを ${this.email} に送信しました`
          );
        } else {
          const generalError = _.get(serverErrors, 'general.msg');
          if (generalError) {
            await this.$store.dispatch('notify/showErrorNotify', generalError);
          }
        }
      }
    }
  }
};
</script>

<style>
#layout-view {
  padding-bottom: 0;
}
</style>
