<template>
  <v-container fluid fill-height>
    <v-layout row wrap align-center justify-center>
      <v-flex md6 xs6 text-center>
        <Logo></Logo>
        <v-card class="mt-10 mx-auto">
          <v-card-text class="pa-12">
            ただいまメンテナンス中です。詳細はお問い合わせください。
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Logo from '../components/Logo.vue';

export default {
  components: {
    Logo
  },
  data: () => ({}),
  computed: {},
  async beforeMount() {},
  methods: {}
};
</script>

<style>
#layout-view {
  padding-bottom: 0;
}
</style>
