<template>
  <div id="shipment-detail">
    <div v-if="isTomUser" class="caption mb-1">
      <strong>shipment_requests: </strong>
      <code>{{ $route.params.shipmentRequestId }}</code
      ><br />
      <strong>荷主: </strong>
      {{ $store.state.shipment.ownerName }}
      <code>{{ $store.state.shipment.ownerId }}</code>
    </div>
    <h3 class="d-flex align-center">
      <v-chip
        label
        class="no-hover"
        :color="stateColor($store.state.shipment.state)"
      >
        {{ formatShipmentRequestsSchema($store.state.shipment.state) }}
      </v-chip>
      <span class="pl-2">{{ $store.state.shipment.ownerOrderNo }}</span>

      <v-btn
        v-if="
          !isTomUser &&
          [
            SHIPMENT_REQUESTS_STATE.VALIDATION_ERROR,
            SHIPMENT_REQUESTS_STATE.PRE_ORDER,
            SHIPMENT_REQUESTS_STATE.DRAFT
          ].includes($store.state.shipment.state)
        "
        color="primary"
        dark
        outlined
        class="ml-4"
        :to="`/shipments/${$route.params.shipmentRequestId}/edit`"
      >
        <v-icon left>edit</v-icon>
        編集
      </v-btn>
      <ApproveShipmentRequestButton
        :isTomUser="isTomUser"
        :shipmentRequestId="$route.params.shipmentRequestId"
        :state="$store.state.shipment.state"
      />
      <RejectShipmentRequestButton
        :isTomUser="isTomUser"
        :shipmentRequestId="$route.params.shipmentRequestId"
        :state="$store.state.shipment.state"
        :onSubmitted="onRejectButtonSubmitted"
      />
      <CancelShipmentRequestButton
        :shipmentRequestId="$route.params.shipmentRequestId"
        :state="$store.state.shipment.state"
        :hasCancelableShipment="
          shipments.some((shipment) => shipment.state === STATE.APPROVED)
        "
        :onSubmitted="onCancelButtonSubmitted"
      />
    </h3>
    <div class="caption mt-1">
      <span>
        <strong>登録日: </strong>
        {{ formatDate({ date: $store.state.shipment.createdAt }) }}
      </span>
      <span v-if="$store.state.shipment.approvedAt" class="ml-4">
        <strong>承認日: </strong>
        {{ formatDate({ date: $store.state.shipment.approvedAt }) }}
      </span>
      <span v-if="$store.state.shipment.canceledAt" class="ml-4">
        <strong>取り消し日: </strong>
        {{ formatDate({ date: $store.state.shipment.canceledAt }) }}
      </span>
    </div>
    <div class="caption mt-1" v-if="$store.state.shipment.editorEmail">
      <span>
        <strong>登録者: </strong>
        {{ $store.state.shipment.editorEmail }}
      </span>
    </div>
    <v-skeleton-loader
      :loading="!$store.state.shipment.state"
      type="list-item-three-line"
      max-width="480px"
    >
      <v-card flat outlined max-width="480px" class="mt-1">
        <v-card-text>
          <strong>送付先:</strong><br />
          <strong>{{ $store.state.shipment.customerName }}</strong>
          <div>{{ $store.state.shipment.customerAddress }}</div>
          <div v-if="$store.state.shipment.customerEmail">
            {{ $store.state.shipment.customerEmail }}
          </div>
          <div v-if="$store.state.shipment.customerPhone">
            {{ $store.state.shipment.customerPhone }}
          </div>
        </v-card-text>
      </v-card>
    </v-skeleton-loader>

    <div v-if="shipments && shipments.length">
      <v-row>
        <v-col class="text-right">
          <v-dialog>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                outlined
                small
                label
                color="blue"
                class="ml-2"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small left> info </v-icon>
                表示項目のヘルプ
              </v-chip>
            </template>
            <v-card>
              <v-card-title> 基本情報 </v-card-title>
              <v-card-text>
                <v-list three-line>
                  <v-list-item
                    v-for="note in fieldInfos.filter((item) => item.content)"
                    :key="'note-' + note.key"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold">{{
                        note.name
                      }}</v-list-item-title>
                      <p v-html="note.content" />
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-card
        class="mt-2"
        v-for="shipment in shipments"
        :key="'item-' + shipment._id"
      >
        <v-card-text>
          <v-row justify="start">
            <v-col cols="4">
              <v-chip label class="no-hover">
                {{ formatShipmentsSchema(shipment.state) }}
              </v-chip>
              <div class="caption mt-1">{{ shipment._id }}</div>
              <div class="mt-1">
                <v-btn color="primary" outlined small class="mr-1">
                  <a
                    :href="
                      createDirectDownloadUrl({
                        _id: shipment._id,
                        category: SHIPPING_PAPER_CATEGORY.SLIP
                      })
                    "
                    target="_blank"
                  >
                    納品書
                  </a>
                </v-btn>
                <v-btn
                  v-if="
                    shipment.shipping_paper && shipment.shipping_paper.label_url
                  "
                  color="primary"
                  outlined
                  small
                  class="mr-1"
                >
                  <a
                    :href="
                      createDirectDownloadUrl({
                        _id: shipment._id,
                        category: SHIPPING_PAPER_CATEGORY.LABEL
                      })
                    "
                    target="_blank"
                  >
                    出荷伝票
                  </a>
                </v-btn>
                <v-btn
                  v-if="
                    shipment.shipping_paper &&
                    shipment.shipping_paper.invoice_url
                  "
                  color="primary"
                  outlined
                  small
                >
                  <a
                    :href="
                      createDirectDownloadUrl({
                        _id: shipment._id,
                        category: SHIPPING_PAPER_CATEGORY.INVOICE
                      })
                    "
                    target="_blank"
                  >
                    インボイス
                  </a>
                </v-btn>
              </div>
            </v-col>
            <v-col cols="4">
              <v-simple-table dense class="shipment-define-table">
                <tbody>
                  <tr>
                    <th>見積送料</th>
                    <td>{{ shipment.shipping_cost.toLocaleString() }}</td>
                  </tr>
                  <tr>
                    <th>箱</th>
                    <td>{{ shipment.box }}</td>
                  </tr>
                  <tr>
                    <th width="130">重量/容積重量</th>
                    <td>{{ shipment.total_weight }}</td>
                  </tr>
                  <tr>
                    <th width="130">ギフトラッピング</th>
                    <td>{{ shipment.gift_wrapping }}</td>
                  </tr>
                  <tr>
                    <th>納品書設定</th>
                    <td v-if="shipment.slip_template">
                      <a
                        :href="`/slips/${shipment.slip_template._id}`"
                        target="_blank"
                        >{{ shipment.slip_template.name }}
                      </a>
                    </td>
                    <td v-else>デフォルト</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
            <v-col cols="4">
              <v-simple-table dense class="shipment-define-table">
                <tbody>
                  <tr>
                    <th>配送方法</th>
                    <td>{{ shipment.shipping_method }}</td>
                  </tr>
                  <tr
                    v-if="
                      shipment.auto_selected_shipping_method_attribute_label
                    "
                  >
                    <th width="170">配送方法の自動選択方法</th>
                    <td>
                      {{
                        shipment.auto_selected_shipping_method_attribute_label
                      }}
                    </td>
                  </tr>
                  <tr v-if="shipment.content_type">
                    <th>内容物種別</th>
                    <td>
                      {{
                        CONTENT_TYPE_LABEL[shipment.content_type] ||
                        shipment.content_type
                      }}
                    </td>
                  </tr>
                  <tr v-if="showTrackingCode({ shipment })">
                    <th>トラッキング番号</th>
                    <td>
                      <a target="_blank" :href="shipment.tracking_url">
                        {{ shipment.tracking_code }}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <th>お届け希望日</th>
                    <td>
                      {{
                        shipment.delivery_schedule_date
                          ? formatDate({
                              date: shipment.delivery_schedule_date,
                              format: 'YYYY/MM/DD'
                            })
                          : '無し'
                      }}
                    </td>
                  </tr>
                  <tr v-if="shipment.shipped_at">
                    <th>出荷日</th>
                    <td>
                      {{
                        formatDate({
                          date: shipment.shipped_at
                        })
                      }}
                    </td>
                  </tr>
                  <tr v-if="shipment.flyer">
                    <th>フライヤー</th>
                    <td>
                      {{ shipment.flyer.number }}: {{ shipment.flyer.title }}
                    </td>
                  </tr>
                  <tr v-if="shipment.duty">
                    <th>関税</th>
                    <td>{{ formatDuty(shipment.duty) }}</td>
                  </tr>
                  <tr v-if="shipment.total_declared_price > 0">
                    <th>申告価格合計</th>
                    <td>
                      {{
                        shipment.items.reduce(
                          (sum, item) => sum + item.price * item.qty,
                          0
                        )
                      }}（円）
                    </td>
                  </tr>
                  <tr v-if="$store.state.shipment.priority">
                    <th>優先出荷</th>
                    <td>優先</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>

          <v-divider></v-divider>

          <v-simple-table class="shipment-items-table">
            <thead>
              <tr>
                <th
                  v-for="header in SHIPMENTS_HEADER"
                  :key="'header-' + header"
                >
                  {{ header }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in shipment.items" :key="index">
                <td>
                  <div v-if="item.item_bundle" small text>
                    [{{ item.item_bundle.bundle_code }}]
                  </div>

                  <span>
                    <IconBarcode class="mr-1"></IconBarcode>
                    <ClipboardChip :text="item.barcode"></ClipboardChip>
                  </span>
                  <router-link
                    :to="`/items/${item._id}`"
                    class="link-row--main"
                    >{{ item.name }}</router-link
                  >
                </td>
                <td>
                  <div
                    v-if="
                      item.item_bundle && item.item_bundle.owner_bundle_code
                    "
                    small
                    text
                  >
                    [{{ item.item_bundle.owner_bundle_code }}]
                  </div>
                  {{ item.ownerItemCode }}
                </td>
                <td>{{ item.qty }}</td>
                <td>{{ item.price }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </div>
    <div v-else>
      <v-card class="mt-2">
        <v-card-text>
          <v-container class="pt-0">
            <v-row>
              <v-col cols="4"> </v-col>
              <v-col cols="4">
                <v-simple-table dense class="shipment-define-table">
                  <tbody>
                    <tr>
                      <th>ギフトラッピング</th>
                      <td>{{ $store.state.shipment.giftWrapping }}</td>
                    </tr>
                    <tr>
                      <th>納品書設定</th>
                      <td v-if="$store.state.shipment.slipName">
                        {{ $store.state.shipment.slipName }}
                      </td>
                      <td v-else>デフォルト</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-col>
              <v-col cols="4">
                <v-simple-table dense class="shipment-define-table">
                  <tbody>
                    <tr>
                      <th>配送方法</th>
                      <td>
                        {{
                          shippingMethod({
                            shippingMethod: $store.state.shipment.shippingMethod
                          })
                        }}
                      </td>
                    </tr>
                    <tr v-if="$store.state.shipment.contentType">
                      <th>内容物種別</th>
                      <td>
                        {{
                          CONTENT_TYPE_LABEL[
                            $store.state.shipment.contentType
                          ] || $store.state.shipment.contentType
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th>お届け希望日</th>
                      <td>
                        {{
                          $store.state.shipment.deliveryDate
                            ? formatDate({
                                date: $store.state.shipment.deliveryDate,
                                format: 'YYYY/MM/DD'
                              })
                            : '無し'
                        }}
                      </td>
                    </tr>

                    <tr v-if="$store.state.shipment.flyer">
                      <th>フライヤー</th>
                      <td>
                        {{ $store.state.shipment.flyer }}
                      </td>
                    </tr>
                    <tr v-if="$store.state.shipment.duty">
                      <th>関税</th>
                      <td>{{ formatDuty($store.state.shipment.duty) }}</td>
                    </tr>
                    <tr
                      v-if="
                        $store.state.shipment.items.filter((i) => i.price)
                          .length > 0
                      "
                    >
                      <th>申告価格合計</th>
                      <td>
                        {{
                          $store.state.shipment.items.reduce(
                            (sum, item) => sum + item.price * item.qty,
                            0
                          )
                        }}（円）
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-container>

          <v-divider></v-divider>

          <v-simple-table class="shipment-items-table">
            <thead>
              <tr>
                <th
                  v-for="header in SHIPMENTS_HEADER"
                  :key="'header-' + header"
                >
                  {{ header }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="({ item, barcode, qty, price }, index) in $store.state
                  .shipment.items"
                :key="index"
              >
                {{/* item はItemsが存在しないbarcodeを指定した場合などは空になる */}}
                <td>
                  <div v-if="item && item.item_bundle" small text>
                    [{{ item.item_bundle.bundle_code }}]
                  </div>

                  <span>
                    <IconBarcode class="mr-1"></IconBarcode>
                    <ClipboardChip :text="barcode"></ClipboardChip>
                  </span>
                  <router-link
                    v-if="item"
                    :to="`/items/${item._id}`"
                    class="link-row--main"
                  >
                    {{ item.names.ja_jp || item.names.en_us }}
                  </router-link>
                </td>
                <td>
                  <div
                    v-if="
                      item &&
                      item.item_bundle &&
                      item.item_bundle.owner_bundle_code
                    "
                    small
                    text
                  >
                    [{{ item.item_bundle.owner_bundle_code }}]
                  </div>
                  <span v-if="item">
                    {{ item.owner_item_code }}
                  </span>
                </td>
                <td>{{ qty }}</td>
                <td>{{ price }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </div>

    <v-flex xs12>
      <v-btn class="mt-4" color="primary accent-4" text to="/shipments">
        一覧に戻る
      </v-btn>
    </v-flex>
  </div>
</template>

<style scoped>
#shipment-detail .theme--light.v-card .v-card__text {
  color: RGBa(0, 0, 0, 0.87);
}

#shipment-detail .th-key th {
  width: 200px;
}
.shipment-define-table tbody tr th,
.shipment-define-table tbody tr td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.link-row--main {
  display: block;
  font-weight: bold;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
}
.link-row--main:hover {
  text-decoration: underline;
  color: rgba(0, 0, 0, 0.87);
}
.shipment-items-table tbody td {
  height: auto;
  padding-top: 8px;
  padding-bottom: 8px;
}
</style>

<script>
const _ = require('lodash');
import { FETCH_SHIPMENT_DETAIL } from '@/store/action-types';
import shipmentRequestsSchema from '../../lib/document/schema/shipment_requests';
import shipmentsSchema from '../../lib/document/schema/shipments';
import boxSizeMap from '@/../lib/document/schema/box_size_map';
import {
  STATE,
  SHIPPING_PAPER_CATEGORY,
  AUTO_SELECTED_SHIPPING_METHOD_LABEL,
  ACCEPTABLE_SHIPPING_METHOD_LABEL
} from '@/../lib/document/schema/shipments';
import { createTrackingUrl } from '@/../lib/common/shipments';
import ClipboardChip from '@/components/chips/ClipboardChip';
import IconBarcode from '@/components/icons/IconBarcode';
import ApproveShipmentRequestButton from '@/components/shipments/ApproveShipmentRequestButton';
import RejectShipmentRequestButton from '@/components/shipments/RejectShipmentRequestButton';
import CancelShipmentRequestButton from '@/components/shipments/CancelShipmentRequestButton';
import { formatDate, formatDuty } from '@/util/formatter';

export default {
  components: {
    ClipboardChip,
    IconBarcode,
    ApproveShipmentRequestButton,
    RejectShipmentRequestButton,
    CancelShipmentRequestButton
  },
  data() {
    const shipmentRequestId = this.$route.params.shipmentRequestId;
    return {
      title: `shipment_requests: ${shipmentRequestId}`,
      SHIPPING_PAPER_CATEGORY,
      SHIPMENT_REQUESTS_STATE: shipmentRequestsSchema.STATE,
      CONTENT_TYPE_LABEL: shipmentsSchema.CONTENT_TYPE_LABEL,
      STATE,
      fieldInfos: [
        {
          key: 'shipping_cost',
          name: '見積送料',
          content:
            '依頼時の運送会社（便種）、荷姿、宛先の条件で配送した場合の送料 / 運賃の見積もり。（出荷作業料金除く）'
        },
        {
          key: 'box',
          name: '箱',
          content:
            '出荷タイプ「梱包あり」の場合、セカイロジ選定の最適な梱包材を指定、「伝票直貼り」の場合には入庫時の梱包材をそのまま利用'
        },
        {
          key: 'weight',
          name: '重量/容積重量',
          content:
            '商品本体に加え、梱包資材や納品書、送り状などの重量を加えたトータルの荷姿重量（g)'
        },
        {
          key: 'gift_wrapping',
          name: 'ギフトラッピング',
          content: 'セカイロジ指定のラッピング袋を使用'
        },
        {
          key: 'shipping_slip',
          name: '納品書設定',
          content:
            '依頼時に指定した納品書テンプレート名、または未指定の場合にはデフォルトの納品書'
        },
        {
          key: 'shipping_method',
          name: '配送方法',
          content:
            '依頼時に指定した配送方法（便種）、またはサジェストの場合には出荷予約を除き提案便種を表示'
        },
        {
          key: 'tracking_number',
          name: 'トラッキング番号',
          content:
            '運送会社ごとに発行される追跡番号、または問い合わせ番号。荷物の輸送ステータスを確認用'
        },
        {
          key: 'delivery_date',
          name: 'お届け希望日',
          content: 'お届け希望日が設定されている場合には日付を表示'
        },
        {
          key: 'flyer',
          name: 'フライヤー',
          content:
            'オプションのフライヤーを選択している場合には、フライヤー番号とフライヤー名を表示'
        },
        {
          key: 'price',
          name: '単価',
          content:
            '依頼時に設定した商品単体ごとの申告価格。国際輸送時の税関申告情報。基本的には販売時または取引単価が該当。販売にあたらないギフトやサンプルの場合には商品原価を記載'
        }
      ]
    };
  },
  computed: {
    SHIPMENTS_HEADER() {
      return ['バーコード / 商品名', '荷主管理コード', '出荷数', '単価（円）'];
    },
    isTomUser: (self) => self.$store.state.user.isTomUser,
    shipments: (self) => {
      return self.$store.state.shipment.shipments.map((s) => {
        const size = _.get(boxSizeMap, `${s.shipping_method}.${s.box}`);
        return _.create(s, {
          shipping_method: ACCEPTABLE_SHIPPING_METHOD_LABEL[s.shipping_method],
          shipping_cost: s.shipping_cost
            ? `${s.shipping_cost}（円）`
            : 'お問い合せください。',
          box: size ? `${s.box} - ${size}` : s.box,
          total_weight:
            s.actual_weight && s.dimensional_weight
              ? `${s.actual_weight}（g）/ ${s.dimensional_weight}（g）`
              : `${s.total_weight}（g）`,
          total_declared_price: s.items.reduce((sum, item) => {
            return sum + item.price * item.qty;
          }, 0),
          auto_selected_shipping_method_attribute_label:
            AUTO_SELECTED_SHIPPING_METHOD_LABEL[
              s.auto_selected_shipping_method_attribute
            ],
          tracking_url: createTrackingUrl({
            shippingMethod: s.shipping_method,
            trackingCode: s.tracking_code
          })
        });
      });
    }
  },
  async mounted() {
    try {
      await this.$store.dispatch(`shipment/${FETCH_SHIPMENT_DETAIL}`, {
        shipmentRequestId: this.$route.params.shipmentRequestId
      });
    } catch (error) {
      await this.$store.dispatch(
        'notify/showErrorNotify',
        error.response.data.errors.general
      );
    }
  },
  methods: {
    stateColor: function (state) {
      if (state === this.SHIPMENT_REQUESTS_STATE.VALIDATION_ERROR) {
        return 'error';
      }
    },
    formatShipmentsSchema(state) {
      return shipmentsSchema.STATE_LABEL[state];
    },
    formatShipmentRequestsSchema(state) {
      return shipmentRequestsSchema.STATE_LABEL[state];
    },
    formatDate,
    formatDuty,
    createTrackingUrl,
    createDirectDownloadUrl({ _id, category }) {
      return `${location.origin}/direct_download/shipping_paper/${_id}_${category}`;
    },
    showTrackingCode({ shipment }) {
      return (
        shipment.tracking_code &&
        shipment.state === shipmentsSchema.STATE.SHIPPED
      );
    },
    shippingMethod({ shippingMethod }) {
      return ACCEPTABLE_SHIPPING_METHOD_LABEL[shippingMethod];
    },
    async onCancelButtonSubmitted() {
      const shipmentRequestId = this.$route.params.shipmentRequestId;
      await this.$store.dispatch(`shipment/${FETCH_SHIPMENT_DETAIL}`, {
        shipmentRequestId
      });
    },
    async onRejectButtonSubmitted() {
      // 棄却後は詳細画面を表示できないので，一覧画面へ遷移する
      this.$router.push({
        path: '/shipments',
        query: {
          notifyType: 'showNotify',
          notifyMessageCode: this.$router.MESSAGE_CODES
            .SHIPMENT_RERQUEST_REJECT_SUCCESS
        }
      });
    }
  }
};
</script>
