'use strict';

import { MANIFEST_DETAIL_FETCHED } from '../mutation-types';
import { FETCH_MANIFEST_DETAIL, CANCEL_MANIFEST } from '../action-types';
import axios from '../../api/common/axios';

export default {
  namespaced: true,
  state: {
    ownerManifestNo: '',
    stateText: '',
    createdAt: null,
    createdBy: '',
    manifestItems: []
  },
  mutations: {
    [MANIFEST_DETAIL_FETCHED](state, { manifest }) {
      state.ownerManifestNo = manifest.owner_manifest_no;
      state.stateText = manifest.state_text;
      state.createdAt = manifest.created_at;
      state.createdBy = manifest.created_by;
      state.deliverySchedule = manifest.delivery_schedule;
      state.trackingCode = manifest.tracking_code;
      state.carrier = manifest.carrier;
      state.manifestItems = manifest.manifest_items.map((m) => {
        return {
          _id: m._id,
          item: {
            _id: m.item._id,
            barcode: m.item.barcode,
            ownerItemCode: m.item.owner_item_code,
            names: { jaJp: m.item.names.ja_jp, enUs: m.item.names.en_us }
          },
          qty: {
            total: m.qty,
            waiting: m.waiting_qty,
            stocked: m.stocked_qty,
            canceled: m.canceled_qty,
            damaged: m.damaged_qty,
            label: m.label_qty
          },
          updatedAt: m.updated_at
        };
      });
    }
  },
  actions: {
    async [FETCH_MANIFEST_DETAIL]({ commit }, params) {
      const response = await axios.get(`/manifests/${params.manifestId}`);

      if (response && response.data && response.data.status === 'ng') {
        throw new Error('データの取得に失敗しました');
      }

      commit(MANIFEST_DETAIL_FETCHED, {
        manifest: response.data.manifest
      });
    },
    async [CANCEL_MANIFEST](context, rcvSlipNo) {
      await axios.post(`/manifests/${rcvSlipNo}/cancel`);
    }
  }
};
