import querystring from 'querystring';
import _ from 'lodash';
import axios from '@/api/common/axios';

import {
  STOCK_DIFFS_FETCHED,
  STOCK_DIFF_CORRECTED
} from '@/store/mutation-types';
import { FETCH_STOCK_DIFFS, CORRECT_STOCK_DIFF } from '../action-types';

export default {
  namespaced: true,
  state: {
    stockDiffs: [],
    total: 0
  },
  mutations: {
    [STOCK_DIFFS_FETCHED](state, { stockDiffs, total }) {
      state.stockDiffs = [...stockDiffs];
      state.total = total;
    },
    [STOCK_DIFF_CORRECTED](state, { stockDiff, qty }) {
      stockDiff.item.stock += qty;
    }
  },
  actions: {
    async [FETCH_STOCK_DIFFS]({ commit }, query) {
      const errorMsg =
        'データ取得時にエラーがありました。セカイロジ担当者までお問い合わせください。';
      const response = await axios
        .get(`/stock_diffs?${querystring.stringify(query)}`)
        .catch((e) => {
          throw new Error(_.get(e, 'response.data.errors.general', errorMsg));
        });

      if (_.get(response, 'data.status') === 'ng') {
        throw new Error(_.get(response, 'data.errors.general', errorMsg));
      }
      const stockDiffs = response.data.stockDiffs;
      const total = response.data.total;
      commit(STOCK_DIFFS_FETCHED, {
        stockDiffs,
        total
      });
    },
    /**
     * 差分を補正する
     * @param {object} stockDiff 補正する対象を記録したStockDiff
     * @param {ItemEventLogs.CATEGORY} 補正する理由
     * @param {number} qty 補正するStockを増減する数
     */
    async [CORRECT_STOCK_DIFF]({ commit }, { stockDiff, category, qty }) {
      await axios.post('/stock_diffs', {
        itemId: stockDiff.item._id,
        warehouseStock: stockDiff.warehouse_stock,
        category,
        qty
      });
      commit(STOCK_DIFF_CORRECTED, {
        stockDiff,
        category,
        qty
      });
    }
  }
};
