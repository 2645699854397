<template>
  <v-layout wrap v-if="$store.state.user.isTomUser">
    <MainHeader back-to="/owners/index"></MainHeader>
    <v-tabs background-color="transparent" grow>
      <template v-for="tab in tabs">
        <template>
          <v-tab :key="'tab-' + tab.name" :to="tab.path">
            {{ tab.name }}
          </v-tab>
        </template>
      </template>
    </v-tabs>
    <v-scroll-x-reverse-transition mode="out-in">
      <router-view :ownerId="ownerId" readonly></router-view>
    </v-scroll-x-reverse-transition>
  </v-layout>
</template>

<script>
import MainHeader from '@/components/layout/MainHeader';

export default {
  components: {
    MainHeader
  },
  data() {
    const ownerId = this.$route.params.ownerId;
    return {
      ownerId,
      tabs: [
        {
          path: `/owners/${ownerId}/index`,
          name: 'ご契約者情報'
        },
        {
          path: `/owners/${ownerId}/admin/users`,
          name: 'アカウント一覧'
        },
        {
          path: `/owners/${ownerId}/payments`,
          name: 'お支払い設定'
        },
        {
          path: `/owners/${ownerId}/permissions`,
          name: '権限設定'
        },
        {
          path: `/owners/${ownerId}/slips`,
          name: '納品書設定'
        }
      ]
    };
  },
  async beforeMount() {
    // TOM荷主じゃない場合は自分の荷主の更新ページに飛ばす
    if (!this.$store.state.user.isTomUser) {
      this.$router.push(`/owners/${this.ownerId}/update`);
    }
  }
};
</script>
