import querystring from 'querystring';
import axios from '@/api/common/axios';

import {
  WEBHOOK_LOGS_FETCHED,
  WEBHOOK_LOG_FETCHED
} from '@/store/mutation-types';
import {
  FETCH_WEBHOOK_LOGS,
  FETCH_WEBHOOK_LOG,
  TEST_WEBHOOK
} from '@/store/action-types';

export default {
  namespaced: true,
  state: {
    webhookLogs: [],
    webhookLog: { rawRequest: [], rawResponse: [] },
    total: 0
  },
  mutations: {
    [WEBHOOK_LOGS_FETCHED](state, params) {
      state.webhookLogs = params.webhookLogs;
      state.total = params.total;
    },
    [WEBHOOK_LOG_FETCHED](state, params) {
      state.webhookLog = params.webhookLog;
      if (params.webhookLog.rawRequest && params.webhookLog.rawResponse) {
        state.webhookLog.rawRequest = JSON.stringify(
          params.webhookLog.rawRequest,
          null,
          2
        ).split('\n');
        state.webhookLog.rawResponse = JSON.stringify(
          params.webhookLog.rawResponse,
          null,
          2
        ).split('\n');
      } else {
        state.webhookLog.rawRequest = [];
        state.webhookLog.rawResponse = [];
      }
    }
  },
  actions: {
    async [FETCH_WEBHOOK_LOGS]({ commit }, params) {
      try {
        const webhookSettingId = params.webhookSettingId;
        delete params.webhookSettingId;
        delete params.log_id;
        const res = await axios.get(
          `/webhook_logs/index/${webhookSettingId}?${querystring.stringify(
            params
          )}`
        );
        const webhookLogs = res.data.webhookLogs;
        const total = res.data.total;
        commit(WEBHOOK_LOGS_FETCHED, { webhookLogs, total });
      } catch (err) {
        throw new Error(err.response.data.errors.general);
      }
    },
    async [FETCH_WEBHOOK_LOG]({ commit }, params) {
      try {
        let webhookLog = {};
        if (params.logId) {
          const res = await axios.get(`/webhook_logs/detail/${params.logId}`);
          webhookLog = res.data.webhookLog;
          if (!webhookLog.rawRequest) {
            webhookLog.rawRequest = 'No data';
          }
          if (!webhookLog.rawResponse) {
            webhookLog.rawResponse = 'No data';
          }
        } else {
          webhookLog.rawRequest = '';
          webhookLog.rawResponse = '';
        }
        commit(WEBHOOK_LOG_FETCHED, { webhookLog });
      } catch (err) {
        commit(WEBHOOK_LOG_FETCHED, {
          webhookLog: {
            rawRequest: 'ログの取得ができませんでした',
            rawResponse: 'ログの取得ができませんでした'
          }
        });
        throw new Error(err.response.data.errors.general);
      }
    },
    async [TEST_WEBHOOK]({}, params) {
      try {
        await axios.get(`/webhook_logs/test/${params.webhookSetting}`);
      } catch (err) {
        throw new Error(err.response.data.errors.general);
      }
    }
  }
};
