<template>
  <div>
    <MainHeader :title="pageTitle" :back-to="backToRoute">
      <template v-if="isListView">
        <v-btn
          color="primary"
          elevation="0"
          class="ml-2"
          to="/manifests/create"
        >
          <v-icon left>file_upload</v-icon>
          入庫依頼作成
          <span class="caption">(CSVアップロード)</span>
        </v-btn>
        <v-btn
          color="primary"
          elevation="0"
          class="ml-2"
          to="/manifests/form"
          v-if="!isTomUser"
        >
          <v-icon left>edit</v-icon>
          入庫依頼作成
          <span class="caption">(フォームから)</span>
        </v-btn>
      </template>
    </MainHeader>

    <v-scroll-x-reverse-transition mode="out-in">
      <router-view></router-view>
    </v-scroll-x-reverse-transition>
  </div>
</template>

<script>
import MainHeader from '@/components/layout/MainHeader';

const PATHS = {
  INDEX: '/manifests/index',
  CREATE: '/manifests/create'
};

export default {
  components: {
    MainHeader
  },
  data: () => {
    return {
      lastQuery: {}
    };
  },
  watch: {
    $route(to) {
      if (to.path === PATHS.INDEX) {
        this.lastQuery = to.query;
      }
    }
  },
  computed: {
    pageTitle() {
      if (this.$route.path === PATHS.CREATE) {
        return '入庫依頼を作成';
      }

      return this.$route.name;
    },
    isListView() {
      return this.$route.path === PATHS.INDEX;
    },
    backToRoute() {
      if (this.isListView) {
        return null;
      }
      return {
        path: PATHS.INDEX,
        query: this.lastQuery
      };
    },
    isTomUser: (self) => self.$store.state.user.isTomUser
  }
};
</script>
