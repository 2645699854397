<template>
  <v-container px-0>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-layout align-center justify-center>
              <v-flex md6 xs12 text-center>
                <v-form @submit.prevent>
                  <v-text-field
                    class="mb-4"
                    v-model="email"
                    name="email"
                    v-validate="'required|email'"
                    data-vv-validate-on="blur"
                    data-vv-as="メールアドレス"
                    :error-messages="errors.collect('email')"
                    label="メールアドレスを入力する"
                    required
                  ></v-text-field>
                  <v-select
                    v-model="category"
                    :items="
                      CATEGORY_PATTERN.map((k) => ({
                        text: CATEGORY_TEXT[k],
                        value: k
                      }))
                    "
                    label="アカウント種別を選択する"
                    height="20"
                  ></v-select>
                  <v-select
                    v-if="this.$store.state.user.isTomUser"
                    v-model="owner"
                    :items="owners"
                    label="荷主を選択する"
                    height="20"
                  />
                  <v-btn text color="primary" @click="onAddUser">
                    アカウントを追加する
                  </v-btn>
                </v-form>
              </v-flex></v-layout
            ></v-card-text
          >
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  CATEGORY_TEXT,
  CATEGORY_PATTERN,
  CATEGORY
} from '@/../lib/document/schema/users';

import { TOM_ID } from '@/../lib/document/schema/owners';

import {
  START_LOADING,
  FINISH_LOADING,
  ADD_USER,
  FETCH_OWNERS_ALL
} from '@/store/action-types';

export default {
  components: {},
  $_veeValidate: {
    validator: 'new'
  },
  data: function () {
    const initialData = {
      email: '',
      category: CATEGORY.OPERATOR,
      owner: '',
      CATEGORY_TEXT,
      CATEGORY_PATTERN
    };
    // TOM荷主の場合は，初期に選ばれるのをTOM荷主にする
    if (this.$store.state.user.isTomUser) {
      return {
        ...initialData,
        ...{
          owner: TOM_ID
        }
      };
    }

    return initialData;
  },
  async beforeMount() {
    if (this.$store.state.user.isTomUser) {
      await this.$store.dispatch(`owners/${FETCH_OWNERS_ALL}`);
    }
  },
  computed: {
    owners: function () {
      return Object.keys(this.$store.state.owners.owners).map((index) => {
        return {
          value: this.$store.state.owners.owners[index]._id,
          text: this.$store.state.owners.owners[index].names.ja_jp
        };
      });
    }
  },
  methods: {
    async onAddUser() {
      this.$validator.errors.clear();
      await this.$store.dispatch('notify/clearNotify');
      if (await this.$validator.validate()) {
        await this.$store.dispatch(`app/${START_LOADING}`, new Date());

        try {
          const message = await this.$store.dispatch(`addUser/${ADD_USER}`, {
            email: this.email,
            category: this.category,
            currentOwner: this.$store.state.user.owner,
            targetOwner: this.owner || this.$store.state.user.owner
          });

          await this.$store.dispatch('notify/showNotify', message);
        } catch (e) {
          await this.$store.dispatch('notify/showErrorNotify', e.message);
        }

        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
      }
    }
  }
};
</script>
