<template>
  <div>
    <v-card>
      <v-card-text>
        <v-layout>
          <v-autocomplete
            v-if="$store.state.user.isTomUser"
            v-model="owner"
            :items="owners"
            item-value="id"
            item-text="name"
            label="荷主"
            class="mr-2 flex-grow-0"
            outlined
            hide-details
          ></v-autocomplete>
          <v-text-field
            label="バーコード / 荷主管理コード / 商品名"
            v-model="keywords"
            prepend-inner-icon="search"
            outlined
            hide-details
          ></v-text-field>
          <v-select
            v-model="stock"
            :items="$store.state.items.stockLabels"
            label="在庫有無"
            class="ml-2 flex-grow-0"
            style="max-width: 160px"
            outlined
            hide-details
          ></v-select>
          <v-select
            v-model="sort"
            :items="$store.state.items.sortLabels"
            prepend-inner-icon="swap_vert"
            label="並び替え"
            class="ml-2 flex-grow-0"
            style="max-width: 260px"
            outlined
            hide-details
          ></v-select>
          <v-btn
            color="primary"
            class="ml-2"
            height="auto"
            outlined
            @click.stop="onClickCsvDownload"
          >
            CSVダウンロード
          </v-btn>
        </v-layout>
      </v-card-text>
      <TableAndPaging
        ref="search"
        :headers="header"
        :items="$store.state.items.items"
        :total="$store.state.items.total"
        :query-params="queryParams"
        :action="`items/${FETCH_ITEMS}`"
        @dblclick-row="onDbleClickRow"
      >
        <template #row="props">
          <td v-if="$store.state.user.isTomUser">
            {{ props.item.owner.names.ja_jp }}
          </td>
          <td class="col__mail-link">
            <span>
              <IconBarcode class="mr-1"></IconBarcode>
              <ClipboardChip :text="props.item.barcode"></ClipboardChip>
            </span>
            <router-link
              :to="`/items/${props.item._id}`"
              class="link-row--main"
              >{{ getItemTitle(props.item) }}</router-link
            >
          </td>
          <td>{{ props.item.owner_item_code }}</td>
          <td>
            <template v-if="props.item.can_show_stock_count">
              {{ props.item.stock }}
              <v-menu left offset-y>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    text
                    small
                    color="primary"
                    elevation="0"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>expand_more</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item
                    v-for="(menu, index) in ROW_MENU_ITEMS"
                    :key="`${props.item._id}_menu_${index}`"
                    :to="getItemToPath(menu.path, props.item)"
                  >
                    <v-list-item-icon class="mr-4">
                      <v-icon v-text="menu.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="menu.label"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template v-else>
              <v-chip label class="no-hover">入庫待ち</v-chip>
            </template>
          </td>
        </template>
      </TableAndPaging>
    </v-card>
  </div>
</template>

<script>
const _ = require('lodash');
import TableAndPaging from '@/components/TableAndPaging';
import ClipboardChip from '@/components/chips/ClipboardChip';
import IconBarcode from '@/components/icons/IconBarcode';
import {
  FETCH_OWNERS_ALL,
  START_LOADING,
  FINISH_LOADING,
  DOWNLOAD_ITEMS_CSV_REQUESTS,
  FETCH_ITEMS
} from '@/store/action-types';

const ROW_MENU_ITEMS = [
  {
    icon: 'input',
    label: '入庫一覧',
    path: '/manifests'
  },
  {
    icon: 'local_shipping',
    label: '出荷一覧',
    path: '/shipments'
  }
];

export default {
  components: {
    TableAndPaging,
    ClipboardChip,
    IconBarcode
  },
  data: () => {
    return {
      FETCH_ITEMS,
      keywords: '',
      owner_item_code: '',
      barcode: '',
      name: '',
      stock: '',
      owner: '',
      sort: ''
    };
  },
  computed: {
    queryParams: function () {
      return {
        filter_keywords: this.keywords,
        filter_owner_item_code: this.owner_item_code,
        filter_barcode: this.barcode,
        filter_name: this.name,
        filter_stock: this.stock,
        filter_owner: this.owner,
        sort: this.sort
      };
    },
    header: function () {
      return this.$store.state.user.isTomUser
        ? this.$store.state.items.headersForTomUser
        : this.$store.state.items.headers;
    },
    owners: function () {
      return [{ id: '', name: '全て' }].concat(
        Object.keys(this.$store.state.owners.owners).map((index) => {
          return {
            id: this.$store.state.owners.owners[index]._id,
            name: this.$store.state.owners.owners[index].names.ja_jp
          };
        })
      );
    },
    getItemTitle() {
      return (item) => {
        if (item.names.ja_jp) {
          return item.names.ja_jp;
        }
        return item.names.en_us;
      };
    },
    ROW_MENU_ITEMS() {
      return ROW_MENU_ITEMS;
    },
    getItemToPath() {
      return (path, item) => {
        return {
          path: path,
          query: {
            filter_keywords: item.barcode
          }
        };
      };
    }
  },
  async beforeMount() {
    await this.$store.dispatch(`owners/${FETCH_OWNERS_ALL}`);
  },
  mounted: function () {
    const params = this.$route.query;
    this.keywords = params.filter_keywords || '';
    this.owner_item_code = params.filter_owner_item_code || '';
    this.barcode = params.filter_barcode || '';
    this.name = params.filter_name || '';
    this.stock = params.filter_stock || '';
    this.owner = params.filter_owner || '';
    this.sort = params.sort || '';
  },
  methods: {
    onDbleClickRow(item) {
      this.$router.push(`/items/${item._id}`);
    },
    async onClickCsvDownload() {
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());

      try {
        await this.$store.dispatch(
          `items/${DOWNLOAD_ITEMS_CSV_REQUESTS}`,
          this.queryParams
        );
      } catch (error) {
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        await this.$store.dispatch(
          'notify/showErrorNotify',
          _.get(
            error,
            'message',
            'エラーが発生しました、画面を更新してやり直してください'
          )
        );
        throw error;
      }
      await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
      await this.$store.dispatch(
        'notify/showNotify',
        '処理を開始しました。処理完了時にメールで通知します。'
      );
    }
  }
};
</script>

<style scoped>
.link-row--main {
  display: block;
  font-weight: bold;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
}
.link-row--main:hover {
  text-decoration: underline;
  color: rgba(0, 0, 0, 0.87);
}
.col__mail-link {
  width: 90%;
}
</style>
