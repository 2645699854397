<template>
  <v-container>
    <v-card outlined>
      <v-card-title>
        <v-row>
          <v-col sm="4">
            <v-text-field
              label="バーコードで絞り込む"
              v-model="bundleCode"
              hint="部分一致で検索します"
              persistent-hint
            />
          </v-col>
          <v-col sm="4">
            <v-text-field
              label="セット管理番号で絞り込む"
              v-model="ownerBundleCode"
              hint="完全一致で検索します"
              persistent-hint
            />
          </v-col>
          <v-col sm="4">
            <v-text-field
              label="セット名で絞り込む"
              v-model="name"
              hint="日本語名・英語名両方を部分一致で検索します"
              persistent-hint
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-subtitle> 検索結果 </v-card-subtitle>

      <TableAndPaging
        ref="search"
        :headers="headers"
        :items="$store.state.bundles.bundles"
        :total="$store.state.bundles.total"
        :params="params"
        :query-params="queryParams"
        :action="`bundles/${FETCH_BUNDLES}`"
      >
        <template #row="props">
          <td>
            <v-btn
              outlined
              small
              class="ma-1 mt-4 mb-2"
              @click="$emit('select-item', { item: props.item })"
            >
              選択する
            </v-btn>
          </td>
          <td>{{ props.item.bundle_code }}</td>
          <td>{{ props.item.owner_bundle_code }}</td>
          <td>
            <v-list-item dense>
              <v-list-item-icon>(日)</v-list-item-icon>
              <v-list-item-title>
                {{ props.item.names.ja_jp }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item dense>
              <v-list-item-icon>(英)</v-list-item-icon>
              <v-list-item-title>
                {{ props.item.names.en_us }}
              </v-list-item-title>
            </v-list-item>
          </td>
          <td>
            <v-list-item
              dense
              v-for="child in props.item.items"
              :key="'child-' + child.item._id"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ child.item.names.ja_jp }} {{ child.qty }}個
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </td>
        </template>
      </TableAndPaging>
    </v-card>
  </v-container>
</template>

<script>
import TableAndPaging from '@/components/TableAndPaging';
import { FETCH_BUNDLES } from '@/store/action-types';

export default {
  name: 'ItemBundleSelector',
  components: {
    TableAndPaging
  },
  props: ['initialParams'],
  data: () => {
    return {
      ownerBundleCode: '',
      bundleCode: '',
      name: '',
      FETCH_BUNDLES,
      headers: [
        '操作',
        'バーコード',
        'セット管理番号',
        'セット名',
        'セットに含まれる商品'
      ]
    };
  },
  computed: {
    params() {
      return {
        ...this.initialParams,
        ...{
          filter_bundle_code: this.bundleCode,
          filter_owner_bundle_code: this.ownerBundleCode,
          filter_name: this.name
        }
      };
    },
    queryParams() {
      return {
        limit: 5
      };
    }
  }
};
</script>
