// このファイルはクライアント側からも読み込まれるため，
// Node.jsでしか動かないコードを書くことは禁止する

'use strict';

const { SHIPPING_METHOD } = require('../../lib/document/schema/shipments');

const TRACKING_URL = {
  JPP: {
    // PRE + tracking_number + POST の形にする
    PRE: 'https://trackings.post.japanpost.jp/services/srv/search/?requestNo1=',
    POST: '&search.x=102&search.y=15&startingUrlPatten=&locale=ja'
  },
  YAMATO: 'https://toi.kuronekoyamato.co.jp/cgi-bin/tneko?no01=',
  SAGAWA: 'http://k2k.sagawa-exp.co.jp/p/web/okurijosearch.do?okurijoNo=',
  FEDEX: 'https://www.fedex.com/fedextrack/?trknbr=',
  DHL:
    'https://www.dhl.com/jp-ja/home/tracking/tracking-express.html?submit=1&tracking-id=',
  ECMS: 'https://ese.ecmsglobal.com/#/ecms/tracking?lang=jp&order_number='
};

const createTrackingUrl = ({ shippingMethod, trackingCode }) => {
  if (!shippingMethod) {
    return '';
  }
  if (!trackingCode) {
    return '';
  }

  let trackingUrl = '';

  switch (shippingMethod) {
    case SHIPPING_METHOD.JPP_EMS:
    case SHIPPING_METHOD.JPP_AIR_PARCEL:
    case SHIPPING_METHOD.JPP_SAL_PARCEL:
    case SHIPPING_METHOD.JPP_SURFACE_PARCEL:
    case SHIPPING_METHOD.JPP_SAL_SPR:
    case SHIPPING_METHOD.JPP_AIR_SPR:
    case SHIPPING_METHOD.JPP_E_PACKET: // 国際eパケットは廃止
    case SHIPPING_METHOD.JPP_AIR_PRINTED_MATTER_REGISTERED:
      trackingUrl = `${TRACKING_URL.JPP.PRE}${trackingCode}${TRACKING_URL.JPP.POST}`;
      break;
    case SHIPPING_METHOD.YAMATO:
    case SHIPPING_METHOD.YAMATO_NEKOPOS:
      trackingUrl = createYamatoTrackingUrl({ trackingCode });
      break;
    case SHIPPING_METHOD.SAGAWA:
      trackingUrl = `${TRACKING_URL.SAGAWA}${trackingCode}`;
      break;
    case SHIPPING_METHOD.FEDEX_PRIORITY:
    case SHIPPING_METHOD.FEDEX_ECONOMY:
    case SHIPPING_METHOD.FEDEX_CONNECT_PLUS:
      trackingUrl = `${TRACKING_URL.FEDEX}${trackingCode}`;
      break;
    case SHIPPING_METHOD.DHL_EXPRESS:
      trackingUrl = `${TRACKING_URL.DHL}${trackingCode}`;
      break;
    case SHIPPING_METHOD.ECMS:
      trackingUrl = `${TRACKING_URL.ECMS}${trackingCode}`;
      break;
    default:
      // SHIPPING_METHOD.CHARTER: 'チャーター便'はここに入る
      trackingUrl = '';
      break;
  }

  return trackingUrl;
};

const createYamatoTrackingUrl = ({ trackingCode }) => {
  if (!trackingCode) {
    return '';
  } else if (!Number(trackingCode)) {
    return '';
  }

  // https://drive.google.com/file/d/1Bei8wI2RwkW41A7QwQrO9rmbIYDycEJXに沿ってパラメータを作成する
  let firstStrings = String(trackingCode).substr(trackingCode.length - 1, 1);
  firstStrings = String(Number(firstStrings) + 1); // ②の処理
  const secondStrings = String(trackingCode).substr(0, 7);
  let trackingNum = Number(`${firstStrings}${secondStrings}`); // ③の処理
  trackingNum -= 9625; // ④の処理
  const hexTrackingNum = trackingNum.toString(16); // ⑤の処理

  return `${TRACKING_URL.YAMATO}${trackingCode}&id=${hexTrackingNum}`;
};

module.exports = { createTrackingUrl, createYamatoTrackingUrl };
