import { ADD_USER } from '../action-types.js';
import axios from '../../api/common/axios';
import _ from 'lodash';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async [ADD_USER]({}, { email, category, currentOwner, targetOwner }) {
      try {
        const response = await axios.post(`/owners/${currentOwner}/add_user`, {
          email,
          category,
          owner: targetOwner
        });

        return _.get(response, 'data.general', 'リクエストが成功しました。');
      } catch (e) {
        throw new Error(
          _.get(
            e,
            'response.data.errors.general',
            `エラーが発生しました、${
              e.message || '画面を更新してやり直してください'
            }`
          )
        );
      }
    }
  }
};
