<template>
  <v-container px-0>
    <v-row>
      <v-col>
        <h3 class="bold">ご利用可能枠</h3>
        <v-card class="mb-2 mt-2">
          <v-container>
            <v-row>
              <v-col md="4">
                <v-text-field
                  v-if="!isTomUser"
                  input
                  required
                  readonly
                  name="creditLimit"
                  label="ご利用可能枠"
                  :value="creditLimit.toLocaleString('ja-JP')"
                  v-validate="{
                    required: true
                  }"
                  data-vv-as="ご利用可能枠"
                ></v-text-field>
                <v-text-field
                  v-else
                  input
                  required
                  :readonly="!isTomUser"
                  name="creditLimit"
                  label="ご利用可能枠"
                  v-model="creditLimit"
                  v-validate="{
                    required: true
                  }"
                  data-vv-as="ご利用可能枠"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="4">
                <v-text-field
                  input
                  required
                  readonly
                  name="remainingCreditLimit"
                  label="残ご利用可能金額"
                  :value="owner.remaining_credit_limit.toLocaleString('ja-JP')"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <v-card-actions v-if="isTomUser">
          <v-btn color="primary" @click="updateCreditLimit"
            >ご利用可能枠更新</v-btn
          >
        </v-card-actions>
        <v-card-actions v-else>
          <v-btn
            color="primary"
            @click.stop="
              showDialog({
                title: 'ご利用可能枠の増枠リクエスト',
                text: 'ご利用可能枠の増枠をリクエストします'
              })
            "
            >増枠リクエスト</v-btn
          >
        </v-card-actions>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3 class="bold">現在の支払い方法</h3>
        <v-card class="mb-2 mt-2">
          <v-card-text> 請求書（口座振込） </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3 class="bold">請求書送付先情報</h3>
        <v-card class="mb-2 mt-2">
          <v-container>
            <v-row v-if="!readonly">
              <v-col md="4">
                <v-btn
                  small
                  :disabled="isSameInvoiceAddress"
                  @click="copyToInvoiceAddress"
                >
                  ご契約者情報をコピー
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="4">
                <v-text-field
                  input
                  required
                  :readonly="readonly"
                  name="invoiceName"
                  :label="invoiceNameLabel"
                  v-model="owner.invoice.name"
                  v-validate="{
                    required: true,
                    max: 40
                  }"
                  :data-vv-as="invoiceNameLabel"
                  :error-messages="invoiceNameErrors"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="isCorporate">
              <v-col md="4">
                <v-text-field
                  input
                  :readonly="readonly"
                  name="invoiceDepartment"
                  label="部署"
                  v-model="owner.invoice.department"
                  v-validate="{
                    max: 40
                  }"
                  data-vv-as="部署"
                  :error-messages="invoiceDepartmentErrors"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="isCorporate">
              <v-col md="4">
                <v-text-field
                  input
                  required
                  :readonly="readonly"
                  name="invoiceStaffName"
                  label="御担当者名"
                  v-model="owner.invoice.staff_name"
                  v-validate="{
                    required: true && this.isCorporate,
                    max: 40
                  }"
                  data-vv-as="御担当者名"
                  :error-messages="invoiceStaffNameErrors"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="4">
                <v-text-field
                  input
                  required
                  :readonly="readonly"
                  name="invoicePostalCode"
                  label="郵便番号"
                  v-model="owner.invoice.postal_code"
                  v-validate="{
                    required: true,
                    max: 8,
                    regex: /^[\d-]+$/
                  }"
                  data-vv-as="郵便番号"
                  :error-messages="invoicePostalCodeErrors"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="4">
                <v-text-field
                  input
                  required
                  :readonly="readonly"
                  name="invoiceAddress"
                  label="住所"
                  v-model="owner.invoice.address"
                  v-validate="{
                    required: true,
                    max: 60
                  }"
                  data-vv-as="住所"
                  :error-messages="invoiceAddressErrors"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="4">
                <v-text-field
                  input
                  required
                  :readonly="readonly"
                  name="invoicePhoneNumber"
                  label="電話番号"
                  v-model="owner.invoice.phone_number"
                  v-validate="{
                    required: true,
                    max: 11,
                    numeric: true
                  }"
                  :error-messages="invoicePhoneNumberErrors"
                  data-vv-as="電話番号"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>

          <v-container>
            <h4>請求書メール送付先（最大4件）</h4>

            <v-row v-if="owner.invoice.emails.length === 0">
              <v-col md="6">
                <p>まだ通知先のメールアドレスが登録されていません。</p>
                <p>以下の入力欄から追加してください。</p>
              </v-col>
            </v-row>
            <v-row :key="m" v-for="(email, m) in this.owner.invoice.emails">
              <v-col md="6">
                <v-text-field
                  v-if="!readonly"
                  input
                  required
                  readonly
                  dense
                  :name="'email' + m"
                  :value="email"
                  v-validate="'required|email'"
                  :error-messages="getEmailErrors(m)"
                  data-vv-as="このメールアドレス"
                  append-outer-icon="delete"
                  @click:append-outer="deleteEmail(m)"
                ></v-text-field>
                <v-text-field
                  v-else
                  input
                  required
                  readonly
                  dense
                  :name="'email' + m"
                  :value="email"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row align="center" v-if="!readonly">
              <v-col md="6">
                <v-text-field
                  input
                  required
                  outlined
                  dense
                  label="追加するメールアドレスを入力"
                  v-model="email"
                  v-validate="'email'"
                  data-vv-as="このメールアドレス"
                  data-vv-validate-on="blur"
                  name="email"
                  :error-messages="emailErrors"
                >
                  <template v-slot:append-outer>
                    <v-btn color="primary" @click="addNewEmail()">
                      <v-icon>add</v-icon>
                      メール送付先を追加
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>

          <v-container v-if="!readonly">
            <v-row>
              <v-col>
                <v-btn @click="updateInvoice()" color="primary" block>
                  {{ this.operationName }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <ConfirmDialog ref="dialog"></ConfirmDialog>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-card-title class="headline">{{ title }}</v-card-title>
        <v-card-text>{{ text }}</v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="red" text @click="dialog = false"> キャンセル </v-btn>
          <v-btn color="teal" text @click="requestIncreaseCreditLimits"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import axios from '@/api/common/axios';
import _ from 'lodash';
import moment from 'moment-timezone';
import { CONTRACT_TYPE } from '@/../lib/document/schema/owners';
import { CATEGORY } from '@/../lib/document/schema/users';
import { mergeErrors } from '@/util/input';
import {
  START_LOADING,
  FINISH_LOADING,
  FETCH_OWNER,
  UPDATE_OWNER_INVOICE,
  UPDATE_CREDIT_LIMIT
} from '@/store/action-types';
import { AGREE_SUCCESS } from '@/store/mutation-types';
import ConfirmDialog from '@/components/ConfirmDialog';

export default {
  components: {
    ConfirmDialog
  },
  props: {
    ownerId: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    initialize: {
      type: Boolean,
      default: false
    }
  },
  $_veeValidate: {
    validator: 'new'
  },
  async mounted() {
    let value;
    if (this.ownerId) {
      value = { owner: this.ownerId };
    } else {
      value = this.$store.state.user;
    }

    await this.fetchOwner(value);
    // 利用規約に同意前にfetchOwnerしてもownerが取れないため，同意後にownerを取り直す
    this.$store.subscribe((mutation) => {
      if (mutation.type === `user/${AGREE_SUCCESS}`) {
        this.fetchOwner(value);
      }
    });
    await this.$validator.validate();
  },
  data() {
    return {
      email: '',
      serverErrors: {},
      creditLimit: '',
      title: '',
      text: '',
      dialog: false
    };
  },
  computed: {
    ...[
      'invoiceName',
      'invoiceDepartment',
      'invoiceStaffName',
      'invoiceAddress',
      'invoicePhoneNumber',
      'invoicePostalCode',
      'email'
    ].reduce((action, propName) => {
      // propName + 'Errors' という名前でエラー文字列を取得するcomputed関数を作成する
      action[propName + 'Errors'] = function () {
        return mergeErrors(this.$validator.errors, this.serverErrors, propName);
      };
      return action;
    }, {}),
    operationName: function () {
      return this.initialize ? '登録' : '更新';
    },
    isCorporate: function () {
      return this.owner.contract_type === CONTRACT_TYPE.CORPORATE;
    },
    isIndivisual: function () {
      return this.owner.contract_type === CONTRACT_TYPE.INDIVIDUAL;
    },
    // 請求書送付先と登録住所が同じか
    isSameInvoiceAddress: function () {
      return (
        [
          'address',
          'postal_code',
          'phone_number',
          'department',
          'staff_name'
        ].every((prop) => this.owner[prop] == this.owner.invoice[prop]) &&
        this.owner.invoice.name == this.owner.names.ja_jp
      );
    },
    nameLabel: function () {
      if (this.isIndivisual) {
        return '個人名';
      }
      return '会社名';
    },
    invoiceNameLabel: function () {
      if (this.isIndivisual) {
        return '宛名';
      }
      return '会社名';
    },
    ...mapState({
      owner: (state) => state.owner
    }),
    isTomUser: (self) => self.$store.state.user.isTomUser
  },
  methods: {
    clearErrors() {
      this.$validator.errors.clear();
      this.serverErrors = {};
    },
    async fetchOwner(ownerId) {
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      await this.$store
        .dispatch(`owner/${FETCH_OWNER}`, ownerId)
        .then(async () => {
          await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        })
        .catch(async (e) => {
          await this.$store.dispatch(
            'notify/showErrorNotify',
            e.response.data.errors.general.msg
          );
          await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        });
      this.creditLimit = this.$store.state.owner.credit_limit || 0;
    },
    async updateInvoice() {
      if (
        !(await this.$refs.dialog.open({
          headline: '更新の確認',
          text: '入力された情報で更新します'
        }))
      ) {
        return;
      }

      this.clearErrors();
      if (this.$store.state.user.category !== CATEGORY.MANAGER) {
        await this.$store.dispatch(
          'notify/showErrorNotify',
          '荷主情報の更新権限がありません。'
        );
        return;
      }
      if (!(await this.$validator.validate())) {
        await this.$store.dispatch(
          'notify/showErrorNotify',
          '入力項目に誤りがあります。修正して再度実行して下さい。'
        );
        return;
      }

      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      await this.$store
        .dispatch(`owner/${UPDATE_OWNER_INVOICE}`, {
          ownerId: this.owner.id,
          invoice: this.owner.invoice
        })
        .then(async () => {
          if (this.initialize) {
            // 設定後に納品書設定へ遷移する
            this.$router.push(`/owners/${this.owner.id}/initialize/slips`);
            // トップへスクロール
            await this.$vuetify.goTo(0);
          } else {
            await this.$store.dispatch(
              'notify/showNotify',
              '登録情報を変更しました。'
            );
          }

          await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        })
        .catch(async (e) => {
          this.serverErrors = {
            ...this.serverErrors,
            ...e.response.data.errors
          };
          await this.$store.dispatch(
            'notify/showErrorNotify',
            '入力項目に誤りがあります。修正して再度実行して下さい。'
          );
          await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        });
    },
    getEmailErrors: function (index) {
      return mergeErrors(
        this.$validator.errors,
        this.serverErrors,
        'email' + index
      );
    },
    deleteEmail: async function (emailOrder) {
      this.clearErrors();
      const invoice = { ...this.owner.invoice };
      invoice.emails.splice(emailOrder, 1);
      this.owner.invoice = {
        ...this.owner.invoice,
        ...invoice
      };

      await this.$validator.validate();
    },
    addNewEmail: async function () {
      this.clearErrors();
      if (!(await this.$validator.validate('email'))) {
        return;
      }
      if (this.owner.invoice.emails.length >= 4) {
        this.errors.add({
          field: 'email',
          msg: '請求書メール送付先は最大4件です'
        });
        return;
      }
      const invoice = { ...this.owner.invoice };
      invoice.emails.push(this.email);
      this.owner.invoice = { ...this.owner.invoice, ...invoice };
      this.email = '';
    },
    copyToInvoiceAddress: async function () {
      [
        'address',
        'postal_code',
        'phone_number',
        'department',
        'staff_name'
      ].forEach((prop) => {
        this.$set(this.owner.invoice, prop, this.owner[prop]);
      });
      // invoice.nameがowner.namesの構造と異なるので別にコピー
      this.$set(this.owner.invoice, 'name', this.owner.names.ja_jp);
      this.clearErrors();
      await this.$validator.validate();
    },
    moment,
    async updateCreditLimit() {
      if (
        !(await this.$refs.dialog.open({
          headline: '更新の確認',
          text: '入力された情報で更新します'
        }))
      ) {
        return;
      }

      this.clearErrors();
      if (this.$store.state.user.category !== CATEGORY.MANAGER) {
        await this.$store.dispatch(
          'notify/showErrorNotify',
          '荷主情報の更新権限がありません。'
        );
        return;
      }
      if (!(await this.$validator.validate('creditLimit'))) {
        await this.$store.dispatch(
          'notify/showErrorNotify',
          '入力項目に誤りがあります。修正して再度実行して下さい。'
        );
        return;
      }

      const param = {
        ownerId: this.ownerId,
        credit_limit: this.creditLimit
      };

      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      await this.$store
        .dispatch(`owner/${UPDATE_CREDIT_LIMIT}`, param)
        .then(async () => {
          await this.$store.dispatch(
            'notify/showNotify',
            '登録情報を変更しました。'
          );
          try {
            let value;
            if (this.ownerId) {
              value = { owner: this.ownerId };
            } else {
              value = this.$store.state.user;
            }
            await this.fetchOwner(value);
          } catch (err) {
            await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
            await this.$store.dispatch(
              'notify/showErrorNotify',
              `ページ情報の取得に失敗しました。ページを更新してください。${err.message}`
            );
          }
          await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        })
        .catch(async (e) => {
          this.serverErrors = {
            ...this.serverErrors,
            ...e.response.data.errors
          };
          await this.$store.dispatch(
            'notify/showErrorNotify',
            '入力項目に誤りがあります。修正して再度実行して下さい。'
          );
          await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        });
    },
    showDialog({ title, text }) {
      this.title = title;
      this.text = text;
      this.dialog = true;
    },
    async requestIncreaseCreditLimits() {
      await this.$store.dispatch('notify/clearNotify');
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());

      try {
        await axios.post('/owners/request_increase_credit_limits');
        await this.$store.dispatch(
          'notify/showNotify',
          'ご利用可能枠の増枠をリクエストしました。'
        );
      } catch (err) {
        await this.$store.dispatch(
          'notify/showErrorNotify',
          _.get(
            err,
            'response.data.errors.general',
            'エラーが発生しました、画面を更新してやり直してください'
          )
        );
        throw err;
      } finally {
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        this.dialog = false;
      }
    }
  }
};
</script>
