import querystring from 'querystring';
import _ from 'lodash';

import { SAMPLE_FETCHED, SAMPLE_CHECKED } from '../mutation-types';
import axios from '../../api/common/axios';

export default {
  namespaced: true,
  state: {
    headers: ['no', 'name'],
    items: [],
    checked: [],
    total: 0
  },
  mutations: {
    [SAMPLE_FETCHED](state, { items, total }) {
      state.items = items;
      state.total = total;
    },
    [SAMPLE_CHECKED](state, values) {
      state.checked = values;
    }
  },
  actions: {
    async fetch({ commit }, query) {
      const errorMsg =
        'データ取得時にエラーがありました。セカイロジ担当者までお問い合わせください。';
      const response = await axios
        .get(`/samples?${querystring.stringify(query)}`)
        .catch((e) => {
          throw new Error(_.get(e, 'response.data.errors.general', errorMsg));
        });

      if (_.get(response, 'data.status') === 'ng') {
        throw new Error(_.get(response, 'data.errors.general', errorMsg));
      }
      commit(SAMPLE_FETCHED, {
        items: response.data.items,
        total: response.data.total
      });
    }
  }
};
