const _ = require('lodash');
import querystring from 'querystring';
import axios from '@/api/common/axios';
import router from '@/router';
import { FETCH_USERS } from '@/store/action-types';
import { USERS_FETCH_SUCCESS } from '@/store/mutation-types';

export default {
  namespaced: true,
  /**
   * @property {Object} state
   * @property {{email: string, category: string}[]} state.items - 表示するユーザー一覧
   * @property {number} state.total - 全体のユーザー数
   */
  state: {
    items: [],
    total: 0
  },
  mutations: {
    [USERS_FETCH_SUCCESS](state, { items, total }) {
      if (!_.isArray(items) || !_.isInteger(total)) {
        throw new Error('ユーザーが取得できませんでした');
      }

      state.items = items;
      state.total = total;
    }
  },
  actions: {
    /**
     * ユーザーの一覧を取得する
     * @param {Object} context
     * @param {function} context.commit
     * @param {Object} query
     * @returns {Promise<void>}
     */
    async [FETCH_USERS]({ commit }, query) {
      const ownerId = router.currentRoute.params.ownerId;

      const errorMsg =
        'データ取得時にエラーがありました。セカイロジ担当者までお問い合わせください。';
      const response = await axios
        .get(`/owners/${ownerId}/users?${querystring.stringify(query)}`)
        .catch((e) => {
          throw new Error(_.get(e, 'response.data.errors.general', errorMsg));
        });

      if (_.get(response, 'data.status') === 'ng') {
        throw new Error(_.get(response, 'data.errors.general', errorMsg));
      }

      commit(USERS_FETCH_SUCCESS, {
        items: _.get(response, 'data.items'),
        total: _.get(response, 'data.total')
      });
    }
  }
};
