<template>
  <v-container fluid fill-height>
    <v-layout row wrap align-center justify-center>
      <v-flex md4 xs4 text-center>
        <Logo></Logo>
        <v-card class="mt-10 mx-auto" max-width="480">
          <v-card-text class="pa-6">
            <v-form class="mb-3" @submit.prevent>
              <v-text-field
                outlined
                required
                name="email"
                v-model="email"
                data-vv-as="メールアドレス"
                v-validate="'required|email|max:256'"
                data-vv-validate-on="blur"
                :error-messages="emailErrors"
                prepend-inner-icon="mail"
                label="メールアドレス"
                @keyup.enter="login"
              ></v-text-field>
              <v-text-field
                outlined
                required
                :type="show ? 'text' : 'password'"
                name="password"
                v-model="password"
                data-vv-as="パスワード"
                v-validate="'required|max:256'"
                data-vv-validate-on="blur"
                :error-messages="passwordErrors"
                prepend-inner-icon="lock"
                :append-icon="show ? 'visibility' : 'visibility_off'"
                @click:append="show = !show"
                label="パスワード"
                @keyup.enter="login"
              ></v-text-field>
              <v-btn @click="login" color="primary" large depressed
                >ログイン</v-btn
              >
            </v-form>
          </v-card-text>
        </v-card>

        <p class="mt-8">
          <router-link to="/auth/forgot" class="u-text-decoration-none">
            パスワードを忘れた方はこちら
          </router-link>
        </p>

        <div class="mt-4">
          <v-btn
            color="primary"
            outlined
            href="https://sekailogi.com/signup/"
            target="_blank"
            >アカウント新規登録</v-btn
          >
        </div>

        <p class="mt-8">
          ログインすることにより、
          <a
            :href="TERMS_OF_SERVICE_LINK"
            target="_blank"
            rel="noopener noreferrer"
            class="u-text-decoration-none"
          >
            利用規約
            <v-icon small>launch</v-icon>
          </a>
          および
          <a
            :href="PRIVACY_POLICY_LINK"
            target="_blank"
            rel="noopener noreferrer"
            class="u-text-decoration-none"
          >
            プライバシーポリシー
            <v-icon small>launch</v-icon>
          </a>
          に同意したとみなされます。
        </p>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
const _ = require('lodash');

import {
  TERMS_OF_SERVICE_LINK,
  PRIVACY_POLICY_LINK
} from '@/../public/agreement-url';
import { mergeErrors } from '@/util/input';
import {
  FINISH_LOADING,
  INITIALIZE_LOGIN_PAGE,
  START_LOADING
} from '@/store/action-types';

import Logo from '../components/Logo.vue';

export default {
  components: {
    Logo
  },
  $_veeValidate: {
    validator: 'new'
  },
  data: () => ({
    email: '',
    password: '',
    show: false
  }),
  computed: {
    TERMS_OF_SERVICE_LINK: () => TERMS_OF_SERVICE_LINK,
    PRIVACY_POLICY_LINK: () => PRIVACY_POLICY_LINK,
    emailErrors: (self) =>
      mergeErrors(
        self.$validator.errors,
        self.$store.state.auth.loginErrors,
        'email'
      ),
    passwordErrors: (self) =>
      mergeErrors(
        self.$validator.errors,
        self.$store.state.auth.loginErrors,
        'password'
      )
  },
  async beforeMount() {
    await this.$store.dispatch(`auth/${INITIALIZE_LOGIN_PAGE}`);
  },
  methods: {
    /**
     * ログイン処理を行う
     * @returns {Promise<void>}
     */
    async login() {
      this.$validator.errors.clear();
      await this.$store.dispatch('notify/clearNotify');
      if (await this.$validator.validate()) {
        await this.$store.dispatch(`app/${START_LOADING}`, new Date());
        try {
          await this.$store.dispatch('auth/login', {
            email: this.email,
            password: this.password
          });
        } catch (e) {
          const errorMessage = _.get(e, 'response.data.errors.general');
          await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
          await this.$store.dispatch(
            'notify/showErrorNotify',
            errorMessage ||
              'エラーが発生しました、画面を更新してやり直してください'
          );
          throw e;
        }
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());

        if (this.$store.state.user.isLoggedIn) {
          this.$router.push(this.$route.query.redirect || '/');
        } else {
          const generalError = _.get(
            this.$store.state.auth.loginErrors,
            'general.msg'
          );
          if (generalError) {
            await this.$store.dispatch('notify/showErrorNotify', generalError);
          }
        }
      }
    }
  }
};
</script>

<style>
#layout-view {
  padding-bottom: 0;
}
</style>
