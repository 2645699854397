<template>
  <v-container>
    <v-layout wrap>
      <v-flex xs12>
        <v-btn
          v-if="$store.state.user.category === 'MANAGER'"
          class="mb-4"
          color="primary accent-4"
          text
          to="/settings/slips"
        >
          納品書を設定する
        </v-btn>
      </v-flex>
      <v-row>
        <v-col>
          <v-card>
            <v-card-text>
              <v-layout>
                <v-flex xs6>
                  <h3 class="font-weight-bold subtitle-2 mt-2">
                    テンプレート名
                  </h3>
                  <div class="caption ml-4">テンプレート名</div>
                  <div class="body-1 text--primary ml-4">
                    {{ detail.templateName }}
                  </div>

                  <h3 class="font-weight-bold subtitle-2 mt-2">対応地域</h3>
                  <div class="caption ml-4">対応地域</div>
                  <div class="body-1 text--primary ml-4">
                    {{ detail.lang === LANG.JA_JP ? '国内向け' : '海外向け' }}
                  </div>

                  <h3 class="font-weight-bold subtitle-2 mt-2">
                    納品書タイトルの表記
                  </h3>
                  <div class="caption ml-4">納品書タイトル</div>
                  <div class="body-1 text--primary ml-4">
                    {{ detail.slipTitle }}
                  </div>

                  <h3 class="font-weight-bold subtitle-2 mt-2">発行元の表記</h3>
                  <template v-if="detail.logo">
                    <div class="caption ml-4">ロゴ画像</div>
                    <v-container>
                      <v-img
                        :src="detail.logo"
                        contain
                        style="max-height: 160px"
                      />
                    </v-container>
                  </template>
                  <div class="caption ml-4">
                    発行元名（兼 送り状伝票記載荷主名）
                  </div>
                  <div class="body-1 text--primary ml-4">
                    {{ detail.ownerInfo.name }}
                  </div>
                  <div class="caption ml-4">1行目</div>
                  <div class="body-1 text--primary ml-4">
                    {{ detail.ownerInfo.line1 }}
                  </div>
                  <div class="caption ml-4">2行目</div>
                  <div class="body-1 text--primary ml-4">
                    {{ detail.ownerInfo.line2 }}
                  </div>
                  <div class="caption ml-4">3行目</div>
                  <div class="body-1 text--primary ml-4">
                    {{ detail.ownerInfo.line3 }}
                  </div>
                  <div class="caption ml-4">4行目</div>
                  <div class="body-1 text--primary ml-4">
                    {{ detail.ownerInfo.line4 }}
                  </div>

                  <h3 class="font-weight-bold subtitle-2 mt-2">
                    商品欄のヘッダー表記
                  </h3>
                  <div class="caption ml-4">商品番号</div>
                  <div class="body-1 text--primary ml-4">
                    {{ detail.header.barcode }}
                  </div>
                  <div class="caption ml-4">商品名</div>
                  <div class="body-1 text--primary ml-4">
                    {{ detail.header.productTitle }}
                  </div>
                  <div class="caption ml-4">個数</div>
                  <div class="body-1 text--primary ml-4">
                    {{ detail.header.qty }}
                  </div>
                  <div class="caption ml-4">購入金額</div>
                  <div class="body-1 text--primary ml-4">
                    {{ detail.header.amount }}
                  </div>
                  <div class="caption ml-4">合計金額</div>
                  <div class="body-1 text--primary ml-4">
                    {{ detail.header.totalAmount }}
                  </div>

                  <h3 class="font-weight-bold subtitle-2 mt-2">備考欄の表記</h3>
                  <div class="caption ml-4">備考欄</div>
                  <!-- white-space: pre-wrap で空白をそのまま表示するので隙間がないようにする -->
                  <!-- display: inline -->
                  <div
                    class="body-1 text--primary ml-4"
                    style="white-space: pre-wrap"
                    >{{ detail.footer.note }}</div
                  >
                </v-flex>

                <v-flex xs6 ml-3>
                  <h3 class="font-weight-bold subtitle-2 mt-2">
                    簡易プレビュー
                  </h3>
                  <SlipPreview
                    :slip-title="detail.slipTitle"
                    :owner-info="detail.ownerInfo"
                    :header="detail.header"
                    :lang="detail.lang"
                    :logo="detail.logo"
                    :footer="detail.footer"
                  ></SlipPreview>

                  <h3 class="font-weight-bold subtitle-2 mt-2">
                    詳細プレビュー
                  </h3>
                  <v-btn
                    text
                    color="primary accent-4"
                    class="v-btn--active mt-2 ml-4"
                    :href="previewUrl"
                    target="_blank"
                  >
                    PDFで確認する
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-flex xs12>
        <v-btn
          v-if="$store.state.user.category === 'MANAGER'"
          class="mt-4"
          color="primary accent-4"
          text
          to="/settings/slips"
        >
          納品書を設定する
        </v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import _ from 'lodash';
import {
  LANG,
  LANG_PATTERN,
  LANG_LABEL
} from '@/../lib/document/schema/slip_templates';
import SlipPreview from '@/components/slip_templates/Preview.vue';
import {
  FETCH_SLIP_TEMPLATE,
  START_LOADING,
  FINISH_LOADING
} from '@/store/action-types';

export default {
  components: {
    SlipPreview
  },
  data() {
    return {
      detail: {
        templateName: '',
        slipTitle: '',
        ownerInfo: {
          line1: '',
          line2: '',
          line3: '',
          line4: '',
          line5: ''
        },
        header: {
          barcode: '',
          productTitle: '',
          qty: '',
          amount: '',
          totalAmount: ''
        },
        lang: '',
        logo: '',
        footer: {
          note: ''
        }
      }
    };
  },
  computed: {
    LANG: () => LANG,
    LANG_PATTERN: () => LANG_PATTERN,
    LANG_LABEL: () => LANG_LABEL,
    previewUrl: (self) => {
      const params = new URLSearchParams({
        title: _.get(self, 'detail.slipTitle', ''),
        ownerInfoLine1: _.get(self, 'detail.ownerInfo.line1', ''),
        ownerInfoLine2: _.get(self, 'detail.ownerInfo.line2', ''),
        ownerInfoLine3: _.get(self, 'detail.ownerInfo.line3', ''),
        ownerInfoLine4: _.get(self, 'detail.ownerInfo.line4', ''),
        ownerInfoLine5: _.get(self, 'detail.ownerInfo.line5', ''),
        headerBarcode: _.get(self, 'detail.header.barcode', ''),
        headerProductTitle: _.get(self, 'detail.header.productTitle', ''),
        headerQty: _.get(self, 'detail.header.qty', ''),
        headerAmount: _.get(self, 'detail.header.amount', ''),
        headerTotalAmount: _.get(self, 'detail.header.totalAmount', ''),
        lang: _.get(self, 'detail.lang', ''),
        logo: _.get(self, 'detail.logo', ''),
        footerNote: _.get(self, 'detail.footer.note', '')
      });
      return `/slip_templates/preview?${params.toString()}`;
    }
  },
  methods: {
    async fetchTemplate() {
      await this.$store.dispatch(`slipTemplates/${FETCH_SLIP_TEMPLATE}`, {
        slipTemplateId: this.$route.params.slipTemplateId
      });
      this.detail = this.$store.state.slipTemplates.detail;
    }
  },
  async beforeMount() {
    await this.$store.dispatch(`app/${START_LOADING}`, new Date());
    try {
      await this.fetchTemplate();
    } catch (err) {
      await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
      await this.$store.dispatch(
        'notify/showErrorNotify',
        `ページ情報の取得に失敗しました。ページを更新してください。${err.message}`
      );
    }
    await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
  }
};
</script>
