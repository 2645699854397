<template>
  <div>
    <v-card>
      <v-card-title class="headline">レポートを送信</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-flex>
          レポート送信する年月を選択し、送信ボタンを押してください。
        </v-flex>
        <v-select
          v-model="selectedDate"
          :items="selectableMonth"
          item-value="id"
          item-text="name"
          label="年月"
          class="mr-2 mt-3 mb-3"
          outlined
          hide-details
        ></v-select>
        <v-btn color="teal" text @click="sendReport()"> レポート送信 </v-btn>
      </v-card-text>
    </v-card>
    <v-card class="mt-6">
      <v-card-title class="headline">CSVアップロード</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-layout>
          <v-flex>
            配送情報が記入されたCSVを選択してアップロードしてください。
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex v-if="isTomUser">
            <CsvUploadForm :category="CATEGORY.ACTUAL_SHIPMENT" />
          </v-flex>
          <v-flex v-else class="text-center my-12">
            アップロード処理が行えません。
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
    <v-flex v-if="isTomUser">
      <CsvUploadProgress
        :category="CATEGORY.ACTUAL_SHIPMENT"
        class="mt-6"
      ></CsvUploadProgress>
    </v-flex>
  </div>
</template>

<script>
import { CATEGORY } from '@/../lib/document/schema/csv_upload_states';
import CsvUploadForm from '@/components/CsvUploadForm';
import CsvUploadProgress from '@/components/CsvUploadProgress';
import moment from 'moment-timezone';
import axios from '@/api/common/axios';
import { START_LOADING, FINISH_LOADING } from '@/store/action-types';

export default {
  components: {
    CsvUploadForm,
    CsvUploadProgress
  },
  data() {
    return {
      selectedDate: ''
    };
  },
  computed: {
    CATEGORY: () => CATEGORY,
    isTomUser: (self) => self.$store.state.user.isTomUser,
    selectableMonth: () =>
      [...Array(6).keys()].map((i) =>
        moment()
          .subtract(i + 1, 'months')
          .tz('Asia/Tokyo')
          .format('YYYY/MM')
      )
  },
  methods: {
    async sendReport() {
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      if (this.selectedDate) {
        const dt = moment(this.selectedDate);
        await axios.get(
          `/shipments/actual_shipment_report?report_date=${dt.format(
            'YYYY-MM-DD'
          )}`
        );
      } else {
        await this.$store.dispatch(
          'notify/showErrorNotify',
          'レポート送信する年月を選択してください。'
        );
      }
      await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
    }
  }
};
</script>
