<template>
  <div class="clipboard-chip-container">
    <v-tooltip
      v-model="tooltipShow"
      :disabled="tooltipDisabled"
      top
      :close-delay="tooltipCloseDelay"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-chip
          label
          link
          :small="small"
          class="clipboard-chip"
          color="rgba(126, 163, 193, 0.12)"
          text-color="rgba(0, 0, 0, 0.54)"
          v-bind="attrs"
          v-on="on"
          v-clipboard:copy="textForCopy || text"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
        >
          {{ textForDisplay || text }}
          <v-icon right small>content_copy</v-icon>
        </v-chip>
      </template>
      <span>{{ tooltipText }}</span>
    </v-tooltip>
  </div>
</template>

<script>
const STATES = {
  DEFAULT: 'default',
  COPIED: 'copied',
  ERROR: 'error'
};

export default {
  props: {
    icon: String,
    text: String,
    small: Boolean,
    textForCopy: String,
    textForDisplay: String
  },

  data: () => {
    return {
      state: STATES.DEFAULT,
      tooltipCloseDelay: 2000,
      tooltipShow: false,
      tooltipDisabled: true
    };
  },

  watch: {
    state(val) {
      if (val === STATES.COPIED || val === STATES.ERROR) {
        this.tooltipDisabled = false;
        this.tooltipShow = true;
        setTimeout(() => {
          this.tooltipDisabled = true;
          this.tooltipShow = false;
          this.state = STATES.DEFAULT;
        }, this.tooltipCloseDelay);
      }
    }
  },

  computed: {
    tooltipText() {
      if (this.state === STATES.COPIED) {
        return 'コピーしました';
      } else if (this.state === STATES.ERROR) {
        return 'コピーに失敗しました';
      }

      return null;
    }
  },

  methods: {
    onCopy() {
      this.state = STATES.COPIED;
      this.$emit('copied');
    },
    onError() {
      this.state = STATES.ERROR;
      this.$emit('error');
    }
  }
};
</script>

<style scoped>
.clipboard-chip-container {
  display: inline-block;
}
.clipboard-chip {
  padding-left: 6px;
}
.clipboard-chip:hover {
  color: #1d75be !important;
}
.clipboard-chip.v-chip.v-size--default {
  height: 24px;
}
.clipboard-chip.v-chip.v-size--small {
  height: 20px;
}
</style>
