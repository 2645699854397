<template>
  <div>
    <v-card>
      <v-card-text>
        <v-layout>
          <v-text-field
            label="荷主名"
            v-model="keywords"
            prepend-inner-icon="search"
            outlined
            hide-details
          ></v-text-field>
          <v-checkbox
            label="削除済みも表示する"
            v-model="showDisabled"
            class="ml-2"
          />
          <v-btn
            color="primary"
            class="ml-2"
            height="56"
            outlined
            @click.stop="onClickCsvDownload"
          >
            CSVダウンロード(全件)
          </v-btn>
        </v-layout>
      </v-card-text>
      <TableAndPaging
        :headers="['荷主番号', '荷主名', 'ステータス', '削除日']"
        :items="$store.state.owners.owners"
        :total="$store.state.owners.total"
        :query-params="queryParams"
        :action="action"
        @dblclick-row="onDoubleClickRow"
      >
        <template v-slot:row="props">
          <td>
            {{ props.item.unique_number }}
          </td>
          <td>
            <router-link
              :to="`/owners/${props.item._id}`"
              class="link-row--main"
            >
              {{ props.item.names.ja_jp }}
            </router-link>
          </td>
          <td>
            {{
              convertOwnerStatus({
                state: props.item.state,
                accountStatus: props.item.account_status
              })
            }}
          </td>
          <td>
            {{
              props.item.stopped_at
                ? moment(props.item.stopped_at)
                    .add(30, 'days')
                    .tz('Asia/Tokyo')
                    .format('YYYY/MM/DD')
                : ''
            }}
          </td>
        </template>
      </TableAndPaging>
    </v-card>
  </div>
</template>

<script>
import _ from 'lodash';
import TableAndPaging from '@/components/TableAndPaging';
import {
  FETCH_OWNERS,
  START_LOADING,
  FINISH_LOADING
} from '@/store/action-types';
import ownersSchema from '@/../lib/document/schema/owners';
import moment from 'moment-timezone';
import axios from '@/api/common/axios';

export default {
  components: {
    TableAndPaging
  },
  data() {
    const params = this.$route.query;
    return {
      keywords: params.filter_keywords || '',
      showDisabled: false
    };
  },
  computed: {
    action: () => `owners/${FETCH_OWNERS}`,
    queryParams: function () {
      return {
        filter_keywords: this.keywords,
        only_available: this.showDisabled ? undefined : true
      };
    }
  },
  methods: {
    convertOwnerStatus({ state, accountStatus }) {
      if (accountStatus === ownersSchema.ACCOUNT_STATUS.READ_ONLY) {
        return '利用中（閲覧のみ）';
      }
      return ownersSchema.STATE_LABEL[state];
    },
    moment,
    onDoubleClickRow(owner) {
      this.$router.push(`/owners/${owner._id}`);
    },
    async onClickCsvDownload() {
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());

      try {
        await axios.get('/owners/csv');
      } catch (error) {
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        await this.$store.dispatch(
          'notify/showErrorNotify',
          _.get(
            error,
            'response.data.errors.general',
            'エラーが発生しました、画面を更新してやり直してください'
          )
        );
        throw error;
      }
      await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
      await this.$store.dispatch(
        'notify/showNotify',
        '処理を開始しました。処理完了時にメールで通知します。'
      );
    }
  }
};
</script>

<style scoped>
.link-row--main {
  display: block;
  font-weight: bold;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
}
.link-row--main:hover {
  text-decoration: underline;
  color: rgba(0, 0, 0, 0.87);
}
</style>
