'use strict';

import {
  CREATE_SHIPMENT,
  FETCH_SHIPMENT_DETAIL,
  UPDATE_SHIPMENT,
  FETCH_SHIPMENT_EDIT,
  POLL_SUBMIT_SHIPMENT
} from '@/store/action-types';
import {
  SHIPMENT_CREATED,
  SHIPMENT_DETAIL_FETCHED,
  SHIPMENT_EDIT_FETCHED,
  SHIPMENT_UPDATED,
  POLL_SUBMIT_SHIPMENT_FETCHED
} from '@/store/mutation-types';

import { JsonError } from '@/util/error';
import { SHIPPING_METHOD } from '@/../lib/document/schema/shipments';
import { SHIP_TYPE_DICTIONARY } from '@/../lib/document/schema/items';
import _ from 'lodash';
import axios from '@/api/common/axios';

export default {
  namespaced: true,
  state: {
    ownerName: '',
    ownerId: '',
    ownerOrderNo: '',
    customerName: '',
    customerAddress: '',
    customerEmail: '',
    customerPhone: '',
    customerCountryCode: '',
    customerPostalCode: '',
    customerState: '',
    customerCity: '',
    customerAddress1: '',
    customerAddress2: '',
    createdAt: null,
    canceledAt: null,
    approvedAt: null,
    shipments: [],
    giftWrapping: '',
    shippingMethod: '',
    slipName: '',
    flyer: '',
    duty: '',
    deliveryDate: '',
    priority: '',
    items: [],
    errors: {},
    sqsTaskId: '',
    id: ''
  },
  mutations: {
    [SHIPMENT_DETAIL_FETCHED](state, { shipment }) {
      state.ownerName = '';
      state.ownerId = '';
      if (
        shipment.shipmentRequest &&
        shipment.shipmentRequest.owner &&
        shipment.shipmentRequest.owner.names &&
        shipment.shipmentRequest.owner.names.ja_jp
      ) {
        state.ownerName = shipment.shipmentRequest.owner.names.ja_jp;
        state.ownerId = shipment.shipmentRequest.owner._id;
      }
      state.state = shipment.shipmentRequest.state;
      state.ownerOrderNo = shipment.shipmentRequest.owner_order_no;
      state.customerName = '';
      state.customerAddress = '';
      state.customerEmail = '';
      state.customerPhone = '';
      if (shipment.shipmentRequest && shipment.shipmentRequest.customer) {
        state.customerName = shipment.shipmentRequest.customer.name;
        state.customerAddress = [
          shipment.shipmentRequest.customer.country_code,
          shipment.shipmentRequest.customer.postal_code,
          shipment.shipmentRequest.customer.state,
          shipment.shipmentRequest.customer.city,
          shipment.shipmentRequest.customer.address1,
          shipment.shipmentRequest.customer.address2
        ].join(' ');

        state.customerEmail = shipment.shipmentRequest.customer.email || '';
        state.customerPhone = shipment.shipmentRequest.customer.phone || '';
        state.customerCountryCode =
          shipment.shipmentRequest.customer.country_code || '';
        state.customerPostalCode =
          shipment.shipmentRequest.customer.postal_code || '';
        state.customerState = shipment.shipmentRequest.customer.state || '';
        state.customerCity = shipment.shipmentRequest.customer.city || '';
        state.customerAddress1 =
          shipment.shipmentRequest.customer.address1 || '';
        state.customerAddress2 =
          shipment.shipmentRequest.customer.address2 || '';
      }
      state.createdAt = shipment.shipmentRequest.created_at;
      state.canceledAt = shipment.shipmentRequest.canceled_at;
      state.approvedAt = shipment.shipmentRequest.approved_at;
      state.giftWrapping = shipment.shipmentRequest.gift_wrapping;
      state.shippingMethod = shipment.shipmentRequest.shipping_method;
      state.slipName = shipment.shipmentRequest.slip_name;
      state.flyer = shipment.shipmentRequest.flyer;
      state.duty = shipment.shipmentRequest.duty;
      state.deliveryDate = shipment.shipmentRequest.delivery_date;
      state.items = shipment.shipmentRequest.items;
      state.editorEmail =
        shipment.shipmentRequest.editor &&
        shipment.shipmentRequest.editor.email;
      state.priority = shipment.shipmentRequest.priority ? true : false;
      state.contentType = shipment.shipmentRequest.content_type;

      state.shipments = shipment.shipments.map((s) => {
        const shipment = {};

        shipment._id = s._id;
        shipment.shipping_method = s.shipping_method;
        shipment.delivery_schedule_date = s.delivery_schedule_date;
        shipment.state = s.state;
        shipment.shipping_cost = s.shipping_cost;
        shipment.total_weight = s.total_weight;
        shipment.actual_weight = s.actual_weight;
        shipment.dimensional_weight = s.dimensional_weight;
        // チャーター便は箱データが無いので表示しない
        if (shipment.shipping_method !== SHIPPING_METHOD.CHARTER) {
          shipment.box = (s.box && s.box.label) || SHIP_TYPE_DICTIONARY.NO_BOX;
        }

        shipment.items = s.items.map((i) => {
          const item = {};
          item._id = i._id._id;
          item.barcode = i._id.barcode;
          item.ownerItemCode = i._id.owner_item_code;
          item.name = '';
          if (i._id.names) {
            item.name = i._id.names.ja_jp || i._id.names.en_us;
          }
          if (i.item_bundle && i.item_bundle.names) {
            item.item_bundle = i.item_bundle;
            item.bundle_name =
              i.item_bundle.names.ja_jp || i.item_bundle.names.en_us;
          }
          item.qty = i.qty;
          item.price = i.price || '';
          return item;
        });

        shipment.tracking_code = s.tracking_code;
        shipment.shipped_at = s.shipped_at;

        shipment.slip_template = s.slip_template;
        if (s.flyer && s.flyer.number && s.flyer.title) {
          shipment.flyer = {
            number: ('0000' + s.flyer.number).slice(-4),
            title: s.flyer.title
          };
        }

        s.gift_wrapping
          ? (shipment.gift_wrapping = '有り')
          : (shipment.gift_wrapping = '無し');

        shipment.duty = s.duty;
        shipment.auto_selected_shipping_method_attribute =
          s.auto_selected_shipping_method_attribute;

        shipment.shipping_paper = s.shipping_paper;
        shipment.content_type = s.content_type;

        return shipment;
      });
    },
    [SHIPMENT_CREATED](state, { sqsTaskId }) {
      state.sqsTaskId = sqsTaskId;
    },
    [SHIPMENT_EDIT_FETCHED](state, { shipment, error }) {
      this.commit(`shipment/${SHIPMENT_DETAIL_FETCHED}`, { shipment });

      // エラーを保持
      if (error instanceof JsonError) {
        state.errors = error.toJson();
      } else {
        state.errors = {
          general: error.message
        };
      }
    },
    [SHIPMENT_UPDATED](state, { sqsTaskId }) {
      state.sqsTaskId = sqsTaskId;
    },
    [POLL_SUBMIT_SHIPMENT_FETCHED](state, { error, id }) {
      // エラーを保持
      if (error instanceof JsonError) {
        state.errors = error.toJson();
      } else {
        if (error.message) {
          state.errors = {
            general: error.message
          };
        } else {
          // エラーをクリア
          state.errors = {};
          state.id = id;
        }
      }
    }
  },
  actions: {
    async [FETCH_SHIPMENT_DETAIL]({ commit }, params) {
      const response = await axios.get(
        `/shipments/${params.shipmentRequestId}`
      );
      commit(SHIPMENT_DETAIL_FETCHED, {
        shipment: response.data.shipment
      });
    },
    async [CREATE_SHIPMENT]({ commit }, params) {
      try {
        const response = await axios.post('/shipments/form', params);
        commit(SHIPMENT_CREATED, {
          sqsTaskId: response.data.sqsTaskId
        });
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          throw new JsonError(error.response.data.errors);
        } else {
          throw new Error(
            _.get(
              error,
              'message',
              'エラーが発生しました、画面を更新してやり直してください'
            )
          );
        }
      }
    },
    async [UPDATE_SHIPMENT]({ commit }, params) {
      try {
        const response = await axios.post('/shipments/edit', params);
        commit(SHIPMENT_UPDATED, {
          sqsTaskId: response.data.sqsTaskId
        });
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          throw new JsonError(error.response.data.errors);
        } else {
          throw new Error(
            _.get(
              error,
              'message',
              'エラーが発生しました、画面を更新してやり直してください'
            )
          );
        }
      }
    },
    async [FETCH_SHIPMENT_EDIT]({ commit }, params) {
      const response = await axios.get(
        `/shipments/${params.shipmentRequestId}/edit`
      );
      commit(SHIPMENT_EDIT_FETCHED, {
        shipment: response.data.shipment,
        error: new JsonError(response.data.errors)
      });
    },
    async [POLL_SUBMIT_SHIPMENT]({ commit }, { sqsTaskId }) {
      let isCompleted = false;
      let result;
      do {
        const response = await axios.get(`/shipments/progress/${sqsTaskId}`);

        result = response.data;
        // 結果がない状態でも status: ok は入っているので
        if (Object.keys(result).length <= 1) {
          await new Promise((resolve) => {
            setTimeout(() => {
              resolve();
            }, 1000);
          });
          continue;
        }

        isCompleted = true;
      } while (!isCompleted);

      if (typeof result.completed !== 'undefined') {
        commit(POLL_SUBMIT_SHIPMENT_FETCHED, {
          error: {},
          id: result.completed
        });
        return;
      }

      // 不要なstatusが結果表示に載るのを防ぐ
      delete result.status;
      commit(POLL_SUBMIT_SHIPMENT_FETCHED, {
        error: new JsonError(result)
      });
    }
  }
};
