const _ = require('lodash');

import querystring from 'querystring';
import axios from '@/api/common/axios';
import {
  FETCH_OWNERS,
  FETCH_OWNERS_ALL,
  FETCH_OWNERS_BILLINGS
} from '@/store/action-types';
import {
  OWNERS_FETCHED,
  OWNERS_BILLINGS_FETCHED
} from '@/store/mutation-types';

export default {
  namespaced: true,
  /**
   * @property {Object} state
   * @property {[Owners]} state.owners - 荷主の一覧
   * @property {number} state.total - 全体の荷主数
   */
  state: {
    owners: [],
    billings: [],
    total: 0,
    billingsTotalCount: 0,
    billingsApprovedCount: 0,
    billingsUnpaidCount: 0
  },
  mutations: {
    [OWNERS_FETCHED](state, { owners, total }) {
      if (!_.isArray(owners) || !_.isInteger(total)) {
        throw new Error('荷主が取得できませんでした');
      }

      state.owners = owners.filter((o) => o.names && o.names.ja_jp);
      state.total = total;
    },
    [OWNERS_BILLINGS_FETCHED](state, { billings, total, approved, unpaid }) {
      if (!_.isArray(billings) || !_.isInteger(total)) {
        throw new Error('荷主が取得できませんでした');
      }

      state.billings = billings;
      state.billingsTotalCount = total;
      state.billingsApprovedCount = approved;
      state.billingsUnpaidCount = unpaid;
    }
  },
  actions: {
    /**
     * 荷主の一覧を取得する
     * @param {Object} context
     * @param {function} context.commit
     * @returns {Promise<void>}
     */
    async [FETCH_OWNERS]({ commit }, query) {
      const response = await axios.get(
        `/owners/index?${querystring.stringify({
          ...{
            limit: 30,
            page: 1,
            sort: 'names.ja_jp:asc'
          },
          ...query
        })}`
      );

      commit(OWNERS_FETCHED, {
        owners: _.get(response, 'data.owners'),
        total: _.get(response, 'data.total')
      });
    },
    /**
     * 荷主の請求一覧を取得する
     * @param {Object} context
     * @param {function} context.commit
     * @returns {Promise<void>}
     */
    async [FETCH_OWNERS_BILLINGS]({ commit }, query) {
      const response = await axios.get(
        `/owners/billings?${querystring.stringify({
          ...{
            limit: 30,
            page: 1
          },
          ...query
        })}`
      );

      let billings = _.get(response, 'data.billings');

      commit(OWNERS_BILLINGS_FETCHED, {
        billings,
        total: _.get(response, 'data.total'),
        approved: _.get(response, 'data.approved'),
        unpaid: _.get(response, 'data.unpaid')
      });
    },
    /**
     * プルダウン用などに荷主全部取る
     * @param {Object} context
     * @param {function} context.commit
     * @returns {Promise<void>}
     */
    async [FETCH_OWNERS_ALL]({ commit }, query) {
      const response = await axios.get(
        `/owners/index?${querystring.stringify({
          ...{
            limit: 0,
            page: 1,
            only_available: true, // 未削除の荷主のみに絞る
            sort: 'created_at:asc'
          },
          ...query
        })}`
      );

      commit(OWNERS_FETCHED, {
        owners: _.get(response, 'data.owners'),
        total: _.get(response, 'data.total')
      });
    }
  }
};
