import querystring from 'querystring';
import _ from 'lodash';
import axios from '@/api/common/axios';

import { STATE_LABEL, STATE } from '@/../lib/document/schema/manifests';
import { MANIFESTS_FETCHED } from '@/store/mutation-types';
import {
  FETCH_MANIFESTS,
  DOWNLOAD_MANIFESTS_CSV_REQUESTS
} from '@/store/action-types';
import { formatDate } from '@/util/formatter';

const headers = [
  'ステータス',
  '入庫依頼番号',
  '着荷予定日',
  'トラッキングコード',
  '運送会社',
  '入庫依頼書'
];
export default {
  namespaced: true,
  state: {
    headers: headers,
    headersForTomUser: ['荷主', ...headers],
    manifests: [],
    statusLabels: [
      '全て',
      ...Object.keys(STATE_LABEL).map((k) => {
        return STATE_LABEL[k];
      })
    ],
    sortLabels: [
      { text: '入庫依頼番号 (A-Z)', value: 'owner_manifest_no:asc' },
      { text: '入庫依頼番号 (Z-A)', value: 'owner_manifest_no:desc' },
      { text: '着荷予定日 (古い順)', value: 'delivery_schedule:asc' },
      { text: '着荷予定日 (新しい順)', value: 'delivery_schedule:desc' },
      { text: '登録日 (古い順)', value: 'created_at:asc' },
      { text: '登録日 (新しい順)', value: 'created_at:desc' }
    ],
    total: 0
  },
  mutations: {
    [MANIFESTS_FETCHED](state, { manifests, total }) {
      state.manifests = manifests;
      state.total = total;
    }
  },
  actions: {
    async [FETCH_MANIFESTS]({ commit }, query) {
      const errorMsg =
        'データ取得時にエラーがありました。セカイロジ担当者までお問い合わせください。';
      const response = await axios
        .get(`/manifests?${querystring.stringify(query)}`)
        .catch((e) => {
          throw new Error(_.get(e, 'response.data.errors.general', errorMsg));
        });

      if (_.get(response, 'data.status') === 'ng') {
        throw new Error(_.get(response, 'data.errors.general', errorMsg));
      }
      response.data.manifests.map((manifest) => {
        manifest.cancelable = manifest.state === STATE.WAIT;
        manifest.state_law = manifest.state;
        manifest.state = STATE_LABEL[manifest.state];
        manifest.created_at = formatDate({ date: manifest.created_at });
        manifest.updated_at = formatDate({ date: manifest.updated_at });
        manifest.delivery_schedule = formatDate({
          date: manifest.delivery_schedule,
          format: 'YYYY-MM-DD'
        });
        return manifest;
      });

      commit(MANIFESTS_FETCHED, {
        manifests: response.data.manifests,
        total: response.data.total
      });
    },
    async [DOWNLOAD_MANIFESTS_CSV_REQUESTS](context, query) {
      const errorMsg =
        'データ取得時にエラーがありました。サポートへご連絡ください。';
      await axios
        .get(`/manifests/csv?${querystring.stringify(query)}`)
        .catch((e) => {
          throw new Error(_.get(e, 'response.data.errors.general', errorMsg));
        });
    }
  },
  getters: {
    /**
     * コンポーネントからは
     * this.$store.getters['manifests/getManifest'](manifestId);
     * のように `this.$storegetters['モジュール名/メソッド名']` としてアクセスする。
     */
    /**
     * FETCH済みのmanifestsのリスト中から指定のmanifestIdのmanifestを取得する。
     */
    getManifest: (state) => (manifestId) => {
      return state.manifests.find((manifest) => manifest._id === manifestId);
    }
  }
};
