'use strict';

module.exports = {
  Ontario: 'ON',
  Quebec: 'QC',
  'Nova Scotia': 'NS',
  'New Brunswick': 'NB',
  Manitoba: 'MB',
  'British Columbia': 'BC',
  'Prince Edward Island': 'PE',
  Saskatchewan: 'SK',
  Alberta: 'AB',
  'Newfoundland and Labrador': 'NL',
  'Northwest Territories': 'NT',
  Yukon: 'YT',
  Nunavut: 'NU'
};
