'use strict';

// https://pe.usps.com/text/pub28/28c2_010.htm
// AE is used for armed forces in Europe, the Middle East, Africa, and Canada;
// AP is for the Pacific; and AA is the Americas excluding Canada.
module.exports = {
  'Armed Forces Africa': 'AE',
  'Armed Forces Americas': 'AA',
  'Armed Forces Canada': 'AE',
  'Armed Forces Europe': 'AE',
  'Armed Forces Middle East': 'AE',
  'Armed Forces Pacific': 'AP',
  AE: 'AE',
  AA: 'AA',
  AP: 'AP'
};
