import { SHOW_NOTIFY, CLEAR_NOTIFY } from '../mutation-types';
import { SHOW_NOTIFY_WITH_CALLBACK } from '../action-types';

export default {
  namespaced: true,
  /**
   * @property {string} color - snackbar の色を指定する
   * @property {string} message - 表示するメッセージ
   * @property {?Function} callback - ボタンを押した時の挙動をカスタマイズする
   * @property {?string} callbackText - ボタンに表示する文字をカスタマイズする
   */
  state: {
    color: '',
    message: '',
    callback: null,
    callbackText: null
  },
  mutations: {
    [SHOW_NOTIFY](
      state,
      { color, message, callback = null, callbackText = null }
    ) {
      state.color = color;
      state.message = message;
      state.callback = callback;
      state.callbackText = callbackText;
    },
    [CLEAR_NOTIFY](state) {
      state.color = '';
      state.message = '';
      state.callback = null;
      state.callbackText = null;
    }
  },
  actions: {
    async showErrorNotify({ commit }, message) {
      commit(SHOW_NOTIFY, { color: 'error', message });
    },
    async showNotify({ commit }, message) {
      commit(SHOW_NOTIFY, { color: 'success', message });
    },
    /**
     * ボタンをカスタマイズした通知を表示する
     * @param {Function} commit
     * @param {string} message
     * @param {Function} callback
     * @param {string} callbackText
     * @returns {Promise<void>}
     */
    async [SHOW_NOTIFY_WITH_CALLBACK](
      { commit },
      { message, callback, callbackText }
    ) {
      commit(SHOW_NOTIFY, {
        color: 'success',
        message,
        callback: callback,
        callbackText: callbackText
      });
    },
    async clearNotify({ commit }) {
      commit(CLEAR_NOTIFY);
    }
  }
};
