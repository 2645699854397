<template>
  <v-main>
    <div
      class="red darken-2 text-center"
      v-if="
        !this.$route.meta.noToolbar &&
        $store.state.user.accountStatus === 'READ_ONLY'
      "
    >
      <span class="white--text"
        >現在メンテナンス等の理由により、新規のご依頼を一時停止しております。詳細はお知らせ欄を参照いただくか、サポート窓口までお問い合わせください。</span
      >
    </div>
    <div class="fill-height" id="layout-view">
      <Notify></Notify>
      <v-fade-transition mode="in-out">
        <router-view></router-view>
      </v-fade-transition>
    </div>
  </v-main>
</template>

<style lang="stylus" scoped>
#layout-view {
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 100px;
}
</style>

<script>
import Notify from '../Notify';
export default {
  components: {
    Notify
  }
};
</script>
