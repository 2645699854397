'use strict';

module.exports = {
  YAMATO: {
    551: '60サイズ',
    552: '60サイズ',
    553: '60サイズ',
    N461: '60サイズ',
    N501: '60サイズ',
    N801: '80サイズ',
    802: '80サイズ',
    804: '80サイズ',
    601: '60サイズ',
    751: '80サイズ',
    N901: '100サイズ',
    903: '100サイズ',
    901: '100サイズ',
    1203: '120サイズ',
    904: '100サイズ',
    1202: '120サイズ', // 廃止
    1401: '140サイズ',
    'N461-v2': '60サイズ',
    'N801-v2': '80サイズ',
    '601-v2': '60サイズ',
    '751-v2': '80サイズ',
    'N901-v2': '100サイズ',
    '903-v2': '100サイズ',
    '901-v2': '100サイズ',
    '1203-v2': '120サイズ',
    '904-v2': '100サイズ',
    1204: '120サイズ',
    '1204-v2': '120サイズ',
    '1401-v2': '140サイズ'
    // 1801/1801-v2 は三辺合計が160を超えるため送れない
    // 1802/1802-v2 は三辺合計が160を超えるため送れない
  },
  SAGAWA: {
    551: '60サイズ',
    552: '60サイズ',
    553: '60サイズ',
    N461: '60サイズ',
    N501: '60サイズ',
    N801: '80サイズ',
    802: '80サイズ',
    804: '80サイズ',
    601: '60サイズ',
    751: '80サイズ',
    N901: '100サイズ',
    903: '100サイズ',
    901: '100サイズ',
    1203: '140サイズ', // 佐川は 120サイズ がないので 140サイズ
    904: '100サイズ',
    1202: '140サイズ', // 佐川は 120サイズ がないので 140サイズ // 廃止
    1401: '140サイズ',
    1801: '180サイズ',
    1802: '180サイズ',
    'N461-v2': '60サイズ',
    'N801-v2': '80サイズ',
    '601-v2': '60サイズ',
    '751-v2': '80サイズ',
    'N901-v2': '100サイズ',
    '903-v2': '100サイズ',
    '901-v2': '100サイズ',
    '1203-v2': '140サイズ', // 佐川は 120サイズ がないので 140サイズ
    1204: '140サイズ', // 佐川は 120サイズ がないので 140サイズ
    '1204-v2': '140サイズ', // 佐川は 120サイズ がないので 140サイズ
    '904-v2': '100サイズ',
    '1202-v2': '140サイズ', // 佐川は 120サイズ がないので 140サイズ
    '1401-v2': '140サイズ',
    '1801-v2': '180サイズ',
    '1802-v2': '180サイズ'
  }
};
