'use strict';

// このファイルはクライアント側からも読み込まれるため，
// Node.jsでしか動かないコードを書くことは禁止する

const LANG = {
  EN_US: 'EN_US',
  JA_JP: 'JA_JP'
};
const LANG_PATTERN = [];
for (const property in LANG) {
  LANG_PATTERN.push(property);
}
const LANG_LABEL = {
  EN_US: '英語',
  JA_JP: '日本語'
};

/**
 * 荷主情報の自由記入欄の入力可能行数
 */
const OWNER_INFO_NUM = 4;

module.exports = { LANG, LANG_PATTERN, LANG_LABEL, OWNER_INFO_NUM };
