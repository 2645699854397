<template>
  <div class="packing-slip">
    <div class="container">
      <div class="title">{{ slipTitle }}</div>

      <div class="header">
        <div class="customer">
          <!-- お届け先住所は個人情報流出リスクを下げるために表示しない -->
        </div>

        <div class="owner">
          <div class="owner-title">
            <template v-if="lang === LANG.JA_JP">発行元</template>
            <template v-else>Owner</template>
          </div>
          <div v-if="logo" class="logo-area">
            <img :src="logo" />
          </div>
          <div>{{ ownerInfo.name }}</div>
          <div>{{ ownerInfo.line1 }}</div>
          <div>{{ ownerInfo.line2 }}</div>
          <div>{{ ownerInfo.line3 }}</div>
          <div>{{ ownerInfo.line4 }}</div>
        </div>
      </div>

      <div class="barcode">
        <img src="/barcode.png" />
      </div>

      <div v-if="header.totalAmount" class="total-amount-wrapper">
        <span class="total-amount">
          {{ header.totalAmount }} {{ dummy.totalAmount }}
        </span>
      </div>

      <table class="list">
        <thead>
          <tr>
            <th v-if="header.barcode">{{ header.barcode }}</th>
            <th>{{ header.productTitle }}</th>
            <th>{{ header.qty }}</th>
            <th v-if="header.amount">{{ header.amount }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in dummy.items" :key="index">
            <td v-if="header.barcode" class="list-barcode">
              {{ item.barcode }}
            </td>
            <td class="list-title">
              {{ lang === LANG.JA_JP ? item.title.ja : item.title.en }}
            </td>
            <td class="list-qty">{{ item.qty }}</td>
            <td v-if="header.amount" class="list-amount">{{ item.amount }}</td>
          </tr>
        </tbody>
      </table>

      <div v-if="footer.note" class="footer">
        <div class="footer-title">
          <template v-if="lang === LANG.JA_JP">備考情報</template>
          <template v-else>Notes</template>
        </div>
        <div class="footer-content">{{ footer.note }}</div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.packing-slip {
  width: min(100%, 1000px);
  min-height: 920px;
  margin: 0 auto;
  padding: 0;
  font-family: Helvetica, arial, freesans, clean, sans-serif;
  font-size: 11px;
  color: #000;
  background-color: #fff;
  border: solid 1px #ccc;
}
.packing-slip * {
  box-sizing: border-box;
}
.packing-slip .container {
  width: min(100%, 960px);
  margin: 0 auto 20px auto;
}
.packing-slip .barcode {
  text-align: center;
}
.packing-slip .barcode img {
  width: 200px;
}
.packing-slip table {
  border-collapse: collapse;
}

/*
以下のスタイルは views/slip/shipment.pug のスタイル部分を
.packing-slip と .packing-slip * と .packing-slip .container 以外コピーしたもの
*/
.packing-slip .title {
  width: 50%;
  margin: 32px auto;
  font-family: Lato, sans-serif;
  font-size: 24px;
  font-weight: 300;
  text-align: center;
  border-bottom: 1px solid #ccc;
}
.packing-slip .header {
  margin: 32px auto;
}
.packing-slip .customer {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  margin: 0;
  padding: 0 10px 0 0;
}
.packing-slip .owner {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  margin: 0;
  padding: 0 0 0 10px;
}
.packing-slip .shipping-title,
.packing-slip .owner-title {
  margin-bottom: 4px;
  font-weight: 700;
}
.packing-slip .name {
  font-size: 15px;
}
.packing-slip .logo-area img {
  height: 80px;
}
.packing-slip .barcode {
  margin: 32px auto;
}
.packing-slip .total-amount-wrapper {
  margin: 32px auto;
  text-align: right;
}
.packing-slip .total-amount {
  margin: 0;
  padding: 4px;
  font-size: 15px;
  border-bottom: 1px solid #ccc;
}
.packing-slip .list {
  width: 100%;
  border: 1px solid #ccc;
}
.packing-slip tr {
  height: 32px;
}
.packing-slip th {
  font-family: Lato, sans-serif;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  padding: 4px;
  background-color: #fff;
  border: 1px solid #ccc;
}
.packing-slip td {
  font-size: 15px;
  padding: 4px 12px;
  vertical-align: middle;
  border: 1px solid #ccc;
}
.packing-slip .list-barcode {
  width: 120px;
  text-align: center;
}
.packing-slip .list-qty {
  width: 64px;
  font-size: 16px;
  text-align: right;
}
.packing-slip .list-amount {
  width: 140px;
  font-size: 16px;
  text-align: right;
}
.packing-slip .footer {
  margin: 32px auto;
  padding: 10px 13px;
  border: 1px solid #ccc;
}
.packing-slip .footer-title {
  margin-bottom: 10px;
  font-size: 15px;
}
.packing-slip .footer-content {
  white-space: pre-wrap;
}
</style>

<script>
import { LANG } from '@/../lib/document/schema/slip_templates';

export default {
  props: {
    slipTitle: String,
    ownerInfo: Object,
    header: {
      barcode: String,
      productTitle: String,
      qty: String,
      amount: String,
      totalAmount: String
    },
    lang: String,
    logo: String,
    footer: {
      note: Object
    }
  },
  computed: {
    LANG: () => LANG,
    dummy: () => ({
      countryCode: 'JP',
      state: { ja: '千葉県', en: 'Chiba' },
      city: { ja: '浦安市', en: 'Urayasu shi' },
      address1: { ja: '千葉県浦安市千鳥12−20', en: '12−20 Chidori' },
      address2: { ja: '日本ロジステック 2F', en: 'Nihon Logistic' },
      name: {
        ja: 'Tokyo Otaku Mode',
        en: 'Tokyo Otaku Mode'
      },
      totalAmount: '6,000',
      items: [
        ...[...Array(3)].map((_, i) => ({
          barcode: '0000000000000',
          title: {
            ja: `サンプル商品 ${i + 1}`,
            en: `SAMPLE PRODUCT ${i + 1}`
          },
          qty: (i + 1).toLocaleString(),
          amount: ((i + 1) * 1000).toLocaleString()
        })),
        ...[...Array(6)].map(() => ({
          barcode: '',
          title: '',
          qty: '',
          amount: ''
        }))
      ]
    })
  }
};
</script>
