<template>
  <div>
    <v-card>
      <v-card-text>
        <v-layout>
          <v-flex v-if="$store.state.user.isTomUser" xs3 mt-2 mr-2>
            <v-autocomplete
              v-model="owner"
              :items="owners"
              item-value="id"
              item-text="name"
              label="荷主"
              class="mr-2 flex-grow-0"
              outlined
              hide-details
            ></v-autocomplete>
          </v-flex>
        </v-layout>
      </v-card-text>
      <TableAndPaging
        ref="search"
        :headers="header"
        :items="$store.state.flyers.flyers"
        :total="$store.state.flyers.total"
        :query-params="queryParams"
        action="flyers/FETCH_FLYERS"
        :showInitNotify="showInitNotify"
      >
        <template #row="props">
          <td v-if="$store.state.user.isTomUser">
            {{ props.item.owner.names.ja_jp }}
          </td>
          <td>
            {{
              props.item.state !== FLYER_STATE.DELETED
                ? props.item.number
                : props.item.former_number
            }}
          </td>
          <td>{{ STATE[props.item.state] }}</td>
          <td>{{ props.item.title }}</td>
          <td>{{ formatDate({ date: props.item.available_at }) }}</td>
          <td>{{ formatDate({ date: props.item.deleted_at }) }}</td>
        </template>
      </TableAndPaging>
    </v-card>
  </div>
</template>

<style></style>

<script>
import TableAndPaging from '@/components/TableAndPaging';
import { FETCH_OWNERS_ALL } from '@/store/action-types';
import { formatDate } from '@/util/formatter';
import { STATE } from '@/../lib/document/schema/flyers';

export default {
  components: {
    TableAndPaging
  },
  data() {
    const query = this.$route.query;
    return {
      owner: '',
      STATE: {
        PREPARATION: '準備中',
        AVAILABLE: '利用可能',
        DELETED: '利用終了'
      },
      showInitNotify: !!query.notifyType && !!query.notifyMessageCode
    };
  },
  computed: {
    isTomUser: (self) => self.$store.state.user.isTomUser,
    queryParams: function () {
      return {
        filter_owner: this.owner
      };
    },
    header: function () {
      return this.$store.state.user.isTomUser
        ? this.$store.state.flyers.headersForTomUser
        : this.$store.state.flyers.headers;
    },
    owners: function () {
      return [{ id: '', name: '全て' }].concat(
        Object.keys(this.$store.state.owners.owners).map((index) => {
          return {
            id: this.$store.state.owners.owners[index]._id,
            name: this.$store.state.owners.owners[index].names.ja_jp
          };
        })
      );
    },
    FLYER_STATE: () => STATE
  },
  methods: {
    formatDate
  },
  async beforeMount() {
    await this.$store.dispatch(`owners/${FETCH_OWNERS_ALL}`);
  }
};
</script>
