<template>
  <v-container>
    <v-row wrap>
      <v-col xs="12">
        <v-form>
          <v-row align="center" justify="center">
            <v-col cols="12">
              <v-card class="mb-4">
                <v-card-title>入庫情報</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col sm="6">
                      <v-text-field
                        name="ownerManifestNo"
                        v-model="ownerManifestNo"
                        :error-messages="mergedError('ownerManifestNo')"
                        label="入庫依頼番号"
                        v-validate="'required|max:50'"
                        data-vv-validate-on="change"
                        data-vv-as="入庫依頼番号"
                        hint="50文字以内の数字、記号、文字列"
                        persistent-hint
                      >
                        <v-chip
                          slot="append"
                          class="ma-2"
                          color="red"
                          label
                          outlined
                          small
                        >
                          必須
                        </v-chip>
                        <v-tooltip bottom slot="append-outer">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="text--secondary"
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              info
                            </v-icon>
                          </template>
                          <div>
                            入庫依頼書を発行する際の荷主様管理IDまたは番号。<br />
                            納品伝票単位または納品個口単位での発行がおすすめ<br />
                            （記載例:2023xxxx_from_office01）
                          </div>
                        </v-tooltip>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="6">
                      <v-menu
                        v-model="deliveryScheduleMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            name="deliverySchedule"
                            v-model="deliverySchedule"
                            label="着荷予定日"
                            placeholder="指定なし"
                            prepend-icon="event"
                            append-icon="cancel"
                            @click:append="deliverySchedule = ''"
                            readonly
                            v-on="on"
                            :error-messages="mergedError('deliverySchedule')"
                            v-validate="'required'"
                            data-vv-validate-on="change"
                            data-vv-as="着荷予定日"
                            hint="翌日以降の予定日"
                            persistent-hint
                          >
                            <v-chip
                              slot="append"
                              class="ma-2"
                              color="red"
                              label
                              outlined
                              small
                            >
                              必須
                            </v-chip>
                            <v-tooltip bottom slot="append-outer">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  class="text--secondary"
                                  small
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  info
                                </v-icon>
                              </template>
                              <div>入庫予定商品の着荷予定日</div>
                            </v-tooltip>
                          </v-text-field>
                        </template>
                        <v-date-picker
                          v-model="deliverySchedule"
                          :min="OneWeekDaysAfter"
                          @input="deliveryScheduleMenu = false"
                          no-title
                          scrollable
                          :day-format="(date) => new Date(date).getDate()"
                        />
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="6">
                      <v-text-field
                        name="carrier"
                        v-model="carrier"
                        :error-messages="mergedError('carrier')"
                        label="運送会社"
                        v-validate="'max:200'"
                        data-vv-validate-on="change"
                        data-vv-as="運送会社"
                        hint="200文字以内の数字、記号、文字列"
                        persistent-hint
                      >
                        <v-chip
                          slot="append"
                          class="ma-2"
                          color="red"
                          label
                          outlined
                          small
                        >
                          入庫依頼書が同梱出来ない場合には必須
                        </v-chip>
                        <v-tooltip bottom slot="append-outer">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="text--secondary"
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              info
                            </v-icon>
                          </template>
                          <div>
                            納品時に利用予定の運送会社、または便種名を記載
                          </div>
                        </v-tooltip>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="6">
                      <v-text-field
                        name="trackingCode"
                        v-model="trackingCode"
                        :error-messages="mergedError('trackingCode')"
                        label="トラッキングコード"
                        v-validate="'max:200'"
                        data-vv-validate-on="change"
                        data-vv-as="トラッキングコード"
                        hint="200文字以内の数字、記号、文字列"
                        persistent-hint
                      >
                        <v-chip
                          slot="append"
                          class="ma-2"
                          color="red"
                          label
                          outlined
                          small
                        >
                          入庫依頼書が同梱出来ない場合には必須
                        </v-chip>
                        <v-tooltip bottom slot="append-outer">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="text--secondary"
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              info
                            </v-icon>
                          </template>
                          <div>納品時の輸送時に発行される追跡番号</div>
                        </v-tooltip>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card class="mb-4">
                <v-card-title>入庫する商品</v-card-title>
                <v-card-text>
                  <ItemSelector @select-item="selectItem"></ItemSelector>
                  <v-subheader>選択済み商品一覧</v-subheader>
                  <v-simple-table>
                    <tbody class="th-key">
                      <tr>
                        <th>バーコード</th>
                        <th>荷主管理コード/セット管理番号</th>
                        <th>商品名</th>
                        <th>アクション</th>
                      </tr>
                      <tr
                        v-for="({ item, qty }, index) in items"
                        :key="item._id"
                      >
                        <td>{{ item.barcode }}</td>
                        <td>
                          {{ item.owner_item_code }}
                        </td>
                        <td>
                          <v-list-item dense>
                            <v-list-item-icon>(日)</v-list-item-icon>
                            <v-list-item-title>
                              {{ item.names.ja_jp }}
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item dense>
                            <v-list-item-icon>(英)</v-list-item-icon>
                            <v-list-item-title>
                              {{ item.names.en_us }}
                            </v-list-item-title>
                          </v-list-item>
                        </td>
                        <td>
                          <v-row>
                            <v-col>
                              <v-text-field
                                :value="qty"
                                label="個数"
                                @change="updateQty({ index, qty: $event })"
                              />
                            </v-col>
                            <v-col>
                              <v-btn
                                text
                                color="error accent-4"
                                class="v-btn--active"
                                @click="removeItem({ index })"
                              >
                                <v-icon small left>delete</v-icon>
                                選択解除
                              </v-btn>
                            </v-col>
                          </v-row>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" v-if="mergedError('general').length">
              <v-alert
                color="error"
                text
                v-html="mergedError('general').split('\n').join('<br />')"
              >
              </v-alert>
            </v-col>
            <v-col cols="12">
              <v-btn
                text
                color="primary accent-4"
                class="v-btn--active"
                block
                @click="postManifest"
              >
                入庫依頼を作成する
              </v-btn>
            </v-col>
            <v-col cols="12" v-if="isManifestCreated">
              <v-btn
                text
                color="teal"
                class="mb-4 v-btn--active"
                to="/manifests/index"
                block
              >
                入庫一覧画面へ戻る
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<style></style>

<script>
import { addWeekdays } from '@/../lib/util/date';
import { mergeErrors } from '@/util/input';
import { JsonError } from '@/util/error';

import ItemSelector from '@/components/ItemSelector';

import axios from '@/api/common/axios';

import {
  START_LOADING,
  FINISH_LOADING,
  FETCH_OWNER
} from '@/store/action-types';

export default {
  $_veeValidate: {
    validator: 'new'
  },
  components: {
    ItemSelector
  },
  data() {
    return {
      ownerManifestNo: '',
      trackingCode: '',
      carrier: '',
      items: [],
      serverErrors: {},
      deliveryScheduleMenu: undefined,
      deliverySchedule: '',
      isManifestCreated: false
    };
  },
  computed: {
    isTomUser: (self) => self.$store.state.user.isTomUser,
    /**
     * 今日から土日含まず1日後の日付を返す
     */
    OneWeekDaysAfter() {
      return this.addWeekdays(new Date(), 1).format('YYYY-MM-DD');
    }
  },
  async mounted() {
    this.$store.dispatch(`owner/${FETCH_OWNER}`, this.$store.state.user);
  },
  methods: {
    async clearError() {
      await this.$store.dispatch('notify/clearNotify');
      this.serverErrors = {};
    },
    /**
     * 入庫依頼を作成するボタン押下時にAPIリクエストを送る
     */
    async postManifest() {
      await this.clearError();

      if (!(await this.$validator.validate())) {
        await this.$store.dispatch(
          'notify/showErrorNotify',
          '入力項目に誤りがあります。エラー表示されている項目を確認してください。'
        );

        return;
      }

      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      try {
        await axios.post('/manifests/form', {
          ownerManifestNo: this.ownerManifestNo,
          deliverySchedule: this.deliverySchedule,
          items: this.items,
          trackingCode: this.trackingCode,
          carrier: this.carrier
        });
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        await this.$store.dispatch(
          'notify/showNotify',
          '入庫依頼を作成しました。'
        );
        this.isManifestCreated = true;
      } catch (error) {
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        if (error.response.data.errors) {
          this.serverErrors = new JsonError(
            error.response.data.errors
          ).toJson();
          await this.$store.dispatch(
            'notify/showErrorNotify',
            '入力項目に誤りがあります。エラー表示されている項目を確認してください。'
          );
        } else {
          let message =
            error &&
            error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.general;
          if (!message) {
            message = '入庫依頼の作成に失敗しました。お問い合わせください。';
          }
          await this.$store.dispatch('notify/showErrorNotify', message);
        }
      }
    },
    async selectItem({ item }) {
      await this.clearError();

      if (this.items.map((obj) => obj.item._id).includes(item._id)) {
        return;
      }
      this.items.push({ item, qty: 1 });

      await this.$store.dispatch(
        'notify/showNotify',
        '選択済み商品一覧に追加しました。'
      );
    },
    removeItem({ index }) {
      this.items = [
        ...this.items.slice(0, index),
        ...this.items.slice(index + 1)
      ];
    },
    updateQty({ index, qty }) {
      this.items = [
        ...this.items.slice(0, index),
        {
          item: this.items[index].item,
          qty,
          price: this.items[index].price
        },
        ...this.items.slice(index + 1)
      ];
    },
    mergedError(field) {
      return mergeErrors(this.$validator.errors, this.serverErrors, field);
    },
    addWeekdays
  }
};
</script>
