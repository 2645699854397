<template>
  <v-layout wrap>
    <v-tabs background-color="transparent" grow>
      <template v-for="tab in tabs">
        <template v-if="canSee(tab.meta && tab.meta.managerOnly, isManager)">
          <v-tab :key="'tab-' + tab.name" :to="tab.path">
            {{ tab.name }}
          </v-tab>
        </template>
      </template>
      <v-tab-item
        v-if="matchedTab"
        :id="matchedTab.path"
        :key="'item-' + matchedTab.name"
      >
        <component :is="matchedTab.component"></component>
      </v-tab-item>
    </v-tabs>
  </v-layout>
</template>

<style scoped>
/deep/ .theme--light.v-tabs-items {
  background-color: transparent;
}
</style>
<script>
import SettingsPassword from '@/components/settings/SettingsPassword';
import UsersList from '@/components/settings/UsersList';
import AddUser from '@/components/settings/AddUser';
import Owner from '@/components/settings/Owner';
import EditSlipComponent from '@/components/settings/EditSlipComponent';
import ApiAndWebhookSettings from '@/components/settings/ApiAndWebhookSetting.vue';
import WebhookLogs from '@/components/settings/WebhookLogs';
import Payments from '@/components/settings/Payments';
import IpRestrictionSettings from '@/components/settings/IpRestrictionSettings';
import { findSelectedTab } from '@/util/tab';

export default {
  components: {
    SettingsPassword,
    UsersList,
    Owner,
    EditSlipComponent
  },
  data: function () {
    return {
      tabs: [
        {
          path: '/settings/password',
          name: 'パスワード変更',
          component: SettingsPassword
        },
        {
          path: `/owners/${this.$store.state.user.owner}/users`,
          name: 'アカウント一覧',
          component: UsersList,
          meta: {
            managerOnly: true
          }
        },
        {
          path: `/owners/${this.$store.state.user.owner}/add_user`,
          name: 'アカウント追加',
          component: AddUser,
          meta: {
            managerOnly: true
          }
        },
        {
          path: '/settings/slips',
          name: '納品書設定',
          component: EditSlipComponent,
          meta: {
            managerOnly: true
          }
        },
        {
          path: '/settings/api',
          name: 'API設定',
          component: ApiAndWebhookSettings,
          meta: {
            managerOnly: true
          }
        },
        {
          path: '/settings/ip_restrictions',
          name: 'IP制限',
          component: IpRestrictionSettings,
          meta: {
            managerOnly: true
          }
        },
        {
          path: `/owners/${this.$store.state.user.owner}/update`,
          name: 'ご契約者情報確認 / 変更',
          component: Owner,
          meta: {
            managerOnly: true
          }
        },
        {
          path: '/payments',
          name: 'お支払い設定',
          component: Payments,
          meta: {
            managerOnly: true
          }
        }
      ]
    };
  },
  computed: {
    isManager: (self) => self.$store.state.user.category === 'MANAGER',
    matchedTab: function () {
      // Webhookログの個別ページはAPI設定タブで表示する
      if (/^\/settings\/api\/logs\/.*/.test(this.$route.path)) {
        return {
          path: '/settings/api',
          name: 'API設定',
          component: WebhookLogs,
          display: true,
          meta: {
            managerOnly: true
          }
        };
      }
      return findSelectedTab({
        tabs: this.tabs,
        path: this.$route.path
      });
    }
  },
  methods: {
    canSee(managerOnly, isManager) {
      return managerOnly ? isManager : true;
    }
  }
};
</script>
