import querystring from 'querystring';
import _ from 'lodash';

import { RETURN_FETCHED, RETURN_COMPLETED } from '../mutation-types';
import { FETCH_RETURN, RESTOCK } from '../action-types';
import axios from '../../api/common/axios';
import { STATE } from '@/../lib/document/schema/returned_shipments';

export default {
  namespaced: true,
  state: {
    headers: ['ステータス', '返送情報', '商品情報'],
    headersForTomUser: ['荷主', 'ステータス', '返送情報', '商品情報'],
    returns: [],
    total: 0
  },
  mutations: {
    [RETURN_FETCHED](state, { returns, total }) {
      state.returns = returns;
      state.total = total;
    },
    [RETURN_COMPLETED](state, { returnedShipmentId }) {
      state.returns = state.returns.map((r) => {
        if (r._id === returnedShipmentId) {
          r.state = STATE.COMPLETE;
        }
        return r;
      });
    }
  },
  actions: {
    async [FETCH_RETURN]({ commit }, query) {
      const errorMsg =
        'データ取得時にエラーがありました。セカイロジ担当者までお問い合わせください。';
      const response = await axios
        .get(`/returns?${querystring.stringify(query)}`)
        .catch((e) => {
          throw new Error(_.get(e, 'response.data.errors.general', errorMsg));
        });

      if (_.get(response, 'data.status') === 'ng') {
        throw new Error(_.get(response, 'data.errors.general', errorMsg));
      }

      commit(RETURN_FETCHED, {
        returns: response.data.returns,
        total: response.data.total
      });
    },
    async UPDATE_RETURNS_STATE({ commit }, { _id, state }) {
      const response = await axios.post('/returns', { _id, state });
      if (response && response.data && response.data.status === 'ng') {
        throw new Error('データの更新に失敗しました');
      }
      commit(RETURN_COMPLETED, {
        returnedShipmentId: _id
      });
    },
    async [RESTOCK]({ commit }, returnedShipmentId) {
      await axios.post(`/returns/restock/${returnedShipmentId}`);

      commit(RETURN_COMPLETED, {
        returnedShipmentId: returnedShipmentId
      });
    }
  }
};
