<template>
  <v-container px-0>
    <v-row>
      <v-col>
        <h3 class="bold">IPアドレス制限</h3>
        <v-card class="mb-2 mt-2">
          <v-container>
            <v-row>
              <v-spacer />
              <v-col md6>
                <v-subheader>
                  現在の接続元IPアドレス
                  <v-chip class="ml-2" color="teal" outlined label small>
                    {{ $store.state.owner.current_ip }}
                  </v-chip></v-subheader
                >
              </v-col>
              <v-spacer />
            </v-row>
            <v-row>
              <v-spacer />
              <v-col md6>
                <ul>
                  <li>アクセス出来るIPアドレスを制限する機能です。</li>
                  <li>
                    許可するIPアドレス欄にはCIDR形式で許可するIPアドレス範囲を指定してください。(192.168.0.0/16
                    のような形式)
                  </li>
                  <li>IPv4のCIDRが指定できます。</li>
                </ul>
              </v-col>
              <v-spacer />
            </v-row>
            <v-row align-content="center" justify="center">
              <v-spacer />
              <v-col md6 class="text-center">
                <v-form @submit.prevent>
                  <v-textarea
                    required
                    outlined
                    rows="10"
                    name="whitelistIps"
                    label="許可するIPアドレス"
                    v-model="whitelistIps"
                    v-validate="{
                      required: true
                    }"
                    data-vv-as="許可するIPアドレス"
                  ></v-textarea>
                </v-form>
                <v-btn text color="primary" @click="submitWhitelistIps">
                  許可するIPアドレスを登録する
                </v-btn>
              </v-col>
              <v-spacer />
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  START_LOADING,
  FINISH_LOADING,
  FETCH_WHITELIST_IPS,
  UPDATE_WHITELIST_IPS
} from '@/store/action-types';

export default {
  props: {},
  $_veeValidate: {
    validator: 'new'
  },
  async mounted() {
    await this.$store.dispatch(`app/${START_LOADING}`, new Date());
    await this.$store
      .dispatch(`owner/${FETCH_WHITELIST_IPS}`)
      .then(async () => {
        this.whitelistIps = this.$store.state.owner.whitelist_ips.join('\n');
      })
      .catch(async (e) => {
        if (e.response) {
          await this.$store.dispatch(
            'notify/showErrorNotify',
            e.response.data.errors.general.msg
          );
        } else {
          await this.$store.dispatch('notify/showErrorNotify', e.message);
        }
      })
      .finally(async () => {
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
      });
  },
  data() {
    return {
      whitelistIps: ''
    };
  },
  computed: {},
  methods: {
    clearErrors() {
      this.$validator.errors.clear();
      this.serverErrors = {};
    },
    async submitWhitelistIps() {
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      await this.$store
        .dispatch(
          `owner/${UPDATE_WHITELIST_IPS}`,
          this.whitelistIps.split('\n')
        )
        .then(async () => {
          await this.$store.dispatch(
            'notify/showNotify',
            'IP制限を設定しました'
          );
        })
        .catch(async (e) => {
          await this.$store.dispatch(
            'notify/showErrorNotify',
            e.response.data.errors.general
          );
        })
        .finally(async () => {
          await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        });
    }
  }
};
</script>
