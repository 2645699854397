import querystring from 'querystring';
import axios from '@/api/common/axios';

import { FLYERS_FETCHED } from '@/store/mutation-types';
import { FETCH_FLYERS } from '@/store/action-types';

const headers = [
  'フライヤー番号',
  'ステータス',
  'フライヤー名',
  '利用開始日',
  '利用終了日'
];
export default {
  namespaced: true,
  state: {
    headers: headers,
    headersForTomUser: ['荷主', ...headers],
    flyers: [],
    total: 0
  },
  mutations: {
    [FLYERS_FETCHED](state, { flyers, total }) {
      state.flyers = flyers;
      state.total = total;
    }
  },
  actions: {
    async [FETCH_FLYERS]({ commit }, query) {
      const errorMsg =
        'データ取得時にエラーがありました。セカイロジ担当者までお問い合わせください。';
      const response = await axios
        .get(`/flyers?${querystring.stringify(query)}`)
        .catch((e) => {
          throw e;
        });

      if (response && response.data && response.data.status === 'ng') {
        throw new Error(
          (response.data.errors && response.data.errors.general) || errorMsg
        );
      }

      const flyers = response.data.flyers;
      const total = response.data.total;
      // numberを表示用に0パディングして返す
      flyers.forEach((flyer) => {
        flyer.number = (flyer.number + '').padStart(4, '0');
        if (flyer.former_number) {
          flyer.former_number = (flyer.former_number + '').padStart(4, '0');
        }
      });
      commit(FLYERS_FETCHED, {
        flyers,
        total
      });
    }
  },
  getters: {}
};
