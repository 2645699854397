'use strict';

const _ = require('lodash');

const STATE_PATTERN = [
  'WAIT', // qty > stocked_qty + canceled_qty
  'COMPLETED', // qty === stocked_qty + canceled_qty
  'CANCELED' // qty === canceled_qty
];
const STATE = _.zipObject(STATE_PATTERN, STATE_PATTERN);

module.exports = {
  STATE_PATTERN,
  STATE,
  STATE_LABEL: {
    WAIT: '入庫待ち',
    COMPLETED: '入庫完了',
    CANCELED: 'キャンセル'
  }
};
