<template>
  <v-layout wrap>
    <v-flex xs12 class="mb-4" text-right>
      <v-row justify="end">
        <v-btn
          color="primary"
          class="edit"
          elevation="0"
          :to="editPath"
          outlined
        >
          <v-icon left>edit</v-icon>
          商品を編集
          <span class="caption">(フォーム)</span>
        </v-btn>
      </v-row>
    </v-flex>
    <v-flex xs6>
      <v-card class="mr-2" height="100%">
        <v-card-text>
          <div class="caption" v-if="$store.state.user.isTomUser">
            荷主: {{ $store.state.item.ownerId }}
          </div>
          <div class="caption">{{ title }}</div>
          <v-card-title class="title text--primary font-weight-bold">
            基本情報
            <v-dialog>
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  outlined
                  small
                  label
                  color="blue"
                  class="ml-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon small left> info </v-icon>
                  ヘルプ
                </v-chip>
              </template>
              <v-card>
                <v-card-title> 基本情報 </v-card-title>
                <v-card-text>
                  <v-list three-line>
                    <v-list-item
                      v-for="note in itemFields.filter((item) => item.content)"
                      :key="'note-' + note.key"
                    >
                      <v-list-item-content>
                        <v-list-item-title class="font-weight-bold">{{
                          note.name
                        }}</v-list-item-title>
                        <p v-html="note.content" />
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-card-title>
          <v-skeleton-loader :loading="!$store.state.item.barcode" type="table">
            <v-simple-table>
              <tbody>
                <tr v-for="field in itemFields" :key="'fields-' + field.key">
                  <th width="200">
                    {{ field.name }}
                  </th>
                  <td>
                    {{ $store.state.item[field.key] }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-skeleton-loader>
        </v-card-text>
        <v-card-title class="title text--primary font-weight-bold">
          属性情報
          <v-dialog>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                outlined
                small
                label
                color="blue"
                class="ml-2"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small left> info </v-icon>
                ヘルプ
              </v-chip>
            </template>
            <v-card>
              <v-card-title> 属性情報 </v-card-title>
              <v-card-text>
                <v-list three-line>
                  <v-list-item
                    v-for="note in itemAttributeFields.filter(
                      (item) => item.content
                    )"
                    :key="'note-' + note.key"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold">{{
                        note.name
                      }}</v-list-item-title>
                      <p v-html="note.content" />
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-card-title>
        <v-card-text>
          <v-skeleton-loader :loading="!$store.state.item.barcode" type="table">
            <v-simple-table>
              <tbody>
                <tr
                  v-for="field in itemAttributeFields"
                  :key="'fields-' + field.key"
                >
                  <th width="200">
                    {{ field.name }}
                  </th>
                  <td>
                    {{ $store.state.item[field.key] }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-skeleton-loader>
        </v-card-text>
      </v-card>
    </v-flex>
    <v-flex xs6>
      <v-card class="ml-2 mb-4">
        <v-card-title class="title text--primary font-weight-bold">
          在庫情報
          <v-dialog>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                outlined
                small
                label
                color="blue"
                class="ml-2"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small left> info </v-icon>
                ヘルプ
              </v-chip>
            </template>
            <v-card>
              <v-card-title> 在庫情報 </v-card-title>
              <v-card-text>
                <v-list three-line>
                  <v-list-item
                    v-for="note in stockFields.filter((item) => item.content)"
                    :key="'note-' + note.key"
                  >
                    <template
                      v-if="
                        !note.onlyAdmin ||
                        (note.onlyAdmin && $store.state.user.isTomUser)
                      "
                    >
                      <v-list-item-content>
                        <v-list-item-title class="font-weight-bold">{{
                          note.name
                        }}</v-list-item-title>
                        <p v-html="note.content" />
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-card-title>
        <v-card-text>
          <v-skeleton-loader :loading="!$store.state.item.barcode" type="table">
            <v-simple-table>
              <tbody>
                <tr v-for="field in stockFields" :key="'fields-' + field.key">
                  <template
                    v-if="
                      !field.onlyAdmin ||
                      (field.onlyAdmin && $store.state.user.isTomUser)
                    "
                  >
                    <th width="200">
                      {{ field.name }}
                    </th>
                    <td>
                      {{
                        field.filter
                          ? field.filter($store.state.item[field.key])
                          : $store.state.item[field.key]
                      }}
                    </td>
                  </template>
                </tr>
              </tbody>
            </v-simple-table>
          </v-skeleton-loader>
        </v-card-text>
      </v-card>
      <v-card class="ml-2">
        <v-card-title class="title text--primary font-weight-bold">
          配送関連情報
          <v-dialog>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                outlined
                small
                label
                color="blue"
                class="ml-2"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small left> info </v-icon>
                ヘルプ
              </v-chip>
            </template>
            <v-card>
              <v-card-title> 配送関連情報 </v-card-title>
              <v-card-text>
                <v-list three-line>
                  <v-list-item
                    v-for="note in shippingFields.filter(
                      (item) => item.content
                    )"
                    :key="'note-' + note.key"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold">{{
                        note.name
                      }}</v-list-item-title>
                      <p v-html="note.content" />
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-card-title>
        <v-card-text>
          <v-skeleton-loader :loading="!$store.state.item.barcode" type="table">
            <v-simple-table>
              <tbody>
                <tr
                  v-for="field in shippingFields"
                  :key="'fields-' + field.key"
                >
                  <th width="200">
                    {{ field.name }}
                  </th>
                  <td>
                    {{
                      field.filter
                        ? field.filter($store.state.item[field.key])
                        : $store.state.item[field.key]
                    }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-skeleton-loader>
          <v-flex v-if="$store.state.user.isTomUser" xs12>
            <v-btn
              class="mt-4"
              color="primary"
              outlined
              @click="
                showDialog({
                  dialogTitle: 'WMSと同期',
                  dialogText:
                    'WMSの3辺・重量情報を同期し、商品の情報を更新します。',
                  dialogSubmit: '同期',
                  dialogCommand: manualSync
                })
              "
            >
              WMSの3辺・重量情報を同期する
            </v-btn>
          </v-flex>
        </v-card-text>
      </v-card>
    </v-flex>
    <v-flex xs12>
      <v-card class="mt-4">
        <v-card-title class="title text--primary font-weight-bold">
          更新履歴一覧
        </v-card-title>
        <v-card-subtitle class="font-weight-light caption text-right">
          ※ 括弧内の表示は数の増減を示します
        </v-card-subtitle>
        <TableAndPaging
          :headers="LOGS_HEADER"
          :items="$store.state.itemlogs.logs"
          :total="$store.state.itemlogs.total"
          :params="params"
          :query-params="queryParams"
          :action="ITEM_LOGS_ACTION"
        >
          <template #row="props">
            <td>{{ formatDate({ date: props.item.eventOccurredAt }) }}</td>
            <td>{{ props.item.category }}</td>
            <td>
              {{ formatEditor({ editor: props.item.editor }) }}
            </td>
            <td>
              <span>{{
                props.item.afterQty +
                props.item.afterReservedQty +
                props.item.afterConfirmedQty +
                props.item.afterReturnedQty
              }}</span>
              <span
                :class="
                  selectDiffClass(
                    props.item.diffQty +
                      props.item.diffReservedQty +
                      props.item.diffConfirmedQty +
                      props.item.diffReturnedQty
                  )
                "
              >
                ({{
                  props.item.diffQty +
                  props.item.diffReservedQty +
                  props.item.diffConfirmedQty +
                  props.item.diffReturnedQty
                }})
              </span>
            </td>
            <td>
              <span>{{ props.item.afterQty }}</span>
              <span :class="selectDiffClass(props.item.diffQty)">
                ({{ props.item.diffQty }})
              </span>
            </td>
            <td>
              <span>{{
                props.item.afterReservedQty + props.item.afterConfirmedQty
              }}</span>
              <span
                :class="
                  selectDiffClass(
                    props.item.diffReservedQty + props.item.diffConfirmedQty
                  )
                "
              >
                ({{ props.item.diffReservedQty + props.item.diffConfirmedQty }})
              </span>
            </td>
            <td>
              <span>{{ props.item.afterReturnedQty }}</span>
              <span :class="selectDiffClass(props.item.diffReturnedQty)">
                ({{ props.item.diffReturnedQty }})
              </span>
            </td>
            <td v-if="props.item.shipment">
              <v-btn
                small
                text
                :to="`/shipment/${props.item.shipment}`"
                :title="props.item.shipment"
              >
                <v-icon small left>open_in_new</v-icon>
                出荷詳細
              </v-btn>
            </td>
            <td v-else-if="props.item.manifest">
              <v-btn
                small
                text
                :to="`/manifests/${props.item.manifest}`"
                :title="props.item.manifest"
              >
                <v-icon small left>open_in_new</v-icon>
                入庫詳細
              </v-btn>
            </td>
            <td v-else-if="props.item.stocktakeConfirmation">
              <v-btn
                small
                text
                :to="`/stocktake/${props.item.stocktakeConfirmation}`"
                rel="noopener noreferrer"
              >
                <v-icon small left>open_in_new</v-icon>
                在庫差分詳細
              </v-btn>
            </td>
            <td v-else-if="props.item.ownerOrderNo">
              <v-btn
                small
                text
                :to="`/returns/?filter_owner_order_no=${props.item.ownerOrderNo}`"
                :title="props.item.ownerOrderNo"
              >
                <v-icon small left>open_in_new</v-icon>
                返送詳細
              </v-btn>
            </td>
            <td v-else-if="props.item.shipmentRequest">
              <v-btn
                small
                text
                :to="`/shipments/${props.item.shipmentRequest}`"
                :title="props.item.shipmentRequest"
              >
                <v-icon small left>open_in_new</v-icon>
                出荷依頼詳細
              </v-btn>
            </td>
            <td v-else>-</td>
          </template>
        </TableAndPaging>
      </v-card>
    </v-flex>
    <v-flex v-if="!$store.state.user.isTomUser" xs12>
      <v-btn
        class="mt-4"
        color="error accent-4"
        @click="
          showDialog({
            dialogTitle: '商品削除',
            dialogText: '商品を削除します',
            dialogSubmit: '削除',
            dialogCommand: deleteItem
          })
        "
      >
        商品削除（入庫依頼前まで削除可能）
      </v-btn>
    </v-flex>
    <v-flex xs12>
      <v-btn class="mt-4" color="primary accent-4" text to="/items">
        一覧に戻る
      </v-btn>
    </v-flex>
    <v-dialog v-model="dialog" max-width="300">
      <v-card>
        <v-card-title class="headline">{{ dialogTitle }}</v-card-title>
        <v-card-text>{{ dialogText }}</v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="error" text @click="dialog = false"> キャンセル </v-btn>
          <v-btn color="primary" text @click="dialogCommand">{{
            dialogSubmit
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<style scoped>
.edit {
  margin-right: 11px;
}
</style>

<script>
import _ from 'lodash';
import {
  FETCH_ITEM_DETAIL,
  FETCH_ITEM_LOGS,
  DELETE_ITEM,
  SYNC_ITEM,
  START_LOADING,
  FINISH_LOADING
} from '@/store/action-types';
import TableAndPaging from '@/components/TableAndPaging';
import { formatDate, formatEditor } from '@/util/formatter';

export default {
  components: {
    TableAndPaging
  },
  data() {
    const itemId = this.$route.params.itemId;
    return {
      title: itemId,
      itemFields: [
        {
          key: 'barcode',
          name: 'バーコード',
          content: `
            1SKU単位で設定される商品識別コード。<br />
            国際標準のGTINコード（JAN、UPC、EAN）、またはセカイロジ独自インハウスコードで記述
          `
        },
        {
          key: 'ownerItemCode',
          name: '荷主管理コード',
          content: 'バーコードとは別に荷主独自で管理するコード'
        },
        {
          key: 'namesJaJp',
          name: '商品名（日本語）',
          content: '日本国内宛の納品書には日本語商品名を記載'
        },
        {
          key: 'namesEnUs',
          name: '商品名（英語）',
          content: '海外宛の納品書には英語商品名を記載'
        },
        {
          key: 'note',
          name: 'ノート',
          content:
            '荷主用の商品に関する自由メモ欄（倉庫への指示や依頼としては利用不可。）'
        },
        {
          key: 'needs_label_print',
          name: 'バーコード貼付',
          content: `倉庫への入庫時に商品のバーコードシールの発行・貼付け作業が必要とされる場合には「要」。<br />
          商品にバーコードがすでに印字または貼付け済で、追加作業が不要な場合には「不要」と表示。`
        }
      ],

      itemAttributeFields: [
        {
          key: 'shippingCategory',
          name: '商品分類',
          content:
            '国際輸送時の税関申告書情報、及び国内伝票における品目名として利用。貨物の分類情報'
        },
        {
          key: 'hsCode',
          name: 'HSコード',
          content: `国際輸送時の税関申告情報。<br />
          「商品の名称及び分類についての統一システムに関する国際条約（HS条約）」に基づいて定められたコード番号。<br />
          基本は共通6桁のコードで表現（ex:6403.99）`
        },
        {
          key: 'originCountry',
          name: '原産国',
          content: `国際輸送時の税関申告情報。<br />
          実質的に製品本体の生産が行われた国、または最終的な製造が行われた国（「Made in xxxx」に該当するもの）`
        },
        {
          key: 'brand',
          name: 'ブランド',
          content: '国際輸送時の税関申告情報。商品のブランド名'
        },
        {
          key: 'specification',
          name: '商品スペック',
          content: '国際輸送時の税関申告情報。商品の使用用途や素材名等を記述。'
        },
        {
          key: 'url',
          name: '商品情報ページ',
          content:
            '国際輸送時の税関申告情報。商品販売ページやカタログページのURL'
        }
      ],
      stockFields: [
        {
          key: 'totalStock',
          name: '総在庫数',
          content:
            '倉庫内に実在する総在庫の数。（不良品在庫除く）。総在庫＝（引当可能在庫＋引当済み在庫＋返送在庫）'
        },
        {
          key: 'stock',
          name: '引当可能在庫数',
          content: `新規に出荷依頼が作成可能な残在庫数。入庫すると増加、新規出荷依頼で減少。<br />
          （引当可能在庫数以上の出荷依頼を作成している場合、実在庫が不足となるためマイナス表記となる）`
        },
        {
          key: 'keepedStock',
          name: '引当済在庫数',
          content: `出荷作業中、または出荷依頼ドラフトや出荷予約状態で既存で引当がかかっている在庫数。<br />
          新規に出荷依頼を行うと増加、出荷が完了または出荷依頼をキャンセルすることで減少`
        },
        {
          key: 'reservedStock',
          name: '> 出荷予約在庫数',
          onlyAdmin: true
        },
        {
          key: 'confirmedStock',
          name: '> ドラフト在庫数',
          onlyAdmin: true
        },
        {
          key: 'returnedStock',
          name: '返送在庫数',
          content:
            '返送一覧に表示された返送商品のうち、まだ再入庫も再出荷もしていない未アクション状態で確保されている在庫数'
        }
      ],
      shippingFields: [
        {
          key: 'shipType',
          name: '出荷タイプ',
          content: `梱包あり_混載可：セカイロジ指定のダンボールに梱包して出荷。1つの出荷依頼で、複数または別のSKUを同梱して出荷して良い場合には、こちらを指定。※ 通常はこちらを推奨 <br />
          梱包あり_混載不可：セカイロジ指定のダンボールに梱包して出荷。1つの出荷依頼で、「1梱包1点」ずつ梱包が必要な場合にはこちらを指定。※ 個口が複数に分かれる分配送コストが高くなる傾向あり <br />
          梱包なし_伝票直貼り：商品がすでに入庫時点で梱包されており、出荷時もそのまま状態で発送伝票のみをカートンに貼り付けて出荷して問題ない場合にはこちらを指定`
        },
        {
          key: 'weight',
          name: '重量(g)'
        },
        {
          key: 'dimensionalWeight',
          name: '容積重量(g)',
          content:
            '出荷に海外宛の民間運輸会社や便種をご利用の際に送料計算の元となる情報。三辺情報から自動で計算'
        },
        {
          key: 'width',
          name: '幅(cm)'
        },
        {
          key: 'depth',
          name: '奥行き(cm)'
        },
        {
          key: 'height',
          name: '高さ(cm)'
        },
        {
          key: 'shipmentExpirationBufferDays',
          name: '消費期限_出荷許容日数',
          content:
            '賞味期限／消費期限が設定されている商品に対し、期限の何日前まで出荷を許容するかを規定する日数。規定を超過した商品は引当可能在庫から除外'
        }
      ],

      queryParams: {
        limit: 10
      },
      params: {
        itemId: itemId
      },
      endApprovedDateMenu: false,
      dialog: false,
      dialogTitle: '',
      dialogText: '',
      dialogSubmit: '',
      dialogCommand: null,
      editPath: `/items/${this.$route.params.itemId}/update`
    };
  },
  computed: {
    LOGS_HEADER() {
      return [
        '更新日時',
        '更新内容',
        '更新者',
        '総在庫数',
        '引当可能在庫数',
        '引当済在庫数',
        '返送在庫数',
        '関連ページ'
      ];
    },
    ITEM_LOGS_ACTION() {
      return `itemlogs/${FETCH_ITEM_LOGS}`;
    }
  },
  mounted() {
    this.$store.dispatch(`item/${FETCH_ITEM_DETAIL}`, {
      itemId: this.$route.params.itemId
    });
  },
  methods: {
    selectDiffClass(diffNum) {
      if (parseInt(diffNum) < 0) {
        return 'red--text';
      }

      return 'blue--text';
    },
    formatDate,
    formatEditor,
    /**
     * ダイアログを表示する
     * @param {string} title
     * @param {string} text
     * @param {function} command
     */
    showDialog({ dialogTitle, dialogText, dialogCommand, dialogSubmit }) {
      this.dialogTitle = dialogTitle;
      this.dialogText = dialogText;
      this.dialogCommand = dialogCommand;
      this.dialogSubmit = dialogSubmit;
      this.dialog = true;
    },
    async deleteItem() {
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      try {
        await this.$store.dispatch(`item/${DELETE_ITEM}`, {
          itemId: this.$route.params.itemId
        });
      } catch (err) {
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        this.dialog = false;
        await this.$store.dispatch(
          'notify/showErrorNotify',
          _.get(
            err,
            'response.data.errors.general',
            'エラーが発生しました、画面を更新してやり直してください'
          )
        );
        throw err;
      }
      await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
      this.dialog = false;
      this.$router.push(this.$route.query.redirect || '/items');
    },
    async manualSync() {
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      try {
        await this.$store.dispatch(`item/${SYNC_ITEM}`, {
          itemId: this.$route.params.itemId
        });
        await this.$store.dispatch(
          'notify/showNotify',
          '商品の同期を完了しました。'
        );
      } catch (err) {
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        this.dialog = false;
        await this.$store.dispatch(
          'notify/showErrorNotify',
          _.get(
            err,
            'response.data.errors.general',
            'エラーが発生しました、画面を更新してやり直してください'
          )
        );
        throw err;
      }
      await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
      this.dialog = false;
    }
  }
};
</script>
