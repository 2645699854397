'use strict';

module.exports = {
  FLAG_LABELS: {
    send_manifest_result_mail: '入庫通知',
    send_shipment_result_mail: '出荷通知',
    send_stocked_mail: '在庫通知',
    send_stocktake_confirmation_mail: '在庫差分発生通知',
    send_return_shipment_notify_mail: '返品入庫発生通知'
  }
};
