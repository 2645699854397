<template>
  <div>
    <v-form>
      <v-layout justify-center>
        <v-card width="480">
          <v-card-title>フライヤー情報</v-card-title>
          <v-card-text>
            <v-text-field
              name="title"
              v-model="title"
              :error-messages="mergedError('title')"
              label="フライヤー名"
              v-validate="'required|max:50'"
              data-vv-validate-on="change"
              data-vv-as="フライヤー名"
              hint="50文字以内の数字、記号、文字列"
              outlined
              persistent-hint
            />
            <div class="mt-8">
              <v-btn
                color="primary"
                block
                elevation="0"
                @click="postFlyerRequest"
              >
                フライヤーを登録する
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-layout>
      <v-alert
        v-if="mergedError('general').length"
        color="error"
        text
        v-html="mergedError('general').split('\n').join('<br />')"
      >
      </v-alert>
    </v-form>
  </div>
</template>

<script>
import { mergeErrors } from '@/util/input';
import { JsonError } from '@/util/error';

import {
  START_LOADING,
  FINISH_LOADING,
  CREATE_FLYER
} from '@/store/action-types';

export default {
  $_veeValidate: {
    validator: 'new'
  },
  data() {
    return {
      title: ''
    };
  },
  computed: {
    isTomUser: (self) => self.$store.state.user.isTomUser
  },
  methods: {
    async clearError() {
      await this.$store.dispatch('notify/clearNotify');
      this.serverErrors = {};
    },
    /**
     * フライヤー登録時のAPIリクエストを送る
     */
    async postFlyerRequest() {
      await this.clearError();

      if (!(await this.$validator.validate())) {
        await this.$store.dispatch(
          'notify/showErrorNotify',
          '入力項目に誤りがあります。エラー表示されている項目を確認してください。'
        );

        return;
      }

      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      try {
        await this.$store.dispatch(`flyer/${CREATE_FLYER}`, {
          title: this.title
        });
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        this.$router.push({
          path: '/flyers/index',
          query: {
            notifyType: 'showNotify',
            notifyMessageCode: this.$router.MESSAGE_CODES.FLYER_CREATE_SUCCESS
          }
        });
      } catch (error) {
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());

        if (error instanceof JsonError) {
          this.serverErrors = error.toJson();
          await this.$store.dispatch(
            'notify/showErrorNotify',
            '入力項目に誤りがあります。エラー表示されている項目を確認してください。'
          );
        } else {
          await this.$store.dispatch('notify/showErrorNotify', error.message);
        }
      }
    },
    mergedError(field) {
      return mergeErrors(this.$validator.errors, this.serverErrors, field);
    }
  }
};
</script>
