<template>
  <tr class="striped-row" @dblclick="onDblClick">
    <td v-if="selectable" class="col-checkbox">
      <v-simple-checkbox
        v-model="selected"
        color="primary"
        :ripple="false"
        @input="onSelect"
      ></v-simple-checkbox>
    </td>
    <slot></slot>
  </tr>
</template>

<script>
export default {
  props: {
    selectable: Boolean,
    value: Object,
    selectedItemIds: Array
  },

  data() {
    return {
      selected: false
    };
  },

  methods: {
    onSelect() {
      this.$emit('select', this.selected, this.value);
    },

    onDblClick() {
      this.$emit('dblclick', this.value);
    }
  }
};
</script>

<style scoped>
.col-checkbox {
  width: 56px;
}
</style>
