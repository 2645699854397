<template>
  <div>
    <v-row>
      <v-col sm="6">
        <v-card>
          <v-card-title class="headline">CSVアップロード</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-layout>
              <v-flex>
                商品情報が記入されたCSVを選択してアップロードしてください。
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex v-if="!isTomUser">
                <CsvUploadForm :category="CATEGORY.CREATE_ITEM" />
              </v-flex>
              <v-flex v-else class="text-center my-12">
                TOM荷主ユーザーはアップロード処理が行えません。
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-layout class="pa-2">
            <v-flex class="text-right">
              <v-icon size="20">help</v-icon>
              <v-btn
                text
                color="primary"
                href="https://docs.google.com/spreadsheets/d/1QvWJmNNcVJ6PqtSZuLM0O2FUQqCM4Chaa96FDfSMw_E"
                target="_blank"
              >
                CSVテンプレート / サンプル / 参考情報
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </v-col>
      <v-col sm="6">
        <v-card class="fill-height">
          <v-card-title class="headline">
            フォームから商品を新規登録
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-layout class="pb-6">
              <v-flex>
                フォームから必要な情報を入力して、商品を新規登録します。
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex
                v-if="!isTomUser"
                class="subtitle-1 text-center"
                cols="12"
              >
                <v-btn
                  text
                  color="primary accent-4"
                  class="v-btn--active"
                  to="/items/form"
                >
                  クリックしてフォームへ移動します
                </v-btn>
              </v-flex>
              <v-flex v-else class="text-center my-12">
                TOM荷主ユーザーは入庫依頼を作成できません。
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <CsvUploadProgress
      :category="CATEGORY.CREATE_ITEM"
      class="mt-6"
    ></CsvUploadProgress>
  </div>
</template>

<script>
import { CATEGORY } from '@/../lib/document/schema/csv_upload_states';
import CsvUploadForm from '@/components/CsvUploadForm';
import CsvUploadProgress from '@/components/CsvUploadProgress';

export default {
  components: {
    CsvUploadForm,
    CsvUploadProgress
  },
  computed: {
    CATEGORY: () => CATEGORY,
    isTomUser: (self) => self.$store.state.user.isTomUser
  }
};
</script>
