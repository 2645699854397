<template>
  <v-snackbar
    id="notify"
    :snackbar="message !== ''"
    :value="message"
    :color="color"
    :multi-line="true"
    :bottom="true"
    :timeout="-1"
  >
    <div v-html="message" />
    <template v-slot:action="{ attrs }">
      <v-btn text @click="callback" v-bind="attrs">{{ callbackText }}</v-btn>
    </template>
  </v-snackbar>
</template>

<style lang="stylus">
#notify {
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>

<script>
export default {
  computed: {
    color: (self) => self.$store.state.notify.color,
    message: (self) => self.$store.state.notify.message,
    callback: (self) =>
      self.$store.state.notify.callback ??
      (() => self.$store.dispatch('notify/clearNotify')),
    callbackText: (self) => self.$store.state.notify.callbackText ?? '×'
  }
};
</script>
