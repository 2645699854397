<template>
  <tr>
    <th
      v-if="selectable"
      :colspan="indeterminate ? headers.length + 1 : 1"
      class="col-checkbox"
    >
      <v-simple-checkbox
        v-model="selected"
        color="primary"
        :ripple="false"
        :indeterminate="indeterminate"
        @input="onSelect"
      ></v-simple-checkbox>
    </th>
    <template v-if="!(selectable && indeterminate)">
      <th v-for="h in headers || []" :key="'th' + h">
        {{ h }}
      </th>
    </template>
  </tr>
</template>

<script>
export default {
  props: {
    selectable: Boolean,
    headers: Array,
    value: Boolean,
    selectedItemIds: Array,
    length: Number
  },

  data() {
    return {
      selected: false
    };
  },

  watch: {
    value(b) {
      this.selected = b;
    }
  },

  computed: {
    indeterminate() {
      return this.selectedItemIds.length > 0;
    }
  },

  methods: {
    onSelect() {
      this.$emit('select', this.selected);
    }
  }
};
</script>

<style scoped>
.col-checkbox {
  width: 56px;
}
</style>
