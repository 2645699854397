import axios from 'axios';

const removeHyphen = (value) => {
  return `${value}`.replace(
    /[\u2010\u2011\u2012\u2013\u2014\u2015\u207b\u208b\u2212\u30fc\uff0d\uff70\u4e00\p{gc=Dash_Punctuation}]/gu,
    ''
  );
};

/**
 * 入力された郵便番号でpostal-code-apiにリクエストして住所情報配列を取得する
 *
 * @param postalCode 7桁の郵便番号
 * @returns postal-code-apiをリクエストして得られた結果データ内の住所配列
 */
const jpPostalCodeToAddress = async ({ postalCode }) => {
  if (!postalCode.match(/^[0-9]{3}-?[0-9]{4}$/)) {
    throw new Error('郵便番号が正しい形式ではありません。');
  }

  const url = `/postal_code/${postalCode}`;

  try {
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data.addresses;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      throw new Error('指定された郵便番号に対応する住所が存在しません');
    }
    throw new Error('郵便番号の処理でエラーが発生しました');
  }
};

export { removeHyphen, jpPostalCodeToAddress };
