<template>
  <v-container px-0>
    <v-row>
      <v-col sm="6">
        <v-card>
          <v-card-title class="headline">CSVで出荷依頼を作成</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-layout>
              <v-flex>
                出荷依頼情報が記入されたCSVを選択してアップロードしてください。
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex v-if="!isTomUser">
                <CsvUploadForm :category="CATEGORY.SHIPMENT" />
              </v-flex>
              <v-flex v-else class="text-center my-12">
                TOM荷主ユーザーはアップロード処理が行えません。
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-layout class="pa-2">
            <v-flex class="text-right">
              <v-icon size="20">help</v-icon>
              <v-btn
                text
                color="primary"
                href="https://docs.google.com/spreadsheets/d/1dlTrY2r9yEJkhC0q1Sg99OR5bGrm4QpKxzXpsi0NeaQ/edit#gid=903051261"
                target="_blank"
              >
                CSVテンプレート / サンプル / 参考情報
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </v-col>
      <v-col sm="6">
        <v-card class="fill-height">
          <v-card-title class="headline">
            フォームから出荷依頼を作成
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-layout class="pb-6">
              <v-flex>
                フォームから必要な情報を入力して、出荷依頼を作成します。
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex
                v-if="!isTomUser"
                class="subtitle-1 text-center"
                cols="12"
              >
                <v-btn
                  text
                  color="primary accent-4"
                  class="v-btn--active"
                  to="/shipments/form"
                >
                  クリックしてフォームへ移動します
                </v-btn>
              </v-flex>
              <v-flex v-else class="text-center my-12">
                TOM荷主ユーザーは出荷依頼を作成できません。
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <CsvUploadProgress :category="CATEGORY.SHIPMENT"></CsvUploadProgress>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { CATEGORY } from '@/../lib/document/schema/csv_upload_states';
import CsvUploadForm from '@/components/CsvUploadForm';
import CsvUploadProgress from '@/components/CsvUploadProgress';

export default {
  components: {
    CsvUploadForm,
    CsvUploadProgress
  },
  computed: {
    CATEGORY: () => CATEGORY,
    isTomUser: (self) => self.$store.state.user.isTomUser
  }
};
</script>
