<template>
  <div>
    <MainHeader title="在庫差分一覧"></MainHeader>
    <stock-diffs-list />
  </div>
</template>

<script>
import MainHeader from '@/components/layout/MainHeader';
import StockDiffsList from '@/components/stock_diffs/StockDiffsList';

export default {
  components: {
    MainHeader,
    StockDiffsList
  },
  data: () => {
    return {};
  },
  computed: {}
};
</script>
