<template>
  <v-container fluid fill-height v-if="isApiError">
    <v-layout row wrap align-center justify-center>
      <v-flex md4 xs4 text-center>
        <Logo></Logo>
        <v-card class="mt-10 mx-auto" max-width="480">
          <v-card-text class="pa-6">
            エラーが発生しました。再度お試しいただくか、改善しない場合はメールまたは<a
              href="https://sekailogi.com"
              >こちら</a
            >からお問合せください。
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { START_LOADING, FINISH_LOADING } from '@/store/action-types';
import axios from '@/api/common/axios';
import Logo from '../components/Logo.vue';

export default {
  components: {
    Logo
  },
  data: function () {
    const query = this.$route.query;
    return { token: query.token || '', isApiError: false };
  },
  async beforeMount() {
    await this.$store.dispatch(`app/${START_LOADING}`, new Date());
    try {
      const response = await axios.post('/auth/init', {
        token: this.token
      });

      if (response && response.data) {
        this.$router.push({
          path: '/auth/reset',
          query: {
            token: response.data.forgotPassToken
          }
        });
      }
    } catch (e) {
      this.isApiError = true;
      const message =
        (e.response &&
          e.response.data &&
          e.response.data.errors &&
          e.response.data.errors.general) ||
        'エラーが発生しました。';
      await this.$store.dispatch('notify/showErrorNotify', message);
    }
    await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
  }
};
</script>
