import axios from '@/api/common/axios';
import {
  API_SETTING_FETCHED,
  API_SETTINGS_FETCHED
} from '@/store/mutation-types';
import {
  FETCH_API_TOKENS,
  FETCH_API_TOKEN,
  GENERATE_API_TOKEN,
  REFRESH_API_TOKEN,
  CHANGE_API_TOKEN
} from '@/store/action-types';
import _ from 'lodash';

export default {
  namespaced: true,
  state: {
    apiSetting: {},
    apiSettings: []
  },
  mutations: {
    [API_SETTING_FETCHED](state, apiSetting) {
      state.apiSetting = apiSetting;
    },
    [API_SETTINGS_FETCHED](state, apiSettings) {
      state.apiSettings = apiSettings;
    }
  },
  actions: {
    async [FETCH_API_TOKEN]({ commit }, params) {
      const res = await axios.get(
        `/api_settings/detail/${params.apiSettingId}`
      );
      const apiSetting = res.data.apiSetting;
      commit(API_SETTING_FETCHED, apiSetting);
    },
    async [FETCH_API_TOKENS]({ commit }) {
      const res = await axios.get('/api_settings/index');
      const apiSettings = res.data.apiSettings;
      commit(API_SETTINGS_FETCHED, apiSettings);
    },
    async [GENERATE_API_TOKEN]({}, {}) {
      const response = await axios
        .post('/api_settings/generate_token', {})
        .catch((error) => {
          throw new Error(error.response.data.errors.general);
        });
      return _.get(response, 'data.general', response.data.token);
    },
    async [REFRESH_API_TOKEN]({}, params) {
      const response = await axios
        .post('/api_settings/refresh_token', params)
        .catch((error) => {
          throw new Error(error.response.data.errors.general);
        });
      return _.get(response, 'data.general', response.data.token);
    },
    async [CHANGE_API_TOKEN]({}, params) {
      await axios.post('/api_settings/change', params).catch((error) => {
        throw new Error(error.response.data.errors.general);
      });
    }
  }
};
