<template>
  <v-app-bar
    class="header"
    v-if="isToolbar"
    color="primary"
    height="56"
    flat
    dark
    fixed
    clipped-left
    app
  >
    <v-toolbar-title>
      <router-link to="/">
        <Logo></Logo>
      </router-link>
    </v-toolbar-title>

    <span class="text-version pl-3">Version: {{ commitHash }}</span>

    <v-spacer></v-spacer>

    <v-menu left bottom offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" v-bind="attrs" v-on="on" depressed tile>
          <v-icon left dark>settings</v-icon>
          設定
        </v-btn>
      </template>
      <v-list>
        <v-list-item>
          <v-list-item-title>{{
            $store.state.user.name || ''
          }}</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>

        <v-list-item
          v-if="isManager"
          @click="
            $router.push({
              path: `/owners/${$store.state.user.owner}/add_user`
            })
          "
        >
          <v-list-item-icon class="mr-4">
            <v-icon>person_add</v-icon>
          </v-list-item-icon>
          <v-list-item-title>アカウント追加</v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if="isManager"
          @click="
            $router.push({ path: `/owners/${$store.state.user.owner}/users` })
          "
        >
          <v-list-item-icon class="mr-4">
            <v-icon>people</v-icon>
          </v-list-item-icon>
          <v-list-item-title>アカウント一覧</v-list-item-title>
        </v-list-item>

        <v-list-item @click="$router.push({ path: '/settings/password' })">
          <v-list-item-icon class="mr-4">
            <v-icon>lock</v-icon>
          </v-list-item-icon>
          <v-list-item-title>パスワード変更</v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if="isManager"
          @click="
            $router.push({ path: `/owners/${$store.state.user.owner}/update` })
          "
        >
          <v-list-item-icon class="mr-4">
            <v-icon>domain</v-icon>
          </v-list-item-icon>
          <v-list-item-title>荷主情報確認/変更</v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if="isManager"
          @click="$router.push({ path: '/settings/slips' })"
        >
          <v-list-item-icon class="mr-4">
            <v-icon>receipt</v-icon>
          </v-list-item-icon>
          <v-list-item-title>納品書設定</v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if="isManager"
          @click="$router.push({ path: '/settings/api' })"
        >
          <v-list-item-icon class="mr-4">
            <v-icon>api</v-icon>
          </v-list-item-icon>
          <v-list-item-title>API設定</v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if="isManager"
          @click="$router.push({ path: '/settings/ip_restrictions' })"
        >
          <v-list-item-icon class="mr-4">
            <v-icon>block</v-icon>
          </v-list-item-icon>
          <v-list-item-title>IP制限</v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if="isManager"
          @click="$router.push({ path: '/payments' })"
        >
          <v-list-item-icon class="mr-4">
            <v-icon>receipt_long</v-icon>
          </v-list-item-icon>
          <v-list-item-title>お支払い設定</v-list-item-title>
        </v-list-item>

        <v-divider class="mt-2 mb-2"></v-divider>

        <v-list-item to="/auth/logout">
          <v-list-item-icon class="mr-4">
            <v-icon>logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title>ログアウト</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-menu left bottom offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" v-bind="attrs" v-on="on" depressed tile>
          <v-icon left dark>help</v-icon>
          ヘルプ
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          href="https://support-sekailogi.zendesk.com/hc/ja"
          target="_blank"
          rel="noopener noreferrer"
        >
          <v-list-item-title>ヘルプセンター</v-list-item-title>
        </v-list-item>
        <v-list-item
          :href="TERMS_OF_SERVICE_LINK"
          target="_blank"
          rel="noopener noreferrer"
        >
          <v-list-item-title>利用規約</v-list-item-title>
        </v-list-item>
        <v-list-item
          :href="PRIVACY_POLICY_LINK"
          target="_blank"
          rel="noopener noreferrer"
        >
          <v-list-item-title>プライバシーポリシー</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapMutations } from 'vuex';
import { TOGGLE_DRAWER } from '../../store/mutation-types';
import META_NODE_ENV from '@/util/environment';
import Logo from '../../components/Logo.vue';
import {
  TERMS_OF_SERVICE_LINK,
  PRIVACY_POLICY_LINK
} from '@/../public/agreement-url';
import { formatDate } from '@/util/formatter';

export default {
  components: {
    Logo
  },

  data: () => ({
    title: null,
    responsive: false,
    errorMessage: '',
    isLoading: false
  }),

  watch: {
    $route(val) {
      this.title = val.name;
    }
  },

  async mounted() {
    this.title = this.$route.name;
    this.onResponsiveInverted();
    window.addEventListener('resize', this.onResponsiveInverted);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResponsiveInverted);
  },

  computed: {
    TERMS_OF_SERVICE_LINK: () => TERMS_OF_SERVICE_LINK,
    PRIVACY_POLICY_LINK: () => PRIVACY_POLICY_LINK,
    isManager() {
      return this.$store.state.user.category === 'MANAGER';
    },
    isDrawer() {
      return !this.$route.meta.noDrawer;
    },
    isToolbar() {
      return !this.$route.meta.noToolbar;
    },
    envMark() {
      if (META_NODE_ENV !== 'production') {
        return META_NODE_ENV;
      }
      return '';
    },
    commitHash: (self) => self.$store.state.version.hash
  },

  methods: {
    ...mapMutations('app', {
      setDrawer: TOGGLE_DRAWER
    }),
    onClickBtn() {
      this.setDrawer(!this.$store.state.app.drawer);
    },
    onResponsiveInverted() {
      if (window.innerWidth < 991) {
        this.responsive = true;
      } else {
        this.responsive = false;
      }
    },
    formatDate
  }
};
</script>

<style scoped>
/deep/ .v-toolbar__content {
  padding: 0 0 0 16px !important;
}
/deep/ .v-btn:not(.v-btn--round).v-size--default {
  height: inherit;
}
.text-version {
  font-size: 12px;
}
.news-list {
  min-width: 503px;
}
.news-list .v-divider {
  margin: 10px 20px !important;
}
.v-list-item__title {
  font-size: 0.875rem !important;
}
</style>
