<template>
  <v-container>
    <v-row>
      <v-col> 使用を開始するためにデフォルト納品書テンプレートを登録してください。 </v-col>
    </v-row>
    <v-stepper alt-labels value="3">
      <v-stepper-header>
        <v-stepper-step step="1" complete> 荷主情報登録 </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="2" complete> 支払い設定登録 </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3"> 納品書設定 </v-stepper-step>
      </v-stepper-header>
    </v-stepper>
    <v-row>
      <v-col>
        <EditSlipComponent :initialize="true" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EditSlipComponent from '@/components/settings/EditSlipComponent';

export default {
  components: {
    EditSlipComponent
  }
};
</script>
