<template>
  <div>
    <v-card>
      <v-card-text>
        <v-layout>
          <v-autocomplete
            :items="YEAR_MONTH"
            v-model="yearMonth"
            item-value="value"
            item-text="text"
            outlined
          />
          <v-select
            v-model="amountStatus"
            :items="amountStatusItems"
            label="締め状況"
            class="ml-2 flex-grow-0"
            multiple
            outlined
            hide-details
            style="max-width: 160px"
          >
            <template v-slot:selection="{ index }">
              <v-chip v-if="index === 0">
                <span>{{ amountStatus.length }}件選択</span>
              </v-chip>
            </template>
          </v-select>
          <v-select
            v-model="paidStatus"
            :items="paidStatusItems"
            label="精算状況"
            class="ml-2 flex-grow-0"
            multiple
            outlined
            hide-details
            style="max-width: 160px"
          >
            <template v-slot:selection="{ index }">
              <v-chip v-if="index === 0">
                <span>{{ paidStatus.length }}件選択</span>
              </v-chip>
            </template>
          </v-select>
          <v-checkbox
            label="削除済みも表示する"
            v-model="showDisabled"
            class="ml-2"
          />
          <v-btn
            color="primary"
            class="ml-2"
            outlined
            height="56"
            @click.stop="onClickCsvDownload"
          >
            CSVダウンロード
          </v-btn>
        </v-layout>
        <v-layout>
          <v-text-field
            label="荷主名"
            v-model="keywords"
            prepend-inner-icon="search"
            outlined
            hide-details
          ></v-text-field>
          <v-btn
            color="amber darken-3"
            class="ml-2"
            outlined
            height="56"
            @click.stop="onClickBulkFix"
            :disabled="!$store.state.owners.billingsApprovedCount"
          >
            確定済みを一括で請求書発行する({{
              $store.state.owners.billingsApprovedCount
            }}件)
          </v-btn>
          <v-btn
            color="teal"
            class="ml-2"
            outlined
            height="56"
            @click.stop="onClickBulkPay"
            :disabled="!$store.state.owners.billingsUnpaidCount"
          >
            請求書発行済を一括で精算する({{
              $store.state.owners.billingsUnpaidCount
            }}件)
          </v-btn>
        </v-layout>
      </v-card-text>
      <TableAndPaging
        :headers="[
          'アクション',
          '荷主番号',
          '荷主名',
          '支払い方法',
          '締め状況',
          '精算状況',
          '当月請求予定金額(税込)',
          'ご利用可能枠（税込）',
          '残ご利用可能金額（税込）'
        ]"
        :items="$store.state.owners.billings"
        :total="$store.state.owners.billingsTotalCount"
        :action="action"
        :query-params="queryParams"
      >
        <template v-slot:row="props">
          <td>
            <v-btn
              v-if="props.item.amount_status === AMOUNT_STATUS.PENDING"
              color="primary"
              text
              @click="
                onClickApprove({
                  billingId: props.item._id,
                  ownerId: props.item.owner._id
                })
              "
            >
              確定する
            </v-btn>
            <v-btn
              v-else-if="props.item.amount_status === AMOUNT_STATUS.APPROVED"
              color="amber darken-3"
              text
              @click="
                onClickFix({
                  billingId: props.item._id,
                  ownerId: props.item.owner._id
                })
              "
            >
              請求書発行する
            </v-btn>
            <v-btn
              v-else-if="
                props.item.amount_status === AMOUNT_STATUS.FIXED &&
                props.item.paid_status !== PAID_STATUS.PAID
              "
              color="teal"
              text
              @click="
                onClickPay({
                  billingId: props.item._id,
                  ownerId: props.item.owner._id
                })
              "
            >
              精算する
            </v-btn>
          </td>
          <td>
            {{ props.item.owner.unique_number }}
          </td>
          <td>
            <router-link
              :to="`/owners/${props.item.owner._id}/billings/update?date=${yearMonth}`"
              class="link-row--main"
            >
              {{
                props.item.owner.names
                  ? props.item.owner.names.ja_jp
                  : '名前未設定'
              }}
            </router-link>
          </td>
          <td>請求書（口座振込）</td>
          <td :class="AMOUNT_STATUS_COLOR[props.item.amount_status]">
            {{ AMOUNT_STATUS_DICTIONARY[props.item.amount_status] }}
          </td>
          <td :class="PAID_STATUS_COLOR[props.item.paid_status]">
            {{ PAID_STATUS_DICTIONARY[props.item.paid_status] }}
          </td>
          <td>{{ props.item.total_amount.toLocaleString('ja-JP') }}円</td>
          <td>{{ props.item.owner.credit_limit.toLocaleString('ja-JP') }}円</td>
          <td>
            {{
              props.item.owner.remaining_credit_limit.toLocaleString('ja-JP')
            }}円
          </td>
        </template>
      </TableAndPaging>
    </v-card>
    <ConfirmDialog ref="dialog"></ConfirmDialog>
  </div>
</template>

<script>
const _ = require('lodash');
import TableAndPaging from '@/components/TableAndPaging';
import {
  FETCH_OWNERS_BILLINGS,
  APPROVE_BILLINGS,
  FIX_BILLINGS,
  BULK_FIX_BILLING,
  PAY_BILLINGS,
  BULK_PAY_BILLINGS,
  START_LOADING,
  FINISH_LOADING
} from '@/store/action-types';
import ownersSchema from '@/../lib/document/schema/owners';
import moment from 'moment-timezone';
import {
  AMOUNT_STATUS,
  AMOUNT_STATUS_DICTIONARY,
  AMOUNT_STATUS_PATTERN,
  PAID_STATUS_DICTIONARY,
  PAID_STATUS,
  PAID_STATUS_PATTERN
} from '@/../lib/document/schema/billings';
import querystring from 'querystring';
import axios from '@/api/common/axios';
import { getYearMonth } from '@/../lib/util/date';
import ConfirmDialog from '@/components/ConfirmDialog';

export default {
  data() {
    const query = this.$route.query;
    const currentYearMonth = moment().format('YYYYMM');
    const yearMonth = query.date || currentYearMonth;
    let amountStatus = AMOUNT_STATUS_PATTERN;
    let paidStatus = PAID_STATUS_PATTERN;
    if (Object.keys(query).length) {
      amountStatus = Array.isArray(query.amountStatus)
        ? query.amountStatus
        : [query.amountStatus].filter((v) => v);
      paidStatus = Array.isArray(query.paidStatus)
        ? query.paidStatus
        : [query.paidStatus].filter((v) => v);
    }

    return {
      YEAR_MONTH: getYearMonth(),
      AMOUNT_STATUS,
      AMOUNT_STATUS_DICTIONARY,
      PAID_STATUS_DICTIONARY,
      AMOUNT_STATUS_COLOR: {
        PENDING: 'red--text',
        APPROVED: 'amber--text text--darken-3',
        FIXED: 'primary--text'
      },
      PAID_STATUS_COLOR: {
        UNPAID: 'red--text',
        PAID: 'teal--text',
        FAIL: ''
      },
      PAID_STATUS,
      yearMonth,
      amountStatus,
      paidStatus,
      showDisabled: false,
      keywords: query.keywords || ''
    };
  },
  components: {
    TableAndPaging,
    ConfirmDialog
  },
  computed: {
    action: () => `owners/${FETCH_OWNERS_BILLINGS}`,
    queryParams: (self) => {
      return {
        date: self.yearMonth,
        amountStatus: self.amountStatus,
        paidStatus: self.paidStatus,
        onlyAvailable: self.showDisabled ? undefined : true,
        keywords: self.keywords
      };
    },
    amountStatusItems: function () {
      return Object.entries(AMOUNT_STATUS_DICTIONARY).map(([key, value]) => {
        return {
          text: value,
          value: key
        };
      });
    },
    paidStatusItems: function () {
      return Object.entries(PAID_STATUS_DICTIONARY).map(([key, value]) => {
        return {
          text: value,
          value: key
        };
      });
    }
  },
  methods: {
    convertOwnerStatus({ state, accountStatus }) {
      if (accountStatus === ownersSchema.ACCOUNT_STATUS.READ_ONLY) {
        return '利用中（閲覧のみ）';
      }
      return ownersSchema.STATE_LABEL[state];
    },
    async onClickCsvDownload() {
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());

      try {
        const errorMsg =
          'データ取得時にエラーがありました。サポートへご連絡ください。';
        await axios
          .get(`/owners/billing_csv?${querystring.stringify(this.queryParams)}`)
          .catch((e) => {
            throw new Error(_.get(e, 'response.data.errors.general', errorMsg));
          });
      } catch (error) {
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        await this.$store.dispatch(
          'notify/showErrorNotify',
          _.get(
            error,
            'message',
            'エラーが発生しました、画面を更新してやり直してください'
          )
        );
        throw error;
      }
      await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
      await this.$store.dispatch(
        'notify/showNotify',
        '処理を開始しました。処理完了時にメールで通知します。'
      );
    },
    async onClickApprove({ billingId, ownerId }) {
      await this.$store.dispatch('notify/clearNotify');
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      try {
        await this.$store.dispatch(`billings/${APPROVE_BILLINGS}`, {
          billingId,
          ownerId
        });
        // 一覧を取り直す
        await this.$store.dispatch(
          `owners/${FETCH_OWNERS_BILLINGS}`,
          this.queryParams
        );
      } catch (error) {
        await this.$store.dispatch(
          'notify/showErrorNotify',
          _.get(
            error,
            'response.data.errors.general',
            'エラーが発生しました、画面を更新してやり直してください'
          )
        );
        throw error;
      } finally {
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
      }
    },
    async onClickFix({ billingId, ownerId }) {
      if (
        !(await this.$refs.dialog.open({
          headline: '請求書の作成',
          text: '請求書の作成処理を行いますか？'
        }))
      ) {
        return;
      }

      await this.$store.dispatch('notify/clearNotify');
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      try {
        await this.$store.dispatch(`billings/${FIX_BILLINGS}`, {
          billingId,
          ownerId
        });
        await this.$store.dispatch(
          'notify/showNotify',
          '請求書の作成処理を開始しました。完了後にメールで通知します。'
        );
      } catch (error) {
        await this.$store.dispatch(
          'notify/showErrorNotify',
          _.get(
            error,
            'response.data.errors.general',
            'エラーが発生しました、画面を更新してやり直してください'
          )
        );
        throw error;
      } finally {
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
      }
    },
    async onClickPay({ billingId, ownerId }) {
      if (
        !(await this.$refs.dialog.open({
          headline: '精算する',
          text: '支払いを確定してもよろしいですか？'
        }))
      ) {
        return;
      }

      await this.$store.dispatch('notify/clearNotify');
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      try {
        await this.$store.dispatch(`billings/${PAY_BILLINGS}`, {
          billingId,
          ownerId
        });
        await this.$store.dispatch(
          'notify/showNotify',
          '精算処理を開始しました。完了後にメールで通知します。'
        );
      } catch (error) {
        await this.$store.dispatch(
          'notify/showErrorNotify',
          _.get(
            error,
            'response.data.errors.general',
            'エラーが発生しました、画面を更新してやり直してください'
          )
        );
        throw error;
      } finally {
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
      }
    },
    async onClickBulkFix() {
      if (!this.$store.state.owners.billingsApprovedCount) {
        await this.$store.dispatch(
          'notify/showErrorNotify',
          '請求書を作成できる請求がありません。'
        );
        return;
      }

      if (
        !(await this.$refs.dialog.open({
          headline: '請求書の一括作成',
          text: '請求書の作成を行いますか？'
        }))
      ) {
        return;
      }

      await this.$store.dispatch('notify/clearNotify');
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      try {
        await this.$store.dispatch(`billings/${BULK_FIX_BILLING}`, {
          keywords: this.queryParams.keywords,
          date: this.queryParams.date
        });
        await this.$store.dispatch(
          'notify/showNotify',
          '請求書の作成処理を開始しました。完了後にメールで通知します。'
        );
      } catch (error) {
        await this.$store.dispatch(
          'notify/showErrorNotify',
          _.get(
            error,
            'response.data.errors.general',
            'エラーが発生しました、画面を更新してやり直してください'
          )
        );
        throw error;
      } finally {
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
      }
    },
    async onClickBulkPay() {
      if (!this.$store.state.owners.billingsUnpaidCount) {
        await this.$store.dispatch(
          'notify/showErrorNotify',
          '精算できる請求がありません。'
        );
        return;
      }
      if (
        !(await this.$refs.dialog.open({
          headline: '精算する',
          text: '支払いを確定してもよろしいですか？'
        }))
      ) {
        return;
      }

      await this.$store.dispatch('notify/clearNotify');
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      try {
        await this.$store.dispatch(`billings/${BULK_PAY_BILLINGS}`, {
          keywords: this.queryParams.keywords,
          date: this.queryParams.date
        });
        await this.$store.dispatch(
          'notify/showNotify',
          '精算処理を開始しました。完了後にメールで通知します。'
        );
      } catch (error) {
        await this.$store.dispatch(
          'notify/showErrorNotify',
          _.get(
            error,
            'response.data.errors.general',
            'エラーが発生しました、画面を更新してやり直してください'
          )
        );
        throw error;
      } finally {
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
      }
    },
    moment
  }
};
</script>

<style scoped>
.link-row--main {
  display: block;
  font-weight: bold;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
}
.link-row--main:hover {
  text-decoration: underline;
  color: rgba(0, 0, 0, 0.87);
}
</style>
