<template>
  <div>
    <v-btn
      v-if="!isTomUser && state === STATE.DRAFT"
      v-bind="{
        [buttonSize]: true
      }"
      color="green lighten-1"
      dark
      outlined
      class="ml-2"
      @click.stop="dialog = true"
    >
      <v-icon left>check</v-icon>
      承認
    </v-btn>

    <v-dialog v-model="dialog" max-width="550">
      <v-card>
        <v-card-title class="headline">承認の確認</v-card-title>
        <v-card-text>
          <span>選択した出荷を承認します</span>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="red" text @click="dialog = false"> キャンセル </v-btn>
          <v-btn color="teal" text @click="approveShipmentRequest">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const _ = require('lodash');
import { START_LOADING, FINISH_LOADING } from '@/store/action-types';
import { APPROVE_SELECTED_REQUESTS } from '@/store/action-types';
import { STATE } from '@/../lib/document/schema/shipment_requests';

export default {
  props: {
    isTomUser: Boolean,
    state: String,
    shipmentRequestId: String,
    buttonSize: String
  },
  data: function () {
    return {
      dialog: false,
      STATE
    };
  },
  methods: {
    async approveShipmentRequest() {
      this.dialog = false;
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      try {
        await this.$store.dispatch(
          `shipments/${APPROVE_SELECTED_REQUESTS}`,
          this.shipmentRequestId
        );
      } catch (e) {
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        await this.$store.dispatch(
          'notify/showErrorNotify',
          _.get(
            e,
            'response.data.errors.general',
            'エラーが発生しました、画面を更新してやり直してください'
          )
        );
        throw e;
      }
      await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
      await this.$store.dispatch(
        'notify/showNotify',
        '処理を開始しました。処理完了時にメールで通知します。'
      );
    }
  },
  computed: {}
};
</script>
