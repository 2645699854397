import {
  OWNER_FETCHED,
  OWNER_PERMISSION_UPDATED,
  TOGGLED_OWNER_UNAVAILABLE,
  OWNER_INVOICE_UPDATED,
  WHITELIST_IPS_FETCHED
} from '@/store/mutation-types';

import {
  FETCH_OWNER,
  UPDATE_OWNER,
  UPDATE_OWNER_PERMISSION,
  TOGGLE_OWNER_UNAVAILABLE,
  UPDATE_OWNER_INVOICE,
  UPDATE_CREDIT_LIMIT,
  FETCH_WHITELIST_IPS,
  UPDATE_WHITELIST_IPS
} from '@/store/action-types';
import axios from '@/api/common/axios';

export default {
  namespaced: true,
  state: {
    // names.ja_jp のみ利用する。
    names: {},
    // deprecated 住所は日本語のみをaddressに入れる
    addresses: {},
    phone_number: '',
    postal_code: '',
    notification: {
      emails: [],
      flags: {}
    },
    contract_type: '',
    address: '',
    organization_name: '',
    department: '',
    staff_name: '',
    email: '',
    invoice: {
      name: '',
      postal_code: '',
      address: '',
      organization_name: '',
      department: '',
      staff_name: '',
      emails: []
    },
    dialog: {
      unit_id: ''
    },
    fetchErrors: [],
    state: '',
    stopped_at: '',
    permissions: [],
    account_status: '',
    credit_limit: '',
    remaining_credit_limit: '',
    whitelist_ips: [],
    current_ip: ''
  },
  mutations: {
    [OWNER_FETCHED](
      state,
      {
        _id,
        unique_number,
        names,
        phone_number,
        postal_code,
        notification,
        contract_type,
        address,
        organization_name,
        department,
        staff_name,
        email,
        invoice,
        dialog,
        ownerState,
        stopped_at,
        permissions,
        account_status,
        credit_limit,
        remaining_credit_limit
      }
    ) {
      state.notification = {};
      state.names = {};
      state.addresses = {};

      state.id = _id || '';
      state.unique_number = unique_number || '';
      // names.ja_jp のみ利用する。
      state.names.ja_jp = (names && names.ja_jp) || '';
      state.phone_number = phone_number || '';
      state.postal_code = postal_code || '';
      state.notification.id = (notification && notification._id) || '';
      state.notification.emails = (notification && notification.emails) || [];
      state.notification.flags = (notification && notification.flags) || {};
      state.contract_type = contract_type || '';
      state.address = address || '';
      state.organization_name = organization_name || '';
      state.department = department || '';
      state.staff_name = staff_name || '';
      state.email = email || '';
      state.state = ownerState || '';
      state.stopped_at = stopped_at || '';
      state.permissions = permissions || [];
      state.account_status = account_status || '';
      if (invoice) {
        state.invoice = { ...state.invoice, ...invoice };
      }
      if (dialog) {
        state.dialog = dialog;
      }
      state.credit_limit = credit_limit || 0;
      state.remaining_credit_limit = remaining_credit_limit || 0;
    },
    [OWNER_PERMISSION_UPDATED](state, permissions) {
      if (permissions) {
        state.permissions = Array.from(permissions);
      } else {
        state.permissions = [];
      }
    },
    [TOGGLED_OWNER_UNAVAILABLE](state, accountStatus) {
      if (accountStatus === 'UNLIMITED') {
        state.account_status = undefined;
      } else {
        state.account_status = accountStatus;
      }
    },
    [OWNER_INVOICE_UPDATED](state, invoice) {
      if (invoice) {
        state.invoice = { ...invoice };
      } else {
        state.invoice = {
          name: '',
          postal_code: '',
          address: '',
          organization_name: '',
          department: '',
          staff_name: '',
          emails: []
        };
      }
    },
    [WHITELIST_IPS_FETCHED](state, { whitelistIps, currentIp }) {
      state.whitelist_ips = whitelistIps;
      state.current_ip = currentIp;
    }
  },
  actions: {
    async [FETCH_OWNER]({ commit }, value) {
      await axios
        .get('/owners/' + value.owner)
        .then(function (res) {
          commit('OWNER_FETCHED', { ...res.data, ownerState: res.data.state });
        })
        .catch(function (e) {
          throw e;
        });
    },
    async [UPDATE_OWNER]({}, owner) {
      await axios.post('/owners/' + owner.id, { owner }).catch(function (e) {
        throw e;
      });
    },
    async [UPDATE_OWNER_PERMISSION](
      { commit, state },
      { ownerId, permissions }
    ) {
      await axios
        .post('/owners/permissions', { ownerId, permissions })
        .then(() => {
          commit('OWNER_PERMISSION_UPDATED', permissions);
        })
        .catch(function (e) {
          commit('OWNER_PERMISSION_UPDATED', state.permissions);
          throw e;
        });
    },
    async [TOGGLE_OWNER_UNAVAILABLE]({ commit }, body) {
      await axios.post('/owners/toggle_owner_unavailable', body);
      commit('TOGGLED_OWNER_UNAVAILABLE', body.accountStatus);
    },
    async [UPDATE_OWNER_INVOICE]({ commit }, { ownerId, invoice }) {
      await axios
        .post('/owners/invoice', { ownerId, invoice })
        .then(() => {
          commit(OWNER_INVOICE_UPDATED, invoice);
        })
        .catch(function (e) {
          throw e;
        });
    },
    async [UPDATE_CREDIT_LIMIT]({}, param) {
      await axios
        .post('/owners/credit_limit', {
          ownerId: param.ownerId,
          credit_limit: param.credit_limit
        })
        .catch(function (e) {
          throw e;
        });
    },
    async [FETCH_WHITELIST_IPS]({ commit }) {
      await axios
        .get('/owners/ip_restrictions')
        .then(function (res) {
          commit('WHITELIST_IPS_FETCHED', {
            whitelistIps: res.data.whitelist_ips || [],
            currentIp: res.data.current_ip
          });
        })
        .catch(function (e) {
          throw e;
        });
    },
    async [UPDATE_WHITELIST_IPS]({}, whitelistIps) {
      await axios
        .post('/owners/ip_restrictions', {
          whitelistIps
        })
        .catch(function (e) {
          throw e;
        });
    }
  }
};
