<template>
  <div>
    <MainHeader title="返送一覧"></MainHeader>
    <v-card>
      <v-card-text>
        <v-layout>
          <v-autocomplete
            v-if="$store.state.user.isTomUser"
            v-model="owner"
            :items="owners"
            item-value="id"
            item-text="name"
            label="荷主"
            class="mr-2 flex-grow-0"
            outlined
            hide-details
          ></v-autocomplete>
          <v-text-field
            label="注文管理番号"
            v-model="owner_order_no"
            outlined
            hide-details
          ></v-text-field>
          <v-text-field
            label="トラッキング番号"
            v-model="tracking_code"
            class="ml-2"
            outlined
            hide-details
          ></v-text-field>
          <v-select
            label="ステータス"
            v-model="returnedStates"
            :items="stateItems"
            class="ml-2 flex-grow-0"
            item-text="text"
            multiple
            outlined
            chips
            small-chips
            hide-details
          >
          </v-select>
        </v-layout>
      </v-card-text>
      <TableAndPaging
        ref="search"
        :headers="header"
        :items="$store.state.returns.returns"
        :total="$store.state.returns.total"
        :query-params="queryParams"
        action="returns/FETCH_RETURN"
      >
        <template #row="props">
          <td v-if="$store.state.user.isTomUser">
            {{ props.item.owner.names.ja_jp }}
          </td>
          <td>
            <div>
              <div class="caption text--secondary">ステータス</div>
              <v-chip label outlined class="ml-2 mb-4">
                {{ formatState(props.item.state) }}
              </v-chip>
            </div>
            <div class="caption text--secondary">アクション</div>
            <div v-if="props.item.reshipmentRequest">
              <v-btn
                small
                text
                :to="`/shipments/${props.item.reshipmentRequest}`"
                :title="props.item.manifest"
              >
                <v-icon small left>open_in_new</v-icon>
                再出荷詳細
              </v-btn>
            </div>
            <div
              v-else-if="
                props.item.state === STATE.NOT_CHECKED ||
                props.item.state === STATE.IN_PROGRESS
              "
            >
              <v-layout column>
                <v-btn
                  small
                  color="primary"
                  elevation="0"
                  class="ml-2"
                  :to="`/shipments/form?returnedShipmentId=${props.item._id}`"
                >
                  再出荷
                </v-btn>
                <v-btn
                  v-if="props.item.isRestocked"
                  small
                  color="primary"
                  elevation="0"
                  class="ml-2 mt-5"
                  @click="
                    updateState({ _id: props.item._id, state: STATE.COMPLETE })
                  "
                >
                  確認済み
                </v-btn>
                <v-btn
                  v-else
                  small
                  color="primary"
                  elevation="0"
                  class="ml-2 mt-5"
                  @click="restock(props.item._id)"
                >
                  再入庫
                </v-btn>
              </v-layout>
            </div>
          </td>
          <td>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr
                    v-for="(obj, index) in [
                      {
                        name: '注文管理番号',
                        value:
                          props.item.shipment.shipment_request.owner_order_no,
                        link: props.item.shipment
                          ? `/shipments/${props.item.shipment.shipment_request._id}`
                          : null
                      },
                      {
                        name: '出荷トラッキング番号',
                        value: props.item.tracking_code
                      },
                      {
                        name: '返送トラッキング番号',
                        value: props.item.return_tracking_code
                      },
                      {
                        name: '返送理由',
                        value: props.item.reason
                      },
                      {
                        name: '返送日',
                        value: formatDate({
                          date: props.item.created_at
                        })
                      }
                    ]"
                    :key="'return_' + index"
                  >
                    <th>{{ obj.name }}</th>
                    <td v-if="obj.type === 'chip'">
                      <v-chip label>{{ obj.value }}</v-chip>
                    </td>
                    <td v-else-if="obj.link">
                      <router-link :to="`${obj.link}`" class="link-row--main">
                        {{ obj.value }}
                      </router-link>
                    </td>
                    <td v-else>{{ obj.value }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
          <td>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th
                      v-for="header in ['', '返送数', '良品数', '不良品数']"
                      class="text-left grey--text text--darken-4"
                      :key="header"
                    >
                      {{ header }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in props.item.shipment.items"
                    :key="'item' + index"
                  >
                    <td>
                      <div class="text--primary font-weight-bold caption">
                        荷主管理コード
                      </div>
                      <div class="ml-2 mb-2">
                        {{ item.owner_item_code }}
                      </div>
                      <div class="text--primary font-weight-bold caption">
                        バーコード
                      </div>
                      <div class="ml-2 mb-2">
                        <span>
                          <ClipboardChip :text="item.barcode"></ClipboardChip>
                        </span>
                      </div>
                      <div class="text--primary font-weight-bold caption">
                        商品名
                      </div>
                      <div class="ml-2 mb-2">
                        <router-link
                          :to="`/items/${item._id}`"
                          class="link-row--main"
                        >
                          {{ item.names.ja_jp || item.names.en_us }}
                        </router-link>
                      </div>
                    </td>
                    <td>{{ item.qty }}</td>
                    <td class="blue--text">
                      {{ item.stockedQty }}
                    </td>
                    <td class="red--text">
                      <!-- 不良品個数表記を正数での表記にするために -1 を掛ける -->
                      {{ item.damagedQty * -1 }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>
      </TableAndPaging>
    </v-card>
  </div>
</template>

<style>
#returns .v-input {
  margin: 0;
}

#returns .v-messages {
  min-height: 0;
}

#returns .v-data-table .v-data-table {
  background-color: inherit;
}
</style>

<script>
import MainHeader from '@/components/layout/MainHeader';
import TableAndPaging from '@/components/TableAndPaging';
import { FETCH_OWNERS_ALL, RESTOCK } from '@/store/action-types';
import {
  STATE,
  STATE_LABEL
} from '@/../lib/document/schema/returned_shipments';
import { formatDate } from '@/util/formatter';
import ClipboardChip from '@/components/chips/ClipboardChip';

export default {
  components: {
    MainHeader,
    TableAndPaging,
    ClipboardChip
  },
  data: function () {
    const query = this.$route.query;

    let returnedStates = [STATE.NOT_CHECKED, STATE.IN_PROGRESS];

    // 他のクエリパラメータが渡ってきている場合、チェックボックスの値が渡ってこないのは「全て未選択」とみなしパラメータに[]を反映
    if (Object.keys(query).length) {
      returnedStates = Array.isArray(query.filter_states)
        ? query.filter_states
        : [query.filter_states];
    }

    return {
      tabs: [
        {
          path: '/returns',
          name: '返送一覧'
        }
      ],
      owner_order_no: query.filter_owner_order_no || '',
      tracking_code: query.filter_tracking_code || '',
      returnedStates: returnedStates,
      owner: query.filter_owner || '',
      STATE
    };
  },
  methods: {
    formatState: function (stateValue) {
      return STATE_LABEL[stateValue];
    },
    updateState: async function ({ _id, state }) {
      try {
        await this.$store.dispatch('returns/UPDATE_RETURNS_STATE', {
          _id,
          state
        });
      } catch (e) {
        this.$store.dispatch('notify/showErrorNotify', `${e.message}`);
        return;
      }
      this.$store.dispatch('notify/showNotify', 'ステータスを変更しました。');
    },
    formatDate,
    restock: async function (returnedShipmentId) {
      try {
        await this.$store.dispatch(`returns/${RESTOCK}`, returnedShipmentId);
      } catch (e) {
        const message =
          (e.response &&
            e.response.data &&
            e.response.data.errors &&
            e.response.data.errors.general) ||
          'エラーが発生しました。';
        this.$store.dispatch('notify/showErrorNotify', message);
        return;
      }
      this.$store.dispatch('notify/showNotify', '再入庫完了しました。');
    }
  },
  computed: {
    isTomUser: (self) => self.$store.state.user.isTomUser,
    stateItems: function () {
      return Object.keys(STATE_LABEL).map((value) => {
        return {
          text: STATE_LABEL[value],
          value
        };
      });
    },
    modifiableStateItems: function () {
      let statelabel = STATE_LABEL;
      delete statelabel.RE_SHIPMENT;
      return Object.keys(statelabel).map((value) => {
        return {
          text: statelabel[value],
          value
        };
      });
    },
    queryParams: function () {
      return {
        filter_owner_order_no: this.owner_order_no,
        filter_tracking_code: this.tracking_code,
        filter_states: this.returnedStates,
        filter_owner: this.owner
      };
    },
    header: function () {
      return this.$store.state.user.isTomUser
        ? this.$store.state.returns.headersForTomUser
        : this.$store.state.returns.headers;
    },
    owners: function () {
      return [{ id: '', name: '全て' }].concat(
        Object.keys(this.$store.state.owners.owners).map((index) => {
          return {
            id: this.$store.state.owners.owners[index]._id,
            name: this.$store.state.owners.owners[index].names.ja_jp
          };
        })
      );
    }
  },
  async beforeMount() {
    await this.$store.dispatch(`owners/${FETCH_OWNERS_ALL}`);
  }
};
</script>
