<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="12"
    viewBox="0 0 20 12"
    class="icon-barcode"
  >
    <path
      id="barcode"
      d="M2,6H4V18H2V6M5,6H6V18H5V6M7,6h3V18H7V6m4,0h1V18H11V6m3,0h2V18H14V6m3,0h3V18H17V6m4,0h1V18H21Z"
      transform="translate(-2 -6)"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'rgba(0,0,0,0.54)'
    }
  }
};
</script>

<style scoped>
.icon-barcode {
  vertical-align: middle;
}
</style>
