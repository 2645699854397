<template>
  <v-form>
    <div>
      <v-card class="mb-4">
        <v-card-text>
          <v-container class="pa-0">
            <v-row>
              <v-col cols="6" class="py-0">
                <v-text-field
                  name="bundleCode"
                  v-model="bundleCode"
                  :error-messages="mergedError('bundleCode')"
                  label="バーコード"
                  data-vv-validate-on="change"
                  v-validate="'required'"
                  data-vv-as="バーコード"
                  hint="既存のセット商品用JANコード、もしくは既存のJANが存在しない場合は「バーコード生成」と入力"
                  outlined
                  persistent-hint
                />
              </v-col>
              <v-col cols="6" class="py-0">
                <v-text-field
                  name="ownerBundleCode"
                  v-model="ownerBundleCode"
                  label="セット管理番号"
                  hint="［任意項目］セット商品に独自に設定する管理番号を入力"
                  outlined
                  persistent-hint
                />
              </v-col>
            </v-row>
            <v-row class="pt-3">
              <v-col cols="6" class="py-0">
                <v-text-field
                  name="namesJaJp"
                  v-model="namesJaJp"
                  :error-messages="mergedError('namesJaJp')"
                  label="セット商品名（日本語）"
                  data-vv-validate-on="change"
                  v-validate="'max:1000'"
                  data-vv-as="セット商品名（日本語）"
                  hint="最大1000文字。日本語または英語はどちらかが必須"
                  outlined
                  persistent-hint
                />
              </v-col>
              <v-col cols="6" class="py-0">
                <v-text-field
                  name="namesJaJp"
                  v-model="namesEnUs"
                  :error-messages="mergedError('namesEnUs')"
                  label="セット商品名（英語）"
                  data-vv-validate-on="change"
                  v-validate="'max:1000'"
                  data-vv-as="セット商品名（英語）"
                  hint="最大1000文字。日本語または英語はどちらかが必須"
                  outlined
                  persistent-hint
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </div>
    <div>
      <v-card class="mb-4">
        <v-card-title>セットに含める商品を選択</v-card-title>
        <v-card-text>
          <ItemSelector
            @select-item="selectItem"
            :initial-params="filterShipType"
          />

          <v-subheader>選択済み商品一覧</v-subheader>
          <v-simple-table>
            <tbody class="th-key">
              <tr>
                <th>バーコード</th>
                <th>荷主管理コード</th>
                <th>商品名</th>
                <th>個数</th>
                <th>アクション</th>
              </tr>
              <tr v-for="({ item, qty }, index) in items" :key="item._id">
                <td>{{ item.barcode }}</td>
                <td>{{ item.owner_item_code }}</td>
                <td>
                  <v-list-item dense>
                    <v-list-item-icon>(日)</v-list-item-icon>
                    <v-list-item-title>
                      {{ item.names.ja_jp }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item dense>
                    <v-list-item-icon>(英)</v-list-item-icon>
                    <v-list-item-title>
                      {{ item.names.en_us }}
                    </v-list-item-title>
                  </v-list-item>
                </td>
                <td>
                  <v-select
                    dense
                    :items="Array.from({ length: 99 }, (_, i) => i + 1)"
                    :value="qty"
                    @change="updateQty({ index, qty: $event })"
                  />
                </td>
                <td>
                  <v-row>
                    <v-col>
                      <v-btn
                        text
                        color="error accent-4"
                        class="v-btn--active"
                        @click="removeItem({ index })"
                      >
                        <v-icon small left>delete</v-icon>
                        選択解除
                      </v-btn>
                    </v-col>
                  </v-row>
                </td>
              </tr>
            </tbody>
          </v-simple-table>

          <v-alert color="error" text v-if="mergedError('items').length">
            {{ mergedError('items') }}
          </v-alert>
        </v-card-text>
      </v-card>
    </div>
    <div v-if="mergedError('general').length">
      <v-alert color="error" text>
        {{ mergedError('general') }}
      </v-alert>
    </div>
    <div>
      <v-btn
        text
        color="primary accent-4"
        class="v-btn--active"
        block
        @click="createItemBundle"
      >
        セット商品を作成する
      </v-btn>
    </div>
    <div v-if="isCreated">
      <v-btn text color="teal" class="mb-4" to="/bundles" block>
        セット商品一覧画面へ
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import { mergeErrors } from '@/util/input';
import { JsonError } from '@/util/error';

import { SHIP_TYPE } from '@/../lib/document/schema/items';

import ItemSelector from '@/components/ItemSelector';

import {
  START_LOADING,
  FINISH_LOADING,
  CREATE_ITEM_BUNDLE
} from '@/store/action-types';

export default {
  $_veeValidate: {
    validator: 'new'
  },
  components: {
    ItemSelector
  },
  data() {
    return {
      bundleCode: '',
      ownerBundleCode: '',
      namesJaJp: '',
      namesEnUs: '',
      // 発送する商品の一覧絞り込み用
      filterOwnerItemCode: '',
      filterBarcode: '',
      filterName: '',
      items: [],
      serverErrors: {},
      isCreated: false
    };
  },
  computed: {
    params() {
      return {
        filter_owner_item_code: this.filterOwnerItemCode,
        filter_barcode: this.filterBarcode,
        filter_name: this.filterName
      };
    },
    queryParams() {
      return {
        limit: 5
      };
    },
    filterShipType() {
      return { filter_ship_type: SHIP_TYPE.MIXED_LOADING };
    }
  },
  async beforeMount() {},
  async mounted() {},
  methods: {
    async clearError() {
      await this.$store.dispatch('notify/clearNotify');
      this.serverErrors = {};
    },
    async createItemBundle() {
      await this.clearError();

      if (!(await this.$validator.validate())) {
        await this.$store.dispatch(
          'notify/showErrorNotify',
          '入力項目に誤りがあります。エラー表示されている項目を確認してください。'
        );
        return;
      }

      await this.$store.dispatch(`app/${START_LOADING}`, new Date());

      try {
        await this.$store.dispatch(`bundles/${CREATE_ITEM_BUNDLE}`, {
          bundleCode: this.bundleCode,
          ownerBundleCode: this.ownerBundleCode,
          namesJaJp: this.namesJaJp,
          namesEnUs: this.namesEnUs,
          items: this.items.map((obj) => ({
            barcode: obj.item.barcode,
            qty: obj.qty
          }))
        });

        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        await this.$store.dispatch(
          'notify/showNotify',
          'セットを作成しました。一覧から確認できます。'
        );
        this.isCreated = true;
      } catch (error) {
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());

        if (error instanceof JsonError) {
          this.serverErrors = error.toJson();
          await this.$store.dispatch(
            'notify/showErrorNotify',
            '入力項目に誤りがあります。エラー表示されている項目を確認してください。'
          );
        } else {
          await this.$store.dispatch('notify/showErrorNotify', error.message);
        }
      }
    },
    // ItemSelector Methods
    //
    async selectItem({ item }) {
      await this.clearError();

      if (this.items.map((obj) => obj.item._id).includes(item._id)) {
        return;
      }
      this.items.push({ item, qty: 1 });

      await this.$store.dispatch(
        'notify/showNotify',
        '選択済み商品一覧に追加しました。'
      );
    },
    removeItem({ index }) {
      this.items = [
        ...this.items.slice(0, index),
        ...this.items.slice(index + 1)
      ];
    },
    updateQty({ index, qty }) {
      this.items = [
        ...this.items.slice(0, index),
        {
          item: this.items[index].item,
          qty
        },
        ...this.items.slice(index + 1)
      ];
    },
    mergedError(field) {
      return mergeErrors(this.$validator.errors, this.serverErrors, field);
    }
  }
};
</script>
