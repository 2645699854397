'use strict';

// ISO 3166-2:CN
module.exports = {
  北京: 'BJ',
  上海: 'SH',
  天津: 'TJ',
  重庆: 'CQ',
  河北: 'HE',
  山西: 'SX',
  河南: 'HA',
  辽宁: 'LN',
  吉林: 'JL',
  黑龙江: 'HL',
  内蒙古: 'NM',
  江苏: 'JS',
  山东: 'SD',
  安徽: 'AH',
  浙江: 'ZJ',
  福建: 'FJ',
  湖北: 'HB',
  湖南: 'HN',
  广东: 'GD',
  广西: 'GX',
  江西: 'JX',
  四川: 'SC',
  海南: 'HI',
  贵州: 'GZ',
  云南: 'YN',
  西藏: 'XZ',
  陕西: 'SN',
  甘肃: 'GS',
  青海: 'QH',
  宁夏: 'NX',
  新疆: 'XJ',
  台湾: 'TW',
  香港: 'HK',
  澳门: 'MO'
};
