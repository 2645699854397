import { CREATE_FLYER } from '@/store/action-types';

import axios from '@/api/common/axios';
import _ from 'lodash';
import { JsonError } from '@/util/error';

export default {
  namespaced: true,
  state: {
    title: ''
  },
  actions: {
    async [CREATE_FLYER]({}, params) {
      await axios.post('/flyers/form', params).catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors &&
          error.response.data.errors.code !== 'ERR_MAINTENANCE'
        ) {
          throw new JsonError(error.response.data.errors);
        } else {
          throw new Error(
            _.get(
              error,
              'message',
              'エラーが発生しました、画面を更新してやり直してください'
            )
          );
        }
      });
    }
  }
};
