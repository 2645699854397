<template>
  <v-container>
    <v-card class="mx-auto mb-5">
      <v-card-title class="headline">入庫操作</v-card-title>
      <v-card-text>
        <p>テスト環境で入庫のステータスを変更するためのツールです。</p>
        <p>
          該当の入庫依頼の入庫依頼番号を指定し入庫完了するなどの操作が行えます。
        </p>
        <v-text-field
          name="owner_manifest_no"
          label="入庫依頼の入庫依頼番号"
          outlined
          v-model="rcvSlipNo"
        />
        <v-row class="mt-3" justify="end">
          <v-btn
            color="primary"
            elevation="0"
            @click="manifestComplete"
            class="mr-4"
          >
            入庫完了状態へ遷移させる
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mx-auto">
      <v-card-title class="headline">出荷操作</v-card-title>
      <v-card-text>
        <p>テスト環境で出荷依頼のステータスを変更するためのツールです。</p>
        <p>
          該当の出荷依頼の注文管理番号を指定し，出荷完了状態へ遷移させる・返送を作成するなどの操作が行えます。
        </p>
        <v-text-field
          name="owner_order_no"
          label="出荷依頼の注文管理番号"
          outlined
          hide-details
          v-model="ownerOrderNo"
        />
        <v-row class="mt-3" justify="end">
          <v-btn
            color="primary"
            elevation="0"
            @click="shipmentComplete"
            class="mr-4"
          >
            出荷完了状態へ遷移させる
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            @click="returnCreate"
            class="mr-4"
          >
            返送を作成する
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<style></style>

<script>
import { START_LOADING, FINISH_LOADING } from '@/store/action-types';
import axios from '@/api/common/axios';

export default {
  components: {},
  data() {
    return { rcvSlipNo: '', ownerOrderNo: '' };
  },
  methods: {
    async manifestComplete() {
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      if (this.rcvSlipNo) {
        try {
          const response = await axios.post('/manifests/test_complete', {
            owner_manifest_no: this.rcvSlipNo
          });
          await this.$store.dispatch(
            'notify/showNotify',
            `入庫完了状態への遷移が完了しました。<a href="/manifests/${response.data._id}">こちら</a>からご確認ください。`
          );
        } catch (e) {
          await this.$store.dispatch(
            'notify/showErrorNotify',
            e.response.data.errors.general || 'エラーが発生しました。'
          );
        }
      } else {
        await this.$store.dispatch(
          'notify/showErrorNotify',
          '入庫依頼番号を入力してください。'
        );
      }
      await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
    },
    async shipmentComplete() {
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      if (this.ownerOrderNo) {
        try {
          const response = await axios.post('/shipments/test_complete', {
            owner_order_no: this.ownerOrderNo
          });
          await this.$store.dispatch(
            'notify/showNotify',
            `出荷完了状態への遷移が完了しました。<a href="/shipments/${response.data._id}">こちら</a>からご確認ください。`
          );
        } catch (e) {
          await this.$store.dispatch(
            'notify/showErrorNotify',
            e.response.data.errors.general || 'エラーが発生しました。'
          );
        }
      } else {
        await this.$store.dispatch(
          'notify/showErrorNotify',
          '出荷依頼の注文管理番号を入力してください。'
        );
      }
      await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
    },
    async returnCreate() {
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      if (this.ownerOrderNo) {
        try {
          await axios.post('/returns/test_create', {
            owner_order_no: this.ownerOrderNo
          });
          await this.$store.dispatch(
            'notify/showNotify',
            `返送の作成が完了しました。<a href="/returns?filter_owner_order_no=${this.ownerOrderNo}">こちら</a>からご確認ください。`
          );
        } catch (e) {
          await this.$store.dispatch(
            'notify/showErrorNotify',
            e.response.data.errors.general || 'エラーが発生しました。'
          );
        }
      } else {
        await this.$store.dispatch(
          'notify/showErrorNotify',
          '出荷依頼の注文管理番号を入力してください。'
        );
      }
      await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
    },
    clear() {}
  },
  computed: {},
  async beforeMount() {}
};
</script>
