<template>
  <div>
    <MainHeader title="後納差出票出力フォーム"></MainHeader>
    <LaterPaySlipFrom />
  </div>
</template>

<script>
import MainHeader from '@/components/layout/MainHeader';

import LaterPaySlipFrom from '@/components/warehouse/LaterPaySlipForm';

export default {
  components: {
    MainHeader,
    LaterPaySlipFrom
  },
  data: () => {
    return {};
  },
  computed: {}
};
</script>
