import { BILLINGS_FETCHED } from '@/store/mutation-types';
import {
  FETCH_BILLINGS,
  FIX_BILLINGS,
  BULK_FIX_BILLING,
  CANCEL_BILLINGS,
  MODIFY_BILLINGS,
  PAY_BILLINGS,
  BULK_PAY_BILLINGS,
  REFUND_BILLINGS,
  APPROVE_BILLINGS
} from '@/store/action-types';
import axios from '@/api/common/axios';
import { AMOUNT_STATUS, PAID_STATUS } from '@/../lib/document/schema/billings';

export default {
  namespaced: true,
  state: {
    billingId: '',
    year: '',
    month: '',
    amount_status: AMOUNT_STATUS.PENDING,
    paid_status: PAID_STATUS.UNPAID,
    amountDetails: [],
    amount: 0,
    total_amount: 0,
    lines: {
      manifests: [],
      shipments: [],
      shippings: [],
      options: []
    },
    remark: '',
    bill_url: '',
    receipt_url: '',
    owner: {
      names: {
        ja_jp: ''
      }
    },
    link: {
      prevOwnerId: '',
      nextOwnerId: ''
    }
  },
  mutations: {
    [BILLINGS_FETCHED](
      state,
      {
        billing: {
          _id,
          year,
          month,
          amount_status,
          paid_status,
          amount_details,
          amount,
          total_amount,
          lines,
          remark,
          bill_url,
          receipt_url,
          owner
        },
        link
      }
    ) {
      state.lines = {};
      state.billingId = _id.toString();
      state.year = year;
      state.month = month;
      state.amount_status = amount_status;
      state.paid_status = paid_status;
      state.amountDetails = amount_details;
      state.amount = amount;
      state.total_amount = total_amount;
      state.lines.manifests = lines.manifests || [];
      state.lines.shipments = lines.shipments || [];
      state.lines.shippings = lines.shippings || [];
      state.lines.options = lines.options || [];
      state.remark = remark;
      state.bill_url = bill_url;
      state.receipt_url = receipt_url;
      state.owner = owner;
      if (link) {
        state.link = link;
      }
    }
  },
  actions: {
    async [FETCH_BILLINGS]({ commit }, { date, ownerId }) {
      const params = { date };
      if (ownerId) {
        params.ownerId = ownerId;
      }

      const res = await axios.get('/billings', {
        params
      });
      commit('BILLINGS_FETCHED', res.data);
    },
    async [APPROVE_BILLINGS]({ commit }, { ownerId, billingId }) {
      const params = { ownerId, billingId };
      await axios
        .post('/billings/approve', params)
        .then(function (res) {
          commit('BILLINGS_FETCHED', { billing: res.data });
        })
        .catch(function (e) {
          throw e;
        });
    },
    async [FIX_BILLINGS]({}, { ownerId, billingId }) {
      const params = { ownerId, billingId };
      await axios.post('/billings/fix', params).catch(function (e) {
        throw e;
      });
    },
    async [BULK_FIX_BILLING]({}, { keywords, date }) {
      const params = { keywords, date };
      await axios.post('/billings/bulk_fix', params).catch(function (e) {
        throw e;
      });
    },
    async [CANCEL_BILLINGS]({ commit }, { ownerId, billingId }) {
      const params = { ownerId, billingId };
      await axios
        .post('/billings/cancel', params)
        .then(function (res) {
          commit('BILLINGS_FETCHED', { billing: res.data });
        })
        .catch(function (e) {
          throw e;
        });
    },
    async [MODIFY_BILLINGS](
      { commit },
      { ownerId, billingId, options, remark }
    ) {
      const params = { ownerId, billingId, options, remark };
      await axios
        .post('/billings/edit', params)
        .then(function (res) {
          commit(BILLINGS_FETCHED, { billing: res.data });
        })
        .catch(function (e) {
          throw e;
        });
    },
    async [PAY_BILLINGS]({}, { ownerId, billingId }) {
      const params = { ownerId, billingId };
      await axios.post('/billings/pay', params).catch(function (e) {
        throw e;
      });
    },
    async [BULK_PAY_BILLINGS]({}, { keywords, date }) {
      const params = { keywords, date };
      await axios.post('/billings/bulk_pay', params).catch(function (e) {
        throw e;
      });
    },
    async [REFUND_BILLINGS]({ commit }, { ownerId, billingId }) {
      const params = { ownerId, billingId };
      await axios
        .post('/billings/refund', params)
        .then(function (res) {
          commit('BILLINGS_FETCHED', { billing: res.data });
        })
        .catch(function (e) {
          throw e;
        });
    }
  }
};
