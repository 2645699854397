// このファイルはクライアント側からも読み込まれるため，
// Node.jsでしか動かないコードを書くことは禁止する

'use strict';

const _ = require('lodash');

const CATEGORY_TEXT = {
  MANAGER: '管理アカウント',
  OPERATOR: '通常アカウント'
};
const CATEGORY_PATTERN = [];
for (const category in CATEGORY_TEXT) {
  CATEGORY_PATTERN.push(category);
}
const CATEGORY = _.zipObject(CATEGORY_PATTERN, CATEGORY_PATTERN);

const STATUS_TEXT = {
  NORMAL: '通常',
  UNINITIALIZED: '未初期化'
};
const STATUS_PATTERN = [];
for (const status in STATUS_TEXT) {
  STATUS_PATTERN.push(status);
}
const STATUS = _.zipObject(STATUS_PATTERN, STATUS_PATTERN);

// アクセス権限
const REALM = {
  FLYERS: 'FLYERS', // フライヤー同梱機能にアクセス可能
  WAREHOUSE: 'WAREHOUSE' // 倉庫機能にアクセス可能
};
const REALM_PATTERN = [];
for (const realm in REALM) {
  REALM_PATTERN.push(realm);
}

module.exports = {
  CATEGORY_TEXT,
  CATEGORY_PATTERN,
  CATEGORY,
  STATUS_TEXT,
  STATUS_PATTERN,
  STATUS,
  REALM,
  REALM_PATTERN
};
