<!--

<CsvUploadProgress :category="CATEGORY.CREATE_ITEM"></CsvUploadProgress>
のようにして使う．
category に指定するものは
import { CATEGORY } from '../../lib/document/schema/csv_upload_states';
のようにimportして指定する．

コンポーネントを設置すると，アクセスしているユーザのOwnersに所属する指定した:categoryに関する
CSVアップロードの進捗が表示されます．
コンポーネントが自動的に /upload/progress に 3 秒ごとにアクセスし，
CSVアップロードの進捗表示を更新します．
現在の処理中の状況と完了した処理の状況を表示します．

処理中の状況は，全体の処理数と，古い方から5件表示を表示します．
詳細としては，ユーザのメールアドレス・そのCSVアップロードの件数・完了数・エラー数を表示します．
完了したものは表示から消えます．

完了した処理の状況は，最新の 5 件の結果を表示します．
エラーがあった場合はエラーの内容を展開して表示できます．

-->
<template>
  <v-card>
    <v-card-title class="headline">CSVアップロード進捗状況</v-card-title>

    <v-divider></v-divider>

    <v-alert text v-if="errorMessage" color="error" tile>
      {{ errorMessage }}
    </v-alert>

    <v-card-text>
      <div>
        現在の荷主内で{{ $store.state.progress.totalCurrent }}件を処理中です。
      </div>
      <div>
        他の荷主を含め、システム全体で{{
          $store.state.progress.totalAll
        }}件を処理中です。
      </div>
    </v-card-text>

    <v-divider class="mx-4"></v-divider>

    <v-subheader> 準備中のファイル一覧 </v-subheader>

    <template v-if="$store.state.progress.preparationItems.length > 0">
      <template v-for="(item, i) in $store.state.progress.preparationItems">
        <template>
          <v-list class="py-0" :key="item._id">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>hourglass_empty</v-icon>
              </v-list-item-icon>
              <v-list-item-icon class="mr-4">
                <v-chip label outlined> 準備中 </v-chip>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.email }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  formatDate({ date: item.created_at })
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content>
                CSVを受け付けました。処理の準備を行っております…
              </v-list-item-content>
              <v-list-item-icon></v-list-item-icon>
              <v-list-item-icon></v-list-item-icon>
            </v-list-item>
          </v-list>
        </template>

        <v-divider
          v-if="i !== $store.state.progress.completedItems.length - 1"
          inset
          class="mr-4"
          :key="'divider_' + item._id"
        />
      </template>

      <v-subheader v-if="isShowContinueMessage" inset>
        {{ this.processingLimit }} 件目以降の表示は省略しています
      </v-subheader>
    </template>

    <v-card-text v-else class="d-flex justify-center pb-12">
      準備中のファイルはありません
    </v-card-text>

    <v-divider class="mx-4"></v-divider>

    <v-subheader> 処理中のファイル一覧 </v-subheader>

    <template v-if="$store.state.progress.processingItems.length > 0">
      <v-list>
        <template v-for="(item, i) in $store.state.progress.processingItems">
          <v-list-item :key="item._id">
            <v-list-item-icon>
              <v-progress-circular
                size="24"
                rotate="-90"
                :value="
                  getPercentage(
                    item.count.completed + item.count.error,
                    item.count.total
                  )
                "
              ></v-progress-circular>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.email }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ formatDate({ date: item.created_at }) }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                {{ showDetails(item) }} (完了:
                {{ item.count.completed }} エラー:
                <span class="red--text text--darken-1">
                  {{ item.count.error }}
                </span>
                )
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider
            v-if="i !== $store.state.progress.processingItems.length - 1"
            inset
            class="mr-4"
            :key="'divider_' + item._id"
          ></v-divider>
        </template>
      </v-list>

      <v-subheader v-if="isShowContinueMessage" inset>
        {{ this.processingLimit }} 件目以降の表示は省略しています
      </v-subheader>
    </template>

    <v-card-text v-else class="d-flex justify-center pb-12">
      処理中のファイルはありません
    </v-card-text>

    <v-divider class="mx-4"></v-divider>

    <v-subheader> 処理が完了したファイル一覧（最新５件） </v-subheader>

    <template v-if="$store.state.progress.completedItems.length > 0">
      <template v-for="(item, i) in $store.state.progress.completedItems">
        <template v-if="!item.error_messages">
          <v-list class="py-0" :key="item._id">
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="primary">check_circle</v-icon>
              </v-list-item-icon>
              <v-list-item-icon class="mr-4">
                <v-chip color="green" label outlined> 成功 </v-chip>
              </v-list-item-icon>
              <v-list-item-content>
                {{ item.email }}
              </v-list-item-content>
              <v-list-item-content>
                {{ formatDate({ date: item.created_at }) }}
              </v-list-item-content>
              <v-list-item-content v-if="item.slip_url">
                <v-btn
                  outlined
                  block
                  color="red"
                  :href="item.slip_url"
                  target="_blank"
                >
                  後納差出票のPDF
                  <v-icon right>picture_as_pdf</v-icon>
                </v-btn>
              </v-list-item-content>

              <v-list-item-icon></v-list-item-icon>
              <v-list-item-icon></v-list-item-icon>
            </v-list-item>
          </v-list>
        </template>

        <template v-else>
          <v-list class="py-0" :key="item._id">
            <v-list-group>
              <template v-slot:activator>
                <v-list-item inactive class="px-0">
                  <v-list-item-icon>
                    <v-icon color="error">error_outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-icon class="mr-4">
                    <v-chip
                      v-if="item.state"
                      :color="STATE_COLOR[item.state]"
                      label
                      outlined
                    >
                      {{ STATE_DICTIONARY[item.state] }}
                    </v-chip>
                    <v-chip v-else color="red" label outlined>失敗</v-chip>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.email }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      formatDate({ date: item.created_at })
                    }}</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-content v-if="item.link">
                    <v-btn outlined block :href="item.link.url" target="_blank">
                      {{ item.link.title }}
                      <v-icon right>open_in_new</v-icon>
                    </v-btn>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <v-list-item>
                <!-- 1 段下げるため-->
                <v-list-item-icon></v-list-item-icon>
                <!-- 余計な余白を入れないため -->
                <v-list-item-subtitle
                  style="white-space: break-spaces; user-select: text"
                  >{{ format(item.error_messages) }}
                </v-list-item-subtitle>
              </v-list-item>
            </v-list-group>
          </v-list>
        </template>

        <v-divider
          v-if="i !== $store.state.progress.completedItems.length - 1"
          inset
          class="mr-4"
          :key="'divider_' + item._id"
        />
      </template>
    </template>

    <v-card-text v-else class="d-flex justify-center pb-12">
      処理が完了したファイルはありません
    </v-card-text>
  </v-card>
</template>

<script>
import { FETCH_PROGRESS } from '@/store/action-types';
import { getPercentage } from '@/util/input';
import { formatDate } from '@/util/formatter';
import {
  STATE_DICTIONARY,
  STATE_COLOR
} from '@/../lib/document/schema/csv_upload_states';

export default {
  props: {
    category: String // 進捗表示する対象を指定する（商品情報登録や入庫情報登録などの種別）
  },
  data() {
    return {
      isLoading: false, // 定期的進捗状況を確認するための通信中はtrueになる
      INTERVAL_MSEC: 3000, // 3秒毎に進捗を取得する
      errorMessage: '', // エラー時に表示するメッセージ
      intervalId: null, // Interval がバックグラウンドで動作しないようにするため
      STATE_DICTIONARY,
      STATE_COLOR
    };
  },
  async mounted() {
    this.intervalId = setInterval(async () => {
      await this.fetch();
    }, this.INTERVAL_MSEC);

    await this.fetch();
  },
  beforeDestroy: function () {
    clearInterval(this.intervalId);
  },
  computed: {
    processingLimit: () => 5, // 古い方から 5 件だけ表示する
    completedLimit: () => 5, // 最新の 5 件だけ表示する
    /**
     * 絞り込んで表示をしているかどうか
     */
    isShowContinueMessage() {
      return this.$store.state.progress.totalCurrent > this.processingLimit;
    }
  },
  methods: {
    showDetails(item) {
      return `${item.count.completed + item.count.error} / ${item.count.total}`;
    },
    async fetch() {
      // 多重にfetchはしない
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      try {
        await this.$store.dispatch(`progress/${FETCH_PROGRESS}`, {
          category: this.category,
          processingLimit: this.processingLimit,
          completedLimit: this.completedLimit
        });
        this.errorMessage = '';
      } catch (error) {
        this.errorMessage = `アップロード進捗データの取得に失敗しました。
        ${this.INTERVAL_MSEC / 1000}
        秒後に自動的に再取得します。エラーが解消しない場合はサポートへご連絡ください。`;
      } finally {
        this.isLoading = false;
      }
    },
    formatDate,
    getPercentage,
    format(text) {
      if (text.length <= 1000) {
        return text;
      } else {
        return `${text.substr(
          0,
          1000
        )}...\nエラーメッセージが長いため省略しました。詳細はメールで送られる内容を確認してください。`;
      }
    }
  }
};
</script>

<style></style>
