export const START_LOADING = 'START_LOADING';
export const FINISH_LOADING = 'FINISH_LOADING';
export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_OWNER = 'FETCH_OWNER';
export const UPDATE_OWNER = 'UPDATE_OWNER';
export const FETCH_OWNERS = 'FETCH_OWNERS';
export const FETCH_OWNERS_ALL = 'FETCH_OWNERS_ALL';
export const FETCH_OWNERS_BILLINGS = 'FETCH_OWNERS_BILLINGS';
export const DOWNLOAD_OWNERS_BILLINGS_CSV_REQUESTS =
  'DOWNLOAD_OWNERS_BILLINGS_CSV_REQUESTS';
export const UPDATE_OWNER_PERMISSION = 'UPDATE_OWNER_PERMISSION';
export const UPDATE_OWNER_INVOICE = 'UPDATE_OWNER_INVOICE';
export const INITIALIZE_LOGIN_PAGE = 'INITIALIZE_LOGIN_PAGE';
export const INITIALIZE_FORGOT_PAGE = 'INITIALIZE_FORGOT_PAGE';
export const INITIALIZE_RESET_PAGE = 'INITIALIZE_RESET_PAGE';
export const INITIALIZE_CHANGE_PASSWORD_PAGE = 'CHANGE_PASSWORD_PAGE_SHOW';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const FETCH_PROGRESS = 'FETCH_PROGRESS';
export const FETCH_ITEM_DETAIL = 'FETCH_ITEM_DETAIL';
export const FETCH_ITEM_LOGS = 'FETCH_ITEM_LOGS';
export const DOWNLOAD_ITEMS_CSV_REQUESTS = 'DOWNLOAD_ITEMS_CSV_REQUESTS';
export const FETCH_MANIFEST_DETAIL = 'FETCH_MANIFEST_DETAIL';
export const FETCH_MANIFESTS = 'FETCH_MANIFESTS';
export const CANCEL_MANIFEST = 'CANCEL_MANIFEST';
export const DOWNLOAD_MANIFESTS_CSV_REQUESTS =
  'DOWNLOAD_MANIFESTS_CSV_REQUESTS';
export const FETCH_SHIPMENTS = 'FETCH_SHIPMENTS';
export const FETCH_SHIPMENT_DETAIL = 'FETCH_SHIPMENT_DETAIL';
export const APPROVE_SELECTED_REQUESTS = 'APPROVE_SELECTED_REQUESTS';
export const APPROVE_REQUESTS = 'APPROVE_REQUESTS';
export const REJECT_SELECTED_REQUESTS = 'REJECT_SELECTED_REQUESTS';
export const REJECT_REQUESTS = 'REJECT_REQUESTS';
export const CANCEL_SELECTED_REQUESTS = 'CANCEL_SELECTED_REQUESTS';
export const DOWNLOAD_SHIPMNTS_CSV_REQUESTS = 'DOWNLOAD_SHIPMNTS_CSV_REQUESTS';
export const FETCH_RETURN = 'FETCH_RETURN';
export const RESTOCK = 'RESTOCK';
export const ADD_USER = 'ADD_USER';
export const FETCH_SHIPPING_ADDRESS = 'FETCH_SHIPPING_ADDRESS';
export const SAVE_SHIPPING_ADDRESS = 'SAVE_SHIPPING_ADDRESS';
export const DELETE_SHIPPING_ADDRESS = 'DELETE_SHIPPING_ADDRESS';
export const FETCH_ITEMS = 'FETCH_ITEMS';
export const CREATE_SHIPMENT = 'CREATE_SHIPMENT';
export const UPDATE_SHIPMENT = 'UPDATE_SHIPMENT';
export const FETCH_SHIPMENT_EDIT = 'FETCH_SHIPMENT_EDIT';
export const POLL_SUBMIT_SHIPMENT = 'POLL_SUBMIT_SHIPMENT';
export const FETCH_STOCK_DIFFS = 'FETCH_STOCK_DIFFS';
export const CORRECT_STOCK_DIFF = 'CORRECT_STOCK_DIFF';
export const FETCH_VERSION = 'FETCH_VERSION';
export const NOTIFY_NEW_VERSION_IF_EXISTS = 'NOTIFY_NEW_VERSION_IF_EXISTS';
export const SHOW_NOTIFY_WITH_CALLBACK = 'SHOW_NOTIFY_WITH_CALLBACK';
export const FETCH_BUNDLES = 'FETCH_BUNDLES';
export const CREATE_ITEM_BUNDLE = 'CREATE_ITEM_BUNDLE';
export const DELETE_ITEM_BUNDLE = 'DELETE_ITEM_BUNDLE';
export const FETCH_SLIP_TEMPLATE = 'FETCH_SLIP_TEMPLATE';
export const FETCH_SLIP_TEMPLATES = 'FETCH_SLIP_TEMPLATES';
export const REGISTER_SLIP_TEMPLATE = 'REGISTER_SLIP_TEMPLATE';
export const DELETE_SLIP_TEMPLATE = 'DELETE_SLIP_TEMPLATE';
export const UPLOAD_LOGO_IMAGE = 'UPLOAD_LOGO_IMAGE';
export const NOTIFY_NEW_AGREEMENT_VERSION_IF_EXISTS =
  'NOTIFY_NEW_AGREEMENT_VERSION_IF_EXISTS';
export const AGREE = 'AGREE';
export const CREATE_FLYER = 'CREATE_FLYER';
export const FETCH_FLYERS = 'FETCH_FLYERS';
export const FETCH_FEDEX_SLIPS = 'FETCH_FEDEX_SLIPS';
export const CLEAR_FEDEX_SLIPS = 'CLEAR_FEDEX_SLIPS';
export const DELETE_ITEM = 'DELETE_ITEM';
export const SYNC_ITEM = 'SYNC_ITEM';
export const FETCH_SHIPPING_CATEGORIES = 'FETCH_SHIPPING_CATEGORIES';
export const REMOVE_SHIPPING_CATEGRY = 'REMOVE_SHIPPING_CATEGRY';
export const CREATE_SHIPPING_CATEGORY = 'CREATE_SHIPPING_CATEGORY';
export const VALIDATE_SHIPPING_CATEGORY = 'VALIDATE_SHIPPING_CATEGORY';
export const FETCH_WEBHOOKS = 'FETCH_WEBHOOKS';
export const FETCH_WEBHOOK = 'FETCH_WEBHOOK';
export const CREATE_WEBHOOK = 'CREATE_WEBHOOK';
export const CHANGE_STATUS = 'CHANGE_STATUS';
export const FETCH_WEBHOOK_LOGS = 'FETCH_WEBHOOK_LOGS';
export const FETCH_WEBHOOK_LOG = 'FETCH_WEBHOOK_LOG';
export const TEST_WEBHOOK = 'TEST_WEBHOOK';
export const DEACTIVATE_USER = 'DEACTIVATE_USER';
export const CHANGE_USER_CATEGORY = 'CHANGE_USER_CATEGORY';
export const GENERATE_API_TOKEN = 'GENERATE_API_TOKEN';
export const REFRESH_API_TOKEN = 'REFRESH_API_TOKEN';
export const CHANGE_API_TOKEN = 'CHANGE_API_TOKEN';
export const FETCH_API_TOKENS = 'FETCH_API_TOKENS';
export const FETCH_API_TOKEN = 'FETCH_API_TOKEN';
export const FETCH_API_LOGS = 'FETCH_API_LOGS';
export const FETCH_API_LOG = 'FETCH_API_LOG';
export const TOGGLE_OWNER_UNAVAILABLE = 'TOGGLE_OWNER_UNAVAILABLE';
export const FETCH_BILLINGS = 'FETCH_BILLINGS';
export const FIX_BILLINGS = 'FIX_BILLINGS';
export const BULK_FIX_BILLING = 'BULK_FIX_BILLING';
export const CANCEL_BILLINGS = 'CANCEL_BILLINGS';
export const MODIFY_BILLINGS = 'MODIFY_BILLINGS';
export const PAY_BILLINGS = 'PAY_BILLINGS';
export const BULK_PAY_BILLINGS = 'BULK_PAY_BILLINGS';
export const APPROVE_BILLINGS = 'APPROVE_BILLINGS';
export const UPDATE_CREDIT_LIMIT = 'UPDATE_CREDIT_LIMIT';
export const REFUND_BILLINGS = 'REFUND_BILLINGS';
export const FETCH_ACCOUNT_REGISTRATIONS = 'FETCH_ACCOUNT_REGISTRATIONS';
export const FETCH_WHITELIST_IPS = 'FETCH_WHITELIST_IPS';
export const UPDATE_WHITELIST_IPS = 'UPDATE_WHITELIST_IPS';
