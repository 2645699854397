<template>
  <div id="stock-diffs-list">
    <v-card>
      <v-card-text>
        <v-layout>
          <v-autocomplete
            v-model="owner"
            :items="owners"
            item-value="id"
            item-text="name"
            label="荷主"
            class="mr-2 flex-grow-0"
            outlined
            hide-details
          ></v-autocomplete>
        </v-layout>
      </v-card-text>
      <TableAndPaging
        :headers="header"
        :items="$store.state.stockDiffs.stockDiffs"
        :total="$store.state.stockDiffs.total"
        :query-params="queryParams"
        :action="`stockDiffs/${FETCH_STOCK_DIFFS}`"
      >
        <template #row="props">
          <td>
            <v-btn
              small
              color="green lighten-1"
              dark
              class="ma-1 mt-4 mb-2"
              @click.stop="
                showAdjustDialog({
                  stockDiff: props.item
                })
              "
              :disabled="isButtonActive(props.item)"
            >
              補正する
            </v-btn>
          </td>
          <td>
            {{ props.item.owner.names.ja_jp }}
          </td>
          <td>
            <router-link
              :to="`/items/${props.item.item._id}`"
              class="link-row--main"
            >
              {{ props.item.item.barcode }}
            </router-link>
          </td>
          <td style="max-width: 400px; min-width: 400px; white-space: normal">
            {{ props.item.item.names.ja_jp }}
          </td>
          <td>
            <span class="stocks">{{ props.item.warehouse_stock }}</span>
            <span class="stocks pad-left">{{
              props.item.expired_warehouse_stock
            }}</span>
            <span class="stocks pad-left">{{
              props.item.item.stock +
              props.item.item.reserved_stock +
              props.item.item.returned_stock
            }}</span>
            <span class="stocks pad-left">{{
              props.item.expired_warehouse_stock
            }}</span>
          </td>
        </template>
      </TableAndPaging>
    </v-card>

    <v-dialog v-model="adjustDialog.state">
      <stock-diff-detail
        :stockDiff="selectedStockDiff"
        @cancel="closeAdjustDialog"
        @correct-qty="correctQty"
      >
      </stock-diff-detail>
    </v-dialog>

    <confirm-dialog ref="confirmDialogComponent"> </confirm-dialog>
  </div>
</template>

<style scoped>
#stock-diffs-list .v-text-field__details {
  min-height: 0;
}
#stock-diffs-list .v-messages {
  min-height: 0;
}
#stock-diffs-list .v-input {
  margin: 0;
}
#stock-diffs-list .v-data-table .v-data-table {
  background-color: inherit;
}
.link-row--main {
  display: block;
  font-weight: bold;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
}
.link-row--main:hover {
  text-decoration: underline;
  color: rgba(0, 0, 0, 0.87);
}
.stocks {
  width: 50px;
  text-align: center;
  display: inline-block;
}
.pad-left {
  padding-left: 1em;
}
</style>

<script>
import TableAndPaging from '@/components/TableAndPaging';
import StockDiffDetail from '@/components/stock_diffs/StockDiffDetail';
import ConfirmDialog from '@/components/ConfirmDialog';
import _ from 'lodash';

import {
  FETCH_STOCK_DIFFS,
  FETCH_OWNERS_ALL,
  CORRECT_STOCK_DIFF,
  START_LOADING,
  FINISH_LOADING
} from '@/store/action-types';

export default {
  components: {
    TableAndPaging,
    StockDiffDetail,
    ConfirmDialog
  },
  data() {
    const query = this.$route.query;
    return {
      FETCH_STOCK_DIFFS,
      owner: query.filter_owner || '',
      query,
      header: [
        'アクション',
        '荷主',
        'バーコード',
        '商品名',
        'WMS在庫数(良品数) | WMS在庫数(消費期限切れ在庫数) | セカイロジ(良品数) | セカイロジ(消費期限切れ在庫数)'
      ],
      selectedStockDiff: null,
      // ダイアログ用変数定義
      adjustDialog: {
        state: false
      }
    };
  },
  methods: {
    showAdjustDialog({ stockDiff }) {
      this.selectedStockDiff = stockDiff;
      this.adjustDialog.state = true;
    },
    closeAdjustDialog() {
      this.adjustDialog.state = false;
      this.selectedStockDiff = null;
    },
    async correctQty({ qty, category }) {
      if (
        !(await this.$refs.confirmDialogComponent.open({
          headline: '',
          text: '在庫数の補正を実行してよろしいですか?',
          negative: 'キャンセル',
          positive: '実行する'
        }))
      ) {
        return;
      }

      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      try {
        await this.$store.dispatch(`stockDiffs/${CORRECT_STOCK_DIFF}`, {
          stockDiff: this.selectedStockDiff,
          category,
          qty
        });
      } catch (err) {
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        await this.$store.dispatch(
          'notify/showErrorNotify',
          _.get(
            err,
            'response.data.errors.general',
            'エラーが発生しました、画面を更新してやり直してください'
          )
        );
        throw err;
      }
      await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
      await this.$store.dispatch('notify/showNotify', '在庫数を補正しました。');
      this.closeAdjustDialog();
    },
    isButtonActive(stockDiff) {
      return (
        stockDiff &&
        stockDiff.item &&
        stockDiff.warehouse_stock ===
          stockDiff.item.stock +
            stockDiff.item.reserved_stock +
            stockDiff.item.returned_stock
      );
    }
  },
  computed: {
    queryParams() {
      return {
        filter_owner: this.owner
      };
    },
    owners() {
      return [{ id: '', name: '全て' }].concat(
        Object.keys(this.$store.state.owners.owners).map((index) => {
          return {
            id: this.$store.state.owners.owners[index]._id,
            name: this.$store.state.owners.owners[index].names.ja_jp
          };
        })
      );
    }
  },
  async beforeMount() {
    await this.$store.dispatch(`owners/${FETCH_OWNERS_ALL}`);
  }
};
</script>
