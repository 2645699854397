import axios from '@/api/common/axios';
import { WEBHOOK_FETCHED, WEBHOOKS_FETCHED } from '@/store/mutation-types';
import {
  FETCH_WEBHOOKS,
  FETCH_WEBHOOK,
  CREATE_WEBHOOK,
  CHANGE_STATUS
} from '@/store/action-types';

export default {
  namespaced: true,
  state: {
    webhookSettings: [],
    webhookSetting: {}
  },
  mutations: {
    [WEBHOOKS_FETCHED](state, webhookSettings) {
      state.webhookSettings = webhookSettings;
    },
    [WEBHOOK_FETCHED](state, webhookSetting) {
      state.webhookSetting = webhookSetting;
    }
  },
  actions: {
    async [FETCH_WEBHOOKS]({ commit }) {
      const res = await axios.get('/webhook_settings/index');
      const webhookSettings = res.data.webhookSettings;
      commit(WEBHOOKS_FETCHED, webhookSettings);
    },
    async [FETCH_WEBHOOK]({ commit }, params) {
      const res = await axios.get(
        `/webhook_settings/detail/${params.webhookSettingId}`
      );
      const webhookSetting = res.data.webhookSetting;
      commit(WEBHOOK_FETCHED, webhookSetting);
    },
    async [CREATE_WEBHOOK]({}, { url }) {
      await axios.post('/webhook_settings/create', { url }).catch((error) => {
        if (error.response.data.errors.code === 'ERR_MAINTENANCE') {
          throw new Error(error.message);
        }
        throw new Error(error.response.data.errors.general);
      });
    },
    async [CHANGE_STATUS]({}, params) {
      await axios.post('/webhook_settings/status', params).catch((error) => {
        if (error.response.data.errors.code === 'ERR_MAINTENANCE') {
          throw new Error(error.message);
        }
        throw new Error(error.response.data.errors.general);
      });
    }
  }
};
