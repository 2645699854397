<template>
  <div>
    <MainHeader :title="pageTitle" :back-to="backToRoute">
      <template v-if="isListView">
        <v-btn color="primary" elevation="0" class="ml-2" :to="PATHS.CREATE">
          <v-icon left>file_upload</v-icon>
          出荷依頼作成
          <span class="caption">(CSVアップロード)</span>
        </v-btn>
        <v-btn
          color="primary"
          elevation="0"
          class="ml-2"
          :to="PATHS.FORM"
          v-if="!isTomUser"
        >
          <v-icon left>edit</v-icon>
          出荷依頼作成
          <span class="caption">(フォームから)</span>
        </v-btn>
      </template>
    </MainHeader>

    <v-scroll-x-reverse-transition mode="out-in">
      <router-view></router-view>
    </v-scroll-x-reverse-transition>
  </div>
</template>

<script>
import MainHeader from '@/components/layout/MainHeader';

const PATHS = {
  INDEX: '/shipments/index',
  CREATE: '/shipments/create',
  FORM: '/shipments/form'
};

export default {
  components: {
    MainHeader
  },
  data: () => {
    return {};
  },
  computed: {
    pageTitle() {
      return this.$route.name;
    },
    isListView() {
      return this.$route.path === PATHS.INDEX;
    },
    backToRoute() {
      if (this.isListView) {
        return null;
      }
      return {
        path: PATHS.INDEX,
        query: this.lastQuery
      };
    },
    PATHS() {
      return PATHS;
    },
    isTomUser() {
      return this.$store.state.user.isTomUser;
    }
  }
};
</script>
