'use strict';

let TOM_ID;
try {
  ({ TOM_ID } = require('lib/document/schema/owners'));
} catch (error) {
  ({ TOM_ID } = require('@/../lib/document/schema/owners'));
}

const STATE_PATTERN = ['USABLE', 'PREPARATION', 'AVAILABLE', 'DELETED'];

const STATE = {};
for (const key of STATE_PATTERN) {
  STATE[key] = key;
}
// フライヤー管理画面にアクセス可能なOwnerのID(string[])
const FLYER_USABLE_OWNERS = [
  TOM_ID,
  '5dc3ead55b0d3450881d6a3b', // 実地テスト用荷主
  '5f10dd2d37145192c91dbf2a', // W Tokyo
  '5ddce005d4aa7b30e27c8965', // フロントウイング
  '5efbded7dd1ea8043fb14f01', // シノアリス
  '60b8aa2a276cc3001926b730', // eStream_eStreamStore
  '5f2202f251d309d939d12496', // 株式会社eStream_SSF
  '61e9f85a61842e0014424164', // 株式会社イ・デ・ヤ
  '60a7487decbc070019a63e3e', // 株式会社Blash9
  '617249b57a054a0019922d1a', // MyDearest株式会社
  '62d92827561e73001e551107', // 一般社団法人XRコンソーシアム
  '61e6324df72a0f001adf15cc' // 株式会社Craft Tokyo
];

module.exports = {
  STATE,
  STATE_PATTERN,
  FLYER_USABLE_OWNERS
};
