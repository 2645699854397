<template>
  <div>
    <v-card>
      <v-card-text>
        <v-layout>
          <v-row class="mr-3" justify="end">
            <v-btn
              color="primary"
              elevation="0"
              height="56"
              outlined
              to="/owners/registrations/form"
            >
              荷主を追加
            </v-btn>
          </v-row>
        </v-layout>
      </v-card-text>
      <TableAndPaging
        :headers="['メールアドレス', 'UNIT ID', '与信金額', '追加日']"
        :items="$store.state.accountRegistrations.accountRegistrations"
        :total="$store.state.accountRegistrations.total"
        :action="action"
      >
        <template v-slot:row="props">
          <td>
            {{ props.item.email }}
          </td>
          <td>
            {{ props.item.unit_id }}
          </td>
          <td>{{ props.item.credit_limit.toLocaleString('ja-JP') }}円</td>
          <td>
            {{
              moment(props.item.created_at)
                .tz('Asia/Tokyo')
                .format('YYYY/MM/DD')
            }}
          </td>
        </template>
      </TableAndPaging>
    </v-card>
  </div>
</template>

<script>
import TableAndPaging from '@/components/TableAndPaging';
import { FETCH_ACCOUNT_REGISTRATIONS } from '@/store/action-types';
import moment from 'moment-timezone';

export default {
  components: {
    TableAndPaging
  },
  data() {
    return {};
  },
  computed: {
    action: () => `accountRegistrations/${FETCH_ACCOUNT_REGISTRATIONS}`
  },
  methods: {
    moment
  }
};
</script>

<style scoped>
.link-row--main {
  display: block;
  font-weight: bold;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
}
.link-row--main:hover {
  text-decoration: underline;
  color: rgba(0, 0, 0, 0.87);
}
</style>
