import _ from 'lodash';
import axios from '@/api/common/axios';

import {
  SHIPPING_ADDRESS_FETCHED,
  SHIPPING_ADDRESS_DELETED,
  SHIPPING_ADDRESS_SAVED
} from '@/store/mutation-types';
import {
  FETCH_SHIPPING_ADDRESS,
  DELETE_SHIPPING_ADDRESS,
  SAVE_SHIPPING_ADDRESS
} from '../action-types';

const convertShippingAddress = (addr) => ({
  _id: addr._id,
  name: addr.name,
  address1: addr.address1,
  address2: addr.address2,
  city: addr.city,
  state: addr.state,
  postalCode: addr.postal_code,
  countryCode: addr.country_code,
  email: addr.email,
  phone: addr.phone
});

export default {
  namespaced: true,
  state: {
    shippingAddresses: []
  },
  mutations: {
    [SHIPPING_ADDRESS_FETCHED](state, { shippingAddresses }) {
      state.shippingAddresses = shippingAddresses.map(convertShippingAddress);
    },
    [SHIPPING_ADDRESS_DELETED](state, { id }) {
      state.shippingAddresses = state.shippingAddresses.filter(
        (addr) => addr._id !== id
      );
    },
    [SHIPPING_ADDRESS_SAVED](state, { shippingAddress }) {
      state.shippingAddresses = [shippingAddress, ...state.shippingAddresses];
    }
  },
  actions: {
    /**
     * 選択した出荷を取り消す
     * @param {Object} context
     * @param {string} selected
     * @returns {Promise<void>}
     */
    async [FETCH_SHIPPING_ADDRESS]({ commit }) {
      const errorMsg =
        'データ取得時にエラーがありました。セカイロジ担当者までお問い合わせください。';
      const response = await axios.get('/shipping_address').catch((e) => {
        throw new Error(_.get(e, 'response.data.errors.general', errorMsg));
      });

      commit(SHIPPING_ADDRESS_FETCHED, {
        shippingAddresses: response.data.results
      });
    },
    async [SAVE_SHIPPING_ADDRESS]({ commit }, { shippingAddress }) {
      const errorMsg =
        'データ保存時にエラーがありました。セカイロジ担当者までお問い合わせください。';

      const response = await axios
        .post('/shipping_address', shippingAddress)
        .catch((e) => {
          throw new Error(_.get(e, 'response.data.errors.general', errorMsg));
        });

      commit(SHIPPING_ADDRESS_SAVED, {
        shippingAddress: convertShippingAddress(response.data.shippingAddress)
      });
    },
    async [DELETE_SHIPPING_ADDRESS]({ commit }, { id }) {
      const errorMsg =
        'データ削除時にエラーがありました。セカイロジ担当者までお問い合わせください。';
      await axios
        .delete('/shipping_address', {
          data: {
            id
          }
        })
        .catch((e) => {
          throw new Error(_.get(e, 'response.data.errors.general', errorMsg));
        });

      commit(SHIPPING_ADDRESS_DELETED, { id });
    }
  }
};
