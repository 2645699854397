<template>
  <div>
    <v-card>
      <v-card-text>
        <v-layout>
          <v-autocomplete
            v-if="$store.state.user.isTomUser"
            v-model="owner"
            :items="owners"
            item-value="id"
            item-text="name"
            label="荷主"
            class="mr-2 flex-grow-0"
            outlined
            hide-details
          ></v-autocomplete>

          <v-text-field
            label="バーコード / 荷主管理番号 / 商品名 / 入庫依頼番号"
            v-model="keywords"
            prepend-inner-icon="search"
            outlined
            hide-details
          ></v-text-field>
          <v-select
            v-model="status"
            :items="$store.state.manifests.statusLabels"
            label="ステータス"
            class="ml-2 flex-grow-0"
            style="max-width: 160px"
            outlined
            hide-details
          ></v-select>
          <v-select
            v-model="sort"
            :items="$store.state.manifests.sortLabels"
            prepend-inner-icon="swap_vert"
            label="並び替え"
            class="ml-2 flex-grow-0"
            style="max-width: 260px"
            outlined
            hide-details
          ></v-select>
          <v-btn
            color="primary"
            class="ml-2"
            height="auto"
            outlined
            @click.stop="onClickCsvDownload"
          >
            CSVダウンロード
          </v-btn>
        </v-layout>
      </v-card-text>
      <TableAndPaging
        ref="search"
        :headers="header"
        :items="$store.state.manifests.manifests"
        :total="$store.state.manifests.total"
        :query-params="queryParams"
        action="manifests/FETCH_MANIFESTS"
        @dblclick-row="onDbleClickRow"
      >
        <template #row="props">
          <td v-if="$store.state.user.isTomUser">
            {{ props.item.owner.names.ja_jp }}
          </td>
          <td>
            <v-chip
              label
              small
              :color="props.item.state === '入庫完了' ? 'success' : 'default'"
              class="no-hover"
            >
              {{ props.item.state }}
            </v-chip>
          </td>
          <td class="col__mail-link">
            <router-link
              :to="`/manifests/${props.item._id}`"
              class="link-row--main"
            >
              {{ props.item.owner_manifest_no }}
            </router-link>
          </td>
          <td>{{ props.item.delivery_schedule }}</td>
          <td>
            {{ truncateText(props.item.tracking_code) }}
          </td>
          <td>{{ truncateText(props.item.carrier) }}</td>
          <td>
            <!-- 入庫依頼書は新しいタブで開く必要がある -->
            <v-btn
              text
              class="ml-1 font-weight-bold"
              color="primary"
              :href="`/manifests/${props.item._id}/slip`"
              target="_blank"
            >
              <v-icon left>print</v-icon>
              入庫依頼書
            </v-btn>
            <v-btn
              small
              outlined
              class="ml-1"
              color="error"
              v-if="props.item.cancelable"
              @click.stop="openConfirmDialog(props.item._id)"
            >
              <v-icon small left>cancel</v-icon>
              未入庫取り消し
            </v-btn>
          </td>
        </template>
      </TableAndPaging>
    </v-card>
    <v-dialog
      v-model="isOpenedConfirmDialog"
      max-width="300"
      @click:outside="closeConfirmDialog"
    >
      <v-card>
        <v-card-title class="headline">入庫依頼取り消し確認</v-card-title>
        <v-card-text>未入庫の入庫依頼を取り消します</v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="red" text @click="closeConfirmDialog"> 中断 </v-btn>
          <v-btn color="teal" text @click="cancelManifest(selectedManifestId)"
            >続行</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import _ from 'lodash';
import TableAndPaging from '@/components/TableAndPaging';
import {
  FETCH_OWNERS_ALL,
  CANCEL_MANIFEST,
  FETCH_MANIFESTS,
  START_LOADING,
  FINISH_LOADING,
  DOWNLOAD_MANIFESTS_CSV_REQUESTS
} from '@/store/action-types';
import { STATE_LABEL } from '@/../lib/document/schema/manifests';

export default {
  components: {
    TableAndPaging
  },
  data: function () {
    const query = this.$route.query;

    return {
      keywords: query.filter_keywords || '',
      barcode: query.filter_barcode || '',
      owner_item_code: query.filter_owner_item_code || '',
      name: query.filter_name || '',
      owner_manifest_no: query.filter_owner_manifest_no || '',
      sort: query.sort || '',
      status: STATE_LABEL[query.filter_status] || '',
      owner: query.filter_owner || '',
      isOpenedConfirmDialog: false,
      selectedManifestId: ''
    };
  },
  computed: {
    queryParams: function () {
      const query = {
        filter_keywords: this.keywords,
        filter_barcode: this.barcode,
        filter_owner_item_code: this.owner_item_code,
        filter_name: this.name,
        filter_owner_manifest_no: this.owner_manifest_no,
        filter_owner: this.owner,
        sort: this.sort
      };

      if (_.invert(STATE_LABEL)[this.status]) {
        query.filter_status = _.invert(STATE_LABEL)[this.status];
      }

      if (this.status === '全て') {
        query.filter_status = '';
      }
      return query;
    },
    header: function () {
      return this.$store.state.user.isTomUser
        ? this.$store.state.manifests.headersForTomUser
        : this.$store.state.manifests.headers;
    },
    owners: function () {
      return [{ id: '', name: '全て' }].concat(
        Object.keys(this.$store.state.owners.owners).map((index) => {
          return {
            id: this.$store.state.owners.owners[index]._id,
            name: this.$store.state.owners.owners[index].names.ja_jp
          };
        })
      );
    }
  },
  methods: {
    onDbleClickRow(manifest) {
      this.$router.push(`/manifests/${manifest._id}`);
    },
    openConfirmDialog(manifestId) {
      this.selectedManifestId = manifestId;
      this.isOpenedConfirmDialog = true;
    },
    closeConfirmDialog() {
      this.selectedManifestId = '';
      this.isOpenedConfirmDialog = false;
    },
    async cancelManifest(manifestId) {
      this.closeConfirmDialog();
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      const manifest = this.$store.getters['manifests/getManifest'](manifestId);
      try {
        await this.$store.dispatch(`manifest/${CANCEL_MANIFEST}`, manifestId);
        await this.$store.dispatch(
          `manifests/${FETCH_MANIFESTS}`,
          this.queryParams
        );
      } catch (err) {
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        await this.$store.dispatch(
          'notify/showErrorNotify',
          _.get(
            err,
            'response.data.errors.general',
            'エラーが発生しました、画面を更新してやり直してください'
          )
        );
        throw err;
      }
      await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
      await this.$store.dispatch(
        'notify/showNotify',
        `${manifest.owner_manifest_no}の未入庫分をキャンセルしました。`
      );
    },
    async onClickCsvDownload() {
      await this.$store.dispatch('notify/clearNotify');
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());

      try {
        await this.$store.dispatch(
          `manifests/${DOWNLOAD_MANIFESTS_CSV_REQUESTS}`,
          this.queryParams
        );
      } catch (error) {
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        await this.$store.dispatch(
          'notify/showErrorNotify',
          _.get(
            error,
            'message',
            'エラーが発生しました、画面を更新してやり直してください'
          )
        );
        throw error;
      }
      await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
      await this.$store.dispatch(
        'notify/showNotify',
        '処理を開始しました。処理完了時にメールで通知します。'
      );
    },
    truncateText(text) {
      if (!text) {
        return '';
      }
      if (text.length < 30) {
        return text;
      } else {
        return `${text.substr(0, 29)}...`;
      }
    }
  },
  async beforeMount() {
    await this.$store.dispatch(`owners/${FETCH_OWNERS_ALL}`);
  }
};
</script>

<style scoped>
.link-row--main {
  display: block;
  font-weight: bold;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
}
.link-row--main:hover {
  text-decoration: underline;
  color: rgba(0, 0, 0, 0.87);
}
.col__mail-link {
  width: 90%;
}
</style>
