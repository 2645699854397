'use strict';

const STATE = {
  VALIDATION_ERROR: 'VALIDATION_ERROR',
  DRAFT: 'DRAFT',
  APPROVAL: 'APPROVAL',
  CANCELED: 'CANCELED',
  PRE_ORDER: 'PRE_ORDER'
};
const STATE_PATTERN = [];
for (const state in STATE) {
  STATE_PATTERN.push(state);
}
const STATE_LABEL = {
  VALIDATION_ERROR: '入力エラー',
  DRAFT: 'ドラフト',
  APPROVAL: '承認済',
  CANCELED: '取り消し',
  PRE_ORDER: '出荷予約'
};

module.exports = {
  STATE,
  STATE_PATTERN,
  STATE_LABEL
};
