<template>
  <div>
    <MainHeader :title="pageTitle" :back-to="backToRoute">
      <template v-if="isListView && isTomUser">
        <v-btn
          color="primary"
          elevation="0"
          class="ml-2"
          to="/shipping_categories/form"
        >
          商品分類を追加
        </v-btn>
      </template>
    </MainHeader>
    <v-scroll-x-reverse-transition mode="out-in">
      <router-view></router-view>
    </v-scroll-x-reverse-transition>
  </div>
</template>

<script>
import MainHeader from '@/components/layout/MainHeader';
import { mapState } from 'vuex';

const PATHS = {
  INDEX: '/shipping_categories/index',
  CREATE: '/shipping_categories/form'
};

export default {
  components: {
    MainHeader
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('user', ['isTomUser']),
    pageTitle() {
      return this.$route.name;
    },
    isListView() {
      return this.$route.path === PATHS.INDEX;
    },
    backToRoute() {
      if (this.isListView) {
        return null;
      }
      return {
        path: PATHS.INDEX,
        query: this.lastQuery
      };
    }
  },
  created() {}
};
</script>
