var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"px-0":""}},[_c('MainHeader',{attrs:{"title":"WEB APIログ","back-to":"/settings/api"}}),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[_c('h3',{staticClass:"font-weight-bold subtitle-1 text-left mt-4"},[_vm._v(" "+_vm._s(_vm.$store.state.apiSettings.apiSetting.name)+" ")])]),_c('v-card-text',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('v-container',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"menu-item text-center",attrs:{"cols":"3"}},[_c('div',[_vm._v("APIバージョン")]),_c('v-chip',{attrs:{"color":"green","label":"","outlined":""}},[_vm._v(_vm._s(_vm.$store.state.apiSettings.apiSetting.api_version))])],1)],1)],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{attrs:{"height":"100%"}},[_c('v-card-text',[_c('TableAndPaging',{ref:"search",attrs:{"headers":_vm.header,"items":_vm.$store.state.apiLogs.apiLogs,"total":_vm.$store.state.apiLogs.total,"query-params":_vm.queryParams,"params":_vm.params,"action":`apiLogs/${_vm.FETCH_API_LOGS}`,"keepPage":true},scopedSlots:_vm._u([{key:"row",fn:function(props){return [_c('td',{on:{"click":function($event){return _vm.fetchApiLogRawRequestAndResponse(props.item._id)}}},[(
                    props.item.response && props.item.response.status === 200
                  )?_c('v-chip',{attrs:{"color":"green","label":"","outlined":""}},[_vm._v(" 成功 ")]):_c('v-chip',{attrs:{"color":"red","label":"","outlined":""}},[_vm._v("失敗")])],1),_c('td',{on:{"click":function($event){return _vm.fetchApiLogRawRequestAndResponse(props.item._id)}}},[_c('v-chip',{staticClass:"mr-2",attrs:{"color":"blue","label":"","outlined":"","small":""}},[_vm._v(" "+_vm._s(props.item.request.url)+" ")])],1),_c('td',{on:{"click":function($event){return _vm.fetchApiLogRawRequestAndResponse(props.item._id)}}},[_vm._v(" "+_vm._s(_vm.formatDate({ date: props.item.responsed_at }))+" ")])]}}])})],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',[_c('v-sheet',{staticClass:"code black--text pa-4"},[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0"},[_c('v-subheader',[_vm._v("ID")])],1),_c('v-col',{staticClass:"pt-0 pb-0"},[_vm._v(_vm._s(_vm.$store.state.apiLogs.apiLog._id)+" ")])],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0"},[_c('v-subheader',[_vm._v("ステータス")])],1),_c('v-col',{staticClass:"pt-0 pb-0"},[(
                  _vm.$store.state.apiLogs.apiLog.response &&
                  _vm.$store.state.apiLogs.apiLog.response.status
                )?_c('v-chip',{attrs:{"color":"green","label":"","outlined":""}},[_vm._v(" "+_vm._s(_vm.$store.state.apiLogs.apiLog.response.status)+" ")]):(_vm.$store.state.apiLogs.apiLog.response)?_c('v-chip',{attrs:{"color":"red","label":"","outlined":""}},[_vm._v("失敗")]):_vm._e()],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0"},[_c('v-subheader',[_vm._v("URL")])],1),_c('v-col',{staticClass:"pt-0 pb-0"},[(
                  _vm.$store.state.apiLogs.apiLog.request &&
                  _vm.$store.state.apiLogs.apiLog.request.url
                )?_c('v-chip',{staticClass:"mr-2",attrs:{"color":"blue","label":"","outlined":"","small":""}},[_vm._v(" "+_vm._s(_vm.$store.state.apiLogs.apiLog.request.url)+" ")]):_vm._e()],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0"},[_c('v-subheader',[_vm._v("日時")])],1),_c('v-col',{staticClass:"pt-0 pb-0"},[_vm._v(_vm._s(_vm.formatDate({ date: _vm.$store.state.apiLogs.apiLog.responsed_at }))+" ")])],1)],1),_c('v-sheet',{staticClass:"code black--text pa-4"},[_c('v-subheader',[_vm._v("Response")]),_vm._l((_vm.$store.state.apiLogs.apiLog.rawResponse),function(line,index){return _c('v-row',{key:`line-${index}`,attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"1"}},[_c('span',{staticClass:"grey--text mr-3 code-row"})]),_c('v-col',{attrs:{"cols":"11"}},[_c('span',[_vm._v(_vm._s(line))])])],1)})],2),_c('v-sheet',{staticClass:"code black--text pa-4"},[_c('v-subheader',[_vm._v("Request")]),_vm._l((_vm.$store.state.apiLogs.apiLog.rawRequest),function(line,index){return _c('v-row',{key:`line-${index}`,attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"1"}},[_c('span',{staticClass:"grey--text mr-3 code-row"})]),_c('v-col',[_c('span',[_vm._v(_vm._s(line))])])],1)})],2)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }