<template>
  <v-container px-0>
    <MainHeader title="APIログ" back-to="/settings/api"></MainHeader>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <h3 class="font-weight-bold subtitle-1 text-left mt-4">
              {{ $store.state.webhookSettings.webhookSetting.url }}
            </h3>
          </v-card-title>
          <v-card-text>
            <v-form @submit.prevent>
              <v-container>
                <v-row align="center">
                  <v-col class="menu-item text-center" cols="3">
                    <div>ステータス</div>
                    <v-chip
                      v-if="
                        $store.state.webhookSettings.webhookSetting.is_active
                      "
                      color="green"
                      label
                      outlined
                      >有効</v-chip
                    >
                    <v-chip v-else color="red" label outlined>無効</v-chip>
                  </v-col>
                  <v-col class="menu-item text-center" cols="3">
                    <div>対象イベント</div>
                    <v-chip
                      color="blue"
                      label
                      outlined
                      class="mr-2"
                      small
                      v-for="targetEvent in $store.state.webhookSettings
                        .webhookSetting.target_events"
                      :key="'item-' + targetEvent"
                    >
                      {{ targetEvent }}
                    </v-chip>
                  </v-col>
                  <v-col class="menu-item text-center" cols="3">
                    <div>APIバージョン</div>
                    <v-chip color="green" label outlined>{{
                      $store.state.webhookSettings.webhookSetting.api_version
                    }}</v-chip>
                  </v-col>
                  <v-col class="menu-item text-center" cols="3">
                    <div>署名トークン（選択して表示）</div>
                    <div class="hidden-text">
                      {{
                        $store.state.webhookSettings.webhookSetting
                          .signature_token
                      }}
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-card height="100%">
          <TableAndPaging
            ref="search"
            :headers="header"
            :items="$store.state.webhookLogs.webhookLogs"
            :total="$store.state.webhookLogs.total"
            :query-params="queryParams"
            :params="params"
            :action="`webhookLogs/${FETCH_WEBHOOK_LOGS}`"
            :keepPage="true"
          >
            <template #row="props">
              <td @click="fetchWebhookLogRawRequestAndResponse(props.item._id)">
                <v-chip
                  v-if="props.item.response && props.item.response.status < 400"
                  color="green"
                  label
                  outlined
                >
                  成功
                </v-chip>
                <v-chip v-else color="red" label outlined>失敗</v-chip>
              </td>
              <td @click="fetchWebhookLogRawRequestAndResponse(props.item._id)">
                <v-chip color="blue" label outlined class="mr-2" small>
                  {{ props.item.event }}
                </v-chip>
              </td>
              <td @click="fetchWebhookLogRawRequestAndResponse(props.item._id)">
                <v-chip label outlined>
                  {{ props.item.state }}
                </v-chip>
              </td>
              <td @click="fetchWebhookLogRawRequestAndResponse(props.item._id)">
                {{ formatDate({ date: props.item.event_occurred_at }) }}
              </td>
            </template>
          </TableAndPaging>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card>
          <v-sheet class="code black--text pa-4">
            <v-row>
              <v-col class="pt-0 pb-0">
                <v-subheader>ID</v-subheader>
              </v-col>
              <v-col class="pt-0 pb-0"
                >{{ $store.state.webhookLogs.webhookLog._id }}
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-0 pb-0">
                <v-subheader>ステータス</v-subheader>
              </v-col>
              <v-col class="pt-0 pb-0">
                <v-chip
                  v-if="
                    $store.state.webhookLogs.webhookLog.response &&
                    $store.state.webhookLogs.webhookLog.response.status < 400
                  "
                  color="green"
                  label
                  outlined
                >
                  成功
                </v-chip>
                <v-chip
                  v-else-if="$store.state.webhookLogs.webhookLog.response"
                  color="red"
                  label
                  outlined
                  >失敗</v-chip
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-0 pb-0">
                <v-subheader>イベントタイプ</v-subheader>
              </v-col>
              <v-col class="pt-0 pb-0">
                <v-chip
                  v-if="$store.state.webhookLogs.webhookLog.event"
                  color="blue"
                  label
                  outlined
                  class="mr-2"
                  small
                >
                  {{ $store.state.webhookLogs.webhookLog.event }}
                </v-chip>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-0 pb-0">
                <v-subheader>送信状況</v-subheader>
              </v-col>
              <v-col class="pt-0 pb-0">
                <v-chip
                  v-if="$store.state.webhookLogs.webhookLog.state"
                  label
                  outlined
                >
                  {{ $store.state.webhookLogs.webhookLog.state }}
                </v-chip>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-0 pb-0"> <v-subheader>日時</v-subheader> </v-col>
              <v-col class="pt-0 pb-0"
                >{{
                  formatDate({
                    date: $store.state.webhookLogs.webhookLog.event_occurred_at
                  })
                }}
              </v-col>
            </v-row>
          </v-sheet>
          <v-sheet class="code black--text pa-4">
            <v-subheader>Response</v-subheader>
            <v-row
              no-gutters
              v-for="(line, index) in $store.state.webhookLogs.webhookLog
                .rawResponse"
              :key="`line-${index}`"
            >
              <v-col cols="1">
                <span class="grey--text mr-3 code-row" />
              </v-col>
              <v-col cols="11">
                <span>{{ line }}</span>
              </v-col>
            </v-row>
          </v-sheet>
          <v-sheet class="code black--text pa-4">
            <v-subheader>Request</v-subheader>
            <v-row
              no-gutters
              v-for="(line, index) in $store.state.webhookLogs.webhookLog
                .rawRequest"
              :key="`line-${index}`"
            >
              <v-col cols="1">
                <span class="grey--text mr-3 code-row" />
              </v-col>
              <v-col>
                <span>{{ line }}</span>
              </v-col>
            </v-row>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.menu-item + .menu-item {
  border-left: 1px solid #9e9e9e;
}
.hidden-text {
  color: transparent;
  text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
}
.hidden-text::selection {
  color: rgba(0, 0, 0, 0.6);
}
.code {
  border-radius: 12px;
  overflow-x: auto;
  letter-spacing: 0.5px;
  word-spacing: 1px;
  font-family: monospace;
  white-space: pre;
  font-weight: 300;
  font-size: 15px;
  counter-reset: linenumber 0;
}

.code-row {
  letter-spacing: 0.8px;
}
.code-row:before {
  counter-increment: linenumber 1;
  content: counter(linenumber);
}
</style>

<script>
import TableAndPaging from '@/components/TableAndPaging';
import MainHeader from '@/components/layout/MainHeader';
import {
  FETCH_WEBHOOK,
  FETCH_WEBHOOK_LOGS,
  FETCH_WEBHOOK_LOG,
  START_LOADING,
  FINISH_LOADING
} from '@/store/action-types';
import { formatDate } from '@/util/formatter';

export default {
  components: {
    TableAndPaging,
    MainHeader
  },
  data() {
    const webhookSettingId = this.$route.params.webhookSettingId;
    return {
      FETCH_WEBHOOK_LOGS,
      params: {
        webhookSettingId: webhookSettingId
      },
      logId: '',
      page: '',
      limit: 10,
      header: ['ステータス', 'イベントタイプ', '送信状況', '日時']
    };
  },
  computed: {
    queryParams: function () {
      return {
        log_id: this.logId,
        page: this.page,
        limit: this.limit
      };
    }
  },
  methods: {
    async fetchWebhookLogRawRequestAndResponse(logId) {
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      this.logId = logId;
      try {
        await this.$store.dispatch(`webhookLogs/${FETCH_WEBHOOK_LOG}`, {
          logId
        });
      } catch (err) {
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        await this.$store.dispatch('notify/showErrorNotify', err.message);
        throw err;
      }
      await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
    },
    formatDate
  },
  async beforeMount() {
    await this.$store.dispatch(`webhookSettings/${FETCH_WEBHOOK}`, {
      webhookSettingId: this.params.webhookSettingId
    });
    await this.$store.dispatch(`webhookLogs/${FETCH_WEBHOOK_LOG}`, {
      logId: this.logId
    });
  },
  mounted: function () {
    const params = this.$route.query;
    this.logId = params.log_id || '';
    this.page = params.page || 1;
    this.limit = params.limit || 10;
  }
};
</script>
