<template>
  <v-container px-0>
    <v-row>
      <v-col>
        <v-card>
          <TableAndPaging
            :headers="['メールアドレス', 'アカウント種類', '']"
            :items="$store.state.users.items"
            :total="$store.state.users.total"
            :action="action"
          >
            <template v-slot:row="props">
              <td>{{ props.item.email }}</td>
              <td>
                <v-icon v-if="props.item.isManager">how_to_reg</v-icon>
                {{ props.item.categoryText }}
              </td>
              <td>
                <v-btn
                  small
                  outlined
                  class="ml-1"
                  color="warning"
                  @click.stop="
                    changeUserCategoryWithConfirmation({
                      userId: props.item.id,
                      currentCategory: props.item.category
                    })
                  "
                >
                  <v-icon small left>change_circle</v-icon>
                  アカウント種別変更
                </v-btn>
              </td>
              <td>
                <v-btn
                  v-if="
                    $store.state.user.name !== props.item.email.split('@')[0]
                  "
                  small
                  outlined
                  class="ml-1"
                  color="error"
                  @click.stop="deactivateUserWithConfirmation(props.item.email)"
                >
                  <v-icon small left>cancel</v-icon>
                  アカウント削除
                </v-btn>
              </td>
            </template>
          </TableAndPaging>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="300">
      <v-card>
        <v-card-title class="headline">{{ title }}</v-card-title>
        <v-card-text>{{ text }}</v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="red" text @click="dialog = false"> キャンセル </v-btn>
          <v-btn color="teal" text @click="command">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import TableAndPaging from '@/components/TableAndPaging';
import {
  FETCH_USERS,
  START_LOADING,
  FINISH_LOADING,
  DEACTIVATE_USER,
  CHANGE_USER_CATEGORY
} from '@/store/action-types';
import { CATEGORY, CATEGORY_TEXT } from '@/../lib/document/schema/users';

export default {
  data() {
    return {
      selected: '',
      targetUserId: '',
      title: '',
      text: '',
      command: '',
      dialog: false
    };
  },
  components: {
    TableAndPaging
  },
  methods: {
    showDialog({ title, text, command }) {
      this.title = title;
      this.text = text;
      this.command = command;
      this.dialog = true;
    },
    deactivateUserWithConfirmation(email) {
      this.showDialog({
        title: 'アカウント削除の確認',
        text: 'アカウントを本当に削除してよろしいですか？',
        command: () => this.deactivateUser(email)
      });
    },
    async deactivateUser(email) {
      await this.$store.dispatch('notify/clearNotify');
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      try {
        await this.$store.dispatch(`user/${DEACTIVATE_USER}`, {
          email
        });
        await this.$store.dispatch(`users/${FETCH_USERS}`);
      } catch (err) {
        await this.$store.dispatch('notify/showErrorNotify', err.message);
      }
      this.dialog = false;
      await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
    },
    changeUserCategoryWithConfirmation({ userId, currentCategory }) {
      this.targetUserId = userId;
      const changes =
        currentCategory === CATEGORY.MANAGER
          ? CATEGORY.OPERATOR
          : CATEGORY.MANAGER;
      this.showDialog({
        title: 'アカウント種類を変更します',
        text: `アカウント種類を「${CATEGORY_TEXT[currentCategory]}」から「${CATEGORY_TEXT[changes]}」に変更してよろしいですか？`,
        command: () => this.changeUserCategory({ userId, category: changes })
      });
    },
    async changeUserCategory({ userId, category }) {
      await this.$store.dispatch('notify/clearNotify');
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      try {
        await this.$store.dispatch(`user/${CHANGE_USER_CATEGORY}`, {
          userId,
          category
        });

        // 自分自身を管理者から通常アカウントへ変更した場合はユーザ一覧を表示する権限もなくなるのでトップページへ飛ばす
        if (
          userId === this.$store.state.user.id &&
          category === CATEGORY.OPERATOR
        ) {
          await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());

          return this.$router.push({
            path: '/',
            query: {
              notifyType: 'showNotify',
              notifyMessageCode: this.$router.MESSAGE_CODES
                .USER_CATEGORY_CHANGED
            }
          });
        } else {
          await this.$store.dispatch(`users/${FETCH_USERS}`);
        }
      } catch (err) {
        await this.$store.dispatch('notify/showErrorNotify', err.message);
      }
      this.dialog = false;
      await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
    }
  },
  computed: {
    action: () => `users/${FETCH_USERS}`
  }
};
</script>
