<template>
  <div>
    <v-row justify="space-between" align="center" v-if="isTomUser && link">
      <v-col md="2">
        <v-btn
          text
          color="primary"
          elevation="0"
          :href="link.prev"
          block
          v-if="link.prev"
        >
          &lt; 前の荷主
        </v-btn>
      </v-col>
      <v-col md="2">
        <v-btn
          text
          color="primary"
          elevation="0"
          :href="link.next"
          block
          v-if="link.next"
        >
          次の荷主 &gt;
        </v-btn>
      </v-col>
    </v-row>
    <MainHeader
      :title="`請求管理 - ${billings.owner.unique_number} : ${
        billings.owner.names ? billings.owner.names.ja_jp : '名前未設定'
      }`"
    />

    <v-container>
      <v-row justify="space-between" align="center">
        <v-row>
          <v-col md="4" class="ml-2">
            <v-autocomplete
              :items="YEAR_MONTH"
              v-model="yearMonth"
              item-value="value"
              item-text="text"
              outlined
              hide-details
              dense
            />
          </v-col>
        </v-row>
        <v-col md="2">
          <v-btn
            text
            color="primary"
            elevation="0"
            class="ml-2"
            :to="
              isTomUser ? `/owners/${billings.owner._id}/payments` : '/payments'
            "
            block
          >
            &gt; お支払い設定
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row align="center" justify="space-between">
        <v-col>
          <v-chip
            v-if="billings.paid_status === PAID_STATUS.UNPAID"
            class="ma-2"
            label
            :color="AMOUNT_STATUS_COLOR[billings.amount_status]"
          >
            {{ AMOUNT_STATUS_DICTIONARY[billings.amount_status] }}
          </v-chip>
          <v-chip
            v-else
            class="ma-2"
            label
            :color="PAID_STATUS_COLOR[billings.paid_status]"
          >
            {{ PAID_STATUS_DICTIONARY[billings.paid_status] }}
          </v-chip>
          <h3 class="d-inline-block">
            {{ billings.year }}年{{ billings.month }}月のご請求
          </h3>
        </v-col>
        <template v-if="isTomUser">
          <v-col
            v-if="
              [AMOUNT_STATUS.APPROVED, AMOUNT_STATUS.FIXED].includes(
                billings.amount_status
              ) && billings.paid_status !== PAID_STATUS.PAID
            "
            md="2"
          >
            <v-btn
              class="ml-2"
              color="error"
              block
              @click="cancelBilling({ ownerId, billingId: billings.billingId })"
            >
              訂正する
            </v-btn>
          </v-col>
          <v-col v-if="billings.amount_status === AMOUNT_STATUS.PENDING" md="2">
            <v-btn
              color="primary"
              block
              @click="
                approveBilling({ ownerId, billingId: billings.billingId })
              "
            >
              確定する
            </v-btn>
          </v-col>
          <v-col
            v-if="billings.amount_status === AMOUNT_STATUS.APPROVED"
            md="2"
          >
            <v-btn
              color="primary"
              block
              @click="fixBilling({ ownerId, billingId: billings.billingId })"
            >
              請求書を発行する
            </v-btn>
          </v-col>
        </template>
        <ConfirmDialog ref="dialog"></ConfirmDialog>
        <!-- <v-col
          v-if="
            isTomUser &&
            billings.amount_status === AMOUNT_STATUS.FIXED &&
            billings.paid_status === PAID_STATUS.PAID
          "
          md="2"
        >
          <v-btn
            class="ml-2"
            color="error"
            block
            @click="refundBilling({ ownerId, billingId: billings.billingId })"
          >
            返金対応する
          </v-btn>
        </v-col> -->
        <v-col
          v-if="
            isTomUser &&
            billings.amount_status === AMOUNT_STATUS.FIXED &&
            billings.paid_status !== PAID_STATUS.PAID
          "
          md="2"
        >
          <v-btn
            class="ml-2"
            color="primary"
            block
            @click="payBilling({ ownerId, billingId: billings.billingId })"
          >
            精算済みにする
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row justify="end" align="center">
        <v-col md="2" v-if="billings.receipt_url">
          <v-btn
            color="primary"
            elevation="0"
            class="ml-2"
            :href="`/direct_download/receipt/${billings.billingId}`"
            target="_blank"
            block
          >
            領収書PDF
          </v-btn>
        </v-col>
        <v-col md="2" v-if="billings.bill_url">
          <v-btn
            color="primary"
            elevation="0"
            class="ml-2"
            :href="`/direct_download/billing/${billings.bill_url}`"
            target="_blank"
            block
          >
            請求書PDF
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-simple-table>
        <tbody>
          <tr>
            <td>当月ご利用金額</td>
            <td class="mono-text-right add-yen">
              {{ billings.amount.toLocaleString('ja-JP') }}
            </td>
          </tr>
          <tr>
            <td>消費税</td>
            <td class="mono-text-right add-yen">
              {{
                (billings.total_amount - billings.amount).toLocaleString(
                  'ja-JP'
                )
              }}
            </td>
          </tr>
          <tr class="amber accent-1">
            <td>請求合計金額</td>
            <td class="mono-text-right add-yen">
              {{ billings.total_amount.toLocaleString('ja-JP') }}
            </td>
          </tr>
          <tr
            v-if="this.$store.state.owner.remaining_credit_limit >= 0"
            class="amber accent-1"
          >
            <td>残ご利用可能金額</td>
            <td class="mono-text-right">
              {{
                `${this.$store.state.owner.remaining_credit_limit.toLocaleString(
                  'ja-JP'
                )} 円(ご利用可能枠：${this.$store.state.owner.credit_limit.toLocaleString(
                  'ja-JP'
                )} 円)`
              }}
            </td>
          </tr>
          <tr
            v-if="this.$store.state.owner.remaining_credit_limit < 0"
            class="amber accent-1"
          >
            <td class="red--text font-weight-bold">超過ご利用額</td>
            <td class="mono-text-right">
              {{
                `${Math.abs(
                  this.$store.state.owner.remaining_credit_limit
                ).toLocaleString(
                  'ja-JP'
                )} 円(ご利用可能枠：${this.$store.state.owner.credit_limit.toLocaleString(
                  'ja-JP'
                )} 円)`
              }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-container>
    <v-container>
      <v-row justify="end" no-gutters>
        <v-col class="mono-text-right caption mb-2">
          ご利用金額は前日までの集計となっております。
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row justify="end" no-gutters>
        <v-col md="4" class="mono-text-right">
          <v-btn
            text
            color="primary"
            elevation="0"
            class="ml-2"
            @click.stop="
              showDialog({
                title: 'ご利用可能枠の増枠リクエスト',
                text: 'ご利用可能枠の増枠をリクエストします'
              })
            "
          >
            ご利用可能枠の増枠をリクエスト
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <hr />
    <v-container>
      <v-row justify="space-between" align="center">
        <v-col>
          <h3>ご請求内訳</h3>
        </v-col>
        <v-col
          md="2"
          v-if="isTomUser && billings.amount_status === AMOUNT_STATUS.PENDING"
        >
          <v-switch
            color="primary"
            class="ml-2"
            label="明細編集モード"
            v-model="isEditMode"
          />
        </v-col>
        <v-col md="2">
          <v-btn
            v-if="ownerId"
            color="primary"
            elevation="0"
            class="ml-2"
            outlined
            :href="`/billings/csv?date=${yearMonth}&ownerId=${ownerId}`"
            block
          >
            CSVダウンロード
          </v-btn>
          <v-btn
            v-else
            color="primary"
            elevation="0"
            class="ml-2"
            outlined
            :href="`/billings/csv?date=${yearMonth}`"
            block
          >
            CSVダウンロード
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <BillingsEditForm
      v-if="isEditMode"
      @on-click-modify="modifyBilling"
      :initOptions="[...billings.lines.options]"
      :initRemark="billings.remark"
    />

    <div v-if="!isEditMode">
      <v-container>
        <v-simple-table dense>
          <thead>
            <tr class="primary">
              <th style="width: 600px" class="white--text">内容</th>
              <th class="white--text">単価</th>
              <th class="white--text">数量</th>
              <th class="white--text">単位</th>
              <th class="white--text" v-if="isTomUser">消費税率</th>
              <th class="white--text">合計金額（税抜）</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>入庫保管 サービス</th>
            </tr>
            <tr
              class="details"
              v-for="(obj, i) in [
                ...billings.lines.manifests,
                ...manifestsOptions
              ]"
              :key="'manifests-' + i"
            >
              <td>{{ obj.summary }}</td>
              <td>
                {{ obj.unit_price && obj.unit_price.toLocaleString('ja-JP') }}
              </td>
              <td>{{ obj.count }}</td>
              <td>{{ obj.unit }}</td>
              <td v-if="isTomUser">
                {{ Math.floor((obj.tax_rate - 1) * 100) }}%
              </td>
              <td class="mono-text-right add-yen">
                {{ obj.amount && obj.amount.toLocaleString('ja-JP') }}
              </td>
            </tr>
            <tr>
              <th class="pt-6 pb-1">出荷 サービス</th>
            </tr>
            <tr
              class="details"
              v-for="(obj, i) in [
                ...billings.lines.shipments,
                ...shipmentsOptions
              ]"
              :key="'shipments-' + i"
            >
              <td>{{ obj.summary }}</td>
              <td>
                {{ obj.unit_price && obj.unit_price.toLocaleString('ja-JP') }}
              </td>
              <td>{{ obj.count }}</td>
              <td>{{ obj.unit }}</td>
              <td v-if="isTomUser">
                {{ Math.floor((obj.tax_rate - 1) * 100) }}%
              </td>
              <td class="mono-text-right add-yen">
                {{ obj.amount && obj.amount.toLocaleString('ja-JP') }}
              </td>
            </tr>

            <tr>
              <th class="pt-6 pb-1">配送 サービス</th>
            </tr>
            <tr
              class="details"
              v-for="(obj, i) in [
                ...billings.lines.shippings,
                ...shippingsOptions
              ]"
              :key="'shippings-' + i"
            >
              <td>{{ obj.summary }}</td>
              <td>
                {{ obj.unit_price && obj.unit_price.toLocaleString('ja-JP') }}
              </td>
              <td>{{ obj.count }}</td>
              <td>{{ obj.unit }}</td>
              <td v-if="isTomUser">
                {{ Math.floor((obj.tax_rate - 1) * 100) }}%
              </td>
              <td class="mono-text-right add-yen">
                {{ obj.amount && obj.amount.toLocaleString('ja-JP') }}
              </td>
            </tr>

            <tr v-if="othersOptions.length > 0">
              <th class="pt-6 pb-1">その他 サービス</th>
            </tr>
            <tr
              class="details"
              v-for="(obj, i) in othersOptions"
              :key="'othersOptions-' + i"
            >
              <td>{{ obj.summary }}</td>
              <td>
                {{ obj.unit_price && obj.unit_price.toLocaleString('ja-JP') }}
              </td>
              <td>{{ obj.count }}</td>
              <td>{{ obj.unit }}</td>
              <td v-if="isTomUser">
                {{ Math.floor((obj.tax_rate - 1) * 100) }}%
              </td>
              <td class="mono-text-right add-yen">
                {{ obj.amount && obj.amount.toLocaleString('ja-JP') }}
              </td>
            </tr>

            <tr class="boder-bottom">
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td v-if="isTomUser"></td>
              <td></td>
            </tr>

            <tr
              v-for="amountDetail in billings.amountDetails"
              :key="'subtotal-tax-' + amountDetail.tax_rate"
            >
              <td></td>
              <td></td>
              <td></td>
              <td v-if="isTomUser"></td>
              <td class="mono-text-right" v-if="amountDetail.tax_rate === 1">
                消費税対象外 小計
              </td>
              <td class="mono-text-right" v-else>
                消費税({{ Math.floor((amountDetail.tax_rate - 1) * 100) }}%)対象
                小計
              </td>
              <td class="mono-text-right add-yen">
                {{ amountDetail.amount.toLocaleString('ja-JP') }}
              </td>
            </tr>
            <tr class="bold">
              <td></td>
              <td></td>
              <td></td>
              <td v-if="isTomUser"></td>
              <td class="mono-text-right">本体金額合計</td>
              <td class="mono-text-right add-yen">
                {{ billings.amount.toLocaleString('ja-JP') }}
              </td>
            </tr>
            <tr
              class="bold"
              v-for="amountDetail in (billings.amountDetails || []).filter(
                (d) => d.tax_rate !== 1
              )"
              :key="'tax-' + amountDetail.tax_rate"
            >
              <td></td>
              <td></td>
              <td></td>
              <td v-if="isTomUser"></td>
              <td class="mono-text-right">
                消費税額({{ Math.floor((amountDetail.tax_rate - 1) * 100) }}%)
              </td>
              <td class="mono-text-right add-yen">
                {{
                  Math.round(
                    amountDetail.amount * (amountDetail.tax_rate - 1)
                  ).toLocaleString('ja-JP')
                }}
              </td>
            </tr>
            <tr class="amber accent-1 bold">
              <td></td>
              <td></td>
              <td></td>
              <td v-if="isTomUser"></td>
              <td class="mono-text-right">合計請求額</td>
              <td class="mono-text-right add-yen">
                {{ billings.total_amount.toLocaleString('ja-JP') }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-container>
      <v-container>
        <v-col class="grey lighten-2">
          <h5>備考欄/補足コメント</h5>
          <p>
            <span
              v-for="(remark, i) in billings.remark.split('\n')"
              :key="`remark-${i}`"
            >
              {{ remark }}
              <br />
            </span>
          </p>
          <div v-if="billings.amount_status !== AMOUNT_STATUS.PENDING">
            <hr />
            <p>下記銀行口座にお振込みをお願い申し上げます。</p>
            <address>
              みずほ銀行(0001) 本店(100)<br />
              普通預金 5373565 <br />
              口座名義 カブシキガイシャトーキョーオタクモード <br />
              * 恐れ入りますが振込手数料はお客様のご負担にてお願い申し上げます。
              <br />
              *
              月末が土日祝日にあたる場合は、前営業日までのお振込をお願い申し上げます。
            </address>
          </div>
        </v-col>
      </v-container>
    </div>

    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-card-title class="headline">{{ title }}</v-card-title>
        <v-card-text>{{ text }}</v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="red" text @click="dialog = false"> キャンセル </v-btn>
          <v-btn color="teal" text @click="requestIncreaseCreditLimits"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.mono-text-right {
  text-align: right;
  font-family: monospace;
}
.add-yen::after {
  content: ' 円';
}
tbody .details td:nth-child(1) {
  padding: 0 24px;
}
tbody .details td {
  height: 24px !important;
}
tbody tr.bold > td {
  font-weight: bold;
}

tbody tr.boder-bottom > td {
  border-bottom: 1px solid #888 !important;
}

tbody tr > th {
  border-bottom: none !important;
}
</style>
<script>
import _ from 'lodash';
import moment from 'moment-timezone';
import MainHeader from '@/components/layout/MainHeader';
import BillingsEditForm from '@/components/billings/BillingsEditForm';
import {
  FETCH_BILLINGS,
  FIX_BILLINGS,
  CANCEL_BILLINGS,
  MODIFY_BILLINGS,
  PAY_BILLINGS,
  APPROVE_BILLINGS,
  // REFUND_BILLINGS,
  FETCH_OWNER
} from '@/store/action-types';
import { mapGetters, mapState } from 'vuex';
import { START_LOADING, FINISH_LOADING } from '@/store/action-types';
import {
  AMOUNT_STATUS,
  AMOUNT_STATUS_DICTIONARY,
  PAID_STATUS,
  PAID_STATUS_DICTIONARY
} from '@/../lib/document/schema/billings';
import { CATEGORY } from '@/../lib/document/schema/billings';
import ConfirmDialog from '@/components/ConfirmDialog';
import { getYearMonth } from '@/../lib/util/date';
import axios from '@/api/common/axios';

export default {
  components: {
    MainHeader,
    BillingsEditForm,
    ConfirmDialog
  },
  data: () => {
    return {
      YEAR_MONTH: getYearMonth(),
      ownerId: '',
      yearMonth: '',
      AMOUNT_STATUS,
      AMOUNT_STATUS_DICTIONARY,
      AMOUNT_STATUS_COLOR: {
        PENDING: 'default',
        APPROVED: 'amber',
        FIXED: 'primary'
      },
      PAID_STATUS,
      PAID_STATUS_DICTIONARY,
      PAID_STATUS_COLOR: {
        UNPAID: 'default',
        PAID: 'teal white--text',
        FAIL: 'error'
      },
      isEditMode: false,
      title: '',
      text: '',
      dialog: false
    };
  },
  computed: {
    isTomUser: (self) => self.$store.state.user.isTomUser,
    ...mapGetters('user', ['hasRealm']),
    ...mapState(['billings']), // this.$store.billingsをthis.billingsで扱えるようにする
    manifestsOptions: (self) => {
      return self.filterOptions(CATEGORY.MANIFESTS);
    },
    shipmentsOptions: (self) => {
      return self.filterOptions(CATEGORY.SHIPMENTS);
    },
    shippingsOptions: (self) => {
      return self.filterOptions(CATEGORY.SHIPPINGS);
    },
    othersOptions: (self) => {
      return self.billings.lines.options.filter(
        (opt) => !opt.category || opt.category === CATEGORY.OTHERS
      );
    },
    link: (self) => {
      if (self.billings.link) {
        return {
          prev:
            self.billings.link.prevOwnerId &&
            `/owners/${self.billings.link.prevOwnerId}/billings/update?date=${self.yearMonth}`,
          next:
            self.billings.link.nextOwnerId &&
            `/owners/${self.billings.link.nextOwnerId}/billings/update?date=${self.yearMonth}`
        };
      }
    }
  },
  methods: {
    filterOptions(category) {
      return this.billings.lines.options.filter(
        (opt) => opt.category === category
      );
    },
    async fetchBilling({ ownerId }) {
      await this.$store.dispatch('notify/clearNotify');
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      try {
        await this.$store.dispatch(`billings/${FETCH_BILLINGS}`, {
          date: `${this.yearMonth}`,
          ownerId
        });
        await this.fetchOwner({ ownerId });
      } catch (error) {
        await this.$store.dispatch(
          'notify/showErrorNotify',
          _.get(
            error,
            'response.data.errors.general',
            'エラーが発生しました、画面を更新してやり直してください'
          )
        );
        throw error;
      } finally {
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
      }
    },
    async modifyBilling({ options, remark }) {
      await this.$store.dispatch('notify/clearNotify');
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      try {
        await this.$store.dispatch(`billings/${MODIFY_BILLINGS}`, {
          billingId: this.billings.billingId,
          ownerId: this.ownerId,
          options,
          remark
        });
        this.isEditMode = false;
        await this.fetchOwner({
          ownerId: this.ownerId
        });
      } catch (error) {
        await this.$store.dispatch(
          'notify/showErrorNotify',
          _.get(
            error,
            'response.data.errors.general',
            'エラーが発生しました、画面を更新してやり直してください'
          )
        );
        throw error;
      } finally {
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
      }
    },
    async approveBilling({ ownerId, billingId }) {
      await this.$store.dispatch('notify/clearNotify');
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      try {
        await this.$store.dispatch(`billings/${APPROVE_BILLINGS}`, {
          billingId,
          ownerId
        });
      } catch (error) {
        await this.$store.dispatch(
          'notify/showErrorNotify',
          _.get(
            error,
            'response.data.errors.general',
            'エラーが発生しました、画面を更新してやり直してください'
          )
        );
        throw error;
      } finally {
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
      }
    },
    async fixBilling({ ownerId, billingId }) {
      if (
        !(await this.$refs.dialog.open({
          headline: '請求書の作成',
          text: '請求書の作成処理を行いますか？'
        }))
      ) {
        return;
      }
      await this.$store.dispatch('notify/clearNotify');
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      try {
        await this.$store.dispatch(`billings/${FIX_BILLINGS}`, {
          billingId,
          ownerId
        });
        await this.$store.dispatch(
          'notify/showNotify',
          '請求書の作成処理を開始しました。完了後にメールで通知します。'
        );
      } catch (error) {
        await this.$store.dispatch(
          'notify/showErrorNotify',
          _.get(
            error,
            'response.data.errors.general',
            'エラーが発生しました、画面を更新してやり直してください'
          )
        );
        throw error;
      } finally {
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
      }
    },
    async cancelBilling({ ownerId, billingId }) {
      if (
        !(await this.$refs.dialog.open({
          headline: '請求の取り消し',
          text: '請求を取り消してよろしいですか？'
        }))
      ) {
        return;
      }
      await this.$store.dispatch('notify/clearNotify');
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      try {
        await this.$store.dispatch(`billings/${CANCEL_BILLINGS}`, {
          billingId,
          ownerId
        });
      } catch (error) {
        await this.$store.dispatch(
          'notify/showErrorNotify',
          _.get(
            error,
            'response.data.errors.general',
            'エラーが発生しました、画面を更新してやり直してください'
          )
        );
        throw error;
      } finally {
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
      }
    },
    async payBilling({ ownerId, billingId }) {
      if (
        !(await this.$refs.dialog.open({
          headline: '支払い済みへの変更',
          text: '支払いを確定してもよろしいですか？'
        }))
      ) {
        return;
      }
      await this.$store.dispatch('notify/clearNotify');
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      try {
        await this.$store.dispatch(`billings/${PAY_BILLINGS}`, {
          billingId,
          ownerId
        });
        await this.$store.dispatch(
          'notify/showNotify',
          '精算処理を開始しました。完了後にメールで通知します。'
        );
      } catch (error) {
        await this.$store.dispatch(
          'notify/showErrorNotify',
          _.get(
            error,
            'response.data.errors.general',
            'エラーが発生しました、画面を更新してやり直してください'
          )
        );
        throw error;
      } finally {
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
      }
    },
    // async refundBilling({ ownerId, billingId }) {
    //   if (
    //     !(await this.$refs.dialog.open({
    //       headline: '返金対応',
    //       text: '返金対応を反映してもよろしいですか？'
    //     }))
    //   ) {
    //     return;
    //   }
    //   await this.$store.dispatch('notify/clearNotify');
    //   await this.$store.dispatch(`app/${START_LOADING}`, new Date());
    //   try {
    //     await this.$store.dispatch(`billings/${REFUND_BILLINGS}`, {
    //       billingId,
    //       ownerId
    //     });
    //   } catch (error) {
    //     await this.$store.dispatch(
    //       'notify/showErrorNotify',
    //       _.get(
    //         error,
    //         'response.data.errors.general',
    //         'エラーが発生しました、画面を更新してやり直してください'
    //       )
    //     );
    //     throw error;
    //   } finally {
    //     await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
    //   }
    // },
    async fetchOwner({ ownerId }) {
      const owner = ownerId ? ownerId : this.$store.state.user.owner;
      await this.$store.dispatch(`owner/${FETCH_OWNER}`, {
        owner
      });
    },
    showDialog({ title, text }) {
      this.title = title;
      this.text = text;
      this.dialog = true;
    },
    async requestIncreaseCreditLimits() {
      await this.$store.dispatch('notify/clearNotify');
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());

      try {
        await axios.post('/owners/request_increase_credit_limits');
        await this.$store.dispatch(
          'notify/showNotify',
          'ご利用可能枠の増枠をリクエストしました。'
        );
      } catch (err) {
        await this.$store.dispatch(
          'notify/showErrorNotify',
          _.get(
            err,
            'response.data.errors.general',
            'エラーが発生しました、画面を更新してやり直してください'
          )
        );
        throw err;
      } finally {
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        this.dialog = false;
      }
    }
  },
  async beforeMount() {
    const ownerId = this.$route.params.ownerId;
    if (this.isTomUser && !ownerId) {
      this.$router.push('/owners/billings');
      return;
    }

    const currentYearMonth = moment().format('YYYYMM');

    // this.$route.query.date には 202211 などが入る
    const yearMonth = this.$route.query.date || currentYearMonth;

    this.ownerId = ownerId;
    this.yearMonth = `${yearMonth}`;

    this.$watch('yearMonth', () => {
      this.$router.push({ query: { date: this.yearMonth } });
      this.fetchBilling({ ownerId });
    });

    await this.fetchBilling({ ownerId });
  }
};
</script>
