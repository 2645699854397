import axios from '@/api/common/axios';
import querystring from 'querystring';
import { API_LOG_FETCHED, API_LOGS_FETCHED } from '@/store/mutation-types';
import { FETCH_API_LOG, FETCH_API_LOGS } from '@/store/action-types';

export default {
  namespaced: true,
  state: {
    apiLog: { rawRequest: [], rawResponse: [] },
    apiLogs: [],
    total: 0
  },
  mutations: {
    [API_LOG_FETCHED](state, params) {
      state.apiLog = params.apiLog;
      if (params.apiLog.rawRequest && params.apiLog.rawResponse) {
        state.apiLog.rawRequest = JSON.stringify(
          params.apiLog.rawRequest,
          null,
          2
        ).split('\n');
        state.apiLog.rawResponse = JSON.stringify(
          params.apiLog.rawResponse,
          null,
          2
        ).split('\n');
      } else {
        state.apiLog.rawRequest = [];
        state.apiLog.rawResponse = [];
      }
    },
    [API_LOGS_FETCHED](state, params) {
      state.apiLogs = params.apiLogs;
      state.total = params.total;
    }
  },
  actions: {
    async [FETCH_API_LOG]({ commit }, params) {
      try {
        let apiLog = {};
        if (params.logId) {
          const res = await axios.get(`/api_logs/detail/${params.logId}`);
          apiLog = res.data.apiLog;
          if (!apiLog.rawRequest) {
            apiLog.rawRequest = 'No data';
          }
          if (!apiLog.rawResponse) {
            apiLog.rawResponse = 'No data';
          }
        } else {
          apiLog.rawRequest = '';
          apiLog.rawResponse = '';
        }
        commit(API_LOG_FETCHED, { apiLog });
      } catch (err) {
        commit(API_LOG_FETCHED, {
          webhookLog: {
            rawRequest: 'ログの取得ができませんでした',
            rawResponse: 'ログの取得ができませんでした'
          }
        });
        throw new Error(err.response.data.errors.general);
      }
    },
    async [FETCH_API_LOGS]({ commit }, params) {
      try {
        const apiSettingId = params.apiSettingId;
        delete params.apiSettingId;
        delete params.log_id;
        const res = await axios.get(
          `/api_logs/index/${apiSettingId}?${querystring.stringify(params)}`
        );
        const apiLogs = res.data.apiLogs;
        const total = res.data.total;
        commit(API_LOGS_FETCHED, { apiLogs, total });
      } catch (err) {
        throw new Error(err.response.data.errors.general);
      }
    }
  }
};
