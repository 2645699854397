<template>
  <v-dialog persistent v-model="state" max-width="290">
    <v-card>
      <v-card-title class="headline">{{ headline }}</v-card-title>
      <v-card-text>{{ text }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="close(false)">
          {{ negative }}
        </v-btn>
        <v-btn color="green darken-1" text @click="close(true)">
          {{ positive }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    state: false,
    headline: '',
    text: '',
    negative: 'いいえ',
    positive: 'はい',
    resolve: () => {}
  }),
  methods: {
    init({ headline, text }) {
      this.headline = headline;
      this.text = text;
    },
    open({ headline, text }) {
      if (headline) {
        this.headline = headline;
      }
      if (text) {
        this.text = text;
      }
      this.state = true;
      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
    close(result) {
      this.resolve(result);
      this.state = false;
      this.headline = '';
      this.text = '';
      this.resolve = () => {};
    }
  }
};
</script>
