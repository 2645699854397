import querystring from 'querystring';
import _ from 'lodash';

import { ITEM_FETCHED } from '../mutation-types';
import { DOWNLOAD_ITEMS_CSV_REQUESTS, FETCH_ITEMS } from '../action-types';
import axios from '../../api/common/axios';

export default {
  namespaced: true,
  state: {
    headers: ['バーコード / 商品名', '荷主管理コード', '在庫数'],
    headersForTomUser: [
      '荷主',
      'バーコード / 商品名',
      '荷主管理コード',
      '在庫数'
    ],
    stockLabels: ['全て', '有り', '無し'],
    sortLabels: [
      { text: '登録日 (古い順)', value: 'created_at:asc' },
      { text: '登録日 (新しい順)', value: 'created_at:desc' },
      { text: '最終入庫日 (古い順)', value: 'measured_at:asc' },
      { text: '最終入庫日 (新しい順)', value: 'measured_at:desc' },
      { text: '荷主管理コード (A-Z)', value: 'owner_item_code:asc' },
      { text: '荷主管理コード (Z-A)', value: 'owner_item_code:desc' },
      { text: '商品名 (A-Z)', value: 'name:asc' },
      { text: '商品名 (Z-A)', value: 'name:desc' },
      { text: '在庫数 (少ない順)', value: 'stock:asc' },
      { text: '在庫数 (多い順)', value: 'stock:desc' }
    ],
    items: [],
    total: 0
  },
  mutations: {
    [ITEM_FETCHED](state, { items, total }) {
      state.items = items;
      state.total = total;
    }
  },
  actions: {
    async [FETCH_ITEMS]({ commit }, query) {
      const errorMsg =
        'データ取得時にエラーがありました。セカイロジ担当者までお問い合わせください。';
      const response = await axios
        .get(`/items?${querystring.stringify(query)}`)
        .catch((e) => {
          throw new Error(_.get(e, 'response.data.errors.general', errorMsg));
        });

      if (_.get(response, 'data.status') === 'ng') {
        throw new Error(_.get(response, 'data.errors.general', errorMsg));
      }

      commit(ITEM_FETCHED, {
        items: response.data.items,
        total: response.data.total
      });
    },
    async [DOWNLOAD_ITEMS_CSV_REQUESTS](context, query) {
      const errorMsg =
        'データ取得時にエラーがありました。サポートへご連絡ください。';
      await axios
        .get(`/items/csv?${querystring.stringify(query)}`)
        .catch((e) => {
          throw new Error(_.get(e, 'response.data.errors.general', errorMsg));
        });
    }
  }
};
