<template>
  <div>
    <v-card max-width="600" class="mx-auto">
      <v-card-title class="headline">CSVアップロード</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-layout>
          <v-flex>
            検品作業実績からダウンロードしたCSVを選択してアップロードしてください。
          </v-flex>
        </v-layout>
        <v-layout>
          <CsvUploadForm :category="CATEGORY.LATER_PAY_SLIP" />
        </v-layout>
      </v-card-text>
    </v-card>
    <CsvUploadProgress
      :category="CATEGORY.LATER_PAY_SLIP"
      class="mt-6"
    ></CsvUploadProgress>
  </div>
</template>

<style></style>

<script>
import CsvUploadForm from '@/components/CsvUploadForm';
import CsvUploadProgress from '@/components/CsvUploadProgress';
import { CATEGORY } from '@/../lib/document/schema/csv_upload_states';

export default {
  components: {
    CsvUploadForm,
    CsvUploadProgress
  },
  data() {
    return {};
  },
  methods: {},
  computed: {
    CATEGORY: () => CATEGORY
  },
  async beforeMount() {}
};
</script>
