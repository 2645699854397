<template>
  <v-row justify="center">
    <v-dialog :value="dialog" persistent scrollable width="min(1024px, 100%)">
      <v-card>
        <v-card-text>
          <h3 class="mt-4">利用規約とプライバシーポリシーについて</h3>
          <p
            v-if="this.$store.state.user.agreementVersion"
            class="text--primary mt-4"
          >
            セカイロジの利用規約・プライバシーポリシーが変更されております。このままサービスのご利用を継続するには、改訂版に同意が必要となります。
            内容をご確認いただき、「同意して利用を開始する」ボタンで先に進んでください。
          </p>
          <p v-else class="text--primary mt-4">
            セカイロジのご利用を開始するにあたっては、利用規約・プライバシーポリシーへの同意が必要となります。
            内容をご確認いただき、「同意して利用を開始する」ボタンで先に進んでください。
          </p>
          <h4 class="mt-8">利用規約</h4>
          <iframe
            :src="this.termOfServiceLink"
            style="width: 100%; height: 300px; border: none"
          />
          <p class="text-right">
            <a
              :href="this.unhandledProductsLink"
              target="_blank"
              rel="noopener noreferrer"
              style="text-decoration: none"
            >
              取り扱い不可商品はこちら
              <v-icon x-small>launch</v-icon>
            </a>
          </p>
          <h4 class="mt-8">プライバシーポリシー</h4>
          <iframe
            :src="this.privacyPolicyLink"
            style="width: 100%; height: 300px; border: none"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" class="mt-2 mb-2 mr-4" @click="agree()">
            同意して利用を開始する
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import _ from 'lodash';
import axios from '@/api/common/axios';
import { START_LOADING, FINISH_LOADING, AGREE } from '@/store/action-types';

export default {
  data() {
    return {
      termOfServiceLink: '',
      privacyPolicyLink: '',
      unhandledProductsLink: ''
    };
  },
  async beforeMount() {
    const links = (await axios.get('/agreement-url.json')).data;
    this.termOfServiceLink = links.TERMS_OF_SERVICE_LINK;
    this.privacyPolicyLink = links.PRIVACY_POLICY_LINK;
    this.unhandledProductsLink = links.UNHANDLED_PRODUCTS_LINK;
  },
  computed: {
    dialog: (self) =>
      self.$store.state.user.isLoggedIn && self.$store.state.agreement.dialog
  },
  methods: {
    async agree() {
      if (
        !this.termOfServiceLink ||
        !this.privacyPolicyLink ||
        !this.unhandledProductsLink
      ) {
        await this.$store.dispatch(
          'notify/showErrorNotify',
          'エラーが発生しました、画面を更新してやり直してください'
        );
        return;
      }

      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      try {
        if (
          !this.termOfServiceLink ||
          !this.privacyPolicyLink ||
          !this.unhandledProductsLink
        ) {
          await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
          await this.$store.dispatch(
            'notify/showErrorNotify',
            'エラーが発生しました、画面を更新してやり直してください'
          );
          return;
        }
        await this.$store.dispatch(`agreement/${AGREE}`, {
          currentAgreementVersion: this.$store.state.agreement
            .currentAgreementVersion
        });
      } catch (error) {
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        await this.$store.dispatch(
          'notify/showErrorNotify',
          _.get(
            error,
            'response.data.errors.general',
            'エラーが発生しました、画面を更新してやり直してください'
          )
        );
        throw error;
      }
      await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
      await this.$store.dispatch(
        'notify/showNotify',
        '利用規約とプライバシーポリシーに同意しました'
      );
    }
  }
};
</script>
