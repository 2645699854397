// このファイルはクライアント側からも読み込まれるため，
// Node.jsでしか動かないコードを書くことは禁止する

'use strict';

const _ = require('lodash');

const CATEGORY_PATTERN = [
  'MANIFEST', // 入庫情報登録CSV
  'SHIPMENT', // 出荷情報登録CSV
  'CREATE_ITEM', // 商品情報登録CSV
  'UPDATE_ITEM', // 商品情報変更CSV
  'CHANGE_OWNER_ITEM_CODE', // 荷主管理コード変更CSV
  'ACTUAL_SHIPMENT', // キャリアの配送履歴CSV
  'LATER_PAY_SLIP' // 後納差出票を出力するための対象CSVアップロード
];

const CATEGORY = _.zipObject(CATEGORY_PATTERN, CATEGORY_PATTERN);

const STATE_PATTERN = ['SUCCESS', 'REVISION', 'FAILED'];

const STATE = _.zipObject(STATE_PATTERN, STATE_PATTERN);

const STATE_DICTIONARY = {
  SUCCESS: '成功',
  REVISION: '要修正',
  FAILED: '失敗'
};

const STATE_COLOR = {
  SUCCESS: 'success',
  REVISION: 'warning',
  FAILED: 'error'
};

module.exports = {
  CATEGORY_PATTERN,
  CATEGORY,
  STATE_PATTERN,
  STATE,
  STATE_DICTIONARY,
  STATE_COLOR
};
