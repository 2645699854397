<template>
  <v-container px-0>
    <v-row>
      <v-spacer />
      <v-col class="text-right">
        <v-btn
          text
          :href="API_DOCUMENT_LINK"
          target="_blank"
          rel="noopener noreferrer"
          color="primary"
        >
          <v-icon left dark>help</v-icon>
          APIドキュメント
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <h3 class="font-weight-bold subtitle-2 text-left mt-4">
              API基本設定
            </h3>
          </v-card-title>
          <v-card-text>
            <v-form @submit.prevent>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <div class="text-caption mt-4 text-right">
                      ※ API Secretを再生成すると既存のAPI
                      Secretは利用できなくなります。
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-simple-table>
                      <thead>
                        <tr>
                          <th>名前</th>
                          <th>APIトークン</th>
                          <th>作成日</th>
                          <th>ログを表示</th>
                          <th>アクション</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="apiSetting in $store.state.apiSettings
                            .apiSettings"
                          :key="'item-' + apiSetting._id"
                        >
                          <td>{{ apiSetting.name }}</td>
                          <td>************</td>
                          <td>{{ apiSetting.createdAt }}</td>
                          <td>
                            <v-btn
                              color="primary"
                              text
                              :to="`/settings/api/web_logs/${apiSetting._id}`"
                            >
                              ログを表示
                            </v-btn>
                          </td>
                          <td>
                            <v-menu left bottom offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                <div
                                  color="primary"
                                  v-bind="attrs"
                                  v-on="on"
                                  depressed
                                  tile
                                >
                                  <v-icon left>more_horiz</v-icon>
                                </div>
                              </template>
                              <v-list>
                                <v-list-item
                                  @click="
                                    apiSettingId = apiSetting._id;
                                    apiSettingName = apiSetting.name;
                                    isOpenTokenNameDialog = true;
                                  "
                                >
                                  <v-list-item-icon class="mr-4">
                                    <v-icon>edit</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title
                                    >名前の変更</v-list-item-title
                                  >
                                </v-list-item>
                              </v-list>
                              <v-list>
                                <v-list-item
                                  @click="
                                    refreshToken({
                                      id: apiSetting._id,
                                      name: apiSetting.name
                                    })
                                  "
                                >
                                  <v-list-item-icon class="mr-4">
                                    <v-icon>cached</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title
                                    >APIトークンを再生成する</v-list-item-title
                                  >
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-btn color="primary" @click="generateToken">
                      <v-icon>add</v-icon>APIトークンを追加する</v-btn
                    >
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <h3 class="font-weight-bold subtitle-2 text-left mt-4">
              Webhook設定
            </h3>
          </v-card-title>
          <v-card-text>
            <v-form @submit.prevent>
              <v-container>
                <v-row>
                  <v-col>
                    <v-simple-table>
                      <thead>
                        <tr>
                          <th>ステータス</th>
                          <th>URL</th>
                          <th>署名トークン</th>
                          <th>テスト</th>
                          <th>ログを表示</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="webhookSetting in $store.state.webhookSettings
                            .webhookSettings"
                          :key="'item-' + webhookSetting._id"
                        >
                          <td>
                            <v-btn
                              color="success"
                              v-if="webhookSetting.is_active"
                              elevation="0"
                              outlined
                              @click.stop="
                                showDialog({
                                  id: webhookSetting._id,
                                  title: 'ステータス変更の確認',
                                  text: 'ステータスを無効にします',
                                  command: deactivateStatus
                                })
                              "
                            >
                              有効
                            </v-btn>
                            <v-btn
                              v-else
                              color="red"
                              elevation="0"
                              outlined
                              @click.stop="
                                showDialog({
                                  id: webhookSetting._id,
                                  title: 'ステータス変更の確認',
                                  text: 'ステータスを有効にします',
                                  command: activateStatus
                                })
                              "
                            >
                              無効
                            </v-btn>
                          </td>
                          <td>{{ webhookSetting.url }}</td>
                          <td>
                            <ClipboardChip
                              :textForCopy="webhookSetting.signature_token"
                              textForDisplay="クリックしてコピーする"
                            ></ClipboardChip>
                          </td>
                          <td>
                            <v-btn
                              color="primary"
                              text
                              @click.stop="
                                showDialog({
                                  id: webhookSetting._id,
                                  title: 'Webhookテスト',
                                  text: 'Webhookのテスト配信をします',
                                  command: test
                                })
                              "
                              >Webhookをテスト</v-btn
                            >
                          </td>
                          <td>
                            <v-btn
                              color="primary"
                              text
                              :to="`/settings/api/webhook_logs/${webhookSetting._id}`"
                            >
                              ログを表示
                            </v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn color="primary" @click="isOpenWebhookSetting = true">
                      <v-icon>add</v-icon>Webhookを追加する</v-btn
                    >
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-overlay :value="isOpenTokenDialog">
      <v-container fluid fill-height>
        <v-layout row wrap align-center justify-center>
          <v-row>
            <v-col>
              <v-card class="mt-10 mx-auto">
                <v-card-title>
                  <h3 class="font-weight-bold subtitle-2 text-left mt-4">
                    APIトークンの追加
                  </h3>
                </v-card-title>
                <v-card-text>
                  <v-text-field
                    input
                    required
                    name="apiSettingName"
                    v-validate="'required|trimmed'"
                    data-vv-as="名前"
                    placeholder="名前"
                    :error-messages="errors.first('apiSettingName')"
                    v-model="apiSettingName"
                  ></v-text-field>
                  <v-sheet class="py-4">
                    <ClipboardChip
                      :textForCopy="token"
                      :textForDisplay="token.substr(0, 60) + '...'"
                    ></ClipboardChip>
                  </v-sheet>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="primary" @click="changeTokenName">
                    閉じる
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-layout>
      </v-container>
    </v-overlay>
    <v-overlay :value="isOpenTokenNameDialog">
      <v-container fluid fill-height>
        <v-layout row wrap align-center justify-center>
          <v-row>
            <v-col>
              <v-card class="mt-10 mx-auto">
                <v-card-title>
                  <h3 class="font-weight-bold subtitle-2 text-left mt-4">
                    APIトークンの名前の変更
                  </h3>
                </v-card-title>
                <v-card-text>
                  <v-text-field
                    input
                    required
                    name="apiSettingName"
                    v-validate="'required|trimmed'"
                    data-vv-as="名前"
                    placeholder="名前"
                    :error-messages="errors.first('apiSettingName')"
                    v-model="apiSettingName"
                  ></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="primary" @click="changeTokenName">
                    保存する
                  </v-btn>
                  <v-btn color="primary" @click="isOpenTokenNameDialog = false">
                    閉じる
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-layout>
      </v-container>
    </v-overlay>
    <v-overlay :value="isOpenWebhookSetting">
      <v-container fluid fill-height>
        <v-layout row wrap align-center justify-center>
          <v-row>
            <v-col>
              <v-card class="mt-10 mx-auto" width="300">
                <v-card-title>
                  <h3 class="font-weight-bold subtitle-2 text-left mt-4">
                    WebHookの追加
                  </h3>
                </v-card-title>
                <v-card-text>
                  <v-text-field
                    required
                    placeholder="URL"
                    v-model="webhookSettingUrl"
                  ></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="primary" @click="create"> 追加する </v-btn>
                  <v-btn color="primary" @click="isOpenWebhookSetting = false">
                    閉じる
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-layout>
      </v-container>
    </v-overlay>
    <v-dialog v-model="dialog" max-width="300">
      <v-card>
        <v-card-title class="headline">{{ title }}</v-card-title>
        <v-card-text>{{ text }}</v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="red" text @click="dialog = false"> キャンセル </v-btn>
          <v-btn color="teal" text @click="command">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style scoped>
.hidden-text {
  color: transparent;
  text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
}
</style>

<script>
import ClipboardChip from '@/components/chips/ClipboardChip';
import {
  FETCH_WEBHOOKS,
  CREATE_WEBHOOK,
  START_LOADING,
  FINISH_LOADING,
  CHANGE_STATUS,
  TEST_WEBHOOK,
  FETCH_API_TOKENS,
  GENERATE_API_TOKEN,
  REFRESH_API_TOKEN,
  CHANGE_API_TOKEN
} from '@/store/action-types';
import { API_DOCUMENT_LINK } from '@/../public/agreement-url';

export default {
  components: {
    ClipboardChip
  },
  $_veeValidate: {
    validator: 'new'
  },
  data() {
    return {
      isOpenTokenDialog: false,
      isOpenTokenNameDialog: false,
      isOpenWebhookSetting: false,
      token: '',
      apiSettingId: '',
      apiSettingName: '',
      webhookSettingUrl: '',
      selected: '',
      title: '',
      text: '',
      command: '',
      dialog: false,
      API_DOCUMENT_LINK
    };
  },
  computed: {},
  methods: {
    async create() {
      await this.$store.dispatch('notify/clearNotify');
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      try {
        await this.$store.dispatch(`webhookSettings/${CREATE_WEBHOOK}`, {
          url: this.webhookSettingUrl
        });
        await this.$store.dispatch(
          'notify/showNotify',
          'Webhookの設定を追加しました'
        );
        this.isOpenWebhookSetting = false;
      } catch (err) {
        await this.$store.dispatch('notify/showErrorNotify', err.message);
      }
      await this.$store.dispatch(`webhookSettings/${FETCH_WEBHOOKS}`);
      await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
    },
    async generateToken() {
      await this.$store.dispatch('notify/clearNotify');
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      try {
        const res = await this.$store.dispatch(
          `apiSettings/${GENERATE_API_TOKEN}`,
          {}
        );
        this.apiSettingId = res.split('_')[0];
        this.token = res;
        this.apiSettingName = '新しいトークン';
        this.isOpenTokenDialog = true;
      } catch (err) {
        await this.$store.dispatch('notify/showErrorNotify', err.message);
      }
      await this.$store.dispatch(`apiSettings/${FETCH_API_TOKENS}`);
      await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
    },
    async refreshToken({ id, name }) {
      await this.$store.dispatch('notify/clearNotify');
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      try {
        const res = await this.$store.dispatch(
          `apiSettings/${REFRESH_API_TOKEN}`,
          { apiSetting: id }
        );
        this.apiSettingId = res.split('_')[0];
        this.token = res;
        this.apiSettingName = name;
        this.isOpenTokenDialog = true;
      } catch (err) {
        await this.$store.dispatch('notify/showErrorNotify', err.message);
      }
      await this.$store.dispatch(`webhookSettings/${FETCH_WEBHOOKS}`);
      await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
    },
    async changeTokenName() {
      this.$validator.errors.clear();
      await this.$store.dispatch('notify/clearNotify');
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      if (await this.$validator.validate()) {
        try {
          await this.$store.dispatch(`apiSettings/${CHANGE_API_TOKEN}`, {
            apiSetting: this.apiSettingId,
            name: this.apiSettingName
          });
          this.isOpenTokenDialog = false;
          this.isOpenTokenNameDialog = false;
        } catch (err) {
          await this.$store.dispatch('notify/showErrorNotify', err.message);
        }
        await this.$store.dispatch(`apiSettings/${FETCH_API_TOKENS}`);
      }

      await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
    },
    async activateStatus() {
      await this.$store.dispatch(`webhookSettings/${CHANGE_STATUS}`, {
        webhookSetting: this.selected,
        willBeActive: true
      });
      await this.$store.dispatch(`webhookSettings/${FETCH_WEBHOOKS}`);
      this.dialog = false;
    },
    async deactivateStatus() {
      await this.$store.dispatch(`webhookSettings/${CHANGE_STATUS}`, {
        webhookSetting: this.selected,
        willBeActive: false
      });
      await this.$store.dispatch(`webhookSettings/${FETCH_WEBHOOKS}`);
      this.dialog = false;
    },
    showDialog({ id, title, text, command }) {
      this.selected = id ? id : '';
      this.title = title;
      this.text = text;
      this.command = command;
      this.dialog = true;
    },
    async test() {
      await this.$store.dispatch('notify/clearNotify');
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      try {
        await this.$store.dispatch(`webhookLogs/${TEST_WEBHOOK}`, {
          webhookSetting: this.selected
        });
        await this.$store.dispatch(
          'notify/showNotify',
          'Webhookのテストを送信しました'
        );
      } catch (err) {
        await this.$store.dispatch('notify/showErrorNotify', err.message);
      }
      this.dialog = false;
      await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
    }
  },
  async beforeMount() {
    await this.$store.dispatch(`webhookSettings/${FETCH_WEBHOOKS}`);
    await this.$store.dispatch(`apiSettings/${FETCH_API_TOKENS}`);
  },
  mounted() {}
};
</script>
