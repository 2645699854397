<template>
  <div>
    <v-card>
      <v-card-title class="headline">CSVアップロード</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-layout>
          <v-flex>
            商品情報を更新するためのCSVを選択してアップロードしてください。
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex v-if="!isTomUser">
            <CsvUploadForm :category="CATEGORY.UPDATE_ITEM" />
          </v-flex>
          <v-flex v-else class="text-center my-12">
            TOM荷主ユーザーはアップロード処理が行えません。
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-layout class="pa-2">
        <v-flex class="text-right">
          <v-icon size="20">help</v-icon>
          <v-btn
            text
            color="primary"
            href="https://docs.google.com/spreadsheets/d/1E4u4yZoCLE0gm-oz545dhwRPnO6Nal6dNX0Vz2XZrcs"
            target="_blank"
          >
            CSVテンプレート / サンプル / 参考情報
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card>
    <CsvUploadProgress
      :category="CATEGORY.UPDATE_ITEM"
      class="mt-6"
    ></CsvUploadProgress>
  </div>
</template>

<script>
import { CATEGORY } from '@/../lib/document/schema/csv_upload_states';
import CsvUploadForm from '@/components/CsvUploadForm';
import CsvUploadProgress from '@/components/CsvUploadProgress';

export default {
  components: {
    CsvUploadForm,
    CsvUploadProgress
  },
  computed: {
    CATEGORY: () => CATEGORY,
    isTomUser: (self) => self.$store.state.user.isTomUser
  }
};
</script>
