<template>
  <div>
    <MainHeader :title="pageTitle" :back-to="backToRoute"></MainHeader>
    <v-tabs background-color="transparent" grow v-if="isListView">
      <template v-for="tab in tabs">
        <v-tab :key="'tab-' + tab.name" :to="tab.path">
          {{ tab.name }}
        </v-tab>
      </template>
    </v-tabs>
    <v-scroll-x-reverse-transition mode="out-in">
      <router-view></router-view>
    </v-scroll-x-reverse-transition>
  </div>
</template>

<script>
import MainHeader from '@/components/layout/MainHeader';
import OwnersList from '@/components/owners/OwnersList';
import OwnersBillings from '@/components/owners/OwnersBillings';
import AccountRegistrationsList from '@/components/owners/AccountRegistrationsList';

const PATHS = {
  INDEX: '/owners/index',
  BILLINGS: '/owners/billings',
  BILLINGS_UPDATE: /\/owners\/.*\/billings\/update/,
  REGISTRATION: '/owners/registrations',
  REGISTRATION_FORM: '/owners/registrations/form'
};

export default {
  components: {
    MainHeader
  },
  data() {
    return {
      lastQuery: {},
      tabs: [
        {
          path: PATHS.INDEX,
          name: '荷主一覧',
          component: OwnersList
        },
        {
          path: PATHS.BILLINGS,
          name: '請求管理',
          component: OwnersBillings
        },
        {
          path: PATHS.REGISTRATION,
          name: '招待中荷主一覧',
          component: AccountRegistrationsList
        }
      ]
    };
  },
  watch: {
    $route(to) {
      if (to.path === PATHS.INDEX) {
        this.lastQuery = to.query;
      }
    }
  },
  computed: {
    pageTitle() {
      if (this.$route.path === PATHS.REGISTRATION_FORM) {
        return '新規荷主を追加';
      }
      return '';
    },
    isListView() {
      return (
        this.$route.path === PATHS.INDEX ||
        this.$route.path === PATHS.BILLINGS ||
        this.$route.path === PATHS.REGISTRATION
      );
    },
    backToRoute() {
      if (this.isListView) {
        return null;
      }
      if (this.$route.path.match(PATHS.BILLINGS_UPDATE)) {
        return {
          path: PATHS.BILLINGS,
          query: this.lastQuery
        };
      }

      return {
        path: PATHS.INDEX,
        query: this.lastQuery
      };
    }
  },
  created() {
    this.lastQuery = this.$route.query;
  }
};
</script>
