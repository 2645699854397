import querystring from 'querystring';
import _ from 'lodash';
import axios from '@/api/common/axios';

import { FEDEX_SLIPS_FETCHED } from '@/store/mutation-types';
import { FETCH_FEDEX_SLIPS, CLEAR_FEDEX_SLIPS } from '@/store/action-types';

export default {
  namespaced: true,
  state: {
    slipUrl: '',
    invoiceUrl: ''
  },
  mutations: {
    [FEDEX_SLIPS_FETCHED](state, { slipUrl, invoiceUrl }) {
      state.slipUrl = slipUrl;
      state.invoiceUrl = invoiceUrl;
    }
  },
  actions: {
    async [FETCH_FEDEX_SLIPS]({ commit }, query) {
      const errorMsg =
        'データ取得時にエラーがありました。セカイロジ担当者までお問い合わせください。';
      const response = await axios
        .get(`/warehouse/fedex_slips?${querystring.stringify(query)}`)
        .catch((e) => {
          throw new Error(_.get(e, 'response.data.errors.general', errorMsg));
        });

      if (_.get(response, 'data.status') === 'ng') {
        throw new Error(_.get(response, 'data.errors.general', errorMsg));
      }

      commit(FEDEX_SLIPS_FETCHED, {
        slipUrl: response.data.slipUrl,
        invoiceUrl: response.data.invoiceUrl
      });
    },
    [CLEAR_FEDEX_SLIPS]({ commit }) {
      commit(FEDEX_SLIPS_FETCHED, {
        slipUrl: '',
        invoiceUrl: ''
      });
    }
  }
};
