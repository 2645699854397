<template>
  <v-container>
    <v-row wrap>
      <v-col xs="12">
        <v-form>
          <v-row align="center" justify="center">
            <v-col cols="12">
              <v-card class="mb-4">
                <v-card-title>基本情報</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col sm="6">
                      <v-text-field
                        name="barcode"
                        v-model="barcode"
                        :error-messages="mergedError('barcode')"
                        label="バーコード"
                        v-validate="'required'"
                        data-vv-validate-on="change"
                        data-vv-as="バーコード"
                        hint="最大14桁の数字のみ。GTINコード（JAN、UPC、EAN等）がない商品の場合には「バーコード生成」と記載ください。"
                        persistent-hint
                      >
                        <v-chip
                          slot="append"
                          class="ma-2"
                          color="red"
                          label
                          outlined
                          small
                        >
                          必須
                        </v-chip>

                        <v-tooltip bottom slot="append-outer">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="text--secondary"
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              info
                            </v-icon>
                          </template>
                          <div>
                            1SKU単位で設定される商品識別コード。国際標準のGTINコード（JAN、UPC、EAN）、またはセカイロジ独自インハウスコードで記述
                          </div>
                        </v-tooltip>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="6">
                      <v-text-field
                        name="ownerItemCode"
                        v-model="ownerItemCode"
                        :error-messages="mergedError('ownerItemCode')"
                        label="荷主管理コード"
                        v-validate="'max:256'"
                        data-vv-validate-on="change"
                        data-vv-as="荷主管理コード"
                        hint=" 最大256桁のアルファベット及び数字"
                        persistent-hint
                      >
                        <v-tooltip bottom slot="append-outer">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="text--secondary"
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              info
                            </v-icon>
                          </template>
                          <div>バーコードとは別に荷主独自で管理するコード</div>
                        </v-tooltip>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="12">
                      <v-text-field
                        name="namesJaJp"
                        v-model="namesJaJp"
                        :error-messages="mergedError('namesJaJp')"
                        label="商品名（日本語）"
                        v-validate="'max:200'"
                        data-vv-validate-on="change"
                        data-vv-as="商品名（日本語）"
                        hint=" 最大200文字"
                        persistent-hint
                      >
                        <v-chip
                          slot="append"
                          class="ma-2"
                          color="red"
                          label
                          outlined
                          small
                        >
                          必須
                        </v-chip>
                        <v-tooltip bottom slot="append-outer">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="text--secondary"
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              info
                            </v-icon>
                          </template>
                          <div>日本国内宛の納品書には日本語商品名を記載</div>
                        </v-tooltip>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="12">
                      <v-text-field
                        name="namesEnUs"
                        v-model="namesEnUs"
                        :error-messages="mergedError('namesEnUs')"
                        label="商品名（英語）"
                        v-validate="'max:200'"
                        data-vv-validate-on="change"
                        data-vv-as="商品名（英語）"
                        hint=" 最大200文字"
                        persistent-hint
                      >
                        <v-chip
                          slot="append"
                          class="ma-2"
                          color="red"
                          label
                          outlined
                          small
                        >
                          海外宛必須
                        </v-chip>
                        <v-tooltip bottom slot="append-outer">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="text--secondary"
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              info
                            </v-icon>
                          </template>
                          <div>海外宛の納品書には英語商品名を記載</div>
                        </v-tooltip>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="6">
                      <v-text-field
                        name="note"
                        v-model="note"
                        :error-messages="mergedError('note')"
                        label="ノート"
                        data-vv-validate-on="change"
                        data-vv-as="ノート"
                        hint=" 最大1000文字"
                        persistent-hint
                      >
                        <v-tooltip bottom slot="append-outer">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="text--secondary"
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              info
                            </v-icon>
                          </template>
                          <div>
                            荷主用の商品に関する自由メモ欄（倉庫への指示や依頼としては利用不可。）
                          </div>
                        </v-tooltip>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card class="mb-4">
                <v-card-title>配送関連情報</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col sm="6">
                      <v-select
                        name="shipType"
                        v-model="shipType"
                        :error-messages="mergedError('shipType')"
                        label="出荷タイプ"
                        :items="SHIP_TYPE_LABEL_PATTERN"
                        v-validate="'required'"
                        data-vv-validate-on="change"
                        data-vv-as="出荷タイプ"
                        dense
                        persistent-hint
                      >
                        <v-chip
                          slot="append"
                          class="ma-2"
                          color="red"
                          label
                          outlined
                          small
                        >
                          必須
                        </v-chip>
                        <v-tooltip bottom slot="append-outer">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="text--secondary"
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              info
                            </v-icon>
                          </template>
                          <div>
                            梱包あり_混載可： <br />
                            <div class="ml-4 mb-4">
                              セカイロジ指定のダンボールに梱包して出荷。<br />
                              1つの出荷依頼で、複数または別のSKUを同梱して出荷して良い場合には、こちらを指定。<br />
                              ※通常はこちらを推奨
                            </div>
                            梱包あり_混載不可：<br />
                            <div class="ml-4 mb-4">
                              セカイロジ指定のダンボールに梱包して出荷。 <br />
                              1つの出荷依頼で、「1梱包1点」ずつ梱包が必要な場合にはこちらを指定。
                              <br />
                              ※個口が複数に分かれる分配送コストが高くなる傾向あり
                            </div>
                            梱包なし_伝票直貼り： <br />
                            <div class="ml-4">
                              商品がすでに入庫時点で梱包されており、出荷時もそのまま状態で発送伝票のみをカートンに貼り付けて出荷して問題ない場合にはこちらを指定
                            </div>
                          </div>
                        </v-tooltip>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="6">
                      <v-text-field
                        name="shipmentExpirationBufferDays"
                        v-model="shipmentExpirationBufferDays"
                        type="number"
                        :error-messages="
                          mergedError('shipmentExpirationBufferDays')
                        "
                        label="消費期限 出荷許容日数"
                        data-vv-validate-on="change"
                        data-vv-as="消費期限 出荷許容日数"
                        v-validate="'numeric'"
                        hint=" 日数のみ ※ 消費期限 / 賞味期限がない商品は記入不可"
                        persistent-hint
                      >
                        <v-tooltip bottom slot="append-outer">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="text--secondary"
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              info
                            </v-icon>
                          </template>
                          <div>
                            賞味期限／消費期限が設定されている商品に対し、期限の何日前まで出荷を許容するかを規定する日数。<br />
                            規定を超過した商品は引当可能在庫から除外
                          </div>
                        </v-tooltip>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card class="mb-4">
                <v-card-title>属性情報</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col sm="6">
                      <v-autocomplete
                        name="shippingCategory"
                        v-model="shippingCategory"
                        :error-messages="mergedError('shippingCategory')"
                        label="商品分類"
                        :items="
                          $store.state.shippingCategories.shippingCategories.map(
                            (v) => v.names.ja_jp
                          )
                        "
                        data-vv-validate-on="change"
                        v-validate="'required'"
                        data-vv-as="商品分類"
                        dense
                        persistent-hint
                      >
                        <v-chip
                          slot="append"
                          class="ma-2"
                          color="red"
                          label
                          outlined
                          small
                        >
                          必須
                        </v-chip>
                        <v-tooltip bottom slot="append-outer">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="text--secondary"
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              info
                            </v-icon>
                          </template>
                          <div>
                            国際輸送時の税関申告書情報、及び国内伝票における品目名として利用。貨物の分類情報
                          </div>
                        </v-tooltip>
                      </v-autocomplete>
                      <small>
                        ※
                        テキスト検索またはプルダウンから検索。登録可能な商品分類の一覧は
                        <a href="/shipping_categories/index" target="_blank">
                          こちら
                        </a>
                        <br />
                        ※
                        適切な選択肢が見当たらない場合には、サポート窓口までお問い合わせください。
                      </small>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="6">
                      <v-text-field
                        name="hsCode"
                        v-model="hsCode"
                        :error-messages="mergedError('hsCode')"
                        label="HSコード"
                        v-validate="'max:7'"
                        data-vv-validate-on="change"
                        data-vv-as="HSコード"
                        hint="最大7文字"
                        persistent-hint
                      >
                        <v-tooltip bottom slot="append-outer">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="text--secondary"
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              info
                            </v-icon>
                          </template>
                          <div>
                            国際輸送時の税関申告情報。<br />
                            「商品の名称及び分類についての統一システムに関する国際条約（HS条約）」に基づいて定められたコード番号。<br />
                            基本は共通6桁のコードで表現（ex:6403.99）
                          </div>
                        </v-tooltip>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="6">
                      <v-autocomplete
                        name="countryCode"
                        v-model="originCountry"
                        :error-messages="mergedError('originCountry')"
                        label="原産国"
                        :items="Object.keys(COUNTRIES).map((c) => COUNTRIES[c])"
                        dense
                      >
                        <v-tooltip bottom slot="append-outer">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="text--secondary"
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              info
                            </v-icon>
                          </template>
                          <div>
                            国際輸送時の税関申告情報。<br />
                            実質的に製品本体の生産が行われた国、または最終的な製造が行われた国
                            （「Made in xxxx」に該当するもの）
                          </div>
                        </v-tooltip>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="6">
                      <v-text-field
                        name="brand"
                        v-model="brand"
                        :error-messages="mergedError('brand')"
                        label="ブランド"
                        v-validate="'max:100'"
                        data-vv-validate-on="change"
                        data-vv-as="ブランド"
                        hint="最大100文字"
                        persistent-hint
                      >
                        <v-chip
                          slot="append"
                          class="ma-2"
                          color="red"
                          label
                          outlined
                          small
                        >
                          中国宛必須
                        </v-chip>
                        <v-tooltip bottom slot="append-outer">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="text--secondary"
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              info
                            </v-icon>
                          </template>
                          <div>国際輸送時の税関申告情報。商品のブランド名</div>
                        </v-tooltip>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="6">
                      <v-text-field
                        name="specification"
                        v-model="specification"
                        :error-messages="mergedError('specification')"
                        label="商品スペック"
                        v-validate="'max:255'"
                        data-vv-validate-on="change"
                        data-vv-as="商品スペック"
                        hint="最大255文字"
                        persistent-hint
                      >
                        <v-chip
                          slot="append"
                          class="ma-2"
                          color="red"
                          label
                          outlined
                          small
                        >
                          中国宛必須
                        </v-chip>
                        <v-tooltip bottom slot="append-outer">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="text--secondary"
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              info
                            </v-icon>
                          </template>
                          <div>
                            国際輸送時の税関申告情報。商品の使用用途や素材名等を記述。
                          </div>
                        </v-tooltip>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="6">
                      <v-text-field
                        name="url"
                        v-model="url"
                        :error-messages="mergedError('url')"
                        label="商品ページURL"
                        data-vv-validate-on="change"
                        data-vv-as="商品ページURL"
                        v-validate="'url'"
                        persistent-hint
                      >
                        <v-chip
                          slot="append"
                          class="ma-2"
                          color="red"
                          label
                          outlined
                          small
                        >
                          中国宛必須
                        </v-chip>
                        <v-tooltip bottom slot="append-outer">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="text--secondary"
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              info
                            </v-icon>
                          </template>
                          <div>
                            国際輸送時の税関申告情報。商品販売ページやカタログページのURL
                          </div>
                        </v-tooltip>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" v-if="mergedError('general').length">
              <v-alert
                color="error"
                text
                v-html="mergedError('general').split('\n').join('<br />')"
              >
              </v-alert>
            </v-col>
            <v-col cols="12">
              <v-btn
                text
                color="primary accent-4"
                class="v-btn--active"
                @click="post"
                block
              >
                商品を登録する
              </v-btn>
            </v-col>
            <v-col cols="12" v-if="isItemCreated">
              <v-btn
                text
                color="teal"
                class="mb-4 v-btn--active"
                to="/items/index"
                block
              >
                商品一覧画面へ戻る
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<style></style>

<script>
import axios from '@/api/common/axios';
import { mergeErrors } from '@/util/input';
import { JsonError } from '@/util/error';
import {
  START_LOADING,
  FINISH_LOADING,
  FETCH_SHIPPING_CATEGORIES
} from '@/store/action-types';
import COUNTRIES from '@/../lib/document/schema/countries';
import { SHIP_TYPE_LABEL_PATTERN } from '@/../lib/document/schema/items';

export default {
  $_veeValidate: {
    validator: 'new'
  },
  data() {
    return {
      COUNTRIES,
      SHIP_TYPE_LABEL_PATTERN,
      isItemCreated: false,
      barcode: '',
      ownerItemCode: '',
      namesJaJp: '',
      namesEnUs: '',
      note: '',
      originCountry: '',
      brand: '',
      specification: '',
      url: '',
      shipmentExpirationBufferDays: 0,
      shippingCategory: '',
      hsCode: '',
      shipType: '',
      serverErrors: {}
    };
  },

  async beforeMount() {
    // 全件取得するためにlimitに0を指定
    await this.$store.dispatch(
      `shippingCategories/${FETCH_SHIPPING_CATEGORIES}`,
      { limit: 0 }
    );
  },

  methods: {
    async clearError() {
      await this.$store.dispatch('notify/clearNotify');
      this.serverErrors = {};
    },

    mergedError(field) {
      return mergeErrors(this.$validator.errors, this.serverErrors, field);
    },

    async post() {
      await this.clearError();
      if (!(await this.$validator.validate())) {
        await this.$store.dispatch(
          'notify/showErrorNotify',
          '入力項目に誤りがあります。エラー表示されている項目を確認してください。'
        );
        return;
      }
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      try {
        await axios.post('/items/form', {
          barcode: this.barcode,
          ownerItemCode: this.ownerItemCode,
          namesJaJp: this.namesJaJp,
          namesEnUs: this.namesEnUs,
          note: this.note,
          originCountry: this.originCountry,
          brand: this.brand,
          specification: this.specification,
          url: this.url,
          shippingCategory: this.shippingCategory,
          hsCode: this.hsCode,
          shipType: this.shipType,
          shipmentExpirationBufferDays: this.shipmentExpirationBufferDays
        });
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        await this.$store.dispatch(
          'notify/showNotify',
          '商品情報を登録しました。'
        );
        this.isItemCreated = true;
      } catch (error) {
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        if (error.response.data.errors) {
          this.serverErrors = new JsonError(
            error.response.data.errors
          ).toJson();
          await this.$store.dispatch(
            'notify/showErrorNotify',
            '入力項目に誤りがあります。エラー表示されている項目を確認してください。'
          );
        } else {
          await this.$store.dispatch('notify/showErrorNotify', error.message);
        }
      }
    }
  }
};
</script>
