<template>
  <div>
    <v-form>
      <v-layout justify-center>
        <v-card width="480">
          <v-card-title>商品分類情報</v-card-title>
          <v-card-text>
            <v-text-field
              name="ja_jp"
              :value="ja_jp"
              :error-messages="errorMessages.ja_jp.join('\n')"
              label="商品分類名"
              hint="86文字以内の数字、記号、文字列"
              @input="updateJaJp"
              outlined
              persistent-hint
            />
            <v-text-field
              name="kana"
              :value="kana"
              :error-messages="errorMessages.kana.join('\n')"
              label="商品分類名(読み)"
              hint="ひらがな"
              @input="updateKana"
              outlined
              persistent-hint
            />
            <v-text-field
              name="en_us"
              :value="en_us"
              :error-messages="errorMessages.en_us.join('\n')"
              label="商品分類名(英語)"
              hint="86文字以内の英数字、記号、文字列"
              @input="updateEnUs"
              outlined
              persistent-hint
            />
            <v-text-field
              name="hs_code"
              :value="hs_code"
              :error-messages="errorMessages.hs_code.join('\n')"
              label="HSコード"
              hint="XXXX.XX のような文字列"
              @input="updateHSCode"
              outlined
              persistent-hint
            />
            <div class="mt-8">
              <v-btn
                color="primary"
                block
                elevation="0"
                @click="postShippingCategoryRequest"
              >
                商品分類を登録する
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-layout>
      <v-alert v-if="generalError" color="error" text>
        <div v-for="(message, index) of generalError" :key="index">
          {{ message }}
        </div>
      </v-alert>
    </v-form>
  </div>
</template>

<script>
import {
  START_LOADING,
  FINISH_LOADING,
  CREATE_SHIPPING_CATEGORY,
  VALIDATE_SHIPPING_CATEGORY
} from '@/store/action-types';
import {
  UPDATE_EN_US,
  UPDATE_JA_JP,
  UPDATE_KANA,
  UPDATE_HS_CODE,
  RESET_SHIPPING_CATEGORY
} from '@/store/mutation-types';
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState('shippingCategory', [
      'en_us',
      'ja_jp',
      'kana',
      'hs_code',
      'errorMessages'
    ]),
    generalError() {
      if (
        this.errorMessages &&
        this.errorMessages.general &&
        this.errorMessages.general.length
      ) {
        return this.errorMessages.general;
      }
      return null;
    }
  },
  methods: {
    // 入力値をvuexに渡す
    ...mapMutations('shippingCategory', {
      updateEnUs: UPDATE_EN_US,
      updateJaJp: UPDATE_JA_JP,
      updateKana: UPDATE_KANA,
      updateHSCode: UPDATE_HS_CODE,
      resetShippingCategory: RESET_SHIPPING_CATEGORY
    }),
    // VALIDATE_SHIPPING_CATEGORY actionをvalidateにマッピングする
    ...mapActions('shippingCategory', { validate: VALIDATE_SHIPPING_CATEGORY }),
    async clearError() {
      await this.$store.dispatch('notify/clearNotify');
    },
    /**
     * フライヤー登録時のAPIリクエストを送る
     */
    async postShippingCategoryRequest() {
      this.clearError();
      if (!(await this.validate())) {
        await this.$store.dispatch(
          'notify/showErrorNotify',
          '入力項目に誤りがあります。エラー表示されている項目を確認してください。'
        );
        return;
      }

      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      try {
        await this.$store.dispatch(
          `shippingCategory/${CREATE_SHIPPING_CATEGORY}`,
          {
            en_us: this.en_us,
            ja_jp: this.ja_jp,
            kana: this.kana,
            hs_code: this.hs_code
          }
        );
      } catch (error) {
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        await this.$store.dispatch(
          'notify/showErrorNotify',
          '入力項目に誤りがあります。エラー表示されている項目を確認してください。'
        );
        return;
      }
      this.resetShippingCategory();
      await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
      this.$router.push({
        path: '/shipping_categories/index',
        query: {
          notifyType: 'showNotify',
          notifyMessageCode: this.$router.MESSAGE_CODES
            .SHIPPING_CATEGORY_CREATE_SUCCESS
        }
      });
    }
  }
};
</script>
