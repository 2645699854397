<template>
  <v-container px-0>
    <v-row>
      <v-col>
        <v-card class="mb-2">
          <v-card-text>
            <v-flex xs4 class="mb-2">
              <h3 class="bold">
                ご契約者情報 | 荷主番号: {{ owner.unique_number }}
              </h3>
            </v-flex>

            <v-flex xs4>
              <v-radio-group
                v-model="owner.contract_type"
                label="契約形態"
                name="contractType"
                v-validate="'required'"
                data-vv-as="契約形態"
                :error-messages="contractTypeErrors"
                :readonly="readonly"
              >
                <v-radio
                  v-for="(value, key) in CONTRACT_TYPE_LABEL"
                  :key="key"
                  :label="value"
                  :value="key"
                ></v-radio>
              </v-radio-group>
            </v-flex>
            <v-flex xs4>
              <v-text-field
                input
                required
                :readonly="readonly"
                name="nameJaJp"
                :label="nameLabel"
                v-model="owner.names.ja_jp"
                v-validate="'required|max:20'"
                :error-messages="nameJaJpErrors"
                :data-vv-as="nameLabel"
              ></v-text-field>
            </v-flex>
            <v-flex xs4 v-show="isIndivisual">
              <v-text-field
                input
                required
                :readonly="readonly"
                name="organizationName"
                label="所属団体・サークル・屋号"
                v-model="owner.organization_name"
                v-validate="{ required: this.isIndivisual, max: 40 }"
                data-vv-as="所属団体・サークル・屋号"
                :error-messages="
                  organizationNameErrors && organizationNameErrors.length
                    ? [
                        ...organizationNameErrors,
                        '該当するものがない場合には「個人事業主」と記入ください'
                      ]
                    : ''
                "
                :error-count="
                  ((organizationNameErrors && organizationNameErrors.length) ||
                    0) + 1
                "
              ></v-text-field>
            </v-flex>
            <v-flex xs4 v-show="isCorporate">
              <v-text-field
                input
                required
                :readonly="readonly"
                name="department"
                label="部署名"
                v-model="owner.department"
                v-validate="{ required: this.isCorporate, max: 40 }"
                data-vv-as="部署名"
                :error-messages="departmentErrors"
              ></v-text-field>
            </v-flex>
            <v-flex xs4 v-if="isCorporate">
              <v-text-field
                input
                required
                :readonly="readonly"
                name="staffName"
                label="御担当者名"
                v-model="owner.staff_name"
                v-validate="{ required: this.isCorporate, max: 40 }"
                data-vv-as="御担当者名"
                :error-messages="staffNameErrors"
              ></v-text-field>
            </v-flex>
            <v-flex xs4>
              <v-text-field
                input
                required
                :readonly="readonly"
                name="postalCode"
                label="郵便番号"
                v-model="owner.postal_code"
                v-validate="{ required: true, max: 8, regex: /^[\d-]+$/ }"
                :error-messages="postalCodeErrors"
                data-vv-as="郵便番号"
              ></v-text-field>
            </v-flex>
            <v-flex xs10>
              <v-text-field
                input
                required
                :readonly="readonly"
                name="address"
                label="住所"
                v-model="owner.address"
                v-validate="'required|max:60'"
                :error-messages="addressErrors"
                data-vv-as="住所"
              ></v-text-field>
            </v-flex>
            <v-flex xs4>
              <v-text-field
                input
                required
                :readonly="readonly"
                name="phoneNumber"
                label="電話番号"
                v-model="owner.phone_number"
                v-validate="'required|max:11|numeric'"
                :error-messages="phoneNumberErrors"
                data-vv-as="電話番号"
              ></v-text-field>
            </v-flex>
            <v-flex xs4>
              <v-text-field
                input
                required
                :readonly="readonly"
                name="email"
                label="ご連絡先メールアドレス"
                v-model="owner.email"
                v-validate="'required|max:40'"
                :error-messages="emailErrors"
                data-vv-as="ご連絡先メールアドレス"
              ></v-text-field>
            </v-flex>
          </v-card-text>

          <v-card-text>
            <h3 class="bold">メール送付先</h3>
            <template v-for="(email, m) in this.owner.notification.emails">
              <v-layout :key="m">
                <v-flex class="mr-1" xs6>
                  <v-text-field
                    input
                    required
                    :readonly="readonly"
                    :name="'email' + m"
                    :value="email"
                    v-validate="'required|email'"
                    :error-messages="getEmailErrors(m)"
                    data-vv-as="このメールアドレス"
                    @input="editEmail({ email: $event, index: m })"
                  ></v-text-field>
                </v-flex>
                <v-layout
                  class="pb-2"
                  justify-start="justify-start"
                  align-end="align-end"
                  v-if="!readonly"
                >
                  <v-btn
                    text
                    @click.stop="
                      emailDialog = true;
                      emailOrder = m;
                    "
                  >
                    <v-icon>delete</v-icon>
                  </v-btn>
                </v-layout>
              </v-layout>
            </template>
          </v-card-text>
          <v-card-actions v-if="!readonly">
            <v-btn text small @click="addNewEmail()">
              <v-icon>add</v-icon>
              メール送付先を追加
            </v-btn>
          </v-card-actions>

          <v-card-text>
            <h3 class="bold">メール通知設定</h3>
            <v-switch
              v-for="key in Object.keys(FLAG_LABELS)"
              :key="`flags-${key}`"
              :label="FLAG_LABELS[key]"
              :input-value="owner.notification.flags[key]"
              :readonly="readonly"
              @change="switchedFlag({ key, flag: $event })"
            ></v-switch>
          </v-card-text>
          <v-card-text v-if="!isDeletable && !initialize">
            <h3 class="bold">ステータス</h3>
            <v-card-text>
              {{ convertOwnerStatus(this.owner.state) }}
            </v-card-text>
            <h3 class="bold">削除日</h3>
            <v-card-text>
              {{
                this.owner.stopped_at
                  ? moment(this.owner.stopped_at)
                      .add(30, 'days')
                      .tz('Asia/Tokyo')
                      .format('YYYY/MM/DD')
                  : ''
              }}
            </v-card-text>
          </v-card-text>

          <v-card-actions v-if="!readonly">
            <v-btn @click.stop="updateDialog = true" color="primary" block>
              {{ this.operationName }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card-actions v-if="!this.initialize && isDeletable">
          <v-btn @click.stop="openDeleteOwnerDialog()" color="error">
            退会手続き
          </v-btn>
        </v-card-actions>

        <v-card-actions v-if="!this.initialize && isTomUser">
          <v-btn
            @click.stop="openToggleOwnerUnavailableDialog()"
            color="warning"
          >
            {{ this.toggleOwnerUnavailableDialogButtonText }}
          </v-btn>
        </v-card-actions>

        <v-dialog v-model="deleteOwnerDialog" max-width="800">
          <v-card>
            <v-card-title class="headline">退会の確認</v-card-title>
            <v-card-text>こちらの操作は取り消しができません</v-card-text>
            <v-card-text
              >個人情報を含むセカイロジの各種ご利用履歴は退会から30日後に完全に削除されます。</v-card-text
            >
            <v-card-text
              >退会完了後は過去の履歴の閲覧も不可となりますので、退会後も必要な情報は予めご自身でダウンロードし、バックアップいただきますようお願いいたします。</v-card-text
            >
            <v-card-text v-if="this.validateErrors.length > 0"
              >以下の理由で退会できません。</v-card-text
            >
            <v-card-text
              v-for="(message, index) of this.validateErrors"
              :key="index"
              class="red--text"
            >
              {{ message }}
            </v-card-text>

            <v-card-actions>
              <v-btn color="red" text @click="cancelDelete()">
                キャンセル
              </v-btn>
              <div v-if="isTomUser">
                <v-btn
                  color="teal"
                  text
                  :disabled="this.validateErrors.length > 0"
                  @click="deleteOwner()"
                >
                  退会する
                </v-btn>
              </div>
              <div v-else>
                <v-btn
                  color="teal"
                  text
                  :disabled="this.validateErrors.length > 0"
                  @click="
                    deleteOwnerDialog = false;
                    deleteConfirmDialog = true;
                  "
                >
                  次へ
                </v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="deleteConfirmDialog" max-width="800">
          <v-card>
            <v-card-title class="headline">アンケート</v-card-title>
            <v-card-text>
              退会理由をお聞かせください。
              <v-radio-group
                :readonly="readonly"
                name="quitReasonType"
                v-model="quitReasonType"
                v-validate="'required'"
                data-vv-as="退会理由"
                :error-messages="quitReasonTypeErrors"
              >
                <v-radio
                  label="自社倉庫運用への切り替え"
                  value="SWITCH_INHOUSE"
                ></v-radio>
                <v-radio
                  label="他社3PLへの乗り換え"
                  value="SWITCH_OTHER"
                ></v-radio>
                <v-radio
                  label="自社サービスのクローズまたは商品の販売終了"
                  value="TERMINATE"
                ></v-radio>
                <v-radio
                  label="コスト面での不満"
                  value="COST_DISSATISFACTION"
                ></v-radio>
                <v-radio
                  label="サービス面での不満"
                  value="SERVICE_DISSATISFACTION"
                ></v-radio>
                <v-radio label="その他" value="OTHER"></v-radio>
              </v-radio-group>
              <v-textarea
                v-if="this.isRequireComment"
                v-validate="this.isRequireComment ? 'required' : ''"
                data-vv-as="具体的な内容"
                name="quitReasonComment"
                outlined
                v-model="quitReasonComment"
                auto-grow
                label="具体的な内容をお聞かせください"
                :error-messages="quitReasonCommentErrors"
              ></v-textarea>
            </v-card-text>
            <v-card-text>
              最後に弊社サービスに対するご意見・コメント等ありましたらご自由に記載ください</v-card-text
            >
            <v-card-text>
              <v-textarea
                outlined
                v-model="quitOtherComment"
                auto-grow
              ></v-textarea>
            </v-card-text>
            <v-card-actions>
              <v-btn color="red" text @click="cancelDelete()">
                キャンセル
              </v-btn>
              <v-btn color="teal" text @click="deleteOwner()"> 退会する </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="deleteCompleteDialog" max-width="800">
          <v-card>
            <v-card-title class="headline"
              >退会手続きが完了しました。</v-card-title
            >
            <v-card-text
              >数秒後にトップページへリダイレクトします。</v-card-text
            >
          </v-card>
        </v-dialog>
        <v-dialog v-model="emailDialog" max-width="300">
          <v-card>
            <v-card-title class="headline">削除の確認</v-card-title>
            <v-card-text>メールアドレスを削除します</v-card-text>
            <v-card-actions>
              <v-btn color="red" text @click="emailDialog = false">
                キャンセル
              </v-btn>
              <v-btn color="teal" text @click="deleteEmail()"> OK </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="updateDialog" max-width="300">
          <v-card>
            <v-card-title class="headline">
              {{ this.operationName }}の確認
            </v-card-title>
            <v-card-text>
              入力された情報で{{ this.operationName }}します
            </v-card-text>
            <v-card-actions>
              <v-btn color="red" text @click="updateDialog = false">
                キャンセル
              </v-btn>
              <v-btn color="teal" text @click="updateOwner()">OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="toggleOwnerUnavailableDialog" max-width="500">
          <v-card>
            <v-card-title class="headline">
              {{ this.toggleOwnerUnavailableDialogTitle }}
            </v-card-title>
            <v-card-text>
              {{ this.toggleOwnerUnavailableDialogText }}</v-card-text
            >
            <v-card-actions>
              <v-btn
                color="red"
                text
                @click="toggleOwnerUnavailableDialog = false"
              >
                キャンセル
              </v-btn>
              <v-btn color="teal" text @click="toggleOwnerUnavailable()">{{
                this.toggleOwnerUnavailableDialogActionText
              }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import { FLAG_LABELS } from '@/../lib/document/schema/notification_settings';
import {
  CONTRACT_TYPE,
  CONTRACT_TYPE_LABEL,
  ACCOUNT_STATUS
} from '@/../lib/document/schema/owners';
import { mergeErrors } from '@/util/input';
import {
  START_LOADING,
  FINISH_LOADING,
  FETCH_OWNER,
  UPDATE_OWNER,
  FETCH_MANIFESTS,
  FETCH_SHIPMENTS,
  FETCH_ITEMS,
  TOGGLE_OWNER_UNAVAILABLE
} from '@/store/action-types';
import { AGREE_SUCCESS } from '@/store/mutation-types';
import axios from '@/api/common/axios';
import ownersSchema from '@/../lib/document/schema/owners';
import moment from 'moment-timezone';

export default {
  props: {
    initialize: {
      type: Boolean,
      default: false
    },
    ownerId: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  $_veeValidate: {
    validator: 'new'
  },
  async mounted() {
    let value;
    if (this.ownerId) {
      value = { owner: this.ownerId };
    } else {
      value = this.$store.state.user;
    }

    await this.fetchOwner(value);
    // 利用規約に同意前にfetchOwnerしてもownerが取れないため，同意後にownerを取り直す
    this.$store.subscribe((mutation) => {
      if (mutation.type === `user/${AGREE_SUCCESS}`) {
        this.fetchOwner(value);
      }
    });
    await this.$validator.validate();
  },
  data() {
    return {
      FLAG_LABELS: FLAG_LABELS,
      CONTRACT_TYPE_LABEL,
      emailOrder: 0,
      updateDialog: false,
      emailDialog: false,
      deleteOwnerDialog: false,
      deleteConfirmDialog: false,
      deleteCompleteDialog: false,
      toggleOwnerUnavailableDialog: false,
      quitReasonType: '',
      quitReasonComment: '',
      quitOtherComment: '',
      validateErrors: [],
      serverErrors: {}
    };
  },
  computed: {
    ...[
      'contractType',
      'nameJaJp',
      'nameEnUs',
      'address',
      'phoneNumber',
      'postalCode',
      'email',
      'organizationName',
      'department',
      'staffName',
      'isSendOriginal',
      'invoiceName',
      'invoiceDepartment',
      'invoiceStaffName',
      'invoiceAddress',
      'invoicePhoneNumber',
      'invoicePostalCode',
      'quitReasonType',
      'quitReasonComment'
    ].reduce((action, propName) => {
      // propName + 'Errors' という名前でエラー文字列を取得するcomputed関数を作成する
      action[propName + 'Errors'] = function () {
        return mergeErrors(this.$validator.errors, this.serverErrors, propName);
      };
      return action;
    }, {}),
    isTomUser: (self) => self.$store.state.user.isTomUser,
    operationName: function () {
      return this.initialize ? '登録' : '更新';
    },
    isCorporate: function () {
      return this.owner.contract_type === CONTRACT_TYPE.CORPORATE;
    },
    isIndivisual: function () {
      return this.owner.contract_type === CONTRACT_TYPE.INDIVIDUAL;
    },
    isRequireComment: function () {
      return (
        this.quitReasonType === 'OTHER' ||
        this.quitReasonType === 'SERVICE_DISSATISFACTION' ||
        this.quitReasonType === 'COST_DISSATISFACTION'
      );
    },
    isDeletable: function () {
      return this.owner.state && this.owner.state === 'AVAILABLE';
    },
    nameLabel: function () {
      if (this.isIndivisual) {
        return '個人名';
      }
      return '会社名';
    },
    ...mapState({
      owner: (state) => state.owner
    }),
    toggleOwnerUnavailableDialogButtonText: function () {
      return this.owner.account_status
        ? '荷主機能の一時停止（閲覧のみに制限）を解除'
        : '荷主機能の一時停止（閲覧のみに制限）';
    },
    toggleOwnerUnavailableDialogTitle: function () {
      return this.owner.account_status
        ? '荷主機能の一時停止解除の確認'
        : '荷主機能の一時停止の確認';
    },
    toggleOwnerUnavailableDialogText: function () {
      return this.owner.account_status
        ? '荷主機能の一時停止を解除しますか'
        : '荷主機能を一時停止しますか';
    },
    toggleOwnerUnavailableDialogActionText: function () {
      return this.owner.account_status ? '解除する' : '停止する';
    }
  },
  methods: {
    async fetchOwner(value) {
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      await this.$store
        .dispatch(`owner/${FETCH_OWNER}`, value)
        .then(async () => {
          await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        })
        .catch(async (e) => {
          await this.$store.dispatch(
            'notify/showErrorNotify',
            e.response.data.errors.general.msg
          );
          await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        });
    },
    getEmailErrors: function (index) {
      return mergeErrors(
        this.$validator.errors,
        this.serverErrors,
        'email' + index
      );
    },
    convertOwnerStatus(state) {
      return ownersSchema.STATE_LABEL[state];
    },
    editEmail({ email, index }) {
      const notification = { ...this.owner.notification };
      notification.emails[index] = email;
      this.owner.notification = { ...this.owner.notification, ...notification };
    },
    cancelDelete: async function () {
      this.deleteConfirmDialog = false;
      this.deleteOwnerDialog = false;
    },
    deleteEmail: async function () {
      this.emailDialog = false;
      const notification = { ...this.owner.notification };
      notification.emails.splice(this.emailOrder, 1);
      this.owner.notification = {
        ...this.owner.notification,
        ...notification
      };
      await this.$validator.errors.clear();
      await this.$validator.validate();
    },
    addNewEmail: function () {
      const notification = { ...this.owner.notification };
      notification.emails.push('');
      this.owner.notification = { ...this.owner.notification, ...notification };
    },
    openDeleteOwnerDialog: async function () {
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      await this.$store.dispatch(`manifests/${FETCH_MANIFESTS}`, {
        filter_status: 'WAIT',
        limit: 0, // 無制限
        filter_owner: this.owner.id
      });
      await this.$store.dispatch(`shipments/${FETCH_SHIPMENTS}`, {
        filter_shipment_states: 'APPROVED',
        limit: 0, // 無制限
        filter_owner: this.owner.id
      });
      await this.$store.dispatch(`items/${FETCH_ITEMS}`, {
        filter_stock: '有り',
        limit: 0, // 無制限
        filter_owner: this.owner.id
      });

      this.validateErrors = [];
      if (
        this.$store.state.shipments &&
        this.$store.state.shipments.shipmentRequests.length > 0
      ) {
        this.validateErrors.push(
          `出荷作業中の依頼が${this.$store.state.shipments.shipmentRequests.length}件ございます。すべてキャンセルするか、出荷完了後の当月請求書の精算後に再度お試しください。`
        );
      }
      if (
        this.$store.state.manifests &&
        this.$store.state.manifests.manifests.length > 0
      ) {
        this.validateErrors.push(
          `入庫待ちの依頼が${this.$store.state.manifests.manifests.length}件ございます。すべての入庫依頼をキャンセルのうえ再度お手続きください。`
        );
      }

      if (
        this.$store.state.items.items &&
        this.$store.state.items.items.length > 0
      ) {
        this.validateErrors.push(
          `倉庫保管中の在庫が${this.$store.state.items.items.length}件ございます。すべての在庫を返品発送、または廃棄完了後に再度お手続きください。`
        );
      }
      await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
      this.deleteOwnerDialog = true;
    },
    deleteOwner: async function () {
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      try {
        if (!this.$store.state.user.isTomUser) {
          if (
            !(await this.$validator.validate('quitReasonType')) ||
            (this.isRequireComment &&
              !(await this.$validator.validate('quitReasonComment')))
          ) {
            await this.$store.dispatch(
              'notify/showErrorNotify',
              '入力項目に誤りがあります。修正して再度実行して下さい。'
            );
            await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
            return;
          }
        }
        const quitServey = {
          reason_type: this.quitReasonType,
          reason_comment: this.quitReasonComment,
          other_comment: this.quitOtherComment
        };
        const body = this.$store.state.user.isTomUser
          ? { owner: this.owner.id }
          : { quit_servey: quitServey };
        await axios.post('/owners/disable', body);
        this.deleteOwnerDialog = false;
        this.deleteConfirmDialog = false;
        this.deleteCompleteDialog = true;

        if (!this.$store.state.user.isTomUser) {
          setTimeout(async function () {
            window.location.href = '/login';
          }, 3000);
        }
      } catch (e) {
        await this.$store.dispatch(
          'notify/showErrorNotify',
          e.response.data.errors.general
        );
      }
      await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
    },

    switchedFlag: function ({ key, flag }) {
      const notification = { ...this.owner.notification };
      notification.flags[key] = flag;
      this.owner.notification = { ...this.owner.notification, ...notification };
    },
    updateOwner: async function () {
      this.updateDialog = false;
      this.serverErrors = {};
      this.$validator.errors.clear();
      if (this.$store.state.user.category !== 'MANAGER') {
        await this.$store.dispatch(
          'notify/showErrorNotify',
          '荷主情報の更新権限がありません。'
        );
        return;
      }
      if (!(await this.$validator.validate())) {
        await this.$store.dispatch(
          'notify/showErrorNotify',
          '入力項目に誤りがあります。修正して再度実行して下さい。'
        );
        return;
      } else {
        await this.$store.dispatch(`app/${START_LOADING}`, new Date());
        await this.$store
          .dispatch(`owner/${UPDATE_OWNER}`, this.owner)
          .then(async () => {
            if (this.initialize) {
              // 初期設定後に支払い・請求書名義設定へ進む
              this.$router.push(`/owners/${this.owner.id}/initialize/payments`);
              // トップへスクロール
              await this.$vuetify.goTo(0);
            } else {
              await this.$store.dispatch(
                'notify/showNotify',
                '登録情報を変更しました。'
              );
            }
            await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
          })
          .catch(async (e) => {
            this.serverErrors = {
              ...this.serverErrors,
              ...e.response.data.errors
            };
            await this.$store.dispatch(
              'notify/showErrorNotify',
              this.serverErrors.general.msg || this.serverErrors.general
            );
            await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
          });
      }
    },
    copyToInvoiceAddress: function () {
      [
        'address',
        'postal_code',
        'phone_number',
        'department',
        'staff_name'
      ].forEach((prop) => {
        this.$set(this.owner.invoice, prop, this.owner[prop]);
      });
      // invoice.nameがowner.namesの構造と異なるので別にコピー
      this.$set(this.owner.invoice, 'name', this.owner.names.ja_jp);
    },
    openToggleOwnerUnavailableDialog: function () {
      this.toggleOwnerUnavailableDialog = true;
    },
    toggleOwnerUnavailable: async function () {
      this.toggleOwnerUnavailableDialog = false;
      const body = {};
      if (!this.owner.account_status) {
        body.accountStatus = ACCOUNT_STATUS.READ_ONLY;
      } else if (this.owner.account_status === ACCOUNT_STATUS.READ_ONLY) {
        body.accountStatus = 'UNLIMITED';
      } else {
        await this.$store.dispatch(
          'notify/showNotify',
          'accountStatusが不正です。Devチームにお問い合わせください。'
        );
        return;
      }

      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      body.ownerId = this.ownerId;

      await this.$store
        .dispatch(`owner/${TOGGLE_OWNER_UNAVAILABLE}`, body)
        .then(async () => {
          await this.$store.dispatch(
            'notify/showNotify',
            'ステータスを変更しました。'
          );
        })
        .catch(async (err) => {
          let errMsg =
            'サーバーエラーが発生しました。時間をおいて再度お試しください。改善しなければお問い合わせください。';
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.errors &&
            err.response.data.errors.general
          ) {
            errMsg = err.response.data.errors.general;
          }
          await this.$store.dispatch('notify/showErrorNotify', errMsg);
        })
        .finally(async () => {
          await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        });
    },
    moment
  }
};
</script>
