<template>
  <v-container>
    <LatestRelease></LatestRelease>
  </v-container>
</template>

<script>
import LatestRelease from '../components/LatestRelease';

export default {
  components: {
    LatestRelease
  }
};
</script>
