import _ from 'lodash';
import axios from '@/api/common/axios';

import {
  SLIP_TEMPLATE_FETCHED,
  SLIP_TEMPLATES_FETCHED
} from '@/store/mutation-types';
import {
  FETCH_SLIP_TEMPLATE,
  FETCH_SLIP_TEMPLATES,
  REGISTER_SLIP_TEMPLATE,
  DELETE_SLIP_TEMPLATE,
  UPLOAD_LOGO_IMAGE
} from '@/store/action-types';
import querystring from 'querystring';

export default {
  namespaced: true,
  state: {
    titles: [],
    detail: {},
    details: []
  },
  mutations: {
    [SLIP_TEMPLATES_FETCHED](state, { titles, details }) {
      state.titles = titles;
      state.details = details;
    },
    [SLIP_TEMPLATE_FETCHED](state, { detail }) {
      state.detail = detail;
    }
  },
  actions: {
    /**
     * Slip Templateの一覧を取得する
     */
    async [FETCH_SLIP_TEMPLATES]({ commit }, query) {
      const errorMsg =
        'データ取得時にエラーがありました。セカイロジ担当者までお問い合わせください。';
      const response = await axios
        .get(`/slip_templates?${querystring.stringify(query)}`)
        .catch((e) => {
          throw new Error(_.get(e, 'response.data.errors.general', errorMsg));
        });

      const { titles, details } = _.get(
        response,
        'data.currentSlipTemplates',
        []
      ).reduce(
        (a, c) => ({
          titles: [...a.titles, c.name],
          details: [
            ...a.details,
            {
              _id: c._id,
              name: c.name,
              title: c.title,
              ownerInfo: c.owner_info,
              header: {
                barcode: c.header.barcode,
                productTitle: c.header.product_title,
                qty: c.header.qty,
                amount: c.header.amount,
                totalAmount: c.header.total_amount
              },
              lang: c.lang,
              logo: c.logo,
              footer: c.footer,
              isDefaultSlip: c.is_default_slip,
              isDeletable: c.is_deletable
            }
          ]
        }),
        { titles: [], details: [] }
      );

      commit(SLIP_TEMPLATES_FETCHED, { titles, details });
    },
    /**
     * 任意のSlip Templateをひとつだけ取得する
     */
    async [FETCH_SLIP_TEMPLATE]({ commit }, { slipTemplateId }) {
      const errorMsg =
        'データ取得時にエラーがありました。セカイロジ担当者までお問い合わせください。';
      const response = await axios
        .get(`/slip_templates/${slipTemplateId}`)
        .catch((e) => {
          throw new Error(_.get(e, 'response.data.errors.general', errorMsg));
        });

      if (!response.data.slipTemplate) {
        throw new Error(
          'データ取得時にエラーがあり、ユニークに情報の取得ができませんでした。セカイロジ担当者までお問い合わせください。'
        );
      }
      const template = response.data.slipTemplate;
      const detail = {
        _id: template._id,
        templateName: template.name,
        slipTitle: template.title,
        ownerInfo: template.owner_info,
        header: {
          barcode: template.header.barcode,
          productTitle: template.header.product_title,
          qty: template.header.qty,
          amount: template.header.amount,
          totalAmount: template.header.total_amount
        },
        lang: template.lang,
        logo: template.logo,
        footer: template.footer,
        isDefaultSlip: template.is_default_slip,
        isDeletable: template.is_deletable
      };
      commit(SLIP_TEMPLATE_FETCHED, { detail });
    },
    /**
     * SlipTemplateを登録/更新する
     */
    async [REGISTER_SLIP_TEMPLATE](context, { params }) {
      const errorMsg =
        'データ取得時にエラーがありました。セカイロジ担当者までお問い合わせください。';
      await axios.post('/slip_templates', { params }).catch((e) => {
        throw new Error(_.get(e, 'response.data.errors.general', errorMsg));
      });
    },
    /**
     * SlipTemplateを削除する
     */
    async [DELETE_SLIP_TEMPLATE](context, { slipTemplateId }) {
      await axios.delete(`/slip_templates/${slipTemplateId}`).catch((err) => {
        const message =
          (err.response &&
            err.response.data &&
            err.response.data.errors &&
            err.response.data.errors.general) ||
          'データ削除時にエラーがありました。セカイロジ担当者までお問い合わせください。';
        throw new Error(message);
      });
    },
    /**
     * logoの画像をアップロードする
     */
    async [UPLOAD_LOGO_IMAGE](context, { formData }) {
      const response = await axios.post('/upload/image', formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      });
      return response;
    }
  },
  getters: {
    /**
     * 指定された名前のテンプレートがあれば返す
     */
    findTemplate: (state) => ({ templateName }) => {
      return state.details.find((detail) => detail.name === templateName);
    },
    /**
     * 指定された名前のテンプレートがデフォルトかどうか
     */
    isDefaultSlip: (state, getters) => ({ templateName }) => {
      const template = getters.findTemplate({ templateName });
      return template && template.isDefaultSlip;
    }
  }
};
