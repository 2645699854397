'use strict';

const _ = require('lodash');

const STATE_PATTERN = ['NOT_CHECKED', 'IN_PROGRESS', 'COMPLETE', 'RE_SHIPMENT'];
const STATE = _.zipObject(STATE_PATTERN, STATE_PATTERN);

module.exports = {
  STATE_PATTERN,
  STATE,
  STATE_LABEL: {
    NOT_CHECKED: '未確認',
    IN_PROGRESS: '確認中',
    COMPLETE: '対応完了',
    RE_SHIPMENT: '再出荷'
  }
};
