import validator from 'validator';

import {
  CREATE_SHIPPING_CATEGORY,
  VALIDATE_SHIPPING_CATEGORY
} from '@/store/action-types';
import {
  UPDATE_EN_US,
  UPDATE_JA_JP,
  UPDATE_KANA,
  UPDATE_HS_CODE,
  UPDATE_ERRORS,
  RESET_ERRORS,
  RESET_SHIPPING_CATEGORY
} from '@/store/mutation-types';
import axios from '@/api/common/axios';

/**
 * 入力値のバリデーション
 * エラー文言を配列で返す
 */
const validate = {
  en_us: (value) => {
    const errors = [];
    if (validator.isEmpty(value, { ignore_whitespace: true })) {
      errors.push('商品分類名(英語)を入力してください');
    }
    if (!validator.isLength(value, { max: 86 })) {
      errors.push('86文字以内で入力してください');
    }
    if (!validator.isAscii(value)) {
      errors.push('英数字のみ使用できます');
    }
    return errors;
  },
  ja_jp: (value) => {
    const errors = [];
    if (validator.isEmpty(value, { ignore_whitespace: true })) {
      errors.push('商品分類名を入力してください');
    }
    if (!validator.isLength(value, { max: 86 })) {
      errors.push('86文字以内で入力してください');
    }
    return errors;
  },
  kana: (value) => {
    const errors = [];
    if (validator.isEmpty(value, { ignore_whitespace: true })) {
      errors.push('商品分類名(読み)を入力してください');
    }
    if (!/^[ぁ-んー（）]*$/.test(value)) {
      errors.push('ひらがなで入力してください');
    }
    return errors;
  },
  hs_code: (value) => {
    const errors = [];
    if (validator.isEmpty(value, { ignore_whitespace: true })) {
      errors.push('HSコードを入力してください');
    }
    if (!validator.isLength(value, { max: 7 })) {
      errors.push('7文字以内で入力してください');
    }
    return errors;
  }
};

/**
 * 商品分類の追加に関するデータストア
 */
export default {
  namespaced: true,
  state: {
    en_us: '',
    ja_jp: '',
    kana: '',
    hs_code: '',
    errorMessages: {
      en_us: [],
      ja_jp: [],
      kana: [],
      hs_code: [],
      general: []
    }
  },
  mutations: {
    /**
     * 商品分類名(英語)の更新を受け入れ、バリデーションを行う
     */
    [UPDATE_EN_US]: (state, value) => {
      state.errorMessages.en_us = validate.en_us(value);
      // 入力を強制的に大文字に変換する
      value = value.toUpperCase();
      state.en_us = value;
    },
    /**
     * 商品分類名の更新を受け入れ、バリデーションを行う
     */
    [UPDATE_JA_JP]: (state, value) => {
      state.errorMessages.ja_jp = validate.ja_jp(value);
      state.ja_jp = value;
    },
    /**
     * 商品分類名(読み)の更新を受け入れ、バリデーションを行う
     */
    [UPDATE_KANA]: (state, value) => {
      state.errorMessages.kana = validate.kana(value);
      state.kana = value;
    },
    /**
     * HSコードの更新を受け入れ、バリデーションを行う
     */
    [UPDATE_HS_CODE]: (state, value) => {
      state.errorMessages.hs_code = validate.hs_code(value);
      state.hs_code = value;
    },
    /**
     * 商品分類名の更新を受け入れ、バリデーションを行う
     */
    [RESET_SHIPPING_CATEGORY]: (state) => {
      state.ja_jp = '';
      state.kana = '';
      state.en_us = '';
      state.hs_code = '';
    },
    /**
     * エラーメッセージを更新する
     * @param {Vuex.Store.state} state
     * @param {string} prop プロパティ名
     * @param {string|string[]} errors エラーメッセージ
     */
    [UPDATE_ERRORS]: (state, { prop, errors }) => {
      if (typeof errors === 'string') {
        errors = [errors];
      }
      state.errorMessages[prop] = errors;
    },
    /**
     * エラーメッセージを初期状態にする
     */
    [RESET_ERRORS]: (state) => {
      state.errorMessages = {
        en_us: [],
        ja_jp: [],
        kana: [],
        hs_code: [],
        general: []
      };
    }
  },
  actions: {
    /**
     * 全ての入力値のバリデーションを行う。
     * dispatch()で呼び出されるとPromiseが返る。
     * @returns {boolean} バリデーションが成功したか
     */
    async [VALIDATE_SHIPPING_CATEGORY]({ state, commit }) {
      let result = true;
      for (const prop of Object.keys(validate)) {
        const errors = validate[prop](state[prop]);
        commit(UPDATE_ERRORS, { prop, errors });
        if (errors.length) {
          result = false;
        }
      }
      return result;
    },
    /**
     * ShippingCategories追加APIを呼び、エラーメッセージをstate.errorMessagesにセットする
     */
    async [CREATE_SHIPPING_CATEGORY]({ commit }, params) {
      commit(RESET_ERRORS);
      await axios.post('/shipping_categories', params).catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          for (const prop of ['en_us', 'ja_jp', 'kana', 'hs_code', 'general']) {
            commit(UPDATE_ERRORS, {
              prop,
              errors: error.response.data.errors[prop]
            });
          }
        } else {
          commit(UPDATE_ERRORS, { prop: 'general', errors: [error.message] });
        }
        throw new Error(error.message);
      });
    }
  }
};
