<template>
  <div>
    <v-card>
      <v-card-text>
        <v-layout>
          <v-autocomplete
            v-if="$store.state.user.isTomUser"
            v-model="owner"
            :items="owners"
            item-value="id"
            item-text="name"
            label="荷主"
            class="mr-2 flex-grow-0"
            outlined
            hide-details
          />
          <v-text-field
            label="バーコード / セット商品管理番号 / 商品名"
            v-model="keywords"
            prepend-inner-icon="search"
            outlined
            hide-details
          />
          <v-select
            v-model="sort"
            :items="$store.state.bundles.sortLabels"
            prepend-inner-icon="swap_vert"
            label="並び替え"
            class="ml-2 flex-grow-0"
            style="max-width: 260px"
            outlined
            hide-details
          ></v-select>
        </v-layout>
      </v-card-text>
      <TableAndPaging
        ref="search"
        :headers="header"
        :items="$store.state.bundles.bundles"
        :total="$store.state.bundles.total"
        :query-params="queryParams"
        :action="`bundles/${FETCH_BUNDLES}`"
      >
        <template #row="props">
          <td v-if="$store.state.user.isTomUser">
            {{ props.item.owner.names.ja_jp }}
          </td>
          <td class="col__main">
            <span>
              <IconBarcode class="mr-1"></IconBarcode>
              <ClipboardChip :text="props.item.bundle_code"></ClipboardChip>
            </span>
            <div>{{ getBundleItemName(props.item) }}</div>
          </td>
          <td>{{ props.item.owner_bundle_code }}</td>
          <td>
            {{ props.item.items.length }}商品
            <v-menu left offset-y min-width="320" max-width="480">
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  text
                  small
                  color="primary"
                  elevation="0"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>expand_more</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-simple-table dense class="menu__items">
                  <tbody>
                    <tr
                      v-for="child in props.item.items"
                      :key="'child-' + child.item._id"
                      class="striped-row"
                    >
                      <td>
                        <span class="items__barcode">{{
                          child.item.barcode
                        }}</span>
                        <router-link
                          :to="`/items/${child.item._id}`"
                          class="link-row--main"
                          >{{ child.item.names.ja_jp }}
                          {{ child.qty }}個</router-link
                        >
                      </td>
                      <td>
                        <v-btn
                          text
                          icon
                          :to="`/items/${child.item._id}`"
                          :title="child.item._id"
                        >
                          <v-icon small left>open_in_new</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card>
            </v-menu>
          </td>
          <td v-if="!$store.state.user.isTomUser">
            <v-btn
              small
              outlined
              color="error"
              @click="openConfirmDialog(props.item)"
            >
              削除
            </v-btn>
          </td>
        </template>
      </TableAndPaging>
    </v-card>
    <v-dialog
      v-model="isOpenedConfirmDialog"
      max-width="300"
      @click:outside="closeConfirmDialog"
    >
      <v-card>
        <v-card-title class="headline">セット削除確認</v-card-title>
        <v-card-text>セット情報を本当に削除しますか？</v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="red" text @click="closeConfirmDialog">
            キャンセル
          </v-btn>
          <v-btn color="teal" text @click="deleteBundle(selectedBundle)"
            >削除する</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TableAndPaging from '@/components/TableAndPaging';
import ClipboardChip from '@/components/chips/ClipboardChip';
import IconBarcode from '@/components/icons/IconBarcode';

import {
  FETCH_OWNERS_ALL,
  FETCH_BUNDLES,
  DELETE_ITEM_BUNDLE,
  START_LOADING,
  FINISH_LOADING
} from '@/store/action-types';

export default {
  components: {
    TableAndPaging,
    ClipboardChip,
    IconBarcode
  },
  data: () => {
    return {
      FETCH_BUNDLES,
      owner: '',
      keywords: '',
      bundleCode: '',
      ownerBundleCode: '',
      name: '',
      sort: '',
      selectedBundle: null,
      isOpenedConfirmDialog: false
    };
  },
  computed: {
    queryParams: function () {
      return {
        filter_owner: this.owner,
        filter_keywords: this.keywords,
        filter_bundle_code: this.bundleCode,
        filter_owner_bundle_code: this.ownerBundleCode,
        filter_name: this.name,
        sort: this.sort
      };
    },
    header: function () {
      return this.$store.state.user.isTomUser
        ? ['荷主', ...this.$store.state.bundles.headers]
        : [...this.$store.state.bundles.headers, '操作'];
    },
    owners: function () {
      return [{ id: '', name: '全て' }].concat(
        Object.keys(this.$store.state.owners.owners).map((index) => {
          return {
            id: this.$store.state.owners.owners[index]._id,
            name: this.$store.state.owners.owners[index].names.ja_jp
          };
        })
      );
    },
    getBundleItemName: function () {
      return function (item) {
        if (item.names.ja_jp) {
          return item.names.ja_jp;
        }
        return item.names.en_us;
      };
    }
  },
  async beforeMount() {
    await this.$store.dispatch(`owners/${FETCH_OWNERS_ALL}`);
  },
  mounted: function () {
    const params = this.$route.query;
    this.keywords = params.filter_keywords || '';
    this.bundleCode = params.filter_bundle_code || '';
    this.ownerBundleCode = params.filter_owner_bundle_code || '';
    this.name = params.filter_name || '';
    this.owner = params.filter_owner || '';
    this.sort = params.sort || '';
  },
  methods: {
    openConfirmDialog(selectedBundle) {
      this.isOpenedConfirmDialog = true;
      this.selectedBundle = selectedBundle;
    },
    closeConfirmDialog() {
      this.isOpenedConfirmDialog = false;
      this.selectedBundle = null;
    },
    async deleteBundle(bundle) {
      this.closeConfirmDialog();
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      try {
        await this.$store.dispatch(`bundles/${DELETE_ITEM_BUNDLE}`, {
          _id: bundle._id,
          bundle_code: bundle.bundle_code
        });
      } catch (err) {
        const message =
          (err.response &&
            err.response.data &&
            err.response.data.errors &&
            err.response.data.errors.general) ||
          err.message;
        await this.$store.dispatch('notify/showErrorNotify', message);
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        return;
      }
      await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
      await this.$store.dispatch(
        'notify/showNotify',
        `${bundle.bundle_code}を削除しました。`
      );
      await this.$store.dispatch(`bundles/${FETCH_BUNDLES}`, this.queryParams);
    }
  }
};
</script>

<style scoped>
.link-row--main {
  display: block;
  font-weight: bold;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
}
.link-row--main:hover {
  text-decoration: underline;
  color: rgba(0, 0, 0, 0.87);
}
.col__main {
  width: 90%;
}
.menu__items {
  padding: 8px 0;
}
.menu__items ::v-deep tbody td {
  padding-top: 8px;
  padding-bottom: 8px;
}
.items__barcode {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
}
</style>
