<template>
  <v-container fluid fill-height>
    <v-layout row wrap align-center justify-center>
      <v-flex md6 xs12 text-center>
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
        <p>ログアウトしています</p>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from '@/api/common/axios';
import { LOGOUT_SUCCESS } from '@/store/mutation-types';

export default {
  async beforeMount() {
    await this.logout();
  },
  methods: {
    /**
     * ログアウトしてログイン画面へリダイレクトする
     * @returns {Promise<void>}
     */
    async logout() {
      try {
        await axios.get('/auth/logout');

        // 成功したらログイン画面へ
        this.$store.commit(`user/${LOGOUT_SUCCESS}`);
        this.$router.push('/login');
      } catch (error) {
        await this.$store.dispatch(
          'notify/showErrorNotify',
          'ログアウトに失敗しました。ページを更新して再度行ってください。'
        );
      }
    }
  }
};
</script>
