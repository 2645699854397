<template>
  <v-container px-0>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-form @submit.prevent>
              <v-layout align-center justify-center>
                <v-flex xs11 text-center>
                  <v-select
                    v-model="editTemplateName"
                    :items="editTemplateItems"
                    label="納品書テンプレートを選択"
                    height="20"
                    @input="selectEditSlip"
                  ></v-select>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs6>
                  <h3 class="font-weight-bold subtitle-2 text-left mt-4">
                    テンプレート名
                  </h3>
                  <v-text-field
                    label="テンプレート名"
                    class="pl-4"
                    placeholder="テンプレート1"
                    v-model="templateName"
                    name="templateName"
                    data-vv-as="テンプレート名"
                    v-validate="'required|trimmed'"
                    data-vv-validate-on="blur"
                    :error-messages="errors.first('templateName')"
                    required
                  ></v-text-field>

                  <h3 class="font-weight-bold subtitle-2 text-left mt-4">
                    対応地域
                  </h3>
                  <v-select
                    class="pl-4"
                    v-model="slip.lang"
                    :items="langPattern"
                    :label="isNew ? '対応地域を選択' : ''"
                    height="20"
                    @change="changeLang"
                    :append-icon="isNew ? '$dropdown' : ''"
                    :readonly="!isNew"
                  ></v-select>

                  <h3 class="font-weight-bold subtitle-2 text-left mt-4">
                    納品書タイトルの表記
                  </h3>
                  <v-text-field
                    label="納品書タイトル"
                    class="pl-4"
                    :placeholder="isJa ? '納品書 兼 領収書' : 'Packing Slip'"
                    v-model="slip.slipTitle"
                    name="slipTitle"
                    data-vv-as="納品書タイトル"
                    v-validate="'required'"
                    data-vv-validate-on="blur"
                    :error-messages="errors.first('slipTitle')"
                    required
                  ></v-text-field>

                  <h3 class="font-weight-bold subtitle-2 text-left mt-4">
                    発行元の表記
                  </h3>

                  <v-container class="py-0">
                    <v-row>
                      <v-col cols="6" class="pa-0">
                        <v-text-field
                          label="ロゴ画像"
                          class="pl-4 mb-6"
                          readonly
                          hint="※任意設定項目"
                          persistent-hint
                          :value="fileName"
                        ></v-text-field>
                      </v-col>
                      <v-col v-show="slip.logo" cols="3" class="pa-0">
                        <v-container>
                          <v-layout row wrap>
                            <v-flex>
                              <v-btn
                                color="error accent-4"
                                class="v-btn--active"
                                @click.prevent="removeDialog = true"
                              >
                                <template>削除</template>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </v-container>
                        <v-dialog v-model="removeDialog" max-width="300">
                          <v-card>
                            <v-card-title class="headline">
                              削除の確認
                            </v-card-title>
                            <v-card-text>ロゴ画像を削除します</v-card-text>
                            <v-card-actions>
                              <v-btn
                                color="red"
                                text
                                @click.prevent="removeDialog = false"
                              >
                                キャンセル
                              </v-btn>
                              <v-btn
                                color="teal"
                                text
                                @click.prevent="removeLogo"
                              >
                                OK
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-col>
                      <v-col :cols="slip.logo ? 3 : 6" class="pa-0 text-right">
                        <FileInputBtn
                          accept="image/png,image/jpeg"
                          :show-text="false"
                          :default-text="'画像を選択'"
                          v-model="file"
                          @input="submitFile"
                        />
                      </v-col>
                    </v-row>
                  </v-container>

                  <v-container v-if="slip.logo" class="py-0">
                    <v-row>
                      <v-col cols="12" class="pa-0">
                        <v-img
                          :src="slip.logo"
                          contain
                          class="ml-4"
                          style="max-height: 160px"
                        />
                      </v-col>
                    </v-row>
                  </v-container>

                  <v-text-field
                    label="発行元名（兼 送り状伝票記載荷主名）"
                    class="pl-4"
                    :placeholder="isJa ? 'セカイロジ' : 'SEKAILOGI'"
                    v-model="slip.ownerInfo.name"
                    name="ownerName"
                    data-vv-as="発行元名（兼 送り状伝票記載荷主名）"
                    v-validate="'required|isAsciiInOversea'"
                    data-vv-validate-on="blur"
                    :error-messages="errors.first('ownerName')"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="1行目"
                    class="pl-4 mb-6"
                    :placeholder="isJa ? '〒272-0011' : '272-0011'"
                    hint="※任意設定項目"
                    persistent-hint
                    v-model="slip.ownerInfo.line1"
                    name="line1"
                    data-vv-as="発送元1行目"
                    data-vv-validate-on="blur"
                    :error-messages="errors.first('line1')"
                  ></v-text-field>
                  <v-text-field
                    label="2行目"
                    class="pl-4 mb-6"
                    :placeholder="
                      isJa ? '千葉県市川市高谷新町6-4' : 'DPL Ichikawa 6-4,'
                    "
                    hint="※任意設定項目"
                    persistent-hint
                    v-model="slip.ownerInfo.line2"
                    name="line2"
                    data-vv-as="発送元2行目"
                    data-vv-validate-on="blur"
                    :error-messages="errors.first('line2')"
                  ></v-text-field>
                  <v-text-field
                    label="3行目"
                    class="pl-4 mb-6"
                    :placeholder="
                      isJa ? 'DPL市川' : 'Koyashinmachi, Ichikawa Shi, Chiba'
                    "
                    hint="※任意設定項目"
                    persistent-hint
                    v-model="slip.ownerInfo.line3"
                    name="line3"
                    data-vv-as="発送元3行目"
                    data-vv-validate-on="blur"
                    :error-messages="errors.first('line3')"
                  ></v-text-field>
                  <v-text-field
                    label="4行目"
                    class="pl-4 mb-6"
                    placeholder="https://sekailogi.com/"
                    hint="※任意設定項目"
                    persistent-hint
                    v-model="slip.ownerInfo.line4"
                    name="line4"
                    data-vv-as="発送元4行目"
                    data-vv-validate-on="blur"
                    :error-messages="errors.first('line4')"
                  ></v-text-field>

                  <h3 class="font-weight-bold subtitle-2 text-left mt-4">
                    商品欄のヘッダー表記
                  </h3>
                  <v-text-field
                    label="商品番号"
                    class="pl-4 mb-6"
                    :placeholder="isJa ? '商品番号' : 'Barcode'"
                    v-model="slip.header.barcode"
                    name="barcode"
                    data-vv-as="商品番号"
                    data-vv-validate-on="blur"
                    :error-messages="errors.first('barcode')"
                    hint="※任意設定項目"
                    persistent-hint
                  ></v-text-field>
                  <v-text-field
                    label="商品名"
                    class="pl-4 mb-6"
                    :placeholder="isJa ? '商品名' : 'Product Title'"
                    v-model="slip.header.productTitle"
                    name="productTitle"
                    data-vv-as="商品名"
                    v-validate="'required'"
                    data-vv-validate-on="blur"
                    :error-messages="errors.first('productTitle')"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="個数"
                    class="pl-4 mb-6"
                    :placeholder="isJa ? '個数' : 'Quantity'"
                    v-model="slip.header.qty"
                    name="qty"
                    data-vv-as="個数"
                    v-validate="'required'"
                    data-vv-validate-on="blur"
                    :error-messages="errors.first('qty')"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-if="isAmountEnabled"
                    label="購入金額"
                    class="pl-4 mb-6"
                    :placeholder="isJa ? '金額 (税込)' : 'Price'"
                    v-model="slip.header.amount"
                    hint="※任意設定項目"
                    persistent-hint
                  ></v-text-field>
                  <v-text-field
                    v-if="isAmountEnabled"
                    label="合計金額"
                    class="pl-4 mb-6"
                    :placeholder="isJa ? '合計金額 ¥' : 'Total Price $'"
                    v-model="slip.header.totalAmount"
                    hint="※任意設定項目"
                    persistent-hint
                  ></v-text-field>

                  <h3 class="font-weight-bold subtitle-2 text-left mt-4">
                    備考欄の表記
                  </h3>
                  <v-textarea
                    label="備考欄"
                    class="pl-4 mb-6"
                    :placeholder="
                      isJa
                        ? 'ご購入いただきありがとうございます。何かお気づきの点があればカスタマーサポートまでご連絡ください。'
                        : 'Thank you for your purchase.'
                    "
                    v-model="slip.footer.note"
                    hint="※任意設定項目"
                    persistent-hint
                  ></v-textarea>
                  <h3 class="font-weight-bold subtitle-2 text-left mt-4">
                    デフォルト納品書の選択
                  </h3>
                  <v-checkbox
                    label="このテンプレートをデフォルトにする。"
                    v-model="slip.isDefaultSlip"
                    v-if="enableDefaultCheckbox"
                    :disabled="initialize"
                  />
                  <div v-if="!enableDefaultCheckbox">
                    このテンプレートはデフォルトに設定されています。
                  </div>
                </v-flex>
                <v-flex xs6 ml-3>
                  <h3 class="font-weight-bold subtitle-2 text-left mt-4">
                    簡易プレビュー
                  </h3>
                  <SlipPreview
                    :slip-title="slip.slipTitle"
                    :owner-info="slip.ownerInfo"
                    :header="slip.header"
                    :lang="slip.lang"
                    :logo="slip.logo"
                    :footer="slip.footer"
                  ></SlipPreview>
                  <h3 class="font-weight-bold subtitle-2 text-left mt-4">
                    詳細プレビュー
                  </h3>
                  <v-btn
                    text
                    color="primary accent-4"
                    class="v-btn--active mt-2 ml-4"
                    :href="previewUrl"
                    target="_blank"
                  >
                    PDFで確認する
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-layout align-center justify-center>
                <template
                  v-if="
                    $store.state.slipTemplates.titles.includes(templateName)
                  "
                >
                  <v-flex mb-6 justify-center text>
                    <v-btn @click="updateDialog = true" color="primary">
                      既存設定を変更する
                    </v-btn>
                  </v-flex>
                  <v-flex mb-6 justify-center text>
                    <v-btn
                      @click="deleteSlipDialog = true"
                      :disabled="!slip.isDeletable"
                      color="error"
                    >
                      削除する
                    </v-btn>
                  </v-flex>
                  <v-flex mb-6 text v-if="!slip.isDeletable">
                    デフォルト指定、未発送/出荷作業中の出荷依頼で使われているものは削除できません
                  </v-flex>
                </template>
                <template v-else>
                  <template v-if="initialize && slip.lang === LANG.JA_JP">
                    <v-flex xs6 text-center text>
                      <v-btn @click="register(true)" color="primary">
                        続けて、海外用納品書設定を行う
                      </v-btn>
                    </v-flex>
                    <v-flex xs6 text-center text>
                      <v-btn
                        @click="register()"
                        color="primary accent-4"
                        class="v-btn--active"
                        text
                      >
                        初期設定を完了し、利用を開始する
                      </v-btn>
                    </v-flex>
                  </template>
                  <template v-else-if="initialize && slip.lang !== LANG.JA_JP">
                    <v-flex xs6 text-center text>
                      <v-btn @click="register()" color="primary">
                        初期設定を完了し、利用を開始する
                      </v-btn>
                    </v-flex>
                  </template>
                  <template v-else>
                    <v-flex xs6 text-center text>
                      <v-btn @click="register()" color="primary">
                        設定を新規登録する
                      </v-btn>
                    </v-flex>
                  </template>
                </template>
                <v-dialog v-model="updateDialog" max-width="300">
                  <v-card>
                    <v-card-title class="headline">変更の確認</v-card-title>
                    <v-card-text>
                      既存の設定を現在の内容で上書きします。よろしいですか？
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        color="red"
                        text
                        @click.prevent="updateDialog = false"
                      >
                        キャンセル
                      </v-btn>
                      <v-btn color="teal" text @click.prevent="register()">
                        OK
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="deleteSlipDialog" max-width="300">
                  <v-card>
                    <v-card-title class="headline">削除の確認</v-card-title>
                    <v-card-text>
                      納品書テンプレートを削除しますか?
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        color="teal"
                        text
                        @click.prevent="deleteSlipDialog = false"
                      >
                        削除しない
                      </v-btn>
                      <v-btn
                        color="red"
                        text
                        @click.prevent="deleteSlip(slip._id)"
                      >
                        削除する
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-layout>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from 'lodash';

import { LANG, LANG_LABEL } from '@/../lib/document/schema/slip_templates';
import SlipPreview from '@/components/slip_templates/Preview.vue';
import {
  FETCH_SLIP_TEMPLATES,
  REGISTER_SLIP_TEMPLATE,
  DELETE_SLIP_TEMPLATE,
  START_LOADING,
  FINISH_LOADING,
  UPLOAD_LOGO_IMAGE,
  FETCH_OWNER
} from '@/store/action-types';
import FileInputBtn from '@/components/FileInputBtn';
import validator from 'validator';

export default {
  props: {
    ownerId: {
      type: String,
      default: ''
    },
    initialize: {
      type: Boolean,
      default: false
    }
  },
  components: {
    SlipPreview,
    FileInputBtn
  },
  $_veeValidate: {
    validator: 'new'
  },
  data() {
    return {
      file: null, // アップロードするファイルオブジェクト
      // 編集対象として選択したテンプレート名
      editTemplateName: '',
      // 変更するテンプレート名
      templateName: '',
      // テンプレートの項目
      slip: {
        _id: '',
        slipTitle: '',
        ownerInfo: {
          name: '',
          line1: '',
          line2: '',
          line3: '',
          line4: ''
        },
        header: {
          barcode: '',
          productTitle: '',
          qty: '',
          amount: '',
          totalAmount: ''
        },
        lang: LANG.JA_JP,
        logo: '',
        footer: {
          note: ''
        },
        isDefaultSlip: false,
        isDeletable: true
      },
      removeDialog: false,
      updateDialog: false,
      deleteSlipDialog: false,
      // 金額の設定を隠す issue #2623
      isAmountEnabled: false
    };
  },
  computed: {
    LANG: () => LANG,
    LANG_LABEL: () => LANG_LABEL,
    isJa: (self) => self.slip.lang === LANG.JA_JP,
    langPattern: function () {
      const langPattern = [];
      for (const property in LANG_LABEL) {
        if (this.initialize && property !== this.slip.lang) {
          continue;
        }
        // JA_JP を先頭にするため
        langPattern.unshift({
          text: this.langPatternName(property),
          value: property
        });
      }
      return langPattern;
    },
    newTemplateName: () => '新しくテンプレートを追加',
    /**
     * 新規作成か更新かどうか
     * 既存のテンプレートを編集の場合、言語設定を変えられないようにする。
     */
    isNew: function () {
      return !this.$store.getters['slipTemplates/findTemplate']({
        templateName: this.templateName
      });
    },
    /**
     * 選択したテンプレートがデフォルトの場合は、チェックボックスを無効にできない
     */
    enableDefaultCheckbox: function () {
      return !this.$store.getters['slipTemplates/isDefaultSlip']({
        templateName: this.templateName
      });
    },
    fileName: (self) => self.file?.name ?? (self.slip.logo ? '設定済み' : ''),
    defaultSlipValues: (self) => ({
      [LANG.JA_JP]: {
        slipTitle: '納品書',
        ownerInfo: {
          name: self.$store.state.owner.names.ja_jp,
          line1: '',
          line2: '',
          line3: '',
          line4: ''
        },
        header: {
          barcode: '',
          productTitle: '商品名',
          qty: '数量',
          amount: '',
          totalAmount: ''
        },
        lang: LANG.JA_JP,
        logo: '',
        footer: {
          note: ''
        },
        isDefaultSlip: self.initialize,
        isDeletable: true
      },
      [LANG.EN_US]: {
        slipTitle: 'Packing Slip',
        ownerInfo: {
          name: self.$store.state.owner.names.ja_jp, // 英語名は保持しないので日本語名をそのまま使用する
          line1: '',
          line2: '',
          line3: '',
          line4: ''
        },
        header: {
          barcode: '',
          productTitle: 'Product Title',
          qty: 'Quantity',
          amount: '',
          totalAmount: ''
        },
        lang: LANG.EN_US,
        logo: '',
        footer: {
          note: ''
        },
        isDefaultSlip: self.initialize,
        isDeletable: true
      }
    }),
    editTemplateItems: function () {
      return [
        {
          text: this.newTemplateName,
          value: this.newTemplateName
        },
        ...this.$store.state.slipTemplates.details.map((slipTemplate) => {
          let text = slipTemplate.name;
          if (slipTemplate.isDefaultSlip) {
            if (slipTemplate.lang === LANG.JA_JP) {
              text += ' (国内宛発送デフォルト)';
            } else {
              text += ' (海外宛発送デフォルト)';
            }
          }
          return {
            text,
            value: slipTemplate.name
          };
        })
      ];
    },
    previewUrl: (self) => {
      const params = new URLSearchParams({
        title: _.get(self, 'slip.slipTitle', ''),
        ownerInfoLine1: _.get(self, 'slip.ownerInfo.line1', ''),
        ownerInfoLine2: _.get(self, 'slip.ownerInfo.line2', ''),
        ownerInfoLine3: _.get(self, 'slip.ownerInfo.line3', ''),
        ownerInfoLine4: _.get(self, 'slip.ownerInfo.line4', ''),
        ownerInfoName: _.get(self, 'slip.ownerInfo.name', ''),
        headerBarcode: _.get(self, 'slip.header.barcode', ''),
        headerProductTitle: _.get(self, 'slip.header.productTitle', ''),
        headerQty: _.get(self, 'slip.header.qty', ''),
        headerAmount: _.get(self, 'slip.header.amount', ''),
        headerTotalAmount: _.get(self, 'slip.header.totalAmount', ''),
        lang: _.get(self, 'slip.lang', ''),
        logo: _.get(self, 'slip.logo', ''),
        footerNote: _.get(self, 'slip.footer.note', '')
      });
      return `/slip_templates/preview?${params.toString()}`;
    }
  },
  methods: {
    // 言語選択セレクターのラベル名
    langPatternName(lang) {
      if (!lang) {
        lang = this.slip.lang;
      }
      return lang === LANG.JA_JP ? '国内向け' : '海外向け';
    },
    async fetchTemplates() {
      await this.$store.dispatch(`slipTemplates/${FETCH_SLIP_TEMPLATES}`, {
        owner: this.ownerId
      });
    },
    async selectEditSlip(templateName) {
      this.$validator.errors.clear();
      await this.$store.dispatch('notify/clearNotify');
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      if (templateName === this.newTemplateName) {
        this.reset();
      } else {
        const selectedTemplate = this.$store.state.slipTemplates.details.find(
          (detail) => detail.name === templateName
        );
        this.file = null;
        this.templateName = selectedTemplate.name;
        this.slip._id = selectedTemplate._id;
        this.slip.slipTitle = selectedTemplate.title;
        this.slip.ownerInfo = selectedTemplate.ownerInfo;
        this.slip.header = selectedTemplate.header;
        this.slip.lang = selectedTemplate.lang;
        this.slip.logo = selectedTemplate.logo;
        this.slip.footer = selectedTemplate.footer;
        this.slip.isDefaultSlip = selectedTemplate.isDefaultSlip;
        this.slip.isDeletable = selectedTemplate.isDeletable;
      }
      await this.$validator.validate();
      await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
    },
    async register(reset) {
      this.updateDialog = false;
      await this.$store.dispatch('notify/clearNotify');
      if (await this.$validator.validate()) {
        this.$validator.errors.clear();
        await this.$store.dispatch(`app/${START_LOADING}`, new Date());
        const params = {
          owner: this.ownerId,
          templateName: this.templateName,
          slipTitle: this.slip.slipTitle,
          ownerInfo: this.slip.ownerInfo,
          header: this.slip.header,
          lang: this.slip.lang,
          logo: this.slip.logo,
          footer: this.slip.footer,
          isDefaultSlip: this.slip.isDefaultSlip
        };
        try {
          await this.$store.dispatch(
            `slipTemplates/${REGISTER_SLIP_TEMPLATE}`,
            {
              params
            }
          );
        } catch (err) {
          await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
          await this.$store.dispatch('notify/showErrorNotify', err.message);
          return;
        }
        try {
          await this.fetchTemplates();
        } catch (err) {
          await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
          await this.$store.dispatch(
            'notify/showErrorNotify',
            'テンプレートの保存に成功しましたが、ページ情報の更新に失敗しました。ページを更新してください。'
          );
          return;
        }
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        if (this.initialize) {
          if (reset) {
            // テンプレート選択をリセットし海外向けを選択する
            this.editTemplateName = this.newTemplateName;
            this.reset(LANG.EN_US);
            // トップへスクロール
            await this.$vuetify.goTo(0);
          } else {
            // 設定後トップページへ遷移する
            this.$router.push('/');
            // トップへスクロール
            await this.$vuetify.goTo(0);
          }
        } else {
          this.editTemplateName = this.templateName;
          await this.selectEditSlip(this.templateName);
          await this.$store.dispatch(
            'notify/showNotify',
            'テンプレートの保存に成功しました。'
          );
        }
      } else {
        await this.$store.dispatch(
          'notify/showErrorNotify',
          '入力項目に誤りがあります。修正して再度実行して下さい。'
        );
      }
    },
    async submitFile() {
      await this.$store.dispatch('notify/clearNotify');
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      const formData = new FormData();
      formData.append('image', this.file);
      try {
        const response = await this.$store.dispatch(
          `slipTemplates/${UPLOAD_LOGO_IMAGE}`,
          { formData }
        );
        if (response.data.status === 'ng') {
          await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
          await this.$store.dispatch(
            'notify/showErrorNotify',
            `ファイルのアップロードに失敗しました: ${response.data.errors.general}`
          );
          return;
        }
        this.slip.logo = response.data.imagePath;
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        await this.$store.dispatch(
          'notify/showNotify',
          'ファイルのアップロードが成功しました。'
        );
      } catch (err) {
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        await this.$store.dispatch(
          'notify/showErrorNotify',
          `ファイルのアップロード時に通信エラーが発生しました。${err.message}`
        );
      }
    },
    removeLogo() {
      this.file = null;
      this.slip.logo = '';
      this.removeDialog = false;
    },
    async deleteSlip(slipTemplateId) {
      this.deleteSlipDialog = false;
      if (!slipTemplateId) {
        return;
      }
      await this.$store.dispatch('notify/clearNotify');
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      try {
        await this.$store.dispatch(`slipTemplates/${DELETE_SLIP_TEMPLATE}`, {
          slipTemplateId
        });
        await this.fetchTemplates();
      } catch (err) {
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        await this.$store.dispatch('notify/showErrorNotify', err.message);
        return;
      }
      await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
      await this.$store.dispatch(
        'notify/showNotify',
        'テンプレートの削除に成功しました。'
      );

      // テンプレート選択をリセット
      this.file = null;
      this.templateName = '';
      this.editTemplateName = this.templateName;
      this.slip = _.cloneDeep(this.defaultSlipValues[LANG.JA_JP]);
    },
    reset(lang = LANG.JA_JP) {
      this.file = null;
      this.templateName = '';
      this.slip = _.cloneDeep(this.defaultSlipValues[lang]);
    },
    async changeLang() {
      const [fromLang, toLang] =
        this.slip.lang === LANG.JA_JP
          ? [LANG.EN_US, LANG.JA_JP]
          : [LANG.JA_JP, LANG.EN_US];

      const change = (object, basePath) => {
        Object.entries(object).forEach(([key, value]) => {
          const path = basePath === '' ? key : `${basePath}.${key}`;
          if (_.isPlainObject(value)) {
            change(value, path);
          } else {
            if (value === _.get(this.defaultSlipValues[fromLang], path)) {
              _.set(
                this.slip,
                path,
                _.get(this.defaultSlipValues[toLang], path)
              );
            }
          }
        });
      };
      change(this.slip, '');
      await this.$validator.validate();
    }
  },
  async beforeMount() {
    await this.$store.dispatch(`app/${START_LOADING}`, new Date());
    try {
      await this.fetchTemplates();
      await this.$store.dispatch(
        `owner/${FETCH_OWNER}`,
        this.$store.state.user
      );
    } catch (err) {
      await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
      await this.$store.dispatch(
        'notify/showErrorNotify',
        `ページ情報の取得に失敗しました。ページを更新してください。${err.message}`
      );
    }
    this.editTemplateName = this.newTemplateName;
    this.reset();
    await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
  },
  mounted() {
    this.$validator.extend('isAsciiInOversea', {
      getMessage: (fieldName) => {
        return `${fieldName}は英数字で入力してください`;
      },
      validate: (value) => {
        const lang = this.slip && this.slip.lang;
        return lang !== LANG.EN_US || validator.isAscii(value);
      }
    });
  }
};
</script>
