import {
  AUTHENTICATE_SUCCESS,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  AGREE_SUCCESS
} from '@/store/mutation-types';

import { DEACTIVATE_USER, CHANGE_USER_CATEGORY } from '@/store/action-types';

import axios from '../../api/common/axios';
import _ from 'lodash';

export default {
  namespaced: true,
  /**
   * @property {Object} state - 操作しているユーザー
   * @property {boolean} state.isLoggedIn - ユーザーがログインしているかどうか
   * @property {boolean} state.isTomUser - TOM荷主に紐づくユーザーかどうか
   * @property {?string} state.id - ユーザー ID
   * @property {?string} state.owner - ユーザーが紐づくオーナー ID
   * @property {?string} state.category - ユーザーのカテゴリ
   * @property {?string} state.name - ユーザー名
   * @property {?string} state.status - 初期化済みかどうかなどの現在の状態
   * @property {?string} state.agreementVersion - 同意した利用規約のバージョン
   * @property {?string[]} state.realm - アクセス可能な権限 UserSchema.REALM の値のいずれかが入る。
   */
  state: {
    isLoggedIn: false,
    isTomUser: false,
    id: null,
    owner: null,
    category: null,
    name: null,
    status: null,
    agreementVersion: null,
    realm: []
  },
  mutations: {
    /**
     * ページごとのユーザーの認証しユーザー情報の取得に成功
     * @param {Object} state
     * @param {Object} user
     */
    [AUTHENTICATE_SUCCESS](state, user) {
      if (!user) {
        throw new Error('ユーザー情報が取得できませんでした');
      }
      state.isLoggedIn = true;
      state.isTomUser = user.isTomUser;
      state.id = user.id;
      state.owner = user.owner;
      state.category = user.category;
      state.name = user.name;
      state.status = user.status;
      state.agreementVersion = user.agreementVersion;
      state.realm = user.realm || [];
      state.accountStatus = user.accountStatus;
    },
    /**
     * ログインに成功
     * @param {Object} state
     * @param {Object} user
     */
    [LOGIN_SUCCESS](state, user) {
      if (!user) {
        throw new Error('ユーザー情報が取得できませんでした');
      }
      state.isLoggedIn = true;
      state.isTomUser = user.isTomUser;
      state.id = user.id;
      state.owner = user.owner;
      state.category = user.category;
      state.name = user.name;
      state.status = user.status;
      state.agreementVersion = user.agreementVersion;
      state.realm = user.realm || [];
      state.accountStatus = user.accountStatus;
    },
    /**
     * ログアウトに成功
     * @param {Object} state
     */
    [LOGOUT_SUCCESS](state) {
      state.isLoggedIn = false;
      state.isTomUser = false;
      state.id = null;
      state.owner = null;
      state.category = null;
      state.name = null;
      state.status = null;
      state.agreementVersion = null;
      state.realm = [];
      state.accountStatus = undefined;
    },

    /**
     * 利用規約の同意に成功
     * @param {Object} state
     * @param {string} userAgreementVersion
     */
    [AGREE_SUCCESS](state, userAgreementVersion) {
      state.agreementVersion = userAgreementVersion;
    }
  },
  actions: {
    /**
     * ユーザを削除する
     * @param {string} email
     */
    async [DEACTIVATE_USER](state, email) {
      await axios.post('/owners/user_deactivate', email);
    },
    // ユーザ権限を指定のcategoryへ切り替える
    async [CHANGE_USER_CATEGORY](state, { userId, category }) {
      try {
        await axios.post('/owners/change_user_category', {
          userId,
          category
        });
      } catch (error) {
        throw new Error(
          _.get(
            error,
            'response.data.errors.general',
            'アカウント種類の変更に失敗しました。'
          )
        );
      }
    }
  },
  getters: {
    /**
     * ユーザーが指定の権限を持つかどうかチェックする
     */
    hasRealm: (state) => (realm) => {
      return state.realm.includes(realm);
    }
  }
};
