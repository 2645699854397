<template>
  <div>
    <v-btn
      v-if="state === STATE.APPROVAL && hasCancelableShipment"
      v-bind="{
        [buttonSize]: true
      }"
      color="error"
      class="ml-2"
      @click.stop="dialog = true"
    >
      <v-icon left>cancel</v-icon>
      取り消し
    </v-btn>

    <v-dialog v-model="dialog" max-width="550">
      <v-card>
        <v-card-title class="headline">出荷取り消しの確認</v-card-title>
        <v-card-text>
          <span>選択した出荷を取り消します</span>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="red" text @click="dialog = false"> キャンセル </v-btn>
          <v-btn color="teal" text @click="cancelShipmentRequest">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const _ = require('lodash');
import { START_LOADING, FINISH_LOADING } from '@/store/action-types';
import { CANCEL_SELECTED_REQUESTS } from '@/store/action-types';
import { STATE } from '@/../lib/document/schema/shipment_requests';

export default {
  props: {
    state: String,
    shipmentRequestId: String,
    buttonSize: String,
    hasCancelableShipment: Boolean,
    onSubmitted: {
      type: Function,
      default: () => {}
    }
  },
  data: function () {
    return {
      dialog: false,
      STATE
    };
  },
  methods: {
    async cancelShipmentRequest() {
      this.dialog = false;
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      try {
        await this.$store.dispatch(
          `shipments/${CANCEL_SELECTED_REQUESTS}`,
          this.shipmentRequestId
        );
        await this.onSubmitted();
      } catch (e) {
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        await this.$store.dispatch(
          'notify/showErrorNotify',
          _.get(
            e,
            'response.data.errors.general',
            'エラーが発生しました、画面を更新してやり直してください'
          )
        );
        throw e;
      }
      await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
      await this.$store.dispatch('notify/showNotify', '出荷が取り消されました');
    }
  },
  computed: {}
};
</script>
