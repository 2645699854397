const _ = require('lodash');

import axios from '@/api/common/axios';
import {
  CHANGE_PASSWORD,
  INITIALIZE_CHANGE_PASSWORD_PAGE,
  INITIALIZE_FORGOT_PAGE,
  INITIALIZE_LOGIN_PAGE,
  INITIALIZE_RESET_PAGE
} from '@/store/action-types';
import {
  AUTHENTICATE_FAILED,
  AUTHENTICATE_REQUEST,
  AUTHENTICATE_SUCCESS,
  CHANGE_PASSWORD_FAILED,
  CHANGE_PASSWORD_PAGE_SHOW,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  FORGOT_FAILED,
  FORGOT_PAGE_SHOW,
  FORGOT_REQUEST,
  FORGOT_SUCCESS,
  LOGIN_FAILED,
  LOGIN_PAGE_SHOW,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  RESET_FAILED,
  RESET_PAGE_SHOW,
  RESET_REQUEST,
  RESET_SUCCESS
} from '@/store/mutation-types';

export default {
  namespaced: true,
  /**
   * @property {Object} state
   * @property {boolean} state.openable - 指定されたページを表示する権限の有無
   * @property {boolean} state.tokenIsValid - パスワードリセット用のトークンが有効かどうか
   * @property {?Object} state.authenticateErrors - 認証 API で返ってきたエラー
   * @property {?Object} state.loginErrors - ログイン API で返ってきたエラー
   * @property {?Object} state.forgotErrors - パスワードリセットリクエスト API で返ってきたエラー
   * @property {?Object} state.resetErrors - パスワードリセット API で返ってきたエラー
   * @property {?Object} state.changePasswordErrors - パスワード変更 API で返ってきたエラー
   */
  state: {
    openable: false,
    tokenIsValid: false,
    authenticateErrors: null,
    loginErrors: null,
    forgotErrors: null,
    resetErrors: null,
    changePasswordErrors: null
  },
  mutations: {
    [AUTHENTICATE_REQUEST](state) {
      state.openable = false;
    },
    [AUTHENTICATE_SUCCESS](state, openable) {
      if (_.isNil(openable)) {
        throw new Error('値を取得できませんでした');
      }
      state.openable = openable;
      if (!openable) {
        state.authenticateErrors = {
          general: {
            msg: 'ページを表示する権限がありません'
          }
        };
      }
    },
    [AUTHENTICATE_FAILED](state, errors) {
      if (!errors) {
        throw new Error('エラー内容が取得できませんでした');
      }
      state.authenticateErrors = errors;
    },
    [LOGIN_PAGE_SHOW](state) {
      state.loginErrors = null;
    },
    [LOGIN_REQUEST](state) {
      state.loginErrors = null;
    },
    [LOGIN_FAILED](state, errors) {
      if (!errors) {
        throw new Error('エラー内容が取得できませんでした');
      }
      state.loginErrors = errors;
    },
    [FORGOT_PAGE_SHOW](state) {
      state.forgotErrors = null;
    },
    [FORGOT_REQUEST](state) {
      state.forgotErrors = null;
    },
    [FORGOT_SUCCESS](state) {
      state.forgotErrors = null;
    },
    [FORGOT_FAILED](state, errors) {
      if (!errors) {
        throw new Error('エラー内容が取得できませんでした');
      }
      state.forgotErrors = errors;
    },
    [RESET_PAGE_SHOW](state, tokenIsValid) {
      state.tokenIsValid = tokenIsValid;
      state.resetErrors = null;
    },
    [RESET_REQUEST](state) {
      state.resetErrors = null;
    },
    [RESET_SUCCESS](state) {
      state.resetErrors = null;
    },
    [RESET_FAILED](state, errors) {
      if (!errors) {
        throw new Error('エラー内容が取得できませんでした');
      }
      state.resetErrors = errors;
    },
    [CHANGE_PASSWORD_PAGE_SHOW](state) {
      state.changePasswordErrors = null;
    },
    [CHANGE_PASSWORD_REQUEST](state) {
      state.changePasswordErrors = null;
    },
    [CHANGE_PASSWORD_SUCCESS](state) {
      state.changePasswordErrors = null;
    },
    [CHANGE_PASSWORD_FAILED](state, errors) {
      if (!errors) {
        throw new Error('エラー内容が取得できませんでした');
      }
      state.changePasswordErrors = errors;
    }
  },
  actions: {
    /**
     * ページを表示する権限があるか確認する
     * @param {Object} context
     * @param {function} context.commit
     * @param {string} path
     * @returns {Promise<void>}
     * @throws {Error}
     */
    async authenticate({ commit }, path) {
      commit(AUTHENTICATE_REQUEST);
      const response = await axios.post(
        '/auth/authentication',
        { path },
        {
          validateStatus: (s) => [200, 401, 403].includes(s)
        }
      );
      if (response.status === 200) {
        commit(AUTHENTICATE_SUCCESS, _.get(response, 'data.openable'));
        commit(`user/${AUTHENTICATE_SUCCESS}`, _.get(response, 'data.user'), {
          root: true
        });
      } else if (response.status === 401) {
        commit(AUTHENTICATE_FAILED, {
          general: {
            msg: 'ページを表示する権限がありません'
          }
        });
      } else if (response.status === 403) {
        commit(AUTHENTICATE_FAILED, {
          general: {
            msg: 'IP制限によりアクセスが制限されています'
          }
        });
      } else {
        throw new Error('想定していないステータスコード');
      }
    },
    /**
     * ログイン画面を初期化する
     * @param {Object} context
     * @param {function} context.commit
     * @returns {Promise<void>}
     */
    async [INITIALIZE_LOGIN_PAGE]({ commit }) {
      commit(LOGIN_PAGE_SHOW);
    },
    /**
     * ログインする
     * @param {Object} context
     * @param {function} context.commit
     * @param {Object} param
     * @param {string} param.email
     * @param {string} param.password
     * @returns {Promise<void>}
     * @throws {Error}
     */
    async login({ commit }, { email, password }) {
      commit(LOGIN_REQUEST);
      const response = await axios.post(
        '/auth/email',
        { email, password },
        {
          validateStatus: (s) => [200, 400, 401].includes(s)
        }
      );
      if (response.status === 200) {
        commit(`user/${LOGIN_SUCCESS}`, _.get(response, 'data.user'), {
          root: true
        });
      } else if (response.status === 400) {
        commit(LOGIN_FAILED, _.get(response, 'data.errors'));
      } else if (response.status === 401) {
        commit(LOGIN_FAILED, {
          general: {
            msg: 'メールアドレスまたはパスワードが正しくありません'
          }
        });
      } else if (response.status === 403) {
        commit(LOGIN_FAILED, {
          general: {
            msg: 'IP制限によりアクセスが制限されています'
          }
        });
      } else {
        throw new Error('想定していないステータスコード');
      }
    },
    /**
     * パスワードリセットリクエスト画面を初期化する
     * @param {Object} context
     * @param {function} context.commit
     * @returns {Promise<void>}
     */
    async [INITIALIZE_FORGOT_PAGE]({ commit }) {
      commit(FORGOT_PAGE_SHOW);
    },
    /**
     * パスワードをリセットするためのトークンを生成する
     * @param {Object} context
     * @param {function} context.commit
     * @param {string} email
     * @returns {Promise<void>}
     * @throws {Error}
     */
    async forgot({ commit }, email) {
      commit(FORGOT_REQUEST);
      const response = await axios.post(
        '/auth/forgot',
        { email },
        {
          validateStatus: (s) => [200, 400, 404].includes(s)
        }
      );
      if (response.status === 200) {
        commit(FORGOT_SUCCESS);
      } else if (response.status === 400) {
        commit(FORGOT_FAILED, _.get(response, 'data.errors'));
      } else if (response.status === 404) {
        commit(FORGOT_FAILED, {
          general: {
            msg: '指定されたメールアドレスが存在しません'
          }
        });
      } else {
        throw new Error('想定していないステータスコード');
      }
    },
    /**
     * パスワードリセット画面を初期化する
     * @param {Object} context
     * @param {function} context.commit
     * @param {string} token
     * @returns {Promise<void>}
     * @throws {Error}
     */
    async [INITIALIZE_RESET_PAGE]({ commit }, token) {
      const { isValid } = (
        await axios.post('/auth/validate_token', {
          token
        })
      ).data;
      commit(RESET_PAGE_SHOW, isValid);
    },
    /**
     * パスワードをリセットする
     * @param {Object} context
     * @param {function} context.commit
     * @param {Object} param
     * @param {string} param.token
     * @param {string} param.password
     * @returns {Promise<void>}
     * @throws {Error}
     */
    async reset({ commit }, { token, password }) {
      commit(RESET_REQUEST);
      const response = await axios.post(
        '/auth/reset',
        { token, password },
        {
          validateStatus: (s) => [200, 400, 401].includes(s)
        }
      );
      if (response.status === 200) {
        commit(RESET_SUCCESS);
      } else if (response.status === 400) {
        commit(RESET_FAILED, _.get(response, 'data.errors'));
      } else if (response.status === 401) {
        commit(RESET_FAILED, {
          general: {
            msg:
              'トークンが無効です、再度パスワードリセットリクエストを行ってください'
          }
        });
      } else {
        throw new Error('想定していないステータスコード');
      }
    },
    /**
     * パスワード変更画面を初期化する
     * @param {Object} context
     * @param {function} context.commit
     * @returns {Promise<void>}
     */
    async [INITIALIZE_CHANGE_PASSWORD_PAGE]({ commit }) {
      commit(CHANGE_PASSWORD_PAGE_SHOW);
    },
    /**
     * パスワードを変更する
     * @param {Object} context
     * @param {function} context.commit
     * @param {Object} param
     * @param {string} param.currentPassword
     * @param {string} param.newPassword
     * @returns {Promise<void>}
     */
    async [CHANGE_PASSWORD]({ commit }, { currentPassword, newPassword }) {
      commit(CHANGE_PASSWORD_REQUEST);
      const response = await axios.post(
        '/settings/password',
        { currentPassword, newPassword },
        {
          validateStatus: (s) => [200, 400, 401, 404].includes(s)
        }
      );
      if (response.status === 200) {
        commit(CHANGE_PASSWORD_SUCCESS);
      } else if (response.status === 400 || response.status === 401) {
        commit(CHANGE_PASSWORD_FAILED, _.get(response, 'data.errors'));
      } else {
        throw new Error('想定していないステータスコード');
      }
    }
  }
};
