const _ = require('lodash');
const moment = require('moment-timezone');

/**
 * @param {?string} date
 * @param {?string} format
 * @returns {string}
 */
const formatDate = ({ date, format = 'YYYY/MM/DD HH:mm' }) => {
  if (_.isNil(date)) {
    return '';
  } else {
    return moment(date).tz('Asia/Tokyo').format(format);
  }
};

/**
 * @param {string} editor
 * @returns {string}
 */
const formatEditor = ({ editor }) => {
  if (!editor) {
    return 'システム';
  }
  if (editor.deleted_at) {
    return '削除されたユーザー';
  }
  return editor.email;
};

/**
 * @param {string} duty 関税を示す文字列
 * @returns {string}
 */
const formatDuty = (duty) => {
  const { DUTY_LABEL } = require('../../lib/document/schema/shipments');
  return DUTY_LABEL[duty];
};

export { formatDate, formatEditor, formatDuty };
