import querystring from 'querystring';
import _ from 'lodash';
import axios from '@/api/common/axios';
import { SHIPMENT_FETCHED } from '@/store/mutation-types';
import {
  APPROVE_SELECTED_REQUESTS,
  APPROVE_REQUESTS,
  FETCH_SHIPMENTS,
  REJECT_SELECTED_REQUESTS,
  REJECT_REQUESTS,
  CANCEL_SELECTED_REQUESTS,
  DOWNLOAD_SHIPMNTS_CSV_REQUESTS
} from '@/store/action-types';

export default {
  namespaced: true,
  state: {
    shipmentRequests: [],
    total: 0,
    approveTargetsCount: 0,
    rejectsTargetsCount: 0
  },
  mutations: {
    [SHIPMENT_FETCHED](
      state,
      { shipmentRequests, total, approveTargetsCount, rejectsTargetsCount }
    ) {
      state.shipmentRequests = [...shipmentRequests];
      state.total = total;
      state.approveTargetsCount = approveTargetsCount;
      state.rejectsTargetsCount = rejectsTargetsCount;
    }
  },
  actions: {
    async [FETCH_SHIPMENTS]({ commit }, query) {
      const errorMsg =
        'データ取得時にエラーがありました。セカイロジ担当者までお問い合わせください。';
      const response = await axios
        .get(`/shipments?${querystring.stringify(query)}`)
        .catch((e) => {
          throw new Error(_.get(e, 'response.data.errors.general', errorMsg));
        });

      if (_.get(response, 'data.status') === 'ng') {
        throw new Error(_.get(response, 'data.errors.general', errorMsg));
      }

      commit(SHIPMENT_FETCHED, {
        shipmentRequests: response.data.shipmentRequests,
        total: response.data.total,
        approveTargetsCount: response.data.approveTargetsCount,
        rejectsTargetsCount: response.data.rejectsTargetsCount
      });
    },
    /**
     * 選択した出荷を承認する
     * @param {Object} context
     * @param {string} selected
     * @returns {Promise<void>}
     */
    async [APPROVE_SELECTED_REQUESTS](context, selected) {
      await axios.post('/shipments/approve', {
        requestId: selected
      });
    },
    /**
     * 絞られた出荷を承認する
     * @returns {Promise<void>}
     */
    async [APPROVE_REQUESTS](context, params) {
      await axios.post('/shipments/approve', params);
    },
    /**
     * 選択した出荷を棄却する
     * @param {Object} context
     * @param {string} selected
     * @returns {Promise<void>}
     */
    async [REJECT_SELECTED_REQUESTS](context, selected) {
      await axios.post('/shipments/rejects', {
        requestId: selected
      });
    },
    /**
     * 絞り込まれた出荷を棄却する
     * @returns {Promise<void>}
     */
    async [REJECT_REQUESTS](context, params) {
      await axios.post('/shipments/rejects', params);
    },
    /**
     * 選択した出荷を取り消す
     * @param {Object} context
     * @param {string} selected
     * @returns {Promise<void>}
     */
    async [CANCEL_SELECTED_REQUESTS](context, selected) {
      await axios.post('/shipments/cancel', {
        requestIds: [selected]
      });
    },
    async [DOWNLOAD_SHIPMNTS_CSV_REQUESTS](context, query) {
      const errorMsg =
        'データ取得時にエラーがありました。サポートへご連絡ください。';
      await axios
        .get(`/shipments/csv?${querystring.stringify(query)}`)
        .catch((e) => {
          throw new Error(_.get(e, 'response.data.errors.general', errorMsg));
        });
    }
  }
};
