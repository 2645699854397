import _ from 'lodash';

import { ACCOUNT_REGISTRATIONS_FETCHED } from '../mutation-types';
import { FETCH_ACCOUNT_REGISTRATIONS } from '../action-types';
import axios from '../../api/common/axios';

export default {
  namespaced: true,
  state: {
    accountRegistrations: [],
    total: 0
  },
  mutations: {
    [ACCOUNT_REGISTRATIONS_FETCHED](state, { accountRegistrations, total }) {
      state.accountRegistrations = accountRegistrations;
      state.total = total;
    }
  },
  actions: {
    async [FETCH_ACCOUNT_REGISTRATIONS]({ commit }) {
      const errorMsg =
        'データ取得時にエラーがありました。セカイロジ担当者までお問い合わせください。';
      const response = await axios.get('/account_registrations').catch((e) => {
        throw new Error(_.get(e, 'response.data.errors.general', errorMsg));
      });

      commit(ACCOUNT_REGISTRATIONS_FETCHED, {
        accountRegistrations: response.data.accountRegistrations,
        total: parseInt(response.data.total, 10)
      });
    }
  }
};
