'use strict';

const _ = require('lodash');

const CATEGORY_PATTERN = [
  'CREATE_ITEM', // Items 作成
  'UPDATE_ITEM', // Items 更新
  'STOCK', // 入庫
  'STOCKTAKE', // 棚卸し
  'REPLACEMENT', // 代替品入庫
  'PROCESSED', // 福袋など在庫を消費しての在庫生成
  'RETURN', // 返品されてきた入庫
  'RETURN_DAMAGE', // 返品されてきた入庫
  'RETURN_DETECTED', // 返送の検出
  'DAMAGE', // 破損
  'DISPOSAL', // 破棄
  'SYSTEM_TROUBLE', // バグなどによって増えた在庫削除
  'SHIP', // 出荷
  'RESHIP', // 再出荷
  'REJECT_SHIPMENT', // 出荷棄却
  'CANCEL_SHIPMENT', // 出荷指示キャンセル
  'ROLLBACKED', // 出荷処理中断 同梱で出荷するItemの在庫不足またはAPIエラーでロールバックされた
  'SASAGE', // ささげによる三辺重量更新
  'UPDATE_SIZE', // サイズ更新
  'UPDATE_BARCODE_NEEDS_LABEL_PRINT', // バーコード貼付の変更
  'PRE_ORDER_SHIPMENT', // 出荷予約のItemsの出荷
  'EDIT_SHIPMENT_REQUEST', // 出荷依頼の編集
  'STOCK_EXPIRED', // 消費期限超過
  'DRAFT_SHIPMENT', // 出荷ドラフトへの変更
  'SWAP_STOCKS',
  'REAL_STOCKS_ADJUSTMENT',
  'OTHER'
];

const CATEGORY_TEXT = {
  CREATE_ITEM: '商品情報作成',
  UPDATE_ITEM: '商品情報編集',
  STOCK: '入庫(良品)',
  STOCKTAKE: '棚卸し',
  REPLACEMENT: '代替品入庫',
  PROCESSED: '加工',
  RETURN: '返品入庫(良品)',
  RETURN_DAMAGE: '返品入庫(不良)',
  RETURN_DETECTED: '返送検出',
  DAMAGE: '入庫(不良)',
  DISPOSAL: '廃棄',
  SYSTEM_TROUBLE: 'システムトラブルによる在庫差分の解消',
  SHIP: '出荷',
  RESHIP: '再出荷',
  REJECT_SHIPMENT: '出荷棄却',
  CANCEL_SHIPMENT: '出荷キャンセル',
  ROLLBACKED: '出荷処理中断',
  SASAGE: '計量・計測',
  UPDATE_SIZE: '商品情報更新',
  UPDATE_BARCODE_NEEDS_LABEL_PRINT: 'バーコード貼付情報の更新',
  PRE_ORDER_SHIPMENT: '出荷依頼作成',
  EDIT_SHIPMENT_REQUEST: '出荷依頼編集',
  STOCK_EXPIRED: '消費期限超過',
  DRAFT_SHIPMENT: '出荷ドラフトへの変更',
  SWAP_STOCKS: '在庫付け替え',
  REAL_STOCKS_ADJUSTMENT: '実在庫数補正',
  OTHER: 'その他'
};

const CATEGORY = _.zipObject(CATEGORY_PATTERN, CATEGORY_PATTERN);

module.exports = {
  CATEGORY_PATTERN,
  CATEGORY_TEXT,
  CATEGORY
};
