<template>
  <v-container fluid fill-height>
    <v-layout row wrap align-center justify-center>
      <template v-if="error">
        <v-flex>
          <h4 class="title">エラーが発生しました</h4>
          <h1 class="display-1 font-weight-thin mb-4">
            {{ error }}
          </h1>
        </v-flex>
      </template>
      <template v-else>
        <v-flex md6 xs12 text-center>
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
          <p>ダウンロードしています</p>
        </v-flex>
      </template>
    </v-layout>
  </v-container>
</template>

<script>
import axios from '@/api/common/axios';

export default {
  async beforeMount() {
    await this.fetch();
  },
  data: () => {
    return {
      error: null
    };
  },
  methods: {
    async fetch() {
      const url = this.$route.path;
      try {
        const response = await axios({
          url,
          method: 'GET',
          responseType: 'arraybuffer'
        });

        const contentType = response.headers['content-type'];

        // ajaxでダウンロードするためのイディオム
        // createObjectURL を作って，aタグで自動的にクリックしてダウンロードさせる
        const href = window.URL.createObjectURL(new Blob([response.data]), {
          type: contentType || 'octet/stream'
        });
        const link = document.createElement('a');
        link.href = href;
        link.download = this.buildDownloadFileName({ url, contentType });
        document.body.appendChild(link);
        link.click();
      } catch (blobError) {
        // responseTypeをblobでリクエストしているので，jsonで解釈して上げる必要がある
        const responseError = JSON.parse(
          Buffer.from(blobError.response.data).toString('utf8')
        );

        if (responseError) {
          this.error = responseError.errors && responseError.errors.general;
        } else {
          this.error =
            'URLをお確かめください。URLに問題がない場合、サポートへご連絡ください。x';
        }
      }
    },
    /**
     * URL から保存用のファイル名を生成する
     * @param {string} baseUrl
     * @param {string} contentType
     * @returns {string}
     */
    buildDownloadFileName({ url, contentType }) {
      const uploadedPath = url.split('/download/').pop();
      const [type, , date] = uploadedPath.split('/');
      const typeForUrl = (() => {
        switch (type) {
          case 'monthly_reports':
            return 'monthly_report';

          case 'lists':
            return 'list';
          default:
            return `${type}_report`;
        }
      })();

      return `${date}_${typeForUrl}.${contentType.split('/').pop()}`;
    }
  }
};
</script>
