/**
 * 渡されたtabsがpathにヒットするかを調べ，ヒットしたtabを返す
 * @param {object} param
 * @param {object[]} tabs タブの集合，path, componentなどをkeyに持つ
 * @param {string} path 検査するpathを渡す．this.$route.pathを渡す．
 * @returns {object|undefined} ヒットするtabがあれば，tabのobjectを返し，そうでなければundefined
 */
const findSelectedTab = function ({ tabs, path }) {
  const tab = tabs.find((tab) => {
    return tab.path === path;
  });

  return tab;
};

export { findSelectedTab };
