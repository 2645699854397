import Vue from 'vue';
import Zendesk from '@dansmaculotte/vue-zendesk';
import axios from './api/common/axios';
import { widgetKey } from '@/constants/zendesk';

/**
 * Web widgetの設定
 */
const CONFIG = {
  key: widgetKey,
  hideOnLoad: true,
  settings: {
    webWidget: {
      authenticate: {
        chat: {
          connectOnPageLoad: false
        }
      }
    }
  }
};

export default {
  vue: null,
  /**
   * イベントリスナーの初期化を行なったかどうか
   */
  eventInitialized: false,
  /*
   * 認証トークンを取得したか
   */
  authenticated: false,
  /*
   * 直前に送られたzendesk chatのステータス
   */
  lastStatus: null,
  CONFIG,
  /**
   * ユーザー認証用トークンの取得
   */
  jwtFn: function (callback) {
    this.authenticated = false;
    axios
      .get('/auth/zendesk_token', {
        headers: { 'X-Requested-With': 'XMLHttpRequest' }
      })
      .then(
        (response) => {
          this.authenticated = true;
          const jwt = response.data.token;
          callback(jwt);
        },
        () => {
          this.hide();
        }
      );
  },
  /**
   * Zendeskプラグインの登録を行う
   */
  setup: function () {
    const config = CONFIG;
    config.settings.webWidget.authenticate.chat.jwtFn = (callback) => {
      this.jwtFn(callback);
    };
    Vue.use(Zendesk, config);
  },
  /**
   * zendeskがロードされていなかったらロードされるまで待つ
   */
  waitReady: async function () {
    if (this.vue.$zendesk.zE) {
      return Promise.resolve();
    }
    return new Promise((resolve) => {
      this.vue.$zendesk.$on('loaded', () => {
        resolve();
      });
    });
  },
  /**
   * Vueのマウント時にウィジェットを初期化するためのメソッド
   * ウィジェットを表示し、各種イベントを捕捉して表示を切り替える。
   */
  initialize: async function (vue) {
    this.vue = vue;
    await this.waitReady();
    this.show({ refreshLogin: true });
    this.watchLoginStatus();
  },
  /**
   * セカイロジにログインしているときにウィジェットを表示する。
   * @param {boolean} refreshLogin jwtの再取得を行ってログイン情報をウィジェットに送る
   */
  show: function ({ refreshLogin } = {}) {
    if (refreshLogin) {
      this.vue.$zendesk.zE('webWidget', 'chat:reauthenticate');
    }
    this.vue.$zendesk.show();
    this.setUpHelpdeskOfflined();
  },
  /**
   * セカイロジからログアウトしているときにウィジェットを非表示にする
   */
  hide: function () {
    this.vue.$zendesk.logout();
    this.vue.$zendesk.hide();
  },
  /**
   * チャットを行った後、zendeskのヘルプデスクがオフラインになったらメッセージフォームの入力になるようにする
   */
  setUpHelpdeskOfflined: async function () {
    if (this.eventInitialized) {
      return;
    }
    this.eventInitialized = true;
    this.vue.$zendesk.zE('webWidget:on', 'chat:status', (status) => {
      // reauthenticateで無限ループが起きるのを防止
      if (
        !['away', 'offline'].includes(this.lastStatus) &&
        ['away', 'offline'].includes(status)
      ) {
        this.vue.$zendesk.zE('webWidget', 'chat:end');
        // reauthenticateをしないとフォームに切り替わらない
        this.vue.$zendesk.zE('webWidget', 'chat:reauthenticate');
      }
      // offline -> online でチャットに戻るようにするには
      // zendesk管理コンソールのウェブウィジェット設定->問い合わせフォームをoffにしておかなければならない
      this.lastStatus = status;
    });
  },
  /**
   * 認証状態の変更を捕捉してユーザーを切り替える
   */
  watchLoginStatus: function () {
    const zendesk = this;
    this.vue.$store.subscribe((mutation) => {
      if (mutation.type === 'user/LOGIN_SUCCESS') {
        zendesk.show({ refreshLogin: true });
      } else if (mutation.type === 'auth/AUTHENTICATE_SUCCESS') {
        zendesk.show();
      } else if (mutation.type === 'user/LOGOUT_SUCCESS') {
        zendesk.hide();
      } else if (mutation.type === 'auth/AUTHENTICATE_FAILED') {
        zendesk.hide();
      }
    });
  }
};
