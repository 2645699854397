'use strict';

// https://en.wikipedia.org/wiki/Provinces_of_China
module.exports = {
  北京: 'Beijing',
  上海: 'Shanghai',
  天津: 'Tianjin',
  重庆: 'Chongqing',
  河北: 'Hebei',
  山西: 'Shanxi',
  河南: 'Henan',
  辽宁: 'Liaoning',
  吉林: 'Jilin',
  黑龙江: 'Heilongjiang',
  内蒙古: 'Inner Mongolia',
  江苏: 'Jiangsu',
  山东: 'Shandong',
  安徽: 'Anhui',
  浙江: 'Zhejiang',
  福建: 'Fujian',
  湖北: 'Hubei',
  湖南: 'Hunan',
  广东: 'Guangdong',
  广西: 'Guangxi',
  江西: 'Jiangxi',
  四川: 'Sichuan',
  海南: 'Hainan',
  贵州: 'Guizhou',
  云南: 'Yunnan',
  西藏: 'Tibet',
  陕西: 'Shaanxi',
  甘肃: 'Gansu',
  青海: 'Qinghai',
  宁夏: 'Ningxia',
  新疆: 'Xinjiang',
  台湾: 'Taiwan',
  香港: 'Hong Kong',
  澳门: 'Macau'
};
