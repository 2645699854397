'use strict';

import axios from 'axios';

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (
      error.response &&
      error.response.data &&
      error.response.data.errors &&
      error.response.data.errors.code &&
      error.response.data.errors.code === 'ERR_MAINTENANCE'
    ) {
      error.message = 'メンテナンス中です。';
    }
    return Promise.reject(error);
  }
);

axios.interceptors.request.use(function (config) {
  const csrfTokenTag = document.querySelector('meta[name="csrf-token"]');
  if (csrfTokenTag === null) {
    throw new Error('csrf-token がセットされていません');
  }
  config.headers = {
    'X-CSRF-Token': csrfTokenTag.content,
    'X-Requested-With': 'XMLHttpRequest',
    Pragma: 'no-cache' //IE11のapiキャッシュを対策するために事前にinterceptorsを設定
  };
  return config;
});

export default axios;
