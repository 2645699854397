<template>
  <v-container px-0>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-layout align-center justify-center>
              <v-flex md6 xs12 text-center>
                <v-form @submit.prevent>
                  <v-text-field
                    required
                    :type="showCurrentPassword ? 'text' : 'password'"
                    name="currentPassword"
                    v-model="currentPassword"
                    data-vv-as="現在のパスワード"
                    v-validate="'required|max:256'"
                    data-vv-validate-on="blur"
                    :error-messages="currentPasswordErrors"
                    prepend-icon="lock"
                    :append-icon="
                      showCurrentPassword ? 'visibility' : 'visibility_off'
                    "
                    @click:append="showCurrentPassword = !showCurrentPassword"
                    label="現在のパスワード"
                    placeholder="password"
                    @keyup.enter="$refs.newPassword.focus()"
                  ></v-text-field>
                  <v-text-field
                    required
                    :type="showNewPassword ? 'text' : 'password'"
                    name="newPassword"
                    v-model="newPassword"
                    data-vv-as="新しいパスワード"
                    v-validate="'required|max:256'"
                    data-vv-validate-on="blur"
                    :error-messages="newPasswordErrors"
                    prepend-icon="lock"
                    :append-icon="
                      showNewPassword ? 'visibility' : 'visibility_off'
                    "
                    @click:append="showNewPassword = !showNewPassword"
                    label="新しいパスワード"
                    placeholder="password"
                    @keyup.enter="change"
                    loading
                    ref="newPassword"
                  >
                    <template v-slot:progress>
                      <v-progress-linear
                        :value="progress"
                        :color="color"
                        height="7"
                        absolute
                      ></v-progress-linear>
                    </template>
                  </v-text-field>
                </v-form>
                <PasswordGuide></PasswordGuide>
                <v-btn color="primary" @click="change">パスワード変更</v-btn>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const _ = require('lodash');

import PasswordGuide from '@/components/PasswordGuide';
import { getPercentage, getPercentageColor, mergeErrors } from '@/util/input';
import {
  CHANGE_PASSWORD,
  FINISH_LOADING,
  INITIALIZE_CHANGE_PASSWORD_PAGE,
  START_LOADING
} from '@/store/action-types';

export default {
  components: {
    PasswordGuide
  },
  $_veeValidate: {
    validator: 'new'
  },
  data: () => ({
    currentPassword: '',
    newPassword: '',
    showCurrentPassword: false,
    showNewPassword: false
  }),
  computed: {
    currentPasswordErrors: (self) =>
      mergeErrors(
        self.$validator.errors,
        self.$store.state.auth.changePasswordErrors,
        'currentPassword'
      ),
    newPasswordErrors: (self) =>
      mergeErrors(
        self.$validator.errors,
        self.$store.state.auth.changePasswordErrors,
        'newPassword'
      ),
    progress: (self) => getPercentage(self.newPassword.length, 12),
    color: (self) => getPercentageColor(self.progress)
  },
  async beforeMount() {
    await this.$store.dispatch(`auth/${INITIALIZE_CHANGE_PASSWORD_PAGE}`);
  },
  methods: {
    /**
     * パスワード変更処理を行う
     * @returns {Promise<void>}
     */
    async change() {
      this.$validator.errors.clear();
      await this.$store.dispatch('notify/clearNotify');
      if (await this.$validator.validate()) {
        await this.$store.dispatch(`app/${START_LOADING}`, new Date());
        try {
          await this.$store.dispatch(`auth/${CHANGE_PASSWORD}`, {
            currentPassword: this.currentPassword,
            newPassword: this.newPassword
          });
        } catch (e) {
          await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
          await this.$store.dispatch(
            'notify/showErrorNotify',
            'エラーが発生しました、画面を更新してやり直してください'
          );
          throw e;
        }
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());

        const serverErrors = this.$store.state.auth.changePasswordErrors;
        if (serverErrors === null) {
          this.$refs.newPassword.blur();
          this.currentPassword = '';
          this.newPassword = '';
          await this.$store.dispatch(
            'notify/showNotify',
            'パスワードの変更が完了しました'
          );
        } else {
          const generalError = _.get(serverErrors, 'general.msg');
          if (generalError) {
            await this.$store.dispatch('notify/showErrorNotify', generalError);
          }
        }
      }
    }
  }
};
</script>
