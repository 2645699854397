<template>
  <div>
    <MainHeader :title="pageTitle" :back-to="backToRoute">
      <template v-if="isFlyerUsableOwner && isListView">
        <v-btn color="primary" elevation="0" class="ml-2" to="/flyers/form">
          フライヤー作成
        </v-btn>
      </template>
    </MainHeader>

    <template v-if="isFlyerUsableOwner">
      <v-scroll-x-reverse-transition mode="out-in">
        <router-view></router-view>
      </v-scroll-x-reverse-transition>
    </template>
    <template v-else>
      <v-layout justify-center>
        <v-card max-width="480" class="mt-12">
          <v-card-text>
            <p>
              フライヤー同梱サービスのご利用を検討のお客様は、担当営業または以下アドレス宛にご連絡ください。
            </p>
            <v-layout justify-center>
              <v-btn
                outlined
                color="primary"
                elevation="0"
                href="mailto:contact_sekailogi@tokyootakumode.com"
              >
                contact_sekailogi@tokyootakumode.com
              </v-btn>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-layout>
    </template>
  </div>
</template>

<script>
import MainHeader from '@/components/layout/MainHeader';
import { mapGetters } from 'vuex';
import { REALM } from '../../lib/document/schema/users';

const PATHS = {
  INDEX: '/flyers/index',
  CREATE: '/flyers/form'
};

export default {
  components: {
    MainHeader
  },
  data: () => {
    return {};
  },
  computed: {
    pageTitle() {
      return this.$route.name;
    },
    isListView() {
      return this.$route.path === PATHS.INDEX;
    },
    backToRoute() {
      if (this.isListView) {
        return null;
      }
      return {
        path: PATHS.INDEX,
        query: this.lastQuery
      };
    },
    isFlyerUsableOwner: function () {
      return this.hasRealm(REALM.FLYERS);
    },
    ...mapGetters('user', ['hasRealm'])
  }
};
</script>
