<template>
  <v-container fluid fill-height>
    <v-layout row wrap align-center justify-center>
      <template v-if="error">
        <v-flex>
          <h4 class="title">エラーが発生しました</h4>
          <h1 class="display-1 font-weight-thin mb-4">
            {{ error }}
          </h1>
        </v-flex>
      </template>
      <template v-else>
        <v-flex md6 xs12 text-center>
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
          <p>リダイレクトしています</p>
        </v-flex>
      </template>
    </v-layout>
  </v-container>
</template>

<script>
import axios from '@/api/common/axios';

export default {
  async beforeMount() {
    await this.fetch();
  },
  data: () => {
    return {
      error: null
    };
  },
  methods: {
    async fetch() {
      try {
        const response = await axios.get(this.$route.path);
        this.$router.push(response.data.redirect);
      } catch (error) {
        if (error) {
          this.error =
            error &&
            error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.general;
        } else {
          this.error =
            'URLをお確かめください。URLに問題がない場合、サポートへご連絡ください。';
        }
      }
    }
  }
};
</script>
