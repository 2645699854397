<template>
  <v-container>
    <v-layout row wrap class="my-0" >
      <v-flex>
        <v-btn
          text
          color="primary accent-4"
          class="v-btn--active"
          @click.prevent="onClick"
        >
          <template v-if="showText && text">
            <v-icon>attach_file</v-icon>
            {{ text }}
          </template>
          <template v-else>{{ defaultText }}</template>
        </v-btn>
        <input
          type="file"
          ref="input"
          style="display: none"
          @change="onFileSelected"
          :accept="accept"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'FileInputBtn',
  props: {
    accept: String,
    showText: {
      type: Boolean,
      default: true
    },
    defaultText: {
      type: String,
      default: 'クリックしてファイルを選択します'
    }
  },
  data() {
    return {
      text: ''
    };
  },
  computed: {},
  watch: {},

  methods: {
    onClick() {
      this.$refs.input.value = '';
      this.$refs.input.click();
    },
    onFileSelected(e) {
      if (!e || !e.target || !e.target.files) {
        return;
      }
      const files = e.target.files;
      const file = files[0];
      this.$emit('input', file);
      this.text = file.name;
    }
  }
};
</script>
