import axios from '@/api/common/axios';
import {
  SHOW_AGREEMENT_DIALOG,
  CLOSE_AGREEMENT_DIALOG,
  AGREE_SUCCESS
} from '../mutation-types';
import { NOTIFY_NEW_AGREEMENT_VERSION_IF_EXISTS, AGREE } from '../action-types';

export default {
  namespaced: true,
  /**
   * @property {boolean} dialog - 利用規約同意ダイアログを表示するかどうか
   */
  state: {
    dialog: false,
    currentAgreementVersion: ''
  },
  mutations: {
    [SHOW_AGREEMENT_DIALOG](state, { currentAgreementVersion }) {
      state.dialog = true;
      state.currentAgreementVersion = currentAgreementVersion;
    },
    [CLOSE_AGREEMENT_DIALOG](state) {
      state.dialog = false;
    }
  },
  actions: {
    /**
     * 利用規約の新しいバージョンがあったら同意ダイアログを表示する
     * @param {Function} commit
     * @param {string} userAgreementVersion
     * @returns {Promise<void>}
     */
    async [NOTIFY_NEW_AGREEMENT_VERSION_IF_EXISTS](
      { commit },
      { userAgreementVersion }
    ) {
      const currentAgreementVersion = (await axios.get('/agreement.json')).data;
      if (
        parseInt(userAgreementVersion || '0', 10) <
        parseInt(currentAgreementVersion, 10)
      ) {
        commit(SHOW_AGREEMENT_DIALOG, { currentAgreementVersion });
      }
    },

    /**
     * 利用規約に同意する
     * @param {Function} commit
     * @returns {Promise<void>}
     */
    async [AGREE]({ commit }, { currentAgreementVersion }) {
      const userAgreementVersion = (
        await axios.post('/agree', {
          version: currentAgreementVersion
        })
      ).data.agreementVersion;
      commit(`user/${AGREE_SUCCESS}`, userAgreementVersion, { root: true });
      commit(CLOSE_AGREEMENT_DIALOG);
    }
  }
};
