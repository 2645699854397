<template>
  <v-container px-0>
    <v-row>
      <v-col>
        <v-card class="mb-2">
          <v-card-text>
            <v-flex xs4>
              <h3 class="bold">権限の編集</h3>
            </v-flex>
            <v-flex xs4>
              <v-checkbox
                v-model="permissions"
                label="フライヤー利用"
                value="FLYERS"
              ></v-checkbox>
              <v-checkbox
                v-model="permissions"
                label="倉庫機能"
                value="WAREHOUSE"
              ></v-checkbox>
            </v-flex>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { UPDATE_OWNER_PERMISSION } from '@/store/action-types';
import {
  START_LOADING,
  FINISH_LOADING,
  FETCH_OWNER
} from '@/store/action-types';

export default {
  props: {
    ownerId: {
      type: String,
      default: ''
    }
  },
  async mounted() {
    await this.$store.dispatch(`app/${START_LOADING}`, new Date());
    await this.$store
      .dispatch(`owner/${FETCH_OWNER}`, { owner: this.ownerId })
      .then(async () => {
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
      })
      .catch(async (e) => {
        await this.$store.dispatch(
          'notify/showErrorNotify',
          e.response.data.errors.general.msg
        );
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
      });
  },
  data() {
    return {};
  },
  computed: {
    permissions: {
      get() {
        return Array.from(this.$store.state.owner.permissions);
      },
      set(value) {
        try {
          this.$store.dispatch(`owner/${UPDATE_OWNER_PERMISSION}`, {
            ownerId: this.ownerId,
            permissions: value
          });
        } catch (error) {
          this.$store.dispatch('notify/showErrorNotify', error.message);
        }
      }
    }
  },
  methods: {}
};
</script>
