<template>
  <v-container fluid fill-height>
    <v-layout row wrap align-center justify-center>
      <v-flex md4 xs4 text-center>
        <Logo></Logo>
        <v-card class="mt-10 mx-auto" max-width="480">
          <v-card-text class="pa-6">
            <template v-if="$store.state.auth.tokenIsValid">
              <v-form @submit.prevent>
                <p>新しいパスワードを入力してください</p>
                <v-text-field
                  outlined
                  required
                  :type="show ? 'text' : 'password'"
                  name="password"
                  v-model="password"
                  data-vv-as="パスワード"
                  v-validate="'required|max:256'"
                  data-vv-validate-on="blur"
                  :error-messages="passwordErrors"
                  prepend-inner-icon="lock"
                  :append-icon="show ? 'visibility' : 'visibility_off'"
                  @click:append="show = !show"
                  label="パスワード"
                  @keyup.enter="reset"
                  loading
                >
                  <template v-slot:progress>
                    <v-progress-linear
                      :value="progress"
                      :color="color"
                      height="7"
                      absolute
                    ></v-progress-linear>
                  </template>
                </v-text-field>
              </v-form>
              <PasswordGuide></PasswordGuide>
              <v-flex text-center>
                <v-btn @click="reset" color="primary" large depressed
                  >リセット</v-btn
                >
              </v-flex>
            </template>
            <template v-else>
              <p>
                トークンの有効期限が切れています。
                <router-link to="/auth/forgot"> こちら </router-link>
                からトークンを再発行してください。
              </p>
            </template>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
const _ = require('lodash');

import PasswordGuide from '@/components/PasswordGuide';
import { getPercentage, getPercentageColor, mergeErrors } from '@/util/input';
import {
  FINISH_LOADING,
  INITIALIZE_RESET_PAGE,
  START_LOADING
} from '@/store/action-types';

import Logo from '../components/Logo.vue';

export default {
  components: {
    Logo,
    PasswordGuide
  },
  $_veeValidate: {
    validator: 'new'
  },
  data: () => ({
    password: '',
    show: false
  }),
  computed: {
    token: (self) => self.$route.query['token'],
    passwordErrors: (self) =>
      mergeErrors(
        self.$validator.errors,
        self.$store.state.auth.resetErrors,
        'password'
      ),
    progress: (self) => getPercentage(self.password.length, 12),
    color: (self) => getPercentageColor(self.progress)
  },
  async beforeMount() {
    try {
      await this.$store.dispatch(`auth/${INITIALIZE_RESET_PAGE}`, this.token);
    } catch (e) {
      await this.$store.dispatch(
        'notify/showErrorNotify',
        'エラーが発生しました、画面を更新してやり直してください'
      );
      throw e;
    }
  },
  methods: {
    /**
     * パスワードリセット処理を行う
     * @returns {Promise<void>}
     */
    async reset() {
      this.$validator.errors.clear();
      await this.$store.dispatch('notify/clearNotify');
      if (await this.$validator.validate()) {
        await this.$store.dispatch(`app/${START_LOADING}`, new Date());
        try {
          await this.$store.dispatch('auth/reset', {
            token: this.token,
            password: this.password
          });
        } catch (e) {
          await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
          await this.$store.dispatch(
            'notify/showErrorNotify',
            'エラーが発生しました、画面を更新してやり直してください'
          );
          throw e;
        }
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());

        const serverErrors = this.$store.state.auth.resetErrors;
        if (serverErrors === null) {
          this.$router.push({
            path: '/login',
            query: {
              notifyType: 'showNotify',
              notifyMessageCode: this.$router.MESSAGE_CODES
                .PASSWORD_RESET_SUCCESS
            }
          });
        } else {
          const generalError = _.get(serverErrors, 'general.msg');
          if (generalError) {
            await this.$store.dispatch('notify/showErrorNotify', generalError);
          }
        }
      }
    }
  }
};
</script>

<style>
#layout-view {
  padding-bottom: 0;
}
</style>
