const _ = require('lodash');

import querystring from 'querystring';
import axios from '@/api/common/axios';
import { FETCH_PROGRESS } from '@/store/action-types';
import { PROGRESS_FETCH_SUCCESS } from '@/store/mutation-types';

export default {
  namespaced: true,
  /**
   * @typedef {Object} Count
   * @property {number} completed
   * @property {number} error
   * @property {number} total
   */
  /**
   * @typedef {Object} PreparationItem
   * @property {string} _id
   * @property {string} email
   * @property {Count} count
   */
  /**
   * @typedef {Object} ProcessingItem
   * @property {string} _id
   * @property {string} email
   * @property {Count} count
   */
  /**
   * @typedef {Object} CompletedItem
   * @property {string} _id
   * @property {string} email
   * @property {string} error_messages
   * @property {string} slip_url
   */
  /**
   * @property {Object} state
   * @property {number} state.totalAll - 非所属の荷主も含めて全体の処理の数
   * @property {number} state.totalCurrent - 所属する荷主に関する処理の数
   * @property {PreparationItem[]} state.preparationItems - 表示するタスク起動待ち処理の一覧
   * @property {ProcessingItem[]} state.processingItems - 表示する待ち処理の一覧
   * @property {CompletedItem[]} state.completedItems - 表示する完了済み処理の一覧
   */
  state: {
    totalAll: 0,
    totalCurrent: 0,
    preparationItems: [],
    processingItems: [],
    completedItems: []
  },
  mutations: {
    [PROGRESS_FETCH_SUCCESS](
      state,
      {
        totalAll,
        totalCurrent,
        preparationItems,
        processingItems,
        completedItems
      }
    ) {
      if (
        !_.isInteger(totalAll) ||
        !_.isInteger(totalCurrent) ||
        !_.isArray(preparationItems) ||
        !_.isArray(processingItems) ||
        !_.isArray(completedItems)
      ) {
        throw new Error('処理中のCSV一覧が取得できませんでした');
      }

      state.totalAll = totalAll;
      state.totalCurrent = totalCurrent;
      state.preparationItems = preparationItems.map((i) => ({
        _id: _.get(i, '_id', ''),
        email: _.get(i, 'email', 'データが取得できませんでした'),
        created_at: _.get(i, 'created_at')
      }));
      state.processingItems = processingItems.map((i) => ({
        _id: _.get(i, '_id', ''),
        email: _.get(i, 'email', 'データが取得できませんでした'),
        count: _.get(i, 'count', {
          completed: 0,
          error: 0,
          total: 0
        }),
        created_at: _.get(i, 'created_at')
      }));
      state.completedItems = completedItems.map((i) => ({
        _id: _.get(i, '_id', ''),
        state: _.get(i, 'state', ''),
        email: _.get(i, 'email', 'データが取得できませんでした'),
        error_messages: _.get(i, 'error_messages'),
        created_at: _.get(i, 'created_at'),
        slip_url: _.get(i, 'slip_url'),
        link: _.get(i, 'link', '')
      }));
    }
  },
  actions: {
    /**
     * CSVアップロードの進捗情報を取得する
     * @param {Object} context
     * @param {function} context.commit
     * @param {Object} query
     * @param {string} query.category
     * @param {number} query.processingLimit
     * @param {number} query.completedLimit
     * @returns {Promise<void>}
     */
    async [FETCH_PROGRESS](
      { commit },
      { category, processingLimit, completedLimit }
    ) {
      const query = {
        category,
        processingLimit,
        completedLimit
      };

      const response = await axios.get(
        `/upload/progress?${querystring.stringify(query)}`
      );

      commit(PROGRESS_FETCH_SUCCESS, {
        totalAll: _.get(response, 'data.processing.total_all'),
        totalCurrent: _.get(response, 'data.processing.total_current'),
        preparationItems: _.get(response, 'data.preparation.items'),
        processingItems: _.get(response, 'data.processing.items'),
        completedItems: _.get(response, 'data.completed.items')
      });
    }
  }
};
