<template>
  <div>
    <MainHeader :title="pageTitle" :back-to="backToRoute">
      <!-- disableTomOwnerでisTomUserを満たすときだけ非表示-->
      <template v-if="isListView && !$store.state.user.isTomUser">
        <v-btn color="primary" elevation="0" class="ml-2" to="/bundles/form">
          セット商品登録
        </v-btn>
      </template>
    </MainHeader>

    <v-scroll-x-reverse-transition mode="out-in">
      <router-view></router-view>
    </v-scroll-x-reverse-transition>
  </div>
</template>

<script>
import MainHeader from '@/components/layout/MainHeader';

const PATHS = {
  INDEX: '/bundles/index',
  CREATE: '/bundles/create'
};

export default {
  components: {
    MainHeader
  },
  data: () => {
    return {};
  },
  computed: {
    pageTitle() {
      return this.$route.name;
    },
    isListView() {
      return this.$route.path === PATHS.INDEX;
    },
    backToRoute() {
      if (this.isListView) {
        return null;
      }
      return {
        path: PATHS.INDEX
      };
    }
  }
};
</script>
