<!--

CsvUploadForm(:category="CATEGORY.CREATE_ITEM")
のようにして使う．
category に指定するものは
import { CATEGORY } from '../../lib/document/schema/csv_upload_states';
のようにimportして指定する．

ファイルインプットをクリックするとファイル選択が呼び出され，アップロードボタンを押すと指定されたファイルをアップロードする．
（アップロード先は /upload/csv になる）
その後 category で指定した種類の処理がサーバサイドで実行され，アップロード者と所属する荷主に通知メールが送られる．

-->
<template>
  <v-container>
    <v-layout>
      <v-row class="fill-height my-0" align-content="center" justify="center">
        <template v-if="loading">
          <v-col cols="6">
            <v-progress-linear
              color="primary accent-4"
              indeterminate
              rounded
              height="6"
            />
          </v-col>
          <v-col class="subtitle-1 text-center" cols="12">
            ファイルをアップロード中です
          </v-col>
        </template>
        <template v-else>
          <v-card
            outlined
            @drop.prevent="onDrop($event)"
            @dragover.prevent="dragover = true"
            @dragenter.prevent="dragover = true"
            @dragleave.prevent="dragover = false"
            :class="{ 'grey lighten-2': dragover }"
          >
            <v-col class="subtitle-1 text-center" cols="12">
              <FileInputBtn accept="text/csv" v-model="file" />
              <div v-if="!dragover">
                <p>
                  <v-icon large>upload_file</v-icon>
                </p>
                またはこちらにファイルをドロップしてください
              </div>

              <div color="red lighten-1" v-else>
                <p><v-icon large>task</v-icon></p>
                ファイルをドラック中です。ファイルをドロップしてください。
              </div>
            </v-col>
          </v-card>
        </template>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
import axios from '../api/common/axios';
import FileInputBtn from '@/components/FileInputBtn';

export default {
  components: {
    FileInputBtn
  },
  props: {
    category: String // アップロードするCSVが何用のCSVなのかを指定する
  },
  data: () => ({
    file: null, // アップロードするファイルオブジェクト
    loading: false, // 通信中かどうかを制御する
    dragover: false // ファイルのドラック状態
  }),
  watch: {
    file: async function () {
      await this.submitFile();
    }
  },
  methods: {
    onDrop(e) {
      this.dragover = false;

      if (!e || !e.dataTransfer || e.dataTransfer.files.length === 0) {
        return;
      }

      this.file = e.dataTransfer.files[0];
    },

    async submitFile() {
      const formData = new FormData();
      formData.append('csv', this.file);
      formData.append('category', this.category);
      this.loading = true;
      try {
        const response = await axios.post('/upload/csv', formData, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        });

        this.loading = false;

        if (response.data.status === 'ng') {
          this.$store.dispatch(
            'notify/showErrorNotify',
            `CSVファイルのアップロードに失敗しました: ${response.data.errors.general}`
          );

          return;
        }

        this.$store.dispatch(
          'notify/showNotify',
          'CSVファイルのアップロードが成功しました。処理完了時にメールで通知します。'
        );
      } catch (error) {
        this.loading = false;
        this.$store.dispatch(
          'notify/showErrorNotify',
          `CSVファイルのアップロード時に通信エラーが発生しました。${error.message}`
        );
      }
    }
  }
};
</script>
