import { FINISH_LOADING, START_LOADING } from '@/store/action-types';
import {
  LOADING_FINISH,
  LOADING_START,
  TOGGLE_DRAWER
} from '@/store/mutation-types';

export default {
  namespaced: true,
  /**
   * @property {Object} state
   * @property {boolean} state.drawer - ドロワーを表示するかどうか
   * @property {boolean} state.loading - ローディングを表示するかどうか
   * @property {?Date} state.loadStartAt - ローディングが早く消えすぎるのを防ぐため
   */
  state: {
    drawer: true,
    loading: false,
    loadStartAt: null
  },
  mutations: {
    [TOGGLE_DRAWER](state, value) {
      state.drawer = value;
    },
    [LOADING_START](state, now) {
      state.loading = true;
      state.loadStartAt = now;
    },
    [LOADING_FINISH](state) {
      state.loading = false;
      state.loadStartAt = null;
    }
  },
  actions: {
    async setDrawer({ commit }, value) {
      commit('TOGGLE_DRAWER', value);
    },
    /**
     * ローディング画面を表示する
     * @param {Object} context
     * @param {function} context.commit
     * @param {Date} now
     */
    async [START_LOADING]({ commit }, now) {
      commit(LOADING_START, now);
    },
    /**
     * ローディング画面を閉じる
     * @param {Object} context
     * @param {Object} context.state
     * @param {function} context.commit
     * @param {Date} now
     */
    async [FINISH_LOADING]({ state, commit }, now) {
      const minTime = 500;
      const passedTime = now - state.loadStartAt;
      if (passedTime >= minTime) {
        commit(LOADING_FINISH);
      } else {
        setTimeout(() => commit(LOADING_FINISH), minTime - passedTime);
      }
    }
  }
};
