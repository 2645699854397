<template>
  <v-container class="fill-height">
    <v-layout row align-center justify-center>
      <v-flex>
        <h4 class="title">エラーが発生しました</h4>
        <h1 class="display-1 font-weight-thin mb-4">ページが見つかりません</h1>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  components: {}
};
</script>
