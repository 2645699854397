<template>
  <div id="shipping-categories-list">
    <v-card>
      <v-card-text>
        <v-layout>
          <v-text-field
            label="分類名で絞り込み"
            v-model="keywords"
            prepend-inner-icon="search"
            outlined
            hide-details
          />
        </v-layout>
      </v-card-text>
      <TableAndPaging
        :headers="header"
        :items="$store.state.shippingCategories.shippingCategories"
        :total="$store.state.shippingCategories.total"
        :query-params="queryParams"
        :action="`shippingCategories/${FETCH_SHIPPING_CATEGORIES}`"
        :showInitNotify="showInitNotify"
      >
        <template #row="props">
          <td>
            {{ props.item.names.ja_jp }}
          </td>
          <td>
            {{ props.item.names.en_us }}
          </td>
          <td>
            {{ props.item.hs_code }}
          </td>
          <td v-if="isTomUser">
            <v-btn
              small
              color="red lighten-1"
              dark
              class="ma-1 mt-4 mb-2"
              @click.stop="
                removeShippingCategory({
                  shippingCategory: props.item
                })
              "
            >
              削除する
            </v-btn>
          </td>
        </template>
      </TableAndPaging>
    </v-card>

    <confirm-dialog ref="confirmDialogComponent"> </confirm-dialog>
  </div>
</template>

<style>
#shipping-categories-list .v-text-field__details {
  min-height: 0;
}
#shipping-categories-list .v-messages {
  min-height: 0;
}
#shipping-categories-list .v-input {
  margin: 0;
}
#shipping-categories-list .v-data-table .v-data-table {
  background-color: inherit;
}
</style>

<script>
import TableAndPaging from '@/components/TableAndPaging';
import ConfirmDialog from '@/components/ConfirmDialog';
import { mapState } from 'vuex';

import {
  FETCH_SHIPPING_CATEGORIES,
  REMOVE_SHIPPING_CATEGRY,
  START_LOADING,
  FINISH_LOADING
} from '@/store/action-types';

export default {
  components: {
    TableAndPaging,
    ConfirmDialog
  },
  data() {
    const query = this.$route.query;

    return {
      FETCH_SHIPPING_CATEGORIES,
      keywords: query.filter_keywords || '',
      showInitNotify: !!query.notifyType && !!query.notifyMessageCode
    };
  },
  methods: {
    async removeShippingCategory({ shippingCategory }) {
      if (
        !(await this.$refs.confirmDialogComponent.open({
          headline: '',
          text: `商品分類 '${shippingCategory.names.en_us}' を削除してよろしいですか?`,
          negative: 'キャンセル',
          positive: '実行する'
        }))
      ) {
        return;
      }

      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      try {
        await this.$store.dispatch(
          `shippingCategories/${REMOVE_SHIPPING_CATEGRY}`,
          {
            shippingCategory
          }
        );
      } catch (err) {
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        await this.$store.dispatch(
          'notify/showErrorNotify',
          err.message ||
            'エラーが発生しました、画面を更新してやり直してください'
        );
        throw err;
      }
      await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
      await this.$store.dispatch(
        'notify/showNotify',
        '商品分類を削除しました。'
      );
    }
  },
  computed: {
    ...mapState('user', ['isTomUser']),
    header() {
      const header = ['商品分類名', '商品分類名(英語)', 'HSコード'];
      if (this.isTomUser) {
        return [...header, ''];
      }
      return header;
    },
    queryParams: function () {
      return {
        filter_keywords: this.keywords
      };
    }
  }
};
</script>
