<template>
  <v-form>
    <v-container>
      <v-row justify="end">
        <v-col md="2">
          <v-btn color="primary" block @click="clickModify"> 更新する </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-textarea label="備考" outlined dense v-model="remark"></v-textarea>
        </v-col>
      </v-row>

      <v-row v-if="options.length > 0">
        <v-col>
          <v-simple-table dense>
            <thead>
              <tr>
                <th>サービス</th>
                <th style="width: 500px">内容</th>
                <th>単価</th>
                <th>数量</th>
                <th>単位</th>
                <th>消費税率</th>
                <th>合計金額（税抜）</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(option, i) in options" :key="`options-${i}`">
                <td>
                  <v-chip label>
                    {{
                      CATEGORY_DICTIONARY[option.category] ||
                      CATEGORY_DICTIONARY[CATEGORY.OTHERS]
                    }}
                  </v-chip>
                </td>
                <td>{{ option.summary }}</td>
                <td>{{ option.unit_price }}</td>
                <td>{{ option.count }}</td>
                <td>{{ option.unit }}</td>
                <td>{{ Math.floor((option.tax_rate - 1) * 100) }}%</td>
                <td>
                  {{ option.amount }}
                </td>
                <td>
                  <v-btn color="red" text @click="removeOption(i)">
                    <v-icon>delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>

      <v-expansion-panels :value="0">
        <v-expansion-panel>
          <v-expansion-panel-header>明細の追加</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col md="6">
                <v-autocomplete
                  v-model="form.category"
                  :items="CATEGORIES"
                  outlined
                  dense
                  label="サービスカテゴリー"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="内容"
                  required
                  outlined
                  dense
                  v-model="form.summary"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  required
                  name="unit_price"
                  label="単価（円）"
                  outlined
                  dense
                  v-model="form.unit_price"
                  v-validate="'required|decimal:2'"
                  data-vv-validate-on="blur"
                  data-vv-as="単価（円）"
                  :error-messages="errors.collect('unit_price')"
                  @change="removeUnitPriceSymbols"
                />
              </v-col>
              <v-col>
                <v-text-field
                  required
                  name="count"
                  label="数量"
                  outlined
                  dense
                  v-model="form.count"
                  v-validate="'required|numeric'"
                  data-vv-validate-on="blur"
                  data-vv-as="数量"
                  :error-messages="errors.collect('count')"
                />
              </v-col>
              <v-col>
                <v-text-field
                  required
                  name="unit"
                  label="単位"
                  hint="ピースや個口などの単位名称を入れる"
                  :persistent-hint="true"
                  outlined
                  dense
                  v-model="form.unit"
                  v-validate="'required'"
                  data-vv-validate-on="blur"
                  data-vv-as="単位"
                  :error-messages="errors.collect('unit')"
                />
              </v-col>
              <v-col>
                <v-text-field
                  label="合計金額（税抜）"
                  outlined
                  dense
                  :value="amount"
                  readonly
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-radio-group dense label="消費税" v-model="form.tax_rate">
                  <v-radio label="課税" :value="1.1"></v-radio>
                  <v-radio label="免税" :value="1.0"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn color="primary" block @click="appendOption">
                  明細を追加 <v-icon> add </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-row justify="end" class="my-0">
        <v-col md="2">
          <v-btn color="primary" block @click="clickModify"> 更新する </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import {
  CATEGORY,
  CATEGORY_PATTERN,
  CATEGORY_DICTIONARY
} from '@/../lib/document/schema/billings';
import unicodeRegExp from 'unicode-regex';

export default {
  components: {},
  $_veeValidate: {
    validator: 'new'
  },
  props: {
    initOptions: Array,
    initRemark: String
  },
  data() {
    return {
      options: [],
      remark: '',
      form: {
        summary: '',
        unit_price: undefined,
        count: undefined,
        unit: undefined,
        tax_rate: 1.1,
        category: CATEGORY.OTHERS
      },
      CATEGORY,
      CATEGORY_DICTIONARY,
      CATEGORIES: CATEGORY_PATTERN.map((category) => {
        return {
          text: CATEGORY_DICTIONARY[category],
          value: category
        };
      })
    };
  },
  computed: {
    amount: function () {
      const total = this.form.count * this.form.unit_price;
      if (isNaN(total)) {
        return 0;
      }
      return Math.floor(total * 100) / 100;
    }
  },
  methods: {
    async appendOption() {
      if (!(await this.$validator.validate())) {
        return;
      }
      this.options.push({
        summary: this.form.summary,
        unit_price: this.form.unit_price,
        count: this.form.count,
        unit: this.form.unit,
        amount: this.amount,
        tax_rate: this.form.tax_rate,
        category: this.form.category
      });
      this.form = {
        summary: '',
        unit_price: undefined,
        count: undefined,
        unit: undefined,
        tax_rate: 1.1,
        category: CATEGORY.OTHERS
      };
    },
    removeOption(index) {
      this.options.splice(index, 1);
    },
    clickModify() {
      this.$emit('on-click-modify', {
        options: this.options,
        remark: this.remark
      });
    },
    removeUnitPriceSymbols() {
      const dashRegExp = unicodeRegExp({
        General_Category: ['Dash_Punctuation']
      }).toString();
      this.form.unit_price = this.form.unit_price
        .replace(/[円¥￥,]/g, '')
        .replace(new RegExp(`${dashRegExp}$`, 'g'), ''); // 末尾ハイフンを取り除く
    }
  },
  mounted() {
    this.options = this.initOptions;
    this.remark = this.initRemark;
  }
};
</script>
