<template>
  <v-container>
    <v-row wrap>
      <v-col xs="12">
        <v-alert
          v-if="Object.keys(mergedErrors).length"
          border="left"
          color="red"
          type="error"
          text
        >
          <div class="text-h6">入力にエラーがあります</div>
          <div
            v-for="key in Object.keys(mergedErrors)"
            :key="`serverError-${key}`"
          >
            {{ fieldNameMap[key] || key }}: {{ mergedErrors[key] }}
          </div>
        </v-alert>

        <v-form>
          <v-row align="center" justify="center">
            <v-col cols="12">
              <v-card class="mb-4">
                <v-card-title>出荷先を選択</v-card-title>
                <v-card-text>
                  <v-btn
                    text
                    class="v-btn--active"
                    color="primary accent-4"
                    @click="$vuetify.goTo('#address')"
                  >
                    出荷先情報を新規作成
                  </v-btn>
                  <v-subheader>または</v-subheader>
                  <v-select
                    v-model="selectedShippingAddress"
                    :items="savedShippingAddresses"
                    label="保存された宛名から選択"
                    xs6
                    hint="選択すると、出荷先情報が自動入力されます"
                    persistent-hint
                    class="mb-5"
                    @change="changeShippingAddress"
                  ></v-select>

                  <v-btn
                    text
                    color="error accent-4"
                    class="v-btn--active"
                    @click="deleteShippingAddress"
                    :disabled="!this.selectedShippingAddress._id"
                  >
                    選択された出荷先情報を「保存された宛名」から削除する
                  </v-btn>
                </v-card-text>
              </v-card>
              <v-card class="mb-4">
                <v-card-title id="address"> 出荷先情報 </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col sm="6">
                      <v-text-field
                        name="name"
                        v-model="name"
                        :error-messages="mergedError('name')"
                        label="宛名"
                        data-vv-validate-on="change"
                        v-validate="'required'"
                        data-vv-as="宛名"
                        hint="本名かつフルネームで記載ください。特に海外宛は税関の関係で略称やニックネーム等では引受が不可となります。"
                        persistent-hint
                      >
                        <v-chip
                          slot="append"
                          class="ma-2"
                          color="red"
                          label
                          outlined
                          small
                        >
                          必須
                        </v-chip>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="6">
                      <v-autocomplete
                        dense
                        :items="
                          Object.keys(COUNTRIES).map((c) => ({
                            text: COUNTRIES[c],
                            value: c
                          }))
                        "
                        v-model="countryCode"
                        name="countryCode"
                        label="国名"
                        data-vv-as="国名"
                        v-validate="'required'"
                        :error-messages="mergedError('countryCode')"
                      >
                        <v-chip
                          slot="append"
                          class="ma-2"
                          color="red"
                          label
                          outlined
                          small
                        >
                          必須
                        </v-chip>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row align="center">
                    <template v-if="countryCode === 'JP'">
                      <v-col sm="3">
                        <v-text-field
                          name="postalCode"
                          v-model="postalCode"
                          label="郵便番号"
                          hint="ハイフン無しの7文字で入力"
                          :error-messages="mergedError('postalCode')"
                          data-vv-validate-on="change"
                          v-validate="'required|max:7|min:7'"
                          data-vv-as="郵便番号"
                          persistent-hint
                          @blur="removeHyphenFromPostalCode"
                        >
                          <v-chip
                            slot="append"
                            class="ma-2"
                            color="red"
                            label
                            outlined
                            small
                          >
                            必須
                          </v-chip>
                        </v-text-field>
                      </v-col>
                      <v-col>
                        <v-btn
                          text
                          class="v-btn--active"
                          color="primary accent-4"
                          @click="fillByPostalCodeApi"
                        >
                          郵便番号から住所を入力する
                        </v-btn>
                      </v-col>
                    </template>
                    <v-col sm="3" v-else>
                      <v-text-field
                        name="postalCode"
                        v-model="postalCode"
                        label="郵便番号"
                        hint="ハイフン無しの最大20文字で入力"
                        :error-messages="mergedError('postalCode')"
                        data-vv-validate-on="change"
                        v-validate="'required|max:20'"
                        data-vv-as="郵便番号"
                        persistent-hint
                        @blur="removeHyphenFromPostalCode"
                      >
                        <v-chip
                          slot="append"
                          class="ma-2"
                          color="red"
                          label
                          outlined
                          small
                        >
                          必須
                        </v-chip>
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col sm="6">
                      <v-autocomplete
                        v-if="suitableStates"
                        dense
                        :items="
                          Object.keys(suitableStates).map((k) => {
                            let text = k;
                            if (countryCode === 'CN') {
                              text = `${k} - ${suitableStates[k]}`;
                            }
                            return {
                              text,
                              value: suitableStates[k]
                            };
                          })
                        "
                        v-model="state"
                        name="state"
                        label="都道府県・州"
                        :error-messages="mergedError('state')"
                      >
                        <v-chip
                          slot="append"
                          class="ma-2"
                          color="red"
                          label
                          outlined
                          small
                        >
                          必須
                        </v-chip>
                      </v-autocomplete>
                      <v-text-field
                        v-else
                        name="state"
                        v-model="state"
                        label="都道府県・州"
                        data-vv-validate-on="change"
                        v-validate="'required|max:24'"
                        data-vv-as="都道府県・州"
                        hint="最大24文字"
                        persistent-hint
                        :error-messages="mergedError('state')"
                      >
                        <v-chip
                          slot="append"
                          class="ma-2"
                          color="red"
                          label
                          outlined
                          small
                        >
                          必須
                        </v-chip>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="6">
                      <v-text-field
                        name="city"
                        v-model="city"
                        :error-messages="mergedError('city')"
                        label="市区町村"
                        data-vv-validate-on="change"
                        v-validate="'required'"
                        data-vv-as="市区町村"
                      >
                        <v-chip
                          slot="append"
                          class="ma-2"
                          color="red"
                          label
                          outlined
                          small
                        >
                          必須
                        </v-chip>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="8">
                      <v-text-field
                        name="address1"
                        v-model="address1"
                        :error-messages="mergedError('address1')"
                        label="住所1"
                        data-vv-validate-on="change"
                        v-validate="'required'"
                        data-vv-as="住所1"
                      >
                        <v-chip
                          slot="append"
                          class="ma-2"
                          color="red"
                          label
                          outlined
                          small
                        >
                          必須
                        </v-chip>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="8">
                      <v-text-field
                        name="address2"
                        v-model="address2"
                        label="住所2(任意)"
                        :error-messages="mergedError('address2')"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="6">
                      <v-text-field
                        name="phone"
                        v-model="phone"
                        :error-messages="mergedError('phone')"
                        label="電話番号"
                        data-vv-validate-on="change"
                        v-validate="'max:20'"
                        hint="最大20文字"
                        persistent-hint
                        data-vv-as="電話番号"
                      >
                        <v-chip
                          slot="append"
                          class="ma-2"
                          color="red"
                          label
                          outlined
                          small
                        >
                          海外宛必須
                        </v-chip>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="6">
                      <v-text-field
                        name="email"
                        v-model="email"
                        :error-messages="mergedError('email')"
                        label="メールアドレス(任意)"
                        v-validate="'email'"
                        data-vv-validate-on="change"
                        data-vv-as="メールアドレス(任意)"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn
                        text
                        color="primary accent-4"
                        class="v-btn--active"
                        @click="postShippingAddress"
                      >
                        出荷先情報を次回以降利用するために保存する
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card class="mb-4">
                <v-card-title>出荷依頼情報</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col sm="6">
                      <v-text-field
                        name="ownerOrderNo"
                        v-model="ownerOrderNo"
                        :error-messages="mergedError('ownerOrderNo')"
                        label="注文管理番号"
                        v-validate="'required|max:50'"
                        data-vv-validate-on="change"
                        data-vv-as="注文管理番号"
                        hint="50文字以内の数字、記号、文字列"
                        persistent-hint
                      >
                        <v-chip
                          slot="append"
                          class="ma-2"
                          color="red"
                          label
                          outlined
                          small
                        >
                          必須
                        </v-chip>
                        <v-tooltip bottom slot="append-outer">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="text--secondary"
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              info
                            </v-icon>
                          </template>
                          <div>
                            すべての出荷依頼を一意に管理する番号。<br />
                            既存依頼の重複番号の登録は不可。Eコマースおいては決済時の注文番号(OrderID)や、カートID等が設定されることが一般的
                          </div>
                        </v-tooltip>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="6">
                      <v-select
                        dense
                        :items="
                          suitableShippingMethods.map((s) => ({
                            text:
                              s === SHIPPING_METHOD.CHARTER
                                ? ACCEPTABLE_SHIPPING_METHOD_LABEL[s] +
                                  ' ※要事前確認'
                                : ACCEPTABLE_SHIPPING_METHOD_LABEL[s],
                            value: s
                          }))
                        "
                        v-model="shippingMethod"
                        name="shippingMethod"
                        label="出荷方法"
                        :error-messages="mergedError('shippingMethod')"
                        @change="deliveryDate = ''"
                      >
                        <v-chip
                          slot="append"
                          class="ma-2"
                          color="red"
                          label
                          outlined
                          small
                        >
                          必須
                        </v-chip>
                        <v-tooltip bottom slot="append-outer">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="text--secondary"
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              info
                            </v-icon>
                          </template>
                          <div>
                            配送方法となる運送会社または便種を指定。<br />
                            特に指定がない場合には、セカイロジが自動で最適な配送方法を提案する「セカイサジェスト」も利用可。
                          </div>
                        </v-tooltip>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row v-if="showDeliveryDateField">
                    <v-col sm="6">
                      <v-menu
                        v-model="deliveryDateMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="deliveryDate"
                            label="お届け希望日"
                            placeholder="指定なし"
                            prepend-icon="event"
                            append-icon="cancel"
                            @click:append="deliveryDate = ''"
                            readonly
                            v-on="on"
                            :error-messages="mergedError('deliveryDate')"
                          />
                        </template>
                        <v-date-picker
                          v-model="deliveryDate"
                          :min="ThreeWeekDaysAfter"
                          @input="deliveryDateMenu = false"
                          no-title
                          scrollable
                          :day-format="(date) => new Date(date).getDate()"
                        />
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="6">
                      <v-select
                        clearable
                        dense
                        :items="selectableTemplateItems"
                        v-model="slipName"
                        name="slipName"
                        label="納品書（任意）"
                        hint="納品書は設定画面から追加できます"
                        persistent-hint
                        :error-messages="mergedError('slipName')"
                      >
                        <v-tooltip bottom slot="append-outer">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="text--secondary"
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              info
                            </v-icon>
                          </template>
                          <div>
                            予め設定した納品書テンプレート名を選択。<br />
                            または未指定の場合にはデフォルトの納品書を利用
                          </div>
                        </v-tooltip>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row v-if="flyers.length > 0">
                    <v-col sm="6">
                      <v-select
                        dense
                        :items="flyers"
                        v-model="flyer"
                        name="flyer"
                        label="フライヤーを選択（任意）"
                        :error-messages="mergedError('flyer')"
                        @change="deliveryDate = ''"
                      >
                        <v-tooltip bottom slot="append-outer">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="text--secondary"
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              info
                            </v-icon>
                          </template>
                          <div>
                            フライヤーを同梱する場合に選択。<br />
                            （同梱オプションの利用には事前申請が必須につきご希望の場合には問い合わせフォームよりご連絡ください）
                          </div>
                        </v-tooltip>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="6">
                      <v-select
                        dense
                        :items="giftWrappings"
                        v-model="giftWrapping"
                        name="giftWrapping"
                        label="ギフトラッピング（任意）"
                        :error-messages="mergedError('giftWrapping')"
                      >
                        <v-tooltip bottom slot="append-outer">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="text--secondary"
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              info
                            </v-icon>
                          </template>
                          <div>
                            セカイロジ指定のラッピング袋を使用。<br />
                            有料オプション。
                          </div>
                        </v-tooltip>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row v-if="withDuty">
                    <v-col sm="6">
                      <v-select
                        dense
                        :items="duties"
                        v-model="duty"
                        name="duty"
                        label="関税支払い方法"
                        :error-messages="mergedError('duty')"
                      >
                        <v-tooltip bottom slot="append-outer">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="text--secondary"
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              info
                            </v-icon>
                          </template>
                          <div>
                            関税が発生した場合の支払いを荷主負担（元払い） or
                            荷受人負担（着払い）かを選択。
                          </div>
                        </v-tooltip>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row v-if="countryCode !== 'JP'">
                    <v-col sm="6">
                      <v-autocomplete
                        dense
                        :items="
                          Object.keys(CONTENT_TYPE).map((key) => ({
                            text: CONTENT_TYPE_LABEL[key],
                            value: key
                          }))
                        "
                        v-model="contentType"
                        name="contentType"
                        label="内容品種別"
                        v-validate="'required'"
                        data-vv-validate-on="change"
                        data-vv-as="内容品種別"
                        :error-messages="mergedError('contentType')"
                      >
                        <v-chip
                          slot="append"
                          class="ma-2"
                          color="red"
                          label
                          outlined
                          small
                        >
                          必須
                        </v-chip>
                        <v-tooltip bottom slot="append-outer">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="text--secondary"
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              info
                            </v-icon>
                          </template>
                          <div>
                            国際輸送時の税関申告情報。<br />
                            「貨物の目的」に当てはまる種別を選択。
                          </div>
                        </v-tooltip>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="4">
                      <v-checkbox v-model="priority">
                        <template v-slot:label>
                          優先出荷
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="text--secondary"
                                small
                                v-bind="attrs"
                                v-on="on"
                              >
                                info
                              </v-icon>
                            </template>
                            <div>
                              引当可能在庫が0未満の出荷依頼を作成する際、不足分の在庫が新規入庫されると優先的に引き当て
                            </div>
                          </v-tooltip>
                        </template>
                      </v-checkbox>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card class="mb-4">
                <v-card-title>出荷する商品・セットを選択</v-card-title>
                <v-card-text>
                  <v-btn-toggle class="ml-3" mandatory v-model="selectBtnIndex">
                    <v-btn color="primary accent-4" text small>
                      商品を選ぶ
                    </v-btn>
                    <v-btn color="primary accent-4" text small>
                      セットを選ぶ
                    </v-btn>
                  </v-btn-toggle>

                  <keep-alive>
                    <component
                      v-bind:is="currentSelector"
                      @select-item="selectItem"
                      :initial-params="filterItemSelector"
                    ></component>
                  </keep-alive>

                  <v-subheader>選択済み商品・セット一覧</v-subheader>
                  <v-simple-table>
                    <tbody class="th-key">
                      <tr>
                        <th>バーコード</th>
                        <th>荷主管理コード/セット管理番号</th>
                        <th>商品名</th>
                        <th width="140">
                          引当可能在庫数
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="text--secondary"
                                small
                                v-bind="attrs"
                                v-on="on"
                              >
                                info
                              </v-icon>
                            </template>
                            <div>
                              新規に出荷依頼が作成可能な残在庫数。<br />
                              入庫すると増加、新規出荷依頼で減少。<br />
                              （引当可能在庫数以上の出荷依頼を作成している場合、実在庫が不足となるためマイナス表記となる）
                            </div>
                          </v-tooltip>
                        </th>
                        <th>
                          出荷個数 / 単価
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="text--secondary"
                                small
                                v-bind="attrs"
                                v-on="on"
                              >
                                info
                              </v-icon>
                            </template>
                            <div>
                              商品単体ごとの申告価格。<br />
                              国際輸送時の税関申告情報。<br />
                              基本的には販売時または取引単価が該当。<br />
                              販売にあたらないギフトやサンプルの場合には商品原価を記載
                            </div>
                          </v-tooltip>
                        </th>
                      </tr>
                      <tr
                        v-for="({ item, qty, price }, index) in items"
                        :key="item._id"
                      >
                        <td>{{ item.barcode || item.bundle_code }}</td>
                        <td>
                          {{ item.owner_item_code || item.owner_bundle_code }}
                        </td>
                        <td>
                          <v-list-item dense>
                            <v-list-item-icon>(日)</v-list-item-icon>
                            <v-list-item-title>
                              {{ item.names.ja_jp }}
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item dense>
                            <v-list-item-icon>(英)</v-list-item-icon>
                            <v-list-item-title>
                              {{ item.names.en_us }}
                            </v-list-item-title>
                          </v-list-item>
                        </td>
                        <td>{{ item.stock }}</td>
                        <td>
                          <v-row align="center">
                            <v-col>
                              <v-chip
                                class="mt-2 mb-4"
                                color="red"
                                label
                                outlined
                                x-small
                              >
                                必須
                              </v-chip>
                              <v-text-field
                                dense
                                :name="`items[${index}].qty`"
                                label="個数"
                                data-vv-as="個数"
                                data-vv-validate-on="change"
                                v-validate="'required|numeric'"
                                :error-messages="
                                  mergedError(`items[${index}].qty`)
                                "
                                :value="qty"
                                @change="updateQty({ index, qty: $event })"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col>
                              <template v-if="countryCode !== 'JP'">
                                <v-chip
                                  class="mt-2 mb-4"
                                  color="red"
                                  label
                                  outlined
                                  x-small
                                >
                                  海外宛必須
                                </v-chip>
                                <v-text-field
                                  dense
                                  suffix="円"
                                  label="単価"
                                  :value="price"
                                  @change="
                                    updatePrice({ index, price: $event })
                                  "
                                >
                                </v-text-field>
                              </template>
                            </v-col>
                            <v-col>
                              <v-btn
                                text
                                color="error accent-4"
                                class="v-btn--active"
                                @click="removeItem({ index })"
                              >
                                <v-icon small left>delete</v-icon>
                                選択解除
                              </v-btn>
                            </v-col>
                          </v-row>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" v-if="mergedError('general').length">
              <v-alert color="error" text>
                <div v-for="e in mergedError('general').split('\n')" :key="e">
                  {{ e }}
                </div>
              </v-alert>
            </v-col>
            <v-col cols="12">
              <v-btn
                text
                color="primary accent-4"
                class="v-btn--active"
                block
                @click="postShipmentRequest"
              >
                出荷依頼を作成する
              </v-btn>
            </v-col>
            <v-col cols="12" v-if="isShipmentRequestCreated">
              <v-btn text color="teal" class="mb-4" to="/shipments" block>
                出荷一覧画面へ戻る
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<style></style>

<script>
import _ from 'lodash';

import axios from '../../api/common/axios';

import {
  SHIPPING_METHOD_PATTERN,
  SHIPPING_METHOD,
  DUTY,
  SHIPPING_METHOD_WITH_DUTY_PATTERN,
  ACCEPTABLE_SHIPPING_METHOD_LABEL,
  ACCEPTABLE_AUTO_SELECTED_SHIPPING_METHOD_PATTERN,
  CONTENT_TYPE,
  CONTENT_TYPE_LABEL
} from '@/../lib/document/schema/shipments';

import { STATE as FLYER_STATE } from '@/../lib/document/schema/flyers';
import { LANG } from '@/../lib/document/schema/slip_templates';

import COUNTRIES from '@/../lib/document/schema/countries';
import JP_STATES from '@/../lib/util/data/jp_states';
import CA_STATES from '@/../lib/util/data/ca_states';
import CN_STATES from '@/../lib/util/data/cn_states';
import CN_STATES_ENG from '@/../lib/util/data/cn_states_eng';
import US_STATES from '@/../lib/util/data/us_states';
import US_MILITARY_POST_STATES from '@/../lib/util/data/us_military_post_states';
import MM_EMS_SHIPPABLE_STATES from '@/../lib/util/data/mm_ems_shippable_states';
import { addWeekdays } from '@/../lib/util/date';
import { mergeErrors, shipmentFormFieldNameMap } from '@/util/input';
import { JsonError } from '@/util/error';
import { removeHyphen, jpPostalCodeToAddress } from '@/util/address';

import ItemSelector from '@/components/ItemSelector';
import ItemBundleSelector from '@/components/ItemBundleSelector';

import {
  START_LOADING,
  FINISH_LOADING,
  FETCH_SHIPPING_ADDRESS,
  SAVE_SHIPPING_ADDRESS,
  DELETE_SHIPPING_ADDRESS,
  CREATE_SHIPMENT,
  POLL_SUBMIT_SHIPMENT,
  FETCH_SLIP_TEMPLATES,
  FETCH_FLYERS,
  FETCH_OWNER
} from '@/store/action-types';

export default {
  $_veeValidate: {
    validator: 'new'
  },
  components: {
    ItemSelector,
    ItemBundleSelector
  },
  data() {
    const query = this.$route.query;
    const TWO_LETTER_US_MILITARY_POST_STATES = {};
    Object.keys(US_MILITARY_POST_STATES).forEach((k) => {
      if (k.length !== 2) {
        return;
      }
      TWO_LETTER_US_MILITARY_POST_STATES[k] = US_MILITARY_POST_STATES[k];
    });

    return {
      CONTENT_TYPE,
      CONTENT_TYPE_LABEL,
      ACCEPTABLE_SHIPPING_METHOD_LABEL,
      TWO_LETTER_US_MILITARY_POST_STATES,
      SHIPPING_METHOD,
      COUNTRIES,
      countryCode: '',
      state: '',
      postalCode: '',
      city: '',
      address1: '',
      address2: '',
      name: '',
      phone: '',
      email: '',
      ownerOrderNo: '',
      shippingMethod: '',
      selectedShippingAddress: {},
      items: [],
      serverErrors: {},
      isShipmentRequestCreated: false,
      // 商品を選ぶ・セットを選ぶボタンの押されているindexが入る
      selectBtnIndex: 0,
      deliveryDateMenu: undefined,
      deliveryDate: '',
      flyer: '',
      giftWrapping: '',
      duty: '',
      slipName: '',
      returnedShipmentId: query.returnedShipmentId || '',
      priority: '',
      contentType: CONTENT_TYPE.MERCHANDISE
    };
  },
  computed: {
    /**
     * 保存された配送先一覧をv-selectで表示するための構造へ変換したもの
     */
    savedShippingAddresses(self) {
      return self.$store.state.shippingAddress.shippingAddresses.map(
        (addr) => ({
          text: addr.name,
          value: addr
        })
      );
    },
    /**
     * 国コードによってプルダウンに表示する州・都道府県一覧を切り替える
     * 主要国じゃないものはフリーテキストのためnull
     */
    suitableStates(self) {
      switch (self.countryCode) {
        case 'JP':
          return JP_STATES;
        case 'US':
          return {
            ...US_STATES,
            ...self.TWO_LETTER_US_MILITARY_POST_STATES
          };
        case 'CA':
          return CA_STATES;
        case 'CN':
          return CN_STATES_ENG;
        case 'MM':
          return MM_EMS_SHIPPABLE_STATES;
        default:
          return null;
      }
    },
    /**
     * 国コードによって選べる発送方法一覧を切り替える
     */
    suitableShippingMethods(self) {
      switch (self.countryCode) {
        case 'JP':
          return [
            ...ACCEPTABLE_AUTO_SELECTED_SHIPPING_METHOD_PATTERN,
            SHIPPING_METHOD.SAGAWA,
            SHIPPING_METHOD.YAMATO,
            SHIPPING_METHOD.YAMATO_NEKOPOS,
            SHIPPING_METHOD.CHARTER
          ];
        default:
          return [
            ...ACCEPTABLE_AUTO_SELECTED_SHIPPING_METHOD_PATTERN,
            ...SHIPPING_METHOD_PATTERN
          ].filter(
            (s) =>
              ![
                SHIPPING_METHOD.SAGAWA,
                SHIPPING_METHOD.YAMATO,
                SHIPPING_METHOD.YAMATO_NEKOPOS,
                SHIPPING_METHOD.JPP_E_PACKET, // 国際eパケットは廃止
                SHIPPING_METHOD.FEDEX_PRIORITY, // FedExはICPだけになる
                SHIPPING_METHOD.FEDEX_ECONOMY,
                SHIPPING_METHOD.JPP_SAL_PARCEL,
                SHIPPING_METHOD.JPP_SAL_SPR
              ].includes(s)
          );
      }
    },
    // ItemSelectorに渡す追加絞り込みプロパティ
    filterItemSelector() {
      return {};
    },
    // selectBtnIndexに応じて表示するコンポーネントを切り替える
    currentSelector() {
      if (this.selectBtnIndex) {
        return 'ItemBundleSelector';
      }

      return 'ItemSelector';
    },
    /**
     * 今日から土日含まず3日後の日付を返す
     */
    ThreeWeekDaysAfter() {
      return this.addWeekdays(new Date(), 3).format('YYYY-MM-DD');
    },
    /**
     * お届け希望日の選択肢を表示するか否か
     * ネコポスはヤマトだがお届け希望日は指定できない
     */
    showDeliveryDateField() {
      return [
        SHIPPING_METHOD.YAMATO,
        SHIPPING_METHOD.SAGAWA,
        SHIPPING_METHOD.CHARTER,
        ...ACCEPTABLE_AUTO_SELECTED_SHIPPING_METHOD_PATTERN
      ].includes(this.shippingMethod);
    },
    flyers(self) {
      const flyers = self.$store.state.flyers.flyers.filter(
        (flyer) => flyer.state === FLYER_STATE.AVAILABLE
      );

      if (!flyers.length) {
        return [];
      }

      return [
        { text: '設定なし', value: '' },
        ...flyers.map((flyer) => ({
          text: flyer.title,
          value: flyer.number
        }))
      ];
    },
    giftWrappings() {
      return [
        { text: '設定なし', value: '' },
        { text: '有り', value: '有り' }
      ];
    },
    duties() {
      return [
        { text: '設定なし', value: '' },
        { text: '関税着払い', value: DUTY.CUSTOMER_PAID },
        { text: '関税元払い', value: DUTY.OWNER_PAID }
      ];
    },
    withDuty() {
      return (
        this.countryCode !== 'JP' &&
        [
          ...SHIPPING_METHOD_WITH_DUTY_PATTERN,
          ...ACCEPTABLE_AUTO_SELECTED_SHIPPING_METHOD_PATTERN
        ].includes(this.shippingMethod)
      );
    },
    selectableTemplateItems: function () {
      const templates = this.$store.state.slipTemplates.details
        .filter((slipTemplate) => {
          if (this.countryCode === 'JP') {
            return slipTemplate.lang === LANG.JA_JP;
          } else {
            return slipTemplate.lang === LANG.EN_US;
          }
        })
        .map((slipTemplate) => {
          return {
            text:
              slipTemplate.name +
              (slipTemplate.isDefaultSlip ? ' (デフォルト)' : ''),
            value: slipTemplate.name
          };
        });

      return templates.length
        ? [{ text: 'デフォルト納品書', value: '' }, ...templates]
        : [{ text: 'デフォルト納品書', value: '' }];
    },
    fieldNameMap: shipmentFormFieldNameMap,
    mergedErrors: (self) => {
      const errors = {
        ...self.$validator.errors.collect(),
        ...self.serverErrors
      };

      return errors;
    }
  },
  async beforeMount() {
    await Promise.all([
      // ページ読み込み時に保存されている配送先住所、カスタム納品書を取得する
      this.$store.dispatch(`shippingAddress/${FETCH_SHIPPING_ADDRESS}`),
      this.$store.dispatch(`slipTemplates/${FETCH_SLIP_TEMPLATES}`),
      // ページ読み込み時にフライヤー情報を取得する
      this.$store.dispatch(`flyers/${FETCH_FLYERS}`),
      this.returnedShipmentId && this.getShipmentDetail(this.returnedShipmentId)
    ]);
  },
  async mounted() {
    this.$store.dispatch(`owner/${FETCH_OWNER}`, this.$store.state.user);
  },
  methods: {
    async getShipmentDetail(returnedShipmentId) {
      try {
        const response = await axios.get(`/returns/${returnedShipmentId}`);
        const shipment = response.data.shipment;
        this.name = shipment.shipment_request.customer.name || '';
        this.postalCode = shipment.shipment_request.customer.postal_code || '';
        this.countryCode =
          shipment.shipment_request.customer.country_code || '';
        switch (this.countryCode) {
          case 'JP':
            this.state =
              JP_STATES[shipment.shipment_request.customer.state] || '';
            break;
          case 'US':
            this.state =
              US_STATES[shipment.shipment_request.customer.state] ||
              this.TWO_LETTER_US_MILITARY_POST_STATES[
                shipment.shipment_request.customer.state
              ] ||
              '';
            break;
          case 'CA':
            this.state =
              CA_STATES[shipment.shipment_request.customer.state] || '';
            break;
          case 'CN':
            this.state =
              CN_STATES_ENG[shipment.shipment_request.customer.state] || '';
            break;
          case 'MM':
            this.state =
              MM_EMS_SHIPPABLE_STATES[
                shipment.shipment_request.customer.state
              ] || '';
            break;
          default:
            this.state = shipment.shipment_request.customer.state || '';
            break;
        }
        this.city = shipment.shipment_request.customer.city || '';
        this.address1 = shipment.shipment_request.customer.address1 || '';
        this.address2 = shipment.shipment_request.customer.address2 || '';
        this.phone = shipment.shipment_request.customer.phone || '';
        this.email = shipment.shipment_request.customer.email || '';
        this.ownerOrderNo =
          'Re_' + shipment.shipment_request.owner_order_no || '';
        this.shippingMethod = shipment.shipping_method || '';
        this.deliveryDate = shipment.delivery_schedule_date || '';
        this.slipName = shipment.slipTemplate ? shipment.slipTemplate.name : '';
        this.giftWrapping = shipment.gift_wrapping ? '有り' : '';
        this.flyer = shipment.flyer
          ? (shipment.flyer.number + '').padStart(4, '0')
          : '';
        this.duty = shipment.duty || '';
        this.priority = shipment.shipment_request.priority ? true : false;
        this.items = shipment.items.map((item) => {
          return { item: { ...item._id }, qty: item.qty, price: item.price };
        });
        this.contentType =
          shipment.shipment_request.contentType || CONTENT_TYPE.MERCHANDISE;
      } catch (error) {
        await this.$store.dispatch(
          'notify/showErrorNotify',
          _.get(
            error,
            'message',
            'エラーが発生しました、画面を更新してやり直してください'
          )
        );
        throw error;
      }
    },

    async clearError() {
      await this.$store.dispatch('notify/clearNotify');
      this.serverErrors = {};
    },
    async fillByPostalCodeApi() {
      try {
        const addresses = await jpPostalCodeToAddress({
          postalCode: this.postalCode
        });

        // ShipmentsEditFormと違い，submit時に `_.invert(ALL_STATES)[this.state]` とやるので，ここはJP_STATEの値をthis.stateに入れる
        this.state = JP_STATES[addresses[0].ja.prefecture];
        this.city = addresses[0].ja.address1;
        this.address1 = addresses[0].ja.address2;
        this.address2 = addresses[0].ja.address3;

        setImmediate(() => {
          this.$validator.validate('postalCode');
          this.$validator.validate('state');
          this.$validator.validate('address1');
          this.$validator.validate('address2');
        });
      } catch (error) {
        await this.$store.dispatch(
          'notify/showErrorNotify',
          _.get(
            error,
            'message',
            'エラーが発生しました、画面を更新してやり直してください'
          )
        );
      }
    },
    /**
     * 郵便番号からハイフン系の文字種を削除する
     */
    removeHyphenFromPostalCode() {
      if (this.postalCode) {
        this.postalCode = removeHyphen(this.postalCode);
      }

      setImmediate(() => {
        this.$validator.validate('postalCode');
      });
    },
    /**
     * プルダウンから保存されている住所を選んだ時に配送先の欄を埋める
     */
    async changeShippingAddress({
      name,
      address1,
      address2,
      city,
      state,
      postalCode,
      countryCode,
      email,
      phone,
      duty
    }) {
      await this.clearError();

      // プルダウンの項目はデータが残ってしまうのでクリアする
      this.deliveryDate = '';
      this.shippingMethod = '';
      this.state = '';
      this.countryCode = '';

      this.name = name;
      this.address1 = address1;
      this.address2 = address2;
      this.city = city;
      this.state = state;
      this.postalCode = postalCode;
      this.countryCode = countryCode;
      this.email = email;
      this.phone = phone;
      this.duty = duty;
    },
    /**
     * 入力されている配送先の住所を保存するAPIを呼び出す
     */
    async postShippingAddress() {
      await this.clearError();

      // filterはstateのところがnullになったときに除去するために噛ませている
      const validations = await Promise.all(
        [
          this.$validator.validate('name'),
          this.$validator.validate('email'),
          this.$validator.validate('phone'),
          this.$validator.validate('address1'),
          this.$validator.validate('city'),
          this.$validator.validate('countryCode'),
          // stateはプルダウンじゃない時にvee-validateを掛けるので条件を入れている
          !this.suitableStates && this.$validator.validate('state'),
          this.$validator.validate('postalCode')
        ].filter((p) => p)
      );

      if (!validations.every((isValid) => isValid)) {
        await this.$store.dispatch(
          'notify/showErrorNotify',
          '発送先情報にエラーが存在します。エラー内容を修正して再度お試しください。'
        );
        return;
      }

      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      try {
        await this.$store.dispatch(`shippingAddress/${SAVE_SHIPPING_ADDRESS}`, {
          shippingAddress: {
            name: this.name,
            address1: this.address1,
            address2: this.address2,
            city: this.city,
            state: this.state,
            postalCode: this.postalCode,
            countryCode: this.countryCode,
            email: this.email,
            phone: this.phone
          }
        });
      } catch (error) {
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        await this.$store.dispatch(
          'notify/showErrorNotify',
          _.get(
            error,
            'message',
            'エラーが発生しました、画面を更新してやり直してください'
          )
        );
        throw error;
      }
      await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
      await this.$store.dispatch(
        'notify/showNotify',
        '配送先情報を保存しました。'
      );
    },
    /**
     * プルダウンで選択されている配送先住所を削除するAPIを呼び出す
     */
    async deleteShippingAddress() {
      await this.clearError();
      if (!confirm(`${this.selectedShippingAddress.name}を削除しますか？`)) {
        return;
      }
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      try {
        await this.$store.dispatch(
          `shippingAddress/${DELETE_SHIPPING_ADDRESS}`,
          { id: this.selectedShippingAddress._id }
        );
      } catch (error) {
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        await this.$store.dispatch(
          'notify/showErrorNotify',
          _.get(
            error,
            'message',
            'エラーが発生しました、画面を更新してやり直してください'
          )
        );
        throw error;
      }
      await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
      await this.$store.dispatch(
        'notify/showNotify',
        '配送先情報を削除しました。'
      );

      // 削除する配送先が選択されているはずなので，選択を解除する
      this.selectedShippingAddress = {};
    },
    /**
     * 出荷依頼を作成するボタン押下時にAPIリクエストを送る
     */
    async postShipmentRequest() {
      if (!(await this.$validator.validate())) {
        await this.$store.dispatch(
          'notify/showErrorNotify',
          '入力項目に誤りがあります。エラー表示されている項目を確認してください。'
        );

        this.scrollTop();
        return;
      }

      await this.clearError();

      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      try {
        const ALL_STATES = {
          ...JP_STATES,
          ...US_STATES,
          ...this.TWO_LETTER_US_MILITARY_POST_STATES,
          ...CA_STATES,
          ...CN_STATES
        };
        let state = _.invert(ALL_STATES)[this.state];
        if (!state) {
          state = this.state;
        }
        await this.$store.dispatch(`shipment/${CREATE_SHIPMENT}`, {
          ownerOrderNo: this.ownerOrderNo,
          shippingMethod: this.shippingMethod,
          items: this.items.map((obj) => ({
            barcode: obj.item.barcode || obj.item.bundle_code,
            qty: obj.qty,
            price: obj.price
          })),
          shippingAddress: {
            name: this.name,
            address1: this.address1,
            address2: this.address2,
            city: this.city,
            state: state,
            postalCode: this.postalCode,
            countryCode: this.countryCode,
            email: this.email,
            phone: this.phone
          },
          deliveryDate: this.deliveryDate,
          flyer: this.flyer,
          giftWrapping: this.giftWrapping,
          duty: this.duty,
          slipName: this.slipName,
          returnedShipmentId: this.returnedShipmentId,
          priority: this.priority,
          contentType: this.contentType
        });

        // 結果をローディング状態で待つ
        await this.$store.dispatch(`shipment/${POLL_SUBMIT_SHIPMENT}`, {
          sqsTaskId: this.$store.state.shipment.sqsTaskId
        });

        if (
          this.$store.state.shipment.errors &&
          Object.keys(this.$store.state.shipment.errors).length
        ) {
          this.scrollTop();
          this.serverErrors = this.$store.state.shipment.errors;
          await this.$store.dispatch(
            'notify/showErrorNotify',
            '入力項目に誤りがあります。エラー表示されている項目を確認してください。'
          );
        } else {
          await this.$store.dispatch(
            'notify/showNotify',
            `<a href="/shipments/${this.$store.state.shipment.id}">出荷依頼を作成しました。</a>一覧から確認して承認を行うと出荷プロセスが開始されます。`
          );
          this.isShipmentRequestCreated = true;
        }

        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
      } catch (error) {
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        this.scrollTop();

        if (error instanceof JsonError) {
          this.serverErrors = error.toJson();
          await this.$store.dispatch(
            'notify/showErrorNotify',
            '入力項目に誤りがあります。エラー表示されている項目を確認してください。'
          );
        } else {
          await this.$store.dispatch('notify/showErrorNotify', error.message);
        }
      }
    },
    async selectItem({ item }) {
      await this.clearError();

      if (this.items.map((obj) => obj.item._id).includes(item._id)) {
        return;
      }
      this.items.push({ item, qty: 1 });

      await this.$store.dispatch(
        'notify/showNotify',
        '選択済み商品一覧に追加しました。'
      );
    },
    removeItem({ index }) {
      this.items = [
        ...this.items.slice(0, index),
        ...this.items.slice(index + 1)
      ];
    },
    updateQty({ index, qty }) {
      this.items = [
        ...this.items.slice(0, index),
        {
          item: this.items[index].item,
          qty,
          price: this.items[index].price
        },
        ...this.items.slice(index + 1)
      ];
    },
    updatePrice({ index, price }) {
      this.items = [
        ...this.items.slice(0, index),
        {
          item: this.items[index].item,
          qty: this.items[index].qty,
          price
        },
        ...this.items.slice(index + 1)
      ];
    },
    mergedError(field) {
      return mergeErrors(this.$validator.errors, this.serverErrors, field);
    },
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    addWeekdays
  }
};
</script>
