// このファイルはクライアント側からも読み込まれるため，
// Node.jsでしか動かないコードを書くことは禁止する

'use strict';

/**
 * CSVでアップデートする時に指定可能なキーの名前
 *
 * @type {string[]}
 */
const UPDATABLE_KEYS = [
  'names_ja_jp',
  'names_en_us',
  'owner_item_code',
  'product_category',
  'ship_type',
  'note',
  'origin_country_code',
  'hs_code',
  'brand',
  'specification',
  'url',
  'shipment_expiration_buffer_days'
];

/**
 * CSVでアップデートする時に内部で管理するキーの名前
 *
 * @type {string[]}
 */
const INTERNAL_UPDATABLE_KEYS = [
  'names_ja_jp',
  'names_en_us',
  'owner_item_code',
  'shipping_category',
  'ship_type',
  'note',
  'origin_country_code',
  'hs_code',
  'brand',
  'specification',
  'url',
  'shipment_expiration_buffer_days'
];

const SHIP_TYPE_PATTERN = [
  'MIXED_LOADING', // 梱包あり_混載可
  'NO_MIXED_LOADING', // 梱包あり_混載不可
  'NO_BOX' // 梱包なし_伝票直貼り=梱包せず箱のまま出荷する
];
const SHIP_TYPE = {};
for (const key of SHIP_TYPE_PATTERN) {
  SHIP_TYPE[key] = key;
}

const SHIP_TYPE_DICTIONARY = {
  MIXED_LOADING: '梱包あり_混載可',
  NO_MIXED_LOADING: '梱包あり_混載不可',
  NO_BOX: '梱包なし_伝票直貼り'
};

const SHIP_TYPE_LABEL_PATTERN = SHIP_TYPE_PATTERN.map((k) => {
  return SHIP_TYPE_DICTIONARY[k];
});

module.exports = {
  UPDATABLE_KEYS,
  INTERNAL_UPDATABLE_KEYS,
  SHIP_TYPE_PATTERN,
  SHIP_TYPE,
  SHIP_TYPE_DICTIONARY,
  SHIP_TYPE_LABEL_PATTERN
};
