// このファイルはクライアント側からも読み込まれるため，
// Node.jsでしか動かないコードを書くことは禁止する

'use strict';

const AMOUNT_STATUS_PATTERN = ['PENDING', 'APPROVED', 'FIXED'];
const AMOUNT_STATUS = {};
for (const key of AMOUNT_STATUS_PATTERN) {
  AMOUNT_STATUS[key] = key;
}
const AMOUNT_STATUS_DICTIONARY = {
  PENDING: '未確定',
  APPROVED: '確定済',
  FIXED: '請求書発行済'
};

const PAID_STATUS = {};
const PAID_STATUS_PATTERN = ['UNPAID', 'PAID', 'FAIL'];
for (const key of PAID_STATUS_PATTERN) {
  PAID_STATUS[key] = key;
}
const PAID_STATUS_DICTIONARY = {
  UNPAID: '未精算',
  PAID: '精算済',
  FAIL: '失敗'
};

const CATEGORY = {};
const CATEGORY_PATTERN = ['MANIFESTS', 'SHIPMENTS', 'SHIPPINGS', 'OTHERS'];
for (const key of CATEGORY_PATTERN) {
  CATEGORY[key] = key;
}
const CATEGORY_DICTIONARY = {
  MANIFESTS: '倉庫保管',
  SHIPMENTS: '出荷',
  SHIPPINGS: '配送',
  OTHERS: 'その他'
};

const SEND_ALERT_RATE = 0.2; // ご利用可能額がこの割合を切ったら通知メールを送付する

module.exports = {
  AMOUNT_STATUS_PATTERN,
  AMOUNT_STATUS,
  AMOUNT_STATUS_DICTIONARY,
  PAID_STATUS_PATTERN,
  PAID_STATUS,
  PAID_STATUS_DICTIONARY,
  CATEGORY,
  CATEGORY_PATTERN,
  CATEGORY_DICTIONARY,
  SEND_ALERT_RATE
};
