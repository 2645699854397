import querystring from 'querystring';
import _ from 'lodash';

import { ITEM_LOGS_FETCHED } from '../mutation-types';
import { FETCH_ITEM_LOGS } from '../action-types';
import axios from '../../api/common/axios';

export default {
  namespaced: true,
  state: {
    logs: [],
    total: 0
  },
  mutations: {
    [ITEM_LOGS_FETCHED](state, { logs, total }) {
      state.logs = logs;
      state.total = total;
    }
  },
  actions: {
    async [FETCH_ITEM_LOGS]({ commit }, params) {
      const errorMsg =
        'データ取得時にエラーがありました。セカイロジ担当者までお問い合わせください。';
      const response = await axios
        .get(
          `/itemlogs?${querystring.stringify({
            itemId: params.itemId,
            filter_owner: params.filter_owner,
            filterKeywords: params.filter_keywords,
            filterStartOfEventOccurredAt:
              params.filter_start_of_event_occurred_at,
            filterEndOfEventOccurredAt: params.filter_end_of_event_occurred_at,
            filterCategories: params.filter_categories,
            page: params.page,
            limit: params.limit
          })}`
        )
        .catch((e) => {
          throw new Error(_.get(e, 'response.data.errors.general', errorMsg));
        });

      if (_.get(response, 'data.status') === 'ng') {
        throw new Error(_.get(response, 'data.errors.general', errorMsg));
      }

      commit(ITEM_LOGS_FETCHED, {
        logs: response.data.logs,
        total: parseInt(response.data.total, 10)
      });
    }
  }
};
