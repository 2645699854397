<template>
  <div class="main-header">
    <v-slide-x-transition mode="out-in">
      <v-btn
        v-if="backTo"
        outlined
        icon
        tile
        :to="backTo"
        class="main-header__back-button"
      >
        <v-icon>navigate_before</v-icon>
      </v-btn>
    </v-slide-x-transition>

    <h1 class="main-header__title">{{ title }}</h1>

    <v-spacer></v-spacer>

    <div class="main-header__action">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    backTo: [String, Object]
  }
};
</script>

<style scoped>
.main-header {
  padding-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main-header__back-button {
  border-radius: 4px;
  margin-right: 8px;
}
.main-header__title {
  font-size: 24px;
  margin: 0.18em 0 0;
}
.main-header__action {
  display: flex;
  align-items: center;
}
</style>
