<template>
  <v-card v-if="stockDiff">
    <v-card-title class="caption">{{ stockDiff.item._id }}</v-card-title>
    <v-card-text>
      <div class="title text--primary font-weight-bold">在庫差分の補正</div>
      <v-simple-table>
        <tbody>
          <tr>
            <th>荷主</th>
            <td>{{ stockDiff.owner.names.ja_jp }}</td>
          </tr>
          <tr>
            <th>バーコード</th>
            <td>{{ stockDiff.item.barcode }}</td>
          </tr>
          <tr>
            <th>荷主管理コード</th>
            <td>{{ stockDiff.item.owner_item_code }}</td>
          </tr>
          <tr>
            <th>商品名 (日本語)</th>
            <td>{{ stockDiff.item.names.ja_jp }}</td>
          </tr>
          <tr>
            <th>商品名 (英語)</th>
            <td>{{ stockDiff.item.names.en_us }}</td>
          </tr>
          <tr>
            <th>WMS在庫数 | 良品数</th>
            <td>{{ stockDiff.warehouse_stock }}</td>
          </tr>
          <tr>
            <th>WMS在庫数 | 消費期限切れ在庫数</th>
            <td>{{ stockDiff.expired_warehouse_stock }}</td>
          </tr>
          <tr>
            <th>セカイロジ | 良品数</th>
            <td>
              {{
                stockDiff.item.stock +
                stockDiff.item.reserved_stock +
                stockDiff.item.returned_stock
              }}
            </td>
          </tr>
          <tr>
            <th>
              セカイロジ | <span class="indent">&gt; 引当可能在庫数</span>
            </th>
            <td>
              {{ stockDiff.item.stock }}
            </td>
          </tr>
          <tr>
            <th>セカイロジ | <span class="indent">&gt; 引当済在庫数</span></th>
            <td>
              {{ stockDiff.item.reserved_stock }}
            </td>
          </tr>
          <tr>
            <th>セカイロジ | <span class="indent">&gt; 返送在庫数</span></th>
            <td>
              {{ stockDiff.item.returned_stock }}
            </td>
          </tr>
          <tr>
            <th>セカイロジ | 消費期限切れ在庫数</th>
            <td>{{ stockDiff.expired_warehouse_stock }}</td>
          </tr>
          <tr>
            <th>補正カテゴリ</th>
            <td>
              <v-select
                v-model="category"
                :items="categories"
                item-value="value"
                item-text="name"
                height="20"
              ></v-select>
            </td>
          </tr>
          <tr>
            <th>
              <span>個数</span>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="text--secondary"
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    info
                  </v-icon>
                </template>
                <div>
                  減らす方向に補正する場合は、引当可能在庫数が減らせる数の最大数です。
                  <br />
                  引当済在庫数を減らしたい場合は、出荷を棄却するなどして引当可能在庫に戻した上で補正してください。
                  <br />
                  返送在庫数を減らしたい場合は、一度再入庫処理を行い引当可能在庫に戻した上で補正してください。
                </div>
              </v-tooltip>
            </th>
            <td>
              <v-select v-model="qty" :items="selectableQty"> </v-select>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
    <v-card-actions>
      <div class="flex-grow-1"></div>
      <v-btn color="red" text @click="$emit('cancel')"> キャンセル </v-btn>
      <v-btn color="teal" text @click="$emit('correct-qty', { qty, category })">
        補正する
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style scoped>
.indent {
  padding-left: 1em;
}
</style>

<script>
import {
  CATEGORY_TEXT,
  CATEGORY
} from '../../../lib/document/schema/item_event_logs';

export default {
  data() {
    return {
      category: CATEGORY.SYSTEM_TROUBLE,
      qty: 0,
      selectableQty: []
    };
  },
  props: ['stockDiff'],
  methods: {
    resetForm() {
      this.category = CATEGORY.SYSTEM_TROUBLE;
      this.selectableQty = [];
      if (!this.stockDiff) {
        return;
      }
      let qty =
        this.stockDiff.warehouse_stock -
        (this.stockDiff.item.stock +
          this.stockDiff.item.reserved_stock +
          this.stockDiff.item.returned_stock);

      // 補正数が在庫を減らす方向の場合
      if (qty < 0) {
        // 減らす数がstockよりも大きい場合はreserved_stockやreturned_stockを減らせないので
        // 減らす数の最大数はstockの数にしなければいけない（差分は全部解消されない）
        // 差分を解消するには出荷予約を棄却してreserved_stockをstockに移すなどしてから在庫差分解消する
        if (this.stockDiff.item.stock < Math.abs(qty)) {
          qty = this.stockDiff.item.stock ? -this.stockDiff.item.stock : 0;
        }
      }
      this.qty = qty;
      for (let i = qty; i != 0; i += qty > 0 ? -1 : 1) {
        this.selectableQty.push({ text: i, value: i });
      }
    }
  },
  mounted() {
    this.resetForm();
  },
  watch: {
    stockDiff() {
      this.resetForm();
    }
  },
  computed: {
    categories() {
      return Object.keys(CATEGORY_TEXT).map((key) => {
        return { value: key, name: CATEGORY_TEXT[key] };
      });
    }
  }
};
</script>
