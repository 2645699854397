<template>
  <v-container>
    <v-row v-if="isLoading">
      <v-col>
        <v-skeleton-loader :loading="isLoading" type="card">
        </v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row v-if="errorMessage">
      <v-col>
        <v-card class="xs12 mb-5">
          <div>
            {{ errorMessage }}
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="!isLoading">
      <v-col>
        <v-card class="xs12 mb-5" v-for="(history, i) in histories" :key="i">
          <v-card-title>
            <h2 class="headline font-weight-bold mb-3">
              <v-chip label color="primary" class="no-hover">
                {{
                  formatDate({
                    date: history.displayPublishedAt,
                    format: 'YYYY/MM/DD'
                  })
                }}
              </v-chip>
              {{ history.title }}
            </h2>
          </v-card-title>
          <v-card-text>
            <span v-html="history.body" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="totalCount">
      <v-col>
        <v-pagination
          v-model="page"
          :length="pagerLength"
          :total-visible="7"
          @input="fetchContent"
        ></v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { BASE_URL, API_KEY } from '@/constants/microcms';
import { formatDate } from '@/util/formatter';

export default {
  data() {
    return {
      histories: [],
      page: 1,
      totalCount: 0,
      limit: 10,
      errorMessage: '',
      isLoading: false
    };
  },
  computed: {
    pagerLength() {
      return Math.ceil(this.totalCount / this.limit);
    }
  },
  async mounted() {
    await this.fetchContent();
  },
  methods: {
    async fetchContent() {
      try {
        this.isLoading = true;
        const query = new URLSearchParams({
          offset: (this.page - 1) * this.limit,
          limit: this.limit,
          orders: '-displayPublishedAt'
        }).toString();
        const res = await fetch(`${BASE_URL}release?${query}`, {
          headers: {
            'X-API-KEY': API_KEY
          }
        });
        if (!res.ok) {
          throw new Error(res.statusText || '更新履歴を取得できませんでした。');
        }
        const json = await res.json();
        this.histories = json.contents;
        this.totalCount = json.totalCount;
      } catch (err) {
        this.errorMessage = err.message;
      } finally {
        this.isLoading = false;
      }
    },
    formatDate
  }
};
</script>

<style></style>
