import querystring from 'querystring';
import axios from '@/api/common/axios';

import {
  SHIPPING_CATEGORIES_FETCHED,
  SHIPPING_CATEGORY_DELETED
} from '@/store/mutation-types';
import {
  FETCH_SHIPPING_CATEGORIES,
  REMOVE_SHIPPING_CATEGRY
} from '@/store/action-types';

const headers = ['Category name', '商品分類名'];

/**
 * 商品分類の一覧に関するデータストア
 */
export default {
  namespaced: true,
  state: {
    headers: headers,
    shippingCategories: [],
    total: 0
  },
  mutations: {
    [SHIPPING_CATEGORIES_FETCHED](state, { shippingCategories, total }) {
      state.shippingCategories = shippingCategories;
      state.total = total;
    },
    [SHIPPING_CATEGORY_DELETED](state, { shippingCategory }) {
      state.shippingCategories = state.shippingCategories.filter(
        (cat) => cat._id !== shippingCategory._id
      );
    }
  },
  actions: {
    /**
     * 商品分類のリストを取得する
     */
    async [FETCH_SHIPPING_CATEGORIES]({ commit }, query) {
      const errorMsg =
        'データ取得時にエラーがありました。セカイロジ担当者までお問い合わせください。';
      const response = await axios
        .get(`/shipping_categories?${querystring.stringify(query)}`)
        .catch((e) => {
          throw e;
        });

      if (response && response.data && response.data.status === 'ng') {
        throw new Error(
          (response.data.errors && response.data.errors.general) || errorMsg
        );
      }

      const shippingCategories = response.data.shipping_categories;
      commit(SHIPPING_CATEGORIES_FETCHED, {
        shippingCategories,
        total: response.data.total
      });
    },
    /**
     * 商品分類を削除する
     */
    async [REMOVE_SHIPPING_CATEGRY]({ commit }, { shippingCategory }) {
      const errorMsg =
        'データ取得時にエラーがありました。セカイロジ担当者までお問い合わせください。';
      if (!shippingCategory || !shippingCategory._id) {
        throw new Error('商品分類IDが指定されていません。');
      }
      const response = await axios
        .delete(`/shipping_categories/${shippingCategory._id}`)
        .catch((e) => {
          throw e;
        });
      if (response && response.data && response.data.status === 'ng') {
        throw new Error(
          (response.data.errors && response.data.errors.general) || errorMsg
        );
      }
      commit(SHIPPING_CATEGORY_DELETED, {
        shippingCategory
      });
    }
  }
};
