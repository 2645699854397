export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const SHOW_NOTIFY = 'SHOW_NOTIFY';
export const CLEAR_NOTIFY = 'CLEAR_NOTIFY';
export const SAMPLE_FETCHED = 'SAMPLE_FETCHED';
export const SAMPLE_CHECKED = 'SAMPLE_CHECKED';
export const LOADING_START = 'LOADING_START';
export const LOADING_FINISH = 'LOADING_FINISH';
export const AUTHENTICATE_REQUEST = 'AUTHENTICATE_REQUEST';
export const AUTHENTICATE_SUCCESS = 'AUTHENTICATE_SUCCESS';
export const AUTHENTICATE_FAILED = 'AUTHENTICATE_FAILED';
export const USERS_FETCH_SUCCESS = 'USERS_FETCH_SUCCESS';
export const LOGIN_PAGE_SHOW = 'LOGIN_PAGE_SHOW';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const FORGOT_PAGE_SHOW = 'FORGOT_PAGE_SHOW';
export const FORGOT_REQUEST = 'FORGOT_REQUEST';
export const FORGOT_SUCCESS = 'FORGOT_SUCCESS';
export const FORGOT_FAILED = 'FORGOT_FAILED';
export const RESET_PAGE_SHOW = 'RESET_PAGE_SHOW';
export const RESET_REQUEST = 'RESET_REQUEST';
export const RESET_SUCCESS = 'RESET_SUCCESS';
export const RESET_FAILED = 'RESET_FAILED';
export const CHANGE_PASSWORD_PAGE_SHOW = 'CHANGE_PASSWORD_PAGE_SHOW';
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';
export const ITEM_FETCHED = 'ITEM_FETCHED';
export const OWNERS_FETCHED = 'OWNERS_FETCHED';
export const OWNERS_BILLINGS_FETCHED = 'OWNERS_BILLINGS_FETCHED';
export const OWNER_FETCHED = 'OWNER_FETCHED';
export const OWNER_UPDATED = 'OWNER_UPDATED';
export const OWNER_PERMISSION_UPDATED = 'OWNER_PERMISSION_UPDATED';
export const OWNER_INVOICE_UPDATED = 'OWNER_INVOICE_UPDATED';
export const PROGRESS_FETCH_SUCCESS = 'PROGRESS_FETCH_SUCCESS';
export const ITEM_DETAIL_FETCHED = 'ITEM_DETAIL_FETCHED';
export const ITEM_LOGS_FETCHED = 'ITEM_LOGS_FETCHED';
export const MANIFESTS_FETCHED = 'MANIFESTS_FETCHED';
export const MANIFEST_DETAIL_FETCHED = 'MANIFEST_DETAIL_FETCHED';
export const SHIPMENT_FETCHED = 'SHIPMENT_FETCHED';
export const SHIPMENT_DETAIL_FETCHED = 'SHIPMENT_DETAIL_FETCHED';
export const SHIPMENT_EDIT_FETCHED = 'SHIPMENT_EDIT_FETCHED';
export const SHIPMENT_UPDATED = 'SHIPMENT_UPDATED';
export const POLL_SUBMIT_SHIPMENT_FETCHED = 'POLL_SUBMIT_SHIPMENT_FETCHED';
export const RETURN_FETCHED = 'RETURN_FETCHED';
export const RETURN_COMPLETED = 'RETURN_COMPLETED';
export const SHIPPING_ADDRESS_FETCHED = 'SHIPPING_ADDRESS_FETCHED';
export const SHIPPING_ADDRESS_DELETED = 'SHIPPING_ADDRESS_DELETED';
export const SHIPPING_ADDRESS_SAVED = 'SHIPPING_ADDRESS_SAVED';
export const SHIPMENT_CREATED = 'SHIPMENT_CREATED';
export const STOCK_DIFFS_FETCHED = 'STOCK_DIFFS_FETCHED';
export const STOCK_DIFF_CORRECTED = 'STOCK_DIFF_CORRECTED';
export const FETCH_VERSION_SUCCESS = 'FETCH_VERSION_SUCCESS';
export const BUNDLES_FETCHED = 'BUNDLES_FETCHED';
export const ITEM_BUNDLE_CREATED = 'ITEM_BUNDLE_CREATED';
export const ITEM_BUNDLE_DELETED = 'ITEM_BUNDLE_DELETED';
export const ITEM_BUNDLE_DELETE_FAILED = 'ITEM_BUNDLE_DELETE_FAILED';
export const SLIP_TEMPLATE_FETCHED = 'SLIP_TEMPLATE_FETCHED';
export const SLIP_TEMPLATES_FETCHED = 'SLIP_TEMPLATES_FETCHED';
export const SHOW_AGREEMENT_DIALOG = 'SHOW_AGREEMENT_DIALOG';
export const CLOSE_AGREEMENT_DIALOG = 'CLOSE_AGREEMENT_DIALOG';
export const AGREE_SUCCESS = 'AGREE_SUCCESS';
export const FLYERS_FETCHED = 'FLYERS_FETCHED';
export const FEDEX_SLIPS_FETCHED = 'FEDEX_SLIPS_FETCHED';
export const SHIPPING_CATEGORIES_FETCHED = 'SHIPPING_CATEGORIES_FETCHED';
export const SHIPPING_CATEGORY_DELETED = 'SHIPPING_CATEGORY_DELETED';
export const UPDATE_EN_US = 'UPDATE_EN_US';
export const UPDATE_JA_JP = 'UPDATE_JA_JP';
export const UPDATE_KANA = 'UPDATE_KANA';
export const UPDATE_HS_CODE = 'UPDATE_HS_CODE';
export const UPDATE_ERRORS = 'UPDATE_ERRORS';
export const RESET_ERRORS = 'RESET_ERRORS';
export const RESET_SHIPPING_CATEGORY = 'RESET_SHIPPING_CATEGORY';
export const WEBHOOKS_FETCHED = 'WEBHOOKS_FETCHED';
export const WEBHOOK_FETCHED = 'WEBHOOK_FETCHED';
export const WEBHOOK_LOGS_FETCHED = 'WEBHOOK_LOGS_FETCHED';
export const WEBHOOK_LOG_FETCHED = 'WEBHOOK_LOG_FETCHED';
export const API_SETTINGS_FETCHED = 'API_SETTINGS_FETCHED';
export const API_SETTING_FETCHED = 'API_SETTING_FETCHED';
export const API_LOGS_FETCHED = 'API_LOGS_FETCHED';
export const API_LOG_FETCHED = 'API_LOG_FETCHED';
export const TOGGLED_OWNER_UNAVAILABLE = 'TOGGLED_OWNER_UNAVAILABLE';
export const BILLINGS_FETCHED = 'BILLINGS_FETCHED';
export const ACCOUNT_REGISTRATIONS_FETCHED = 'ACCOUNT_REGISTRATIONS_FETCHED';
export const WHITELIST_IPS_FETCHED = 'WHITELIST_IPS_FETCHED';
