const _ = require('lodash');

/**
 * vee-validate とサーバー側のバリデーションの結果をマージしてエラーメッセージを表示する
 * @param {ErrorBag} clientErrors
 * @param {?Object} serverErrors
 * @param {string} field
 * @returns {string|FieldError[]}
 */
export function mergeErrors(clientErrors, serverErrors, field) {
  return (
    _.get(serverErrors, `${field}.msg`) ||
    _.get(serverErrors, `${field}`) ||
    clientErrors.collect(field)
  );
}

/**
 * 分子が分母に対して何 % かを最大 100 % で取得する
 * @param {number} numerator
 * @param {number} denominator
 * @returns {number}
 */
export function getPercentage(numerator, denominator) {
  return Math.min(100, (numerator / denominator) * 100);
}

/**
 * % に応じた色を取得する
 * @param {number} percentage
 * @returns {string}
 */
export function getPercentageColor(percentage) {
  if (percentage === 0) {
    return 'grey';
  } else {
    return ['error', 'warning', 'success'][Math.floor(percentage / 40)];
  }
}

export function shipmentFormFieldNameMap() {
  return {
    name: '宛名',
    postalCode: '郵便番号',
    countryCode: '国名',
    state: '都道府県・州',
    city: '市区町村',
    address1: '住所1',
    address2: '住所2',
    phone: '電話番号',
    email: 'メールアドレス',
    ownerOrderNo: '注文管理番号',
    shippingMethod: '出荷方法',
    deliveryDate: 'お届け希望日',
    flyer: 'フライヤー',
    slipName: '納品書',
    giftWrapping: 'ギフトラッピング',
    duty: '関税',
    priority: '優先出荷',
    timeout: 'タイムアウト',
    general: ''
  };
}
