<template>
  <v-container px-0>
    <MainHeader title="WEB APIログ" back-to="/settings/api"></MainHeader>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <h3 class="font-weight-bold subtitle-1 text-left mt-4">
              {{ $store.state.apiSettings.apiSetting.name }}
            </h3>
          </v-card-title>
          <v-card-text>
            <v-form @submit.prevent>
              <v-container>
                <v-row align="center">
                  <v-col class="menu-item text-center" cols="3">
                    <div>APIバージョン</div>
                    <v-chip color="green" label outlined>{{
                      $store.state.apiSettings.apiSetting.api_version
                    }}</v-chip>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-card height="100%">
          <v-card-text>
            <TableAndPaging
              ref="search"
              :headers="header"
              :items="$store.state.apiLogs.apiLogs"
              :total="$store.state.apiLogs.total"
              :query-params="queryParams"
              :params="params"
              :action="`apiLogs/${FETCH_API_LOGS}`"
              :keepPage="true"
            >
              <template #row="props">
                <td @click="fetchApiLogRawRequestAndResponse(props.item._id)">
                  <v-chip
                    v-if="
                      props.item.response && props.item.response.status === 200
                    "
                    color="green"
                    label
                    outlined
                  >
                    成功
                  </v-chip>
                  <v-chip v-else color="red" label outlined>失敗</v-chip>
                </td>
                <td @click="fetchApiLogRawRequestAndResponse(props.item._id)">
                  <v-chip color="blue" label outlined class="mr-2" small>
                    {{ props.item.request.url }}
                  </v-chip>
                </td>
                <td @click="fetchApiLogRawRequestAndResponse(props.item._id)">
                  {{ formatDate({ date: props.item.responsed_at }) }}
                </td>
              </template>
            </TableAndPaging>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card>
          <v-sheet class="code black--text pa-4">
            <v-row>
              <v-col class="pt-0 pb-0">
                <v-subheader>ID</v-subheader>
              </v-col>
              <v-col class="pt-0 pb-0"
                >{{ $store.state.apiLogs.apiLog._id }}
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-0 pb-0">
                <v-subheader>ステータス</v-subheader>
              </v-col>
              <v-col class="pt-0 pb-0">
                <v-chip
                  v-if="
                    $store.state.apiLogs.apiLog.response &&
                    $store.state.apiLogs.apiLog.response.status
                  "
                  color="green"
                  label
                  outlined
                >
                  {{ $store.state.apiLogs.apiLog.response.status }}
                </v-chip>
                <v-chip
                  v-else-if="$store.state.apiLogs.apiLog.response"
                  color="red"
                  label
                  outlined
                  >失敗</v-chip
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-0 pb-0">
                <v-subheader>URL</v-subheader>
              </v-col>
              <v-col class="pt-0 pb-0">
                <v-chip
                  v-if="
                    $store.state.apiLogs.apiLog.request &&
                    $store.state.apiLogs.apiLog.request.url
                  "
                  color="blue"
                  label
                  outlined
                  class="mr-2"
                  small
                >
                  {{ $store.state.apiLogs.apiLog.request.url }}
                </v-chip>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-0 pb-0"> <v-subheader>日時</v-subheader> </v-col>
              <v-col class="pt-0 pb-0"
                >{{
                  formatDate({
                    date: $store.state.apiLogs.apiLog.responsed_at
                  })
                }}
              </v-col>
            </v-row>
          </v-sheet>
          <v-sheet class="code black--text pa-4">
            <v-subheader>Response</v-subheader>
            <v-row
              no-gutters
              v-for="(line, index) in $store.state.apiLogs.apiLog.rawResponse"
              :key="`line-${index}`"
            >
              <v-col cols="1">
                <span class="grey--text mr-3 code-row" />
              </v-col>
              <v-col cols="11">
                <span>{{ line }}</span>
              </v-col>
            </v-row>
          </v-sheet>
          <v-sheet class="code black--text pa-4">
            <v-subheader>Request</v-subheader>
            <v-row
              no-gutters
              v-for="(line, index) in $store.state.apiLogs.apiLog.rawRequest"
              :key="`line-${index}`"
            >
              <v-col cols="1">
                <span class="grey--text mr-3 code-row" />
              </v-col>
              <v-col>
                <span>{{ line }}</span>
              </v-col>
            </v-row>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.menu-item + .menu-item {
  border-left: 1px solid #9e9e9e;
}
.hidden-text {
  color: transparent;
  text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
}
.code {
  border-radius: 12px;
  overflow-x: auto;
  letter-spacing: 0.5px;
  word-spacing: 1px;
  font-family: monospace;
  white-space: pre;
  font-weight: 300;
  font-size: 15px;
  counter-reset: linenumber 0;
}

.code-row {
  letter-spacing: 0.8px;
}
.code-row:before {
  counter-increment: linenumber 1;
  content: counter(linenumber);
}
</style>

<script>
import TableAndPaging from '@/components/TableAndPaging';
import MainHeader from '@/components/layout/MainHeader';
import {
  FETCH_API_TOKEN,
  FETCH_API_LOGS,
  FETCH_API_LOG,
  START_LOADING,
  FINISH_LOADING
} from '@/store/action-types';
import { formatDate } from '@/util/formatter';

export default {
  components: {
    TableAndPaging,
    MainHeader
  },
  data() {
    const apiSettingId = this.$route.params.apiSettingId;
    return {
      FETCH_API_LOGS,
      params: {
        apiSettingId: apiSettingId
      },
      logId: '',
      page: '',
      limit: 10,
      header: ['ステータス', 'URL', '日時']
    };
  },
  computed: {
    queryParams: function () {
      return {
        log_id: this.logId,
        page: this.page,
        limit: this.limit
      };
    }
  },
  methods: {
    async fetchApiLogRawRequestAndResponse(logId) {
      await this.$store.dispatch(`app/${START_LOADING}`, new Date());
      this.logId = logId;
      try {
        await this.$store.dispatch(`apiLogs/${FETCH_API_LOG}`, {
          logId
        });
      } catch (err) {
        await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
        await this.$store.dispatch('notify/showErrorNotify', err.message);
        throw err;
      }
      await this.$store.dispatch(`app/${FINISH_LOADING}`, new Date());
    },
    formatDate
  },
  async beforeMount() {
    await this.$store.dispatch(`apiSettings/${FETCH_API_TOKEN}`, {
      apiSettingId: this.params.apiSettingId
    });
    await this.$store.dispatch(`apiLogs/${FETCH_API_LOG}`, {
      logId: this.logId
    });
  },
  mounted: function () {
    const params = this.$route.query;
    this.logId = params.log_id || '';
    this.page = params.page || 1;
    this.limit = params.limit || 10;
  }
};
</script>
