import Vue from 'vue';
import vuetify from './plugins/vuetify';
import './plugins/vee-validate';
import './plugins/vue-clipboard2';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import 'babel-polyfill';
import zendesk from './zendesk';

Vue.config.productionTip = false;

zendesk.setup();

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
  mounted: function () {
    const vue = this;
    zendesk
      .initialize(vue)
      .then(() => {})
      .catch(() => {});
  }
}).$mount('#app');
