'use strict';

const SECOND = 1 * 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const WEEK = 7 * DAY;
const JST = 'Asia/Tokyo';

module.exports = {
  SECOND: SECOND,
  MINUTE: MINUTE,
  HOUR: HOUR,
  DAY: DAY,
  WEEK: WEEK,
  TZ: {
    JST
  },
  /**
   * 指定された基準日からdaysで指定した日付後の平日を返す
   * @param {Date} date 基準日
   * @param {number} days 何日足すか
   * @returns {Moment} 遅らせた日付
   */
  addWeekdays(date, days) {
    const moment = require('moment-timezone');
    const momentDate = moment(date);
    while (days > 0) {
      momentDate.add(1, 'days');
      // 土日の場合はdaysを減らさずにもう一度回す
      const SATURDAY = 6;
      const SUNDAY = 7;
      const isoWeekDay = momentDate.isoWeekday();
      if (isoWeekDay !== SATURDAY && isoWeekDay !== SUNDAY) {
        days -= 1;
      }
    }
    return momentDate;
  },
  getYearMonth() {
    const moment = require('moment-timezone');
    const currentYear = moment().format('YYYY');
    const currentMonth = moment().format('MM');

    const YEARS = [...Array(currentYear - 2022).keys()].map((i) => {
      const year = currentYear - i;
      return year.toString();
    });
    const MONTHS = [...Array(12).keys()].map((i) => {
      const padded = (i + 1).toString().padStart(2, '0');
      return padded;
    });

    const YEAR_MONTH = [];
    // YEARSが今年,昨年...と入っているので，若い方からarrayにpushするためにreverseする
    for (const year of YEARS.reverse()) {
      for (const month of MONTHS) {
        YEAR_MONTH.push({
          text: `${year}年${month}月`,
          value: `${year}${month}`
        });

        // 今年は01月から現在の月までをリストに追加して終わる
        if (year === currentYear && month === currentMonth) {
          break;
        }
      }
    }
    return YEAR_MONTH.reverse();
  }
};
