import Vue from 'vue';
import VeeValidate from 'vee-validate';
import ja from 'vee-validate/dist/locale/ja';

VeeValidate.Validator.localize('ja', ja);
VeeValidate.Validator.extend('trimmed', {
  getMessage: (field) =>
    `${field}の先頭と末尾は空白以外の文字を入力してください`,
  validate: (value) => /^\S.*/.test(value) && /.*\S$/.test(value)
});
Vue.use(VeeValidate, { locale: ja });
