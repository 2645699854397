import Vue from 'vue';
import Vuex from 'vuex';
import agreement from '@/store/modules/agreement';
import auth from '@/store/modules/auth';
import app from '@/store/modules/app';
import user from '@/store/modules/user';
import users from '@/store/modules/users';
import owners from '@/store/modules/owners';
import notify from '@/store/modules/notify';
import samples from '@/store/modules/samples';
import items from '@/store/modules/items';
import item from '@/store/modules/item';
import itemlogs from '@/store/modules/itemlogs';
import progress from '@/store/modules/progress';
import owner from '@/store/modules/owner';
import manifests from '@/store/modules/manifests';
import manifest from '@/store/modules/manifest';
import shipments from '@/store/modules/shipments';
import shipment from '@/store/modules/shipment';
import returns from '@/store/modules/returns';
import addUser from '@/store/modules/add_user';
import shippingAddress from '@/store/modules/shipping_address';
import stockDiffs from '@/store/modules/stock_diffs';
import version from '@/store/modules/version';
import bundles from '@/store/modules/bundles';
import slipTemplates from '@/store/modules/slip_templates';
import flyers from '@/store/modules/flyers';
import flyer from '@/store/modules/flyer';
import warehouse from '@/store/modules/warehouse';
import shippingCategories from '@/store/modules/shipping_categories';
import shippingCategory from '@/store/modules/shipping_category';
import webhookSettings from '@/store/modules/webhook_settings';
import webhookLogs from '@/store/modules/webhook_logs';
import apiSettings from '@/store/modules/api_settings';
import apiLogs from '@/store/modules/api_logs';
import billings from '@/store/modules/billings';
import accountRegistrations from '@/store/modules/account_registrations';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    agreement,
    auth,
    app,
    user,
    users,
    owners,
    notify,
    samples,
    items,
    item,
    itemlogs,
    progress,
    owner,
    manifests,
    manifest,
    shipments,
    shipment,
    returns,
    addUser,
    shippingAddress,
    version,
    bundles,
    stockDiffs,
    slipTemplates,
    flyers,
    flyer,
    warehouse,
    shippingCategories,
    shippingCategory,
    webhookSettings,
    webhookLogs,
    apiSettings,
    apiLogs,
    billings,
    accountRegistrations
  }
});
